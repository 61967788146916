<template>
    <div class="content">
        <div class="face-box">
            <el-upload
              class="avatar-uploader"
              action="''"
              drag
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleChangeUpload">
              <el-avatar :size="100" :src="form.logo || require('@/assets/images/nohead.png')" class="face-box-logo">
              </el-avatar>
              <div class="edit-btn el-icon-edit-outline"></div>
            </el-upload>
        </div>
        <div class="info-item">
            <div class="info-item-label">姓名</div>
            <div class="info-item-txt">
                <input type="text" v-model="form.name" placeholder="请输入姓名">
            </div>
        </div>
        <div class="info-item">
            <div class="info-item-label">身份证</div>
            <div class="info-item-txt">
                <input type="text" v-model="form.sfzh" maxlength="18" placeholder="请输入身份证号">
            </div>
        </div>
        <div class="info-item">
            <div class="info-item-label">身高</div>
            <div class="info-item-txt">
                <input type="number" v-model="form.height" maxlength="3" placeholder="请输入身高" @input="filterNum($event)"><span class="item-unit">CM</span>
            </div>
        </div>
        <div class="info-item">
            <div class="info-item-label">体重</div>
            <div class="info-item-txt">
                <input type="number" v-model="form.weight" maxlength="3" placeholder="请输入体重" @input="filterNum($event)"><span class="item-unit">KG</span>
            </div>
        </div>
        <div class="slice-line"></div>
        <div class="info-item">
            <div class="info-item-label">手机号码</div>
            <div class="info-item-txt">
                <input class="info-item-send-input" v-model="form.mtel" type="number" maxlength="11" placeholder="请输入手机号" @input="filterNum($event)">
            </div>
        </div>
        <div class="info-item">
            <div class="info-item-label">验证码</div>
            <div class="info-item-txt info-item-sendcode">
                <input class="info-item-send-input" type="number" v-model="form.code" maxlength="4" placeholder="请输入验证码" @input="filterNum($event)">
                <el-button type="primary" class="item-unit-send" size="small" @click="sendCode()" :disabled="countdown > 0" round>{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}</el-button>
            </div>
        </div>
        <el-button type="primary" class="submit-btn" size="" round>注册队员</el-button>
        <MyCropperMobile :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog"></MyCropperMobile>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isPreview: false,
                dialogVisible: false,
                cropperImage: "",
                previewImg: '',
                sendTxt: "发送验证码",
                countdown: 0,
                timer: null,
                interval: 60,
                form: {
                    logo: "",
                    name: "",
                    height: "",
                    weight: "",
                    sfzh: "",
                    mtel: "",
                    code: ""
                }
            }
        },
        created() {
            document.title = '注册球员';  
        },
        methods: {
            handleLogoUpdate(croppedImageUrl) {
                // let blobImage = this.base64ToBlob(croppedImageUrl);
                // let formData = new FormData();
                // formData.append('file', blobImage, +new Date+'.jpg');
                // this.$api.post('api/Team/playerupload', formData).then(response => {
                //     this.form.logo = response.data.data;
                // })
                this.form.logo = croppedImageUrl;
            },
            closeDialog() {
                this.dialogVisible = false
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.dialogVisible = true
                })
            },
            filterNum(event){
                event.target.value = event.target.value.replace(/[^\d-]/g, '');
                if (event.target.value === '-' && this.integerValue !== 0) {
                    event.target.value = '';
                }
            },
            sendCode() {
                if (this.countdown === 0) {
                    this.countdown = this.interval;
                    this.timer = setInterval(() => {
                        if (this.countdown > 0) {
                            this.countdown--;
                        } else {
                            this.form.code = 3699
                            clearInterval(this.timer);
                        }
                    }, 1000);
                }
            }
        }
    }
</script>
<style scoped>
    .content {
        margin-top: 10px;
        background-color: #fff;
        min-height: calc(100vh - 10px);
    }
    .face-box {
        height: 200px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    .face-box-logo {
        position: relative;
    }
    
    .edit-btn {
        background-color: #079ffd;
        color: #fff;
        padding: 5px;
        border-radius: 15px;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        right: 3px;
        bottom: 3px;
        position: absolute;
    }
    
    ::v-deep .el-upload {
        border: 0px;
    }
    
    ::v-deep .el-upload-dragger {
        border: 0px;
    }
    
    .info-item {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        border-bottom: 1px solid #f7f7f7;
    }
    
    .info-item-label {
        margin-left: 30px;
        width: 100px;
    }
    
    .info-item-txt input{
        border: 0px;
        outline: none;
        text-align: right;
        margin-right: 50px;
        font-size: 18px;
        
    }
    
    .info-item-send-input {
        border: 0px;
        outline: none;
        text-align: left!important;
        margin-right: 120px!important;
        font-size: 18px;
        width: 120px;
    }
    
    .info-item-txt {
        position: relative; 
    }
    
    .item-unit {
        position: absolute;
        right: 18px;
        font-size: 14px;
        color: #999;
    }
    
    .item-unit-send {
        position: absolute;
        right: 18px;
        width: 90px;
        text-align: center;
    }
    
    .slice-line {
        background-color: #f7f7f7;
        height: 8px;
        width: 100vw;
    }
    
    .info-item-sendcode {
        display: flex;
        align-items: center;
        
    }
    
    .submit-btn {
        margin-top: 80px;
        width: 80%;
        height: 50px;
        border-radius: 50px;
        font-size: 18px;
    }

</style>