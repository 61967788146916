import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
    loginTimestamp: null,
	currentPage: 1,
  },
  mutations: {
    setLogin(state, timestamp) {
      state.isLoggedIn = true;
      state.loginTimestamp = timestamp;
    },
    setLogout(state) {
      state.isLoggedIn = false;
      state.loginTimestamp = null;
    },
	setCurrentPage(state, page) {
	    state.currentPage = page;
	},
  },
  actions: {
    login({ commit }, { timestamp }) {
      commit('setLogin', timestamp);
    },
    logout({ commit }) {
      commit('setLogout');
    }
  }
});

export default store;
