import { render, staticRenderFns } from "./waterMarkSetting.vue?vue&type=template&id=44ea8920&scoped=true"
import script from "./waterMarkSetting.vue?vue&type=script&lang=js"
export * from "./waterMarkSetting.vue?vue&type=script&lang=js"
import style0 from "./waterMarkSetting.vue?vue&type=style&index=0&id=44ea8920&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ea8920",
  null
  
)

export default component.exports