<template>
  <div id="app">
	<router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
    @import url("assets/css/iconfont.css");
	body {
		margin: 0;
		/* background-color: #f1ecf3; */
	}
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }
    .router-link-active,
    .router-link-exact-active,
    a {
      text-decoration: none; /* 去除下划线 */
      color: inherit; /* 继承父元素的字体颜色 */
    }
    
    .imgs-item .el-upload-dragger {
      width: 150px!important;
      height: 110px!important;
    }
    
    .imgs-item .avatar-uploader .el-upload {
        width: 150px!important;
        height: 110px!important;
        border: 0px
    }
	
	.cropper-view-box {
		display: flex!important;
	}
</style>
