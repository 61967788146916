<template>
  <el-collapse class="side-menu-container" v-model="activeNames" @change="handleChange">
    <router-link to="/" exact class="home-item">
        <el-collapse-item title="首页" name="0" class="hide-arrow">
                <template #title>
                  <i class="iconfont icon-shouye"></i>首页
				  <i class="iconfont icon-quan" style="position: absolute;right: 10px;font-size: 16px;font-weight: bold;"></i>
                </template>
        </el-collapse-item>
    </router-link>
    
    <el-collapse-item title="队伍管理" name="1">
      <template #title>
        <i class="iconfont icon-tongshiqunzu"></i>队伍管理
      </template>
      <router-link to="/createteam">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-zudui"></i>
            创建球队
          </div>
      </router-link>
      <router-link to="/teammgmt">
          <div class="submenu-item">
            <i class="iconfont icon-yonghu"></i>
            管理球队
          </div>
      </router-link>
      
	  <router-link to="/refmgmt">
	      <div class="submenu-item">
	        <i class="iconfont icon-laoshi"></i>
	        裁判管理
	      </div>
	  </router-link>
      
    </el-collapse-item>
    <el-collapse-item title="比赛管理" name="2">
      <template #title>
        <i class="iconfont icon-bisai"></i>比赛管理
      </template>
      <router-link to="/points">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-zuzhiguanli2"></i>
            积分成绩
          </div>
      </router-link>
      <router-link to="/memberauth">
          <div class="submenu-item">
            <i class="iconfont icon-gerenzhuye"></i>
            实名认证
          </div>
      </router-link>
      <router-link to="/register">
          <div class="submenu-item">
            <i class="iconfont icon-gerenzhuye"></i>
            报名管理
          </div>
      </router-link>
      <!-- <router-link to="/schedule">
          <div class="submenu-item">
            <i class="iconfont icon-keshihuajianmo"></i>
            设计赛程表
          </div>
      </router-link> -->
    </el-collapse-item>
	<el-collapse-item title="设计赛程表" name="81">
	  <template #title>
	    <i class="iconfont icon-keshihuajianmo"></i>设计赛程表
	  </template>
	    <router-link to="/schedule">
	      <div class="submenu-item first-submenu">
	        <i class="iconfont icon-keshihuajianmo"></i>制作赛程表
	      </div>
	    </router-link>
	    <router-link to="/designmatch">
	      <div class="submenu-item">
	        <i class="iconfont icon-keshihuajianmo"></i>设计对阵图
	      </div>
	    </router-link>
	</el-collapse-item>
    <el-collapse-item title="竞猜管理" name="8">
      <template #title>
        <i class="iconfont icon-lingganxuqiu"></i>竞猜管理
      </template>
        <router-link to="/newgame">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-xiangmushuchu"></i>创建比赛
          </div>
        </router-link>
        <router-link to="/guess">
          <div class="submenu-item">
            <i class="iconfont icon-bangzhuzhongxin3"></i>竞猜管理
          </div>
        </router-link>
        <div class="submenu-item">
            <i class="iconfont icon-gongzuotai"></i>竞猜结果
        </div>
    </el-collapse-item>
    <el-collapse-item title="发布通知" name="4">
      <template #title>
        <i class="iconfont icon-youjianyouxiang"></i>发布通知
      </template>
      <router-link to="/pubnotice">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-xiaoxizhongxin"></i>全员通知
          </div>
      </router-link>
      <router-link to="/listnotice">
          <div class="submenu-item">
            <i class="iconfont icon-zuoyeguanli"></i>通知管理
          </div>
        </router-link>
    </el-collapse-item>
	
	<el-collapse-item title="图片播报" name="12">
	  <template #title>
	    <i class="iconfont icon-haibao" style="font-size: 22px;margin-left: 10px;"></i>图片播报
	  </template>
	  <router-link to="/articlelive">
	      <div class="submenu-item first-submenu">
	        <i class="iconfont icon-zhengshu-copy"></i>创建播报
	      </div>
	  </router-link>
	  <router-link to="/articlemgmt">
	      <div class="submenu-item">
	        <i class="iconfont icon-zuoyeguanli"></i>管理播报
	      </div>
	    </router-link>
	</el-collapse-item>
    
    <el-collapse-item title="人气投票" name="3">
      <template #title>
        <i class="iconfont icon-wenjuantoupiao"></i>人气投票
      </template>
        <router-link to="/createvote">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-Diff"></i>创建投票
          </div>
        </router-link>
      <div class="submenu-item">
        <i class="iconfont icon-lishibisai"></i>历史记录
      </div>
    </el-collapse-item>
	
	<el-collapse-item title="制作证书" name="7">
		<template #title>
			<i class="iconfont icon-zhengshuzizhi"></i>制作证书
		</template>
		<router-link to="/createcert">
			<div class="submenu-item first-submenu">
				<i class="iconfont icon-mobansheji"></i>制作模板
			</div>
		</router-link>
		<router-link to="/printcert">
			<div class="submenu-item">
				<i class="iconfont icon-zhengshu-copy"></i>打印证书
			</div>
		</router-link>
	</el-collapse-item>
    
    <el-collapse-item title="直播管理" name="5">
      <template #title>
        <i class="iconfont icon-shipinhuiyi"></i>直播管理
      </template>
        <router-link to="/camera">
          <div class="submenu-item first-submenu">
            <i class="iconfont icon-yanshi"></i>比赛地址
          </div>
        </router-link>
		<router-link to="/watermark">
		  <div class="submenu-item">
		    <i class="iconfont icon-tupian"></i>水印管理
		  </div>
		</router-link>
        <router-link to="/activelive">
          <div class="submenu-item">
            <i class="iconfont icon-gengxinrizhi"></i>活动直播
          </div>
        </router-link>  
		<!-- <router-link to="/articlelive">
		  <div class="submenu-item">
		    <i class="iconfont icon-zhengshu-copy"></i>图文直播
		  </div>
		</router-link> -->
        
    </el-collapse-item>
    
	
	
	<el-collapse-item title="素材管理" name="6">
		<template #title>
			<i class="iconfont icon-zhibolive"></i>素材管理
		</template>
		<router-link to="/video">
			<div class="submenu-item first-submenu">
				<i class="iconfont icon-xunlianrenwu"></i>视频管理
			</div>
		</router-link> 
		<router-link to="/photo">
			<div class="submenu-item">
				<i class="iconfont icon-tupian"></i>图片管理
			</div>
		</router-link>
		<router-link to="/file">
			<div class="submenu-item">
				<i class="iconfont icon-zuoyeguanli"></i>文件管理
			</div>
		</router-link>
	  
	</el-collapse-item>

  </el-collapse>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'], // 默认展开的菜单项
    };
  },
  methods: {
    handleChange(activeNames) {
      console.log(activeNames);
    },
  },
};
</script>
<style scoped>
    /* 设置整个折叠面板的基调 */
    .el-collapse {
      border: none; /* 移除默认边框 */
      width: 200px;
    }
    
    /* 设置一级菜单的样式 */
    .el-collapse-item__header {
      color: #333; /* 标题颜色 */
      padding: 10px 20px; /* 内边距 */
      font-size: 18px!important; /* 字体大小 */
      border-bottom: 1px solid #ebeef5; /* 下边框线 */
      cursor: pointer; /* 鼠标样式 */
    }
    
    /* 鼠标悬浮在一级菜单上的效果 */
    .el-collapse-item__header:hover {
      background-color: #f6f6f6; /* 鼠标悬浮背景色 */
    }
    
    /* 一级菜单标题旁的图标 */
    .el-collapse-item__header .el-collapse-item__arrow {
      font-size: 12px; /* 图标字体大小 */
      vertical-align: middle; /* 垂直居中 */
      margin-right: 5px; /* 与标题的间隔 */
    }
    
    /* 二级菜单的背景色和内边距 */
    .el-collapse-item__wrap {
      
    }
    
    /* 二级菜单项的样式 */
    .el-collapse-item__wrap .submenu-item {
      padding: 8px 20px; /* 内边距 */
      cursor: pointer; /* 鼠标样式 */
      font-size: 16px;
      /* border-top: 1px solid #ebeef5; */
      height: 40px;
      line-height: 40px;
      text-align: left;
      text-indent: 1em;
    }
    
    /* 鼠标悬浮在二级菜单项上的效果 */
    .el-collapse-item__wrap .submenu-item:hover {
      background-color: #f4f4f4; /* 鼠标悬浮背景色 */
      color: #409EFF
    }
    
    /* 设置二级菜单项内的图标 */
    .submenu-item .iconfont {
      margin-right: 10px; /* 图标和文字之间的间隔 */
      font-size: 20px;
    }
    
    .iconfont {
        font-size: 28px;
        margin: 0px 7px
    }
    
    .el-collapse-item__content {
        padding-bottom: 0px!important;
    }
    
    .side-menu-container {
      max-height: 100vh; /* 设置最大高度为视口的高度 */
      overflow-y: auto; /* 超出部分允许垂直滚动 */
      width: 250px; /* 侧边菜单的宽度，根据需要调整 */
      position: fixed; /* 固定位置，确保滚动只影响侧边菜单 */
      left: 0; /* 侧边菜单的水平位置，根据需要调整 */
      top: 100px; /* 侧边菜单的垂直位置，根据需要调整 */
      bottom: 0; /* 确保侧边菜单从顶部延伸到底部 */
      /* background-color: #fff; */
      z-index: 1000; /* 确保侧边菜单在其他内容之上 */
    }
    
    /* 为所有滚动条设置样式 */
    ::-webkit-scrollbar {
      width: 5px; /* 滚动条宽度 */
      height: 5px;
    }
    
    /* 滚动条轨道（track）的样式 */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* 轨道颜色 */
      border-radius: 10px; /* 轨道圆角 */
    }
    
    /* 滚动条滑块（thumb）的样式 */
    ::-webkit-scrollbar-thumb {
      background: #a6a6a6; /* 滑块颜色 */
      border-radius: 10px; /* 滑块圆角 */
    }
    
    /* 当鼠标悬停在滑块上时的样式 */
    ::-webkit-scrollbar-thumb:hover {
      background: #07a0ff; /* 滑块悬停颜色 */
    }
    
    /* 容器和菜单项的基础样式 */
    .side-menu-container .el-collapse-item {
      
      
    }
    
    /* 为一级菜单项添加图标和文本样式 */
    .side-menu-container .el-collapse-item .iconfont {
        margin-right: 8px; /* 图标和文本之间的间距 */
        
    }
    
    /* 二级菜单项样式 */
    .submenu-item {
      display: flex;
      align-items: center; /* 图标和文本垂直居中 */
      padding: 10px 15px; /* 根据需要调整填充 */
      border-left: 3px solid transparent; /* 添加左侧边框（透明），准备激活时使用 */
      transition: all 0.3s ease; /* 平滑过渡效果 */
      
    }
    
    .el-collapse-item .first-submenu {
        box-shadow: inset 0 10px 15px -3px rgba(202, 184, 183, 0.2),
                    inset 0 4px 10px 1px rgba(202, 184, 183, 0.2); /* 添加阴影效果 */
    }
    
    /* 二级菜单项悬浮和激活状态 */
    .side-menu-container .el-collapse-item .submenu-item:hover,
    .side-menu-container .el-collapse-item .submenu-item.active {
      background-color: #cce4ff; /* 悬浮或激活时的背景色 */
      border-left-color: #409EFF; /* 激活状态的左边框颜色 */
      
    }
    
    /* 图标样式 */
    .iconfont {
      min-width: 20px; /* 确保图标宽度一致 */
      text-align: center; /* 图标居中对齐 */
    }
    
    /* 激活状态的样式 */
    .router-link-active {
      background-color: #d9eaff; /* 激活状态的背景色 */
      border-left: 4px solid #409EFF; /* 激活状态的左边框 */
      color: #409EFF; /* 激活状态的文本颜色 */
      font-weight: bold; /* 增加文字粗细，强调激活项 */
      transition: background-color 0.1s ease, color 0.1s ease; /* 平滑的过渡效果 */
      display: block;
    }
    
    .no-active {
        border-left: 0px;
    }
    
    /* 可选：被点击（:active）的样式 */
    .submenu-item:active {
      background-color: #cce4ff; /* 点击时的更亮的背景色，提供点击反馈 */
      color: #3071E9; /* 点击时文本颜色，稍微深一点以形成对比 */
      transition: background-color 0.1s ease, color 0.1s ease; /* 平滑的过渡效果 */
    }
    
   .hide-arrow .el-collapse-item__header .el-collapse-item__arrow {
      display: none;
    }
	
	.router-link-active /deep/ .el-collapse-item__header {
	  background-color: #cce4ff!important;
	  color: #3071E9!important;
	}
	

    
    
</style>
