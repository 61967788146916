<template>
  <el-form ref="loginForm" @submit.native.prevent="login" class="login-form">
    <el-form-item>
      <el-input v-model="loginForm.username" placeholder="用户名" @keydown.enter="login"></el-input>
    </el-form-item>
    <el-form-item>
      <el-input type="password" v-model="loginForm.password" placeholder="密码" @keydown.enter="login"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="login">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      let formData = new FormData();
      formData.append('username', this.loginForm.username);
      formData.append('password', this.loginForm.password);
      this.$api.post('playv2/login', formData).then(response => {
        if (response.data.code == 1) {
            this.$message({
                type: 'success',
                message: '登录成功'
            });
            localStorage.setItem('userinfo', JSON.stringify(response.data.data));
            this.$store.dispatch('setLogin', { username: this.loginForm.username, timestamp: new Date().getTime() });
            this.$router.push({ path: '/' });
        } else {
            this.$message({
                type: 'error',
                message: response.data.data
            });
        }
        
        
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
      
    }
  }
};
</script>

<style scoped>
.login-form {
  width: 300px;
  margin: 100px auto;
}
</style>
