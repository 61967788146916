<template>
  <div>
    <canvas ref="qrcodeCanvas" style="display: none;"></canvas>
	<img ref="qrcodeImage" alt="QR Code">
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
	name: 'QrCodeGenerator',
	props: {
		url: {
			type: String,
			required: true
		}
	},
	watch: {
      url: 'generateQRCode'
    },
  mounted() {
    this.generateQRCode()
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(canvas, this.url, (error) => {
        if (error) console.error(error)
        console.log('QR code generated successfully!')
		const imageURL = canvas.toDataURL('image/png')
		const img = this.$refs.qrcodeImage
		img.src = imageURL

		// 使图片可见
		img.style.display = 'block'
      })
    }
  }
}
</script>

<style scoped>
canvas {
  display: block;
  margin: auto;
}

img {
	width: 100%;
}
</style>
