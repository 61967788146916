<template>
  <el-row :gutter="5" class="photo-grid" v-if="mode == 'grid'">
    <el-col :span="8" v-for="(photo, index) in sortedPhotos" :key="index" class="photo-item">
		<div class="photo-container">
			<el-image :src="photo.src" :alt="photo.title" class="photo-image" :class="{'loaded':photo.load}" fit="cover" :preview-src-list="sortedPreList"></el-image>
		</div>
    </el-col>
  </el-row>
  
  <el-row :gutter="5" class="photo-fall" v-else-if="mode == 'fall'">
        <el-col :span="12" class="photo-column" v-for="(column, columnIndex) in columns" :key="columnIndex">
			<div class="photo-fall-item" v-for="(photo, photoIndex) in column" :key="photoIndex">
				<el-image :src="photo.src" :alt="photo.title" class="fall-photo-image" :class="{'loaded':photo.load}" fit="cover" :preview-src-list="sortedPreList"></el-image>
			</div>
        </el-col>
    </el-row>
	
	<el-timeline v-else>
	      <el-timeline-item
	        v-for="(hours, date) in timePhotos"
	        :key="date"
	        :timestamp="date"
	        placement="top"
			style="text-align: left;"
	      >
	        <div v-for="(photos, hour) in hours" :key="hour">
	            <div slot="header" class="time-header">{{ hour }}:00 - {{ hour }}:59</div>
	            <div class="photos">
					<el-image
	                    v-for="photo in photos"
	                    :key="photo.src"
	                    :src="photo.src"
	                    :alt="photo.title"
						fit="cover"
						 :class="{'loaded':photo.load}"
						:preview-src-list="sortedPreList"
						class="time-photo-item"
	                ></el-image>
	            </div>
	        </div>
	      </el-timeline-item>
	</el-timeline>
</template>

<script>
export default {
	props: {
	    mode: {
	      type: String,
	      required: true
	    },
		sort: {
		  type: String,
		  required: true
		}
	},
	watch: {
	    mode() {
	      // 这里可以放置响应逻辑
			this.photos.forEach(photo => {
	            photo.load = false;
	        });
			this.photos.forEach((photo, index) => {
			  const image = new Image();
			  image.src = photo.src;
			  
			  image.onload = () => this.onImageLoad(index);
			});
	    }
	},
	data() {
	  return {
		photos: [
			  {
				  title: '1',
				  src: require('@/assets/images/img/1.jpeg'),
				  load: false,
				  time: "2024-07-24 06:00:00"
			  },
			  {
				  title: '2',
				  src: require('@/assets/images/img/2.jpg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '3',
				  src: require('@/assets/images/img/3.jpeg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '4',
				  src: require('@/assets/images/img/4.jpg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '5',
				  src: require('@/assets/images/img/5.jpg'),
				  load: false,
				  time: "2024-07-24 06:35:00"
			  },
			  {
				  title: '6',
				  src: require('@/assets/images/img/6.jpeg'),
				  load: false,
				  time: "2024-07-25 08:35:00"
			  },
			  {
				  title: '7',
				  src: require('@/assets/images/img/7.jpeg'),
				  load: false,
				  time: "2024-07-25 09:35:00"
			  },
			  {
			  	  title: '8',
			  	  src: require('@/assets/images/img/8.jpeg'),
			  	  load: false,
				  time: "2024-07-26 09:35:00"
			  },
			  {
			  		title: '9',
			  		src: require('@/assets/images/img/9.jpeg'),
			  		load: false,
					time: "2024-07-26 10:35:00"
			  }
		],
		preList: [
			require('@/assets/images/img/1.jpeg'),
			require('@/assets/images/img/2.jpg'),
			require('@/assets/images/img/3.jpeg'),
			require('@/assets/images/img/4.jpg'),
			require('@/assets/images/img/5.jpg'),
			require('@/assets/images/img/6.jpeg'),
			require('@/assets/images/img/7.jpeg'),
			require('@/assets/images/img/8.jpeg'),
			require('@/assets/images/img/9.jpeg'),
		]
	  }
  },
  methods: {
    onImageLoad(index) {
		this.$nextTick(() => {
		    let item = this.photos[index]
			item.load = true
		});
    },
	groupPhotosByDateTime(photos) {
	  const grouped = {};
	
	  photos.forEach(photo => {
	    const [date, time] = photo.time.split(' ');
	    const hour = time.split(':')[0];
	
	    if (!grouped[date]) {
	      grouped[date] = {};
	    }
	
	    if (!grouped[date][hour]) {
	      grouped[date][hour] = [];
	    }
	
	    grouped[date][hour].push(photo);
	  });
	
	  return grouped;
	}
  },
  computed: {
		columns() {
			// 将照片分为两列
			const columns = [[], []];
			this.sortedPhotos.forEach((photo, index) => {
				columns[index % 2].push(photo);
			});
			return columns;
		},
		sortedPhotos() {
			
	        return this.sort == 'desc' ? this.photos : this.photos.slice().reverse();
		},
		sortedPreList() {
			return this.sort == 'desc' ? this.preList : this.preList.slice().reverse();
		},
		timePhotos() {
			return this.groupPhotosByDateTime(this.sortedPhotos)
		}
    },
  mounted() {
    this.photos.forEach((photo, index) => {
      const image = new Image();
      image.src = photo.src;
	  
      image.onload = () => this.onImageLoad(index);
	  
    });
  }
}
</script>

<style scoped>
.photo-grid {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px; /* 确保间隙一致 */
}

.photo-item {
  /* display: flex;
  justify-content: center;
  align-items: flex-start; */
  width: 100%;
  margin-bottom: 3px;
}

.photo-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.photo-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  transition: transform 0.2s ease-out;
}

.photo-image.loaded {
  transform: scale(1);
}

.el-row::before {
	content: none
}


.photo-fall {
  margin-left: -5px; /* 调整左边距以抵消间隙 */
}
.photo-column {
  padding-left: 5px; /* 调整左边距以创建间隙 */
}
.photo-fall-item {
  margin-bottom: 5px; /* 调整底边距以创建垂直间隙 */
}
.fall-photo-image{
  width: 100%;
  display: block;
  transform: scale(0.6);
  transition: transform 0.2s ease-out;
}

.fall-photo-image.loaded {
  transform: scale(1);
}

.photos {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
}

.time-photo-item {
	width: 100%;
	aspect-ratio: 1 / 1;
	transform: scale(0.6);
	transition: transform 0.2s ease-out;
}

.time-photo-item.loaded {
  transform: scale(1);
}

.el-timeline {
	padding-left: 15px;
}

/deep/ .el-timeline-item__timestamp {
	font-size: 16px;
	/* font-weight: bold; */
	color: #000;
}

.time-header {
	margin: 15px 0px;
	color: #666;
}
</style>
