<template>
    <div class="content">
        <top-nav title="创建投票"></top-nav>
        <div class="main-box">
            <el-steps :active="active" finish-status="success" simple style="margin: 20px 0px">
              <el-step title="基本信息" ></el-step>
              <el-step title="选择球员" ></el-step>
              <el-step title="创建投票" ></el-step>
            </el-steps>
            <div style="display: flex;justify-content: center;" v-if="active == 0">
                <el-form ref="form" :model="form" label-width="80px" style="width: 700px">
                  <el-form-item label="投票标题">
                    <el-input v-model="form.name" placeholder="请输入投票的标题"></el-input>
                  </el-form-item>
                  <el-form-item label="投票时间">
                    <el-col :span="11">
                      <el-date-picker type="date" placeholder="开始时间" value-format="yyyy-MM-dd" v-model="form.starttime" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2" style="text-align: center;font-weight: 200">-</el-col>
                    <el-col :span="11">
                      <el-date-picker type="date" placeholder="结束时间" value-format="yyyy-MM-dd" v-model="form.endtime" style="width: 100%;"></el-date-picker>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="上传封面">
                    <el-upload
                      class="avatar-uploader"
                      action="''"
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="handleChangeUpload">
                      <img v-if="form.cover" :src="form.cover" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <template #tip>
                          <div class="el-upload__tip">请上传jpg/png格式图片，500*320像素，2M以下图片
                          </div>
                      </template>
                    </el-upload>
                  </el-form-item>
                  <el-form-item>
                    <el-button style="width: 65%;" type="primary" @click="nextStep">下一步<i class="el-icon-right el-icon--right"></i></el-button>
                    <el-button style="width: 30%;">重置</el-button>
                  </el-form-item>
                </el-form>
            </div>
            <div style="" v-if="active == 1">
                <div class="empty-data" v-if="form.players.length === 0">
                    <el-button round class="" style="width: 200px;height: 60px;font-size: 17px;border-radius: 60px;" icon="el-icon-back" @click="prevStep">上一步</el-button>
                    <el-button @click="playersVisible = true" type="primary" icon="el-icon-menu" round class="" style="width: 200px;height: 60px;font-size: 17px;border-radius: 60px;">选择球员</el-button>
                </div>
                <div class="btn-area" v-else>
                    <el-button @click="playersVisible = true" type="primary" icon="el-icon-menu" class="">选择球员</el-button>
                    <div>
                        <el-button icon="el-icon-back"  @click="prevStep">上一步</el-button>
                        <el-button type="primary"  @click="nextStep" style="width: 200px;">下一步<i class="el-icon-right el-icon--right"></i></el-button>
                    </div>
                </div>
                <div style="display: flex;flex-wrap: wrap;justify-content: flex-start" v-if="form.players.length > 0">
                    <div class="player-item" style="width: calc(100% / 10 - 10px);margin: 0;margin-right: 30px;margin-top: 20px;" v-for="(item,index) in form.players" :key="index">
                        <div class="member-item-l-box">
                            <img @click="showPre(item.portrait || defaultlogo)" class="member-item-face" :src="item.portrait || require('@/assets/images/nohead.png')" alt="" />
                            <div class="member-item-sex" :class="item.sex == 1 ? 'nan-class' : 'nv-class'">
                                <i class="el-icon-male" v-if="item.sex == 1"></i>
                                <i class="el-icon-female" v-else></i>
                            </div>
                        </div>
                        <span class="member-item-name">
                            {{item.name}}
                            <span class="member-item-number">#{{item.playno}}</span>
                        </span>
                        <span class="member-item-info">
                            <span class="member-item-info-type">{{getTypeName(item.playtype)}} · {{calculateAge(item.sfzh)}}</span>
                        </span>
                    </div>
                </div>
                
            </div>
            <div v-if="active == 2">
                <el-descriptions class="margin-top" title="基本信息" :column="3" border>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-edit"></i>
                        投票标题
                      </template>
                      {{form.title}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-time"></i>
                        投票时间
                      </template>
                      {{form.starttime}} 至 {{form.endtime}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-time"></i>
                        参与人数
                      </template>
                      {{form.players.length}} 人
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-picture-outline"></i>
                        封面
                      </template>
                      <el-image
                            style="width: 500px; height: 320px"
                            :src="form.cover"
                            fit="cover"></el-image>
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-picture-user"></i>
                        参与人员
                      </template>
                      <div style="height: 400px;overflow-y: scroll;">
                        <el-table
                            :data="form.players"
                            style="width: 100%">
                            <el-table-column
                              prop="name"
                              label="头像"
                              width="180">
                              <template  slot-scope="scope">
                                <el-avatar size="50" :src="scope.row.portrait"></el-avatar>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="name"
                              label="姓名"
                              width="180">
                            </el-table-column>
                            <el-table-column
                              prop="playno"
                              label="球号"
                              width="180">
                            </el-table-column>
                            <el-table-column
                              prop="playtype"
                              label="身份"
                              width="180"
                              >
                                <template  slot-scope="scope">
                                    {{getTypeName(scope.row.playtype)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                              prop="teamname"
                              label="所属球队"
                              width="">
                            </el-table-column>
                          </el-table>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
                <div style="margin-top: 20px;">
                    <el-button icon="el-icon-back"  @click="prevStep">上一步</el-button>
                    <el-button type="primary"  @click="confirmSubmit()" style="width: 200px;">创建投票<i class="el-icon-check el-icon--right"></i></el-button>
                </div>
            </div>
            <div v-if="active == 3">
                <el-result icon="success" title="创建成功" subTitle="请选择操作">
                    <template slot="extra">
                        <el-button type="primary" size="medium" @click="reCreate()">再次创建</el-button>
                        <el-button type="" size="medium" @click="toList()">投票列表</el-button>
                    </template>
                </el-result>
            </div>
        </div>
        <el-dialog
          title="选择球员"
          :visible.sync="playersVisible"
          width="80%"
          >
          <div class="choose-players-box">
                <div class="choose-players-box-l">
                    <el-menu
                        class="el-menu-vertical-demo"
                        default-active="0"
                        >
                        <el-menu-item v-for="(item,index) in teamData" :key="index" :index="index" @click="getPlayerData(item.id)">
                          <el-avatar size="small" :src="item.logo" style="margin-right: 10px;"></el-avatar>
                          <span slot="title">{{item.name}}</span>
                        </el-menu-item>
                    </el-menu>
                </div>
                <div class="choose-players-box-r">
                    <div class="empty-data" v-if="members.length == 0">
                        该队伍暂无球员
                    </div>
                    <div class="player-item" v-for="(item,index) in members" :key="index" @click="setPlayer(item)">
                        <i class="iconfont icon-xuanzhongjiaobiao choose-icon" v-if="chooseCheck(item, form.players)"></i>
                        <div class="member-item-l-box">
                            <img @click="showPre(item.portrait || defaultlogo)" class="member-item-face" :src="item.portrait || require('@/assets/images/nohead.png')" alt="" />
                            <div class="member-item-sex" :class="item.sex == 1 ? 'nan-class' : 'nv-class'">
                                <i class="el-icon-male" v-if="item.sex == 1"></i>
                                <i class="el-icon-female" v-else></i>
                            </div>
                        </div>
                        <span class="member-item-name">
                            {{item.name}}
                            <span class="member-item-number">#{{item.playno}}</span>
                        </span>
                        <span class="member-item-info">
                            <span class="member-item-info-type">{{getTypeName(item.playtype)}} · {{calculateAge(item.sfzh)}}</span>
                        </span>
                    </div>
                </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="playersVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="playersVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <my-cropper :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="500" :height="320"></my-cropper>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            liveid: "",
            active: 0,
            form: {
                title: '',
                starttime: '',
                endtime: '',
                cover: '',
                players: []
            },
            dialogVisible: false,
            cropperImage: "",
            playersVisible: false,
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            teamData: [],
            pageSize: -1,
            pageIndex: 1,
            members: [],
        }
        },
        created() {
            this.$api.post('api/Main/GetGame').then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                this.liveid = response.data.data.id
                this.fetchData()
                
            }).catch(error => {
                console.error("There was an error!", error);
            })
      
        },
        methods: {
            fetchData() {
                this.$api.get('api/Team/Teams', {
                    params : {
                        PageIndex: this.pageIndex,
                        limit: this.pageSize,
                        teamgroup: ""
                    }
                  
                })
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    
                    this.teamData = response.data.rows
                    this.getPlayerData(this.teamData[0].id)
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            getPlayerData(id) {
                this.$api.get('api/Team/Players', {
                  params: {
                    limit: -1,
                    teamid: id,
                  }
                })
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.members = response.data.rows
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    console.log("成功", URL.createObjectURL(file.raw))
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.dialogVisible = true
                })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            handleLogoUpdate(croppedImageUrl) {
                // let blobImage = this.base64ToBlob(croppedImageUrl);
                this.form.cover = croppedImageUrl
                // let formData = new FormData();
                // formData.append('file', blobImage, +new Date+'.jpg');
                // this.$api.post('api/Team/playerupload', formData).then(response => {
                //     this.form.logo = response.data.data;
                // })
            },
            closeDialog() {
                this.dialogVisible = false
            },
            nextStep() {
                this.active = this.active + 1
            },
            prevStep() {
                this.active = this.active - 1
            },
            getTypeName(type) {
                switch (type) {
                  case 0: return '队员';
                  case 1: return '队长';
                  case 2: return '教练兼队员';
                  case 3: return '领队兼队员';
                  case 4: return '教练';
                  case 5: return '领队';
                  default: return '未知';
                }
            },
            calculateAge(idNumber) {
                if (idNumber && idNumber.length === 18) {
                  // 获取出生年月日
                  const birthYear = idNumber.substring(6, 10);
                  const birthMonth = idNumber.substring(10, 12);
                  const birthDay = idNumber.substring(12, 14);
                  
                  // 创建出生日期
                  const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
                  const now = new Date();
                  
                  // 计算年龄
                  let age = now.getFullYear() - birthDate.getFullYear();
                  const m = now.getMonth() - birthDate.getMonth();
                  
                  // 如果当前月份小于出生月份，或者月份相同但当前日期小于出生日期，则年龄减1
                  if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
                    age--;
                  }
                  
                  return age+"岁";
                }
                return "未知"; // 如果身份证号不合法，返回null
            },
            chooseCheck(item,array) {
                return array.some(player => player.id === item.id);
            },
            setPlayer(item) {
                let check = this.chooseCheck(item, this.form.players)
                console.log("这个球员",check)
                console.log(item, this.form.players)
                if (check == false) {
                    this.form.players.push(item)
                }else {
                    const indexToRemove = this.form.players.findIndex(player => player.id === item.id);
                    
                    // 如果找到了匹配的数组，则将其从二维数组中移除
                    if (indexToRemove !== -1) {
                      this.form.players.splice(indexToRemove, 1);
                    }
                }
            },
            confirmSubmit() {
                this.$confirm('确认创建投票?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.nextStep()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            reCreate() {
                this.active = 0
                this.form = {
                    title: '',
                    starttime: '',
                    endtime: '',
                    cover: '',
                    players: []
                }
            },
            toList() {
                this.$router.push({ path: '/votelist'});
            }
            
        }
    }
  </script>

<style scoped>
    .avatar-uploader  /deep/ .el-upload{
        width: 250px;
        height: 160px;
        
    }
    
    .avatar-uploader  /deep/ .el-upload-dragger{
        width: 250px!important;
        height: 160px!important;
        border: 0;
        line-height: 160px;
    }
    
    .empty-data {
        text-align: center;
        line-height: 500px;
        color: #ccc;
        width: 100%;
    }
    
    .choose-players-box {
        display: flex;
        width: 100%;
    }
    
    .choose-players-box-l {
        width: 300px;
        height: 500px;
        overflow-y: scroll;
    }
    .choose-players-box-r {
        width: calc(100% - 300px);
        height: 500px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
    }
    
    .el-menu-vertical-demo {
        text-align: left;
    }
    
    .member-item-l-box {
        width: 80px;
        height: 80px;
        border: 5px solid #f0f0f0;
        border-radius: 100px;
        position: relative;
        margin-bottom: 10px;
        
    }
    
    .member-item-face {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        
    }
    
    .member-item-sex {
        width: 25px;
        height: 25px;
        background-color: #fff;
        text-align: center;
        line-height: 25px;
        border-radius: 25px;
        font-size: 20px;
        color: #1296db;
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid #f0f0f0;
    }
    
    .nan-class {
        color: #1296db;
    }
    
    .nv-class {
        color: #ff679c;
    }
    
    .el-icon-male {
        font-weight: bolder;
        
    }
    
    .member-item-name {
        font-size: 20px;
        font-weight: bold;
    }
    
    .member-item-number {
        padding-left: 10px;
        font-weight: 400;
        color: #1296db;
    }
    
    .member-item-info {
        display: flex;
        flex-direction: column;
        
    }
    
    .member-item-info-inline {
        height: 20px;
        line-height: 20px;
        color: #666;
    }
    
    .member-item-info-short .iconfont{
        font-size: 20px;
    }
    .member-item-info-inline .iconfont {
        font-size: 16px;
    }
    
    .member-item-info-type {
        height: 50px;
        line-height: 50px;
        
    }
    
    .player-item {
        width: calc(100% / 8 - 10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin: 10px;
        height: 180px;
        padding: 10px;
        cursor: pointer;
        position: relative;
    }
    
    
    .choose-icon {
        position: absolute;
        right: -8px;
        bottom: 0;
        color: #1296db;
    }
    
    .btn-area {
        display: flex;
        justify-content: space-between;
        height: 40px;
        width: 100%;
    }
    
</style>