<template>
    <div class="content">
        <top-nav :title="title" :isBak="is_bak"></top-nav>
        <div class="main-box"  v-show="searchType === 'player'">
            <div class="search_item" v-for="(item,index) in searchData" :key="index">
                <router-link :to="`/teammembers/${item.teamname}/${item.teamId}`">
                <div class="main-left">
                    <el-image
                        style="width: 100px; height: 100px"
                        :src="item.portrait"
                        :fit="fit"></el-image>
                    <div class="main-left-info">
                        <span class="main-left-info-title">{{item.name}}</span>
                        <span class="main-left-info-detail">球队: {{item.teamname}}</span>
                        <!-- <span class="main-left-info-detail">积分: 暂无</span> -->
                    </div>
                </div>
                </router-link>
                <div class="main-right">
                    联系方式：{{item.tel || "暂无"}}
                </div>
            </div>
        </div>
        
        <div class="main-box"  v-show="searchType === 'team'">
            <el-table
              :data="teamData"
              style="width: 100%">
              <el-table-column
                prop="team"
                label="球队"
                width="350"
                align="center"
                >
                <template slot-scope="scope">
                    <div class="team-logo-box">
                        
                        <div class="team-logo">
                            <div class="block">
                                <el-avatar @click.native="showPre(scope.row.logo)" shape="square" :size="60" :src="scope.row.logo || defaultlogo"></el-avatar>
                            </div>
                        </div>
                        <div class="team-info">
                            <router-link :to="`/teammembers/${scope.row.name}/${scope.row.id}`">
                            <span class="team-info-name">{{scope.row.name}}</span>
                            </router-link>
                            <span class="team-info-score">{{scope.row.shortnick}}</span>
                        </div>
                    </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="playernum"
                label="成员人数"
                width="130"
                align="center"
                >
                
              </el-table-column>
              <el-table-column
                label="战绩"
                width="300"
                align="center"
                >
                <template slot-scope="scope">
                    <span class="score-win">{{scope.row.winnum}}</span> 胜 :
                    <span class="score-lose">{{scope.row.gamenum - scope.row.winnum}}</span> 负
                </template>
              </el-table-column>
              <el-table-column
				prop="integral"
                label="积分"
                width="180"
                align="center"
                >
              </el-table-column>
              <el-table-column
                label="分组"
                align="center"
                >
                <template slot-scope="scope">
                    <span class="team-tag" v-if="scope.row.teamtag">{{scope.row.teamtag}} / </span>
                    <span class="team-group" v-if="scope.row.teamgroup">{{scope.row.teamgroup}} </span>
                    <span class="team-seq" v-if="scope.row.seq !== '' && scope.row.seq !== 0">/ {{scope.row.seq}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="center"
                width="300"
                >
                <template slot-scope="scope">
                    <el-tag type="danger" effect="dark" v-if="scope.row.isJoin == 1" style="margin-right: 10px;">截止报名</el-tag>
                    <el-tag type="" effect="dark" v-if="scope.row.playerage != 0 || scope.row.playerminage != 0">年龄限制 {{scope.row.playerminage}} - {{scope.row.playerage}}岁</el-tag>
                </template>
              </el-table-column>
            </el-table>
        </div>
        
        <div class="main-box"  v-show="searchType === 'score'">
            
            <el-table
              :data="scoreData"
              style="width: 100%">
              <el-table-column
                prop="starttime"
                label="比赛日期"
                align="right"
                width="200"
                :formatter="formatDate"
                >
                
              </el-table-column>
              <el-table-column
                prop="starttime"
                label="比赛时间"
                align="left"
                :formatter="formatTime"
                >
              </el-table-column>
              <el-table-column
                prop="team"
                label="积分成绩"
                width="800"
                align="center"
                >
                <template slot-scope="scope">
                    <div class="team-logo-box">
                        <div class="team-box">
                            <div class="team-logo">
                                <div class="block">
                                    <el-avatar @click.native="showPre(scope.row.homelogo)" shape="circle" :size="60" :src="scope.row.homelogo"></el-avatar>
                                </div>
                            </div>
                            <div class="team-info">
                                <router-link :to="`/teammembers/${scope.row.homename}`">
                                <span class="team-info-name">{{scope.row.homename}}</span>
                                </router-link>
                                <span class="team-info-score">{{scope.row.homename}}</span>
                            </div>
                        </div>
                        
                        <div class="team-vs">
                            <div class="team-grade font-red">{{scope.row.homescore}}</div>
                            VS
                            <div class="team-grade font-blue">{{scope.row.guestscore}}</div>
                        </div>
                        
                        <div class="team-box" style="justify-content: right;">
                            <div class="team-info" style="text-align: right;">
                                <router-link :to="`/teammembers/${scope.row.guestname}`">
                                    <span class="team-info-name">{{scope.row.guestname}}</span>
                                </router-link>
                                <span class="team-info-score" style="text-align: right;">{{scope.row.guestname}}</span>
                            </div>
                            <div class="team-logo">
                                <div class="block" style="margin-left: 10px;">
                                    <el-avatar @click.native="showPre(scope.row.guestlogo)" shape="circle" :size="60" :src="scope.row.guestlogo" fit="cover"></el-avatar>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
              </el-table-column>
            </el-table>
        </div>
        
        <div class="footer-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pagesize"
              @current-change="changepage"
              :hide-on-single-page="true"
              >
            </el-pagination>
        </div>
    </div> 
</template>

<script>
    export default {
        data() {
            return {
                title: "关键词：",
                is_bak: true,
                gameData: [],
                pageindex: 1,
                pagesize: 10,
                total: 0,
                key: "",
                searchData: [],
                searchType: "",
                teamData: [],
                scoreData: [
                    {
                        starttime: 1713600117,
                        homelogo: "http://www.douzhan.club/resources/teamlogo/bf7a215e-4e6d-40aa-beb6-5dfa3d7ece9a.jpg",
                        gusetlogo: "http://www.douzhan.club/resources/teamlogo/431e6e88-f35f-4b63-8558-4a46671cdf4d.png",
                        homename: "主队队名",
                        guestname: "客队队名",
                        homescore: 26,
                        guestscore: 18,
                        
                    }
                ]
            }
        },
        watch: {
            // 监听路由变化
            '$route.params.p': function(newVal) {
              this.searchType = newVal; // 更新searchType
            }
        },
        created() {
            this.title = "关键词："+this.$route.params.t
            this.searchType = this.$route.params.p
            this.key = this.$route.params.t
            this.$api.post('api/Main/GetGame').then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                this.gameData = response.data.data
				if (this.searchType == "player") {
					this.search()
				}
                
				if (this.searchType == "team") {
					this.searchTeam()
				}
                
            }).catch(error => {
                console.error("There was an error!", error);
            }) 
        },
        beforeRouteUpdate(to, from, next) {
            console.log(to)
          if (to.name === from.name && to.params.t !== from.params.t) {
            this.title = "关键词："+to.params.t
            this.key = to.params.t
            this.search();
          }
          next();
        },
        methods: {
            search() {
                let formData = new FormData()
                formData.append("pageindex", this.pageindex)
                formData.append("pagesize", this.pagesize)
                formData.append("liveid", this.gameData.id)
                formData.append("search", this.key)
                formData.append("type", 1)
                this.$api.post('m/run/search',formData).then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    console.log(response.data.data)
                    this.total = response.data.data.count
                    this.searchData = response.data.data.players
                    
                }).catch(error => {
                    console.error("There was an error!", error);
                }) 
                
            },
			searchTeam() {
				let params = {
					PageIndex: this.pageindex,
					limit: this.pagesize,
					search: this.key,
				}
				this.$api.get('api/Team/Teams', {
					params: params
				})
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							type: 'error',
							message: response.data.data
						});
						this.$router.push({ path: '/login'});
						return;
					}
					
					this.teamData = response.data.rows
					this.total = response.data.total
					this.pagesize = response.data.PageSize
				
				})
				.catch(error => {
					console.error("There was an error!", error);
				});
			},
            changepage(p) {
                this.pageindex = p
                this.search()
            },
            formatDate(row, column, value) {
                const date = new Date(value);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                return date.toLocaleDateString('default', options);
            },
            formatTime(row, column, value) {
                const date = new Date(value);
                const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                return date.toLocaleString('default',options);
            },
        }
    }
</script>

<style scoped>
    .search_item {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #ccc;
        padding: 10px;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .main-left {
        display: flex;
        
    }
    
    .main-left-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 20px;
    }
    
    .main-box {
        padding: 20px;
    }
    
    .main-left-info-title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    
    .main-left-info-detail {
        font-size: 16px;
        color: #666;
        margin-bottom: 10px;
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 20px;
        /* justify-content: space-between; */
    }
    
    .team-box {
        display: flex;
        width: 400px;
    }
    
    .team-vs {
        width: 200px;
        font-weight: bold;
        font-size: 20px;
        line-height: 60px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
    }
    
    .font-blue {
        color: #009dff;
        font-size: 34px
    }
    
    .font-red {
        color: #ff0004;
        font-size: 34px
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
		
	.score-win {
		font-size: 18px;
		font-weight: bold;
		color: #039f2f;
		margin-right: 5px;
	}
	.score-lose {
		font-size: 18px;
		font-weight: bold;
		color: #e80000;
		margin-right: 5px;
	}

    
</style>