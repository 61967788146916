<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: left;align-items: center;">
                    <span>直播水印</span>
                </div>
                <div class="mobile">
                    <div class="mobile-head"></div>
                    <div class="mobile-btn"></div>
                    <div class="mobile-main-box">
                        <i class="el-icon-video-play play-icon"></i>
                        <img :style="{'width': videowatermarkSettings.size+'%','opacity':videowatermarkSettings.opacity+'%'}" class="video-watermark" :src="videowatermarkSettings.image" alt="" />
                    </div>
                    <el-form ref="watermarkForm" :model="videowatermarkSettings" style="width: 100%;margin-top: 100px;">
                      <!-- 上传水印图片 -->
                      <el-form-item style="width: 30%;">
                        <el-upload
                          class="avatar-uploader"
                          action="''"
                          drag
                          :auto-upload="false"
                          :show-file-list="false"
                          :on-change="handleChangeUploadvideo">
                          <img v-if="videowatermarkSettings.image" :src="videowatermarkSettings.image" class="avatar">
                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        
                          
                        </el-upload>
                      </el-form-item>
                      <div style="width: 80%;">
                      <!-- 水印透明度 -->
                          <el-form-item label="透明度">
                            <el-slider style="width: 70%;margin-left: 70px;" v-model="videowatermarkSettings.opacity" :min="0" :max="100"></el-slider>
                          </el-form-item>
                          
                          <el-form-item label="水印尺寸">
                            <el-slider style="width: 70%;margin-left: 70px;" v-model="videowatermarkSettings.size" :min="0" :max="100"></el-slider>
                          </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="margin-top: 60px;">应用水印</el-button>
                            </el-form-item>
                      </div>
                    </el-form>
                    
                </div>
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>图片水印</span>
                </div>
                <div class="image-box mobile">
                    <div class="mobile-head"></div>
                    <div class="mobile-btn"></div>
                    <div class="mobile-main-box" style="padding: 0px;width: 100%;height: 300px;">
                        <div class="watermark-box-img" style="width: 100%;">
                            <div class="watermark-box-img-box" style="width: 100%;">
                              <img class="watermark-box-background-img" src="@/assets/images/basketball.jpeg" alt=""  />
                              <div class="watermark-img" :class="watermarkSettings.position" :style="{'width': watermarkSettings.size+'%','opacity':watermarkSettings.opacity+'%'}">
                                <img style="width: 100%;" :src="watermarkSettings.image" alt="" />
                              </div>
                              <div class="watermark-img" :class="secondwatermarkSettings.position" :style="{'width': secondwatermarkSettings.size+'%','opacity':secondwatermarkSettings.opacity+'%'}">
                                <img style="width: 100%;" :src="secondwatermarkSettings.image" alt="" />
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="watermark-box">
                      
                      <!-- 表单内容 -->
                      <el-form ref="watermarkForm" :model="watermarkSettings" style="width: 100%;">
                        <!-- 上传水印图片 -->
                        <el-form-item label="主水印图片">
                          <el-upload
                            class="avatar-uploader"
                            action="''"
                            drag
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="handleChangeUpload">
                            <img v-if="watermarkSettings.image" :src="watermarkSettings.image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          
                            
                          </el-upload>
                        </el-form-item>
                        
                        <!-- 水印透明度 -->
                        <el-form-item label="透明度">
                          <el-slider style="width: 70%;margin-left: 70px;" v-model="watermarkSettings.opacity" :min="0" :max="100"></el-slider>
                        </el-form-item>
                        
                        <el-form-item label="水印尺寸">
                          <el-slider style="width: 70%;margin-left: 70px;" v-model="watermarkSettings.size" :min="0" :max="100"></el-slider>
                        </el-form-item>
                        
                        <!-- 水印位置 -->
                        <el-form-item label="水印位置">
                          <el-radio-group v-model="watermarkSettings.position">
                            <el-radio label="left-top">左上</el-radio>
                            <el-radio label="right-top">右上</el-radio>
                            <el-radio label="left-bottom">左下</el-radio>
                            <el-radio label="right-bottom">右下</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        
                        
                        <!-- 设置按钮 -->
                        <el-form-item>
                          <el-button type="primary" @click="applySettings">应用设置</el-button>
                        </el-form-item>
                      </el-form>
                      <el-form ref="watermarkForm" :model="secondwatermarkSettings" style="width: 100%;margin-left: 20px;border-left: 1px dashed #ccc;padding-left: 40px" >
                        <!-- 上传水印图片 -->
                        <el-form-item label="副水印图片">
                          <el-upload
                            class="avatar-uploader"
                            action="''"
                            drag
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="handleChangeUploadSecond">
                            <img v-if="secondwatermarkSettings.image" :src="secondwatermarkSettings.image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          
                            
                          </el-upload>
                        </el-form-item>
                        
                        <!-- 水印透明度 -->
                        <el-form-item label="透明度">
                          <el-slider style="width: 70%;margin-left: 70px;" v-model="secondwatermarkSettings.opacity" :min="0" :max="100"></el-slider>
                        </el-form-item>
                        
                        <el-form-item label="水印尺寸">
                          <el-slider style="width: 70%;margin-left: 70px;" v-model="secondwatermarkSettings.size" :min="0" :max="100"></el-slider>
                        </el-form-item>
                        
                        <!-- 水印位置 -->
                        <el-form-item label="水印位置">
                          <el-radio-group v-model="secondwatermarkSettings.position">
                            <el-radio label="left-top">左上</el-radio>
                            <el-radio label="right-top">右上</el-radio>
                            <el-radio label="left-bottom">左下</el-radio>
                            <el-radio label="right-bottom">右下</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        
                      </el-form>
                  </div>
                
                  
                  <el-dialog :visible.sync="showUploads" title="上传图片" width="40%">
                        <div style="display: flex;justify-content: left;flex-wrap: wrap;">
                            <el-upload
                              action=""
                              list-type="picture-card"
                              :on-change="handleChangeUploadMuti"
                              :auto-upload="false"
                              multiple
                              >
                              <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                  </el-dialog>
            </div>
         </div>
        <!-- vueCropper 剪裁图片实现-->
        <my-cropper :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="auto" :height="auto"></my-cropper>
    </div>
</template>

<script>
    export default {
        data() {
          return {
            showSettings: false,
            showUploads: false,
            watermarkSettings: {
                image: require('@/assets/images/defaultlogo.jpg'), // 水印图片
                opacity: 100, // 透明度
                position: 'left-top', // 水印位置
                size: 10,
            },
            secondwatermarkSettings: {
                image: require('@/assets/images/defaultlogo.jpg'), // 水印图片
                opacity: 100, // 透明度
                position: 'right-bottom', // 水印位置
                size: 10,
            },
            videowatermarkSettings: {
                image: require('@/assets/images/defaultlogo.jpg'), // 水印图片
                opacity: 100, // 透明度
                size: 10,
            },
            watermarkSettingsBack: {},
            isPreview: false,
            dialogVisible: false,
            cropperImage: "",
            previewImg: '', 
            cropperType: "img",
            srcList: [
                // 'https://img0.baidu.com/it/u=3413601597,2982070531&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=467',
                // 'https://img2.baidu.com/it/u=596330519,4158459487&fm=253&fmt=auto&app=138&f=JPEG?w=749&h=500',
                // 'https://img0.baidu.com/it/u=3780768077,3034976556&fm=253&fmt=auto&app=138&f=JPEG?w=946&h=500',
                // 'https://img1.baidu.com/it/u=2631760325,1796851181&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=467',
                // 'https://img1.baidu.com/it/u=3780805695,3042859363&fm=253&fmt=auto&app=138&f=JPEG?w=427&h=285',
                // 'https://img1.baidu.com/it/u=2549663372,4080218794&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=467',
                // 'https://img2.baidu.com/it/u=3104917001,4135138867&fm=253&fmt=auto&app=138&f=JPEG?w=808&h=500',
                // 'https://img1.baidu.com/it/u=2631760325,1796851181&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=467',
                // 'https://img1.baidu.com/it/u=3780805695,3042859363&fm=253&fmt=auto&app=138&f=JPEG?w=427&h=285',
                // 'https://img1.baidu.com/it/u=2549663372,4080218794&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=467',
                // 'https://img2.baidu.com/it/u=3104917001,4135138867&fm=253&fmt=auto&app=138&f=JPEG?w=808&h=500',
            ]
          }
        },
        created() {
            
        },
        methods: {
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    this.cropperType = "img"
                    this.watermarkSettings.image = URL.createObjectURL(file.raw)
                })
            },
            handleChangeUploadvideo(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    this.cropperType = "video"
                    this.videowatermarkSettings.image = URL.createObjectURL(file.raw)
                })
            },
            handleChangeUploadSecond(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    this.cropperType = "second"
                    this.secondwatermarkSettings.image = URL.createObjectURL(file.raw)
                })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            handleLogoUpdate(croppedImageUrl) {
                if (this.cropperType == "img") {
                    this.watermarkSettings.image = croppedImageUrl
                } else if (this.cropperType == "second") {
                    this.secondwatermarkSettings.image = croppedImageUrl
                } else {
                    this.videowatermarkSettings.image = croppedImageUrl
                }
                
                // let blobImage = this.base64ToBlob(croppedImageUrl);
                // let formData = new FormData();
                // formData.append('file', blobImage, +new Date+'.jpg');
                // this.$api.post('api/Team/playerupload', formData).then(response => {
                //     this.form.logo = response.data.data;
                // })
            },
            closeDialog() {
                this.dialogVisible = false
            },
            applySettings() {
                this.$message({
                    type: 'success',
                    message: '设置成功'
                });
                this.showSettings = false
            },
            resetSetting() {
                this.watermarkSettings = this.watermarkSettingsBack
                this.showSettings = false
            },
            setSetting() {
                this.watermarkSettingsBack = this.watermarkSettings
                this.showSettings = true
            },
            handleChangeUploadMuti(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                this.$nextTick(async () => {
                    this.applyWatermark(file)
                    this.showUploads = false
                    // this.srcList.push(URL.createObjectURL(file.raw))
                })
            },
            applyWatermark(file) {
                const img = new Image();
                img.src = URL.createObjectURL(file.raw);
                
                img.onload = () => {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  
                  // 设置画布大小为图片大小
                  canvas.width = img.width;
                  canvas.height = img.height;
                  
                  // 先绘制原始图片
                  ctx.drawImage(img, 0, 0, img.width, img.height);
                  
                  // 设置水印图片
                  const watermarkImage = new Image();
                  watermarkImage.src = this.watermarkSettings.image;
                  
                  const secondwatermarkImage = new Image();
                  secondwatermarkImage.src = this.secondwatermarkSettings.image;
                              
                  watermarkImage.onload = () => {
                    this.drawImageWatermark(ctx, watermarkImage, img.width, img.height);
                    this.drawImageWatermarksecond(ctx, secondwatermarkImage, img.width, img.height)     
                    // 将canvas转换为data URL并保存
                    const dataUrl = canvas.toDataURL('image/png');
                    this.srcList.push(dataUrl);
                  };
                };
            },
            drawImageWatermark(ctx, watermarkImage, width, height) {
                const position = this.watermarkSettings.position;
                let x = 0;
                let y = 0;
            
                const scale = width * this.watermarkSettings.size / 100 / watermarkImage.width;
                const scaledWidth = watermarkImage.width * scale;
                const scaledHeight = watermarkImage.height * scale;
            
                switch(position) {
                  case 'left-top':
                    x = 10;
                    y = 10;
                    break;
                  case 'right-top':
                    x = width - scaledWidth - 10;
                    y = 10;
                    break;
                  case 'left-bottom':
                    x = 10;
                    y = height - scaledHeight - 10;
                    break;
                  case 'right-bottom':
                    x = width - scaledWidth - 10;
                    y = height - scaledHeight -10;
                    break;
                }
            
                ctx.globalAlpha = this.watermarkSettings.opacity / 100;
                ctx.drawImage(watermarkImage, x, y, scaledWidth, scaledHeight);
                ctx.globalAlpha = 1.0;  // Reset opacity to default
            },
            drawImageWatermarksecond(ctx, watermarkImage, width, height) {
                const position = this.secondwatermarkSettings.position;
                let x = 0;
                let y = 0;
            
                const scale = width * this.secondwatermarkSettings.size / 100 / watermarkImage.width;
                const scaledWidth = watermarkImage.width * scale;
                const scaledHeight = watermarkImage.height * scale;
            
                switch(position) {
                  case 'left-top':
                    x = 10;
                    y = 10;
                    break;
                  case 'right-top':
                    x = width - scaledWidth - 10;
                    y = 10;
                    break;
                  case 'left-bottom':
                    x = 10;
                    y = height - scaledHeight - 10;
                    break;
                  case 'right-bottom':
                    x = width - scaledWidth - 10;
                    y = height - scaledHeight -10;
                    break;
                }
            
                ctx.globalAlpha = this.secondwatermarkSettings.opacity / 100;
                ctx.drawImage(watermarkImage, x, y, scaledWidth, scaledHeight);
                ctx.globalAlpha = 1.0;  // Reset opacity to default
            },
            drawTextWatermark(ctx, watermarkImage, width, height) {
                if (!this.secondwatermarkSettings.text) return;
            
                const position = this.secondwatermarkSettings.position;
                ctx.font = `${this.secondwatermarkSettings.fontweight} ${this.secondwatermarkSettings.fontsize + 4}px 微软雅黑`;
                ctx.fillStyle = this.secondwatermarkSettings.color;
                const textWidth = ctx.measureText(this.secondwatermarkSettings.text).width;
                let x = 0;
                let y = this.secondwatermarkSettings.fontsize;  // Default to fontsize to ensure it's not cut off at top
                // const waterWidth = this.watermarkSettings.image.width;
                const scale = width * this.secondwatermarkSettings.size / 100 / watermarkImage.width;
                const scaledHeight = watermarkImage.height * scale;
                switch(position) {
                  case 'left-top':
                    x = 10;
                    y = scaledHeight + 35;
                    break;
                  case 'right-top':
                    x = width - textWidth - 10;
                    y = scaledHeight + 35;
                    break;
                  case 'left-bottom':
                    x = 10;
                    y = height - scaledHeight - 35;  // padding from the bottom
                    break;
                  case 'right-bottom':
                    x = width - textWidth - 10;
                    y = height - scaledHeight - 35;  // padding from the bottom
                    break;
                }
            
                ctx.fillText(this.secondwatermarkSettings.text, x, y);
            }
            
            
        }
      }
</script>
<style lang='less' scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(45% - 50px);
        margin: 10px;
        overflow: hidden;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(55% - 50px);
        margin: 10px;
        height: auto;
    }
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }   
      
    .watermark-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
    }
    
    .watermark-box-img {
        width: 100%;
        height: 100%;
    }
    
    .watermark-box-img-box {
        width: 100%;
        border-radius: 10px;
        /* margin-top: 50px; */
        margin-bottom: 0px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        height: 100%;
        
    }
    
    .watermark-box-background-img {
        width: 100%;
        border-radius: 10px;
        height: 100%;
    }
    
    .watermark-img {
        position: absolute;
        height: auto;
    }
    
    .left-top {
        left: 10px;
        top: 10px;
    }
    
    .left-top .watermark-text{
        left: 0px;
        bottom: -35px;
    }
    
    .right-top {
        right: 10px;
        top: 10px;
    }
    
    .right-top .watermark-text{
        right: 0px;
        bottom: -35px;
    }
    
    .left-bottom {
        left: 10px;
        bottom: 10px;
    }
    
    .left-bottom .watermark-text{
        left: 0px;
        top: -35px;
    }
    
    .right-bottom {
        right: 10px;
        bottom: 10px;
    }
    
    .right-bottom .watermark-text{
        right: 0px;
        top: -35px;
    }
    
    .watermark-text {
        white-space: nowrap;
        text-align: right;
        position: absolute;
        right: 0; /* 右对齐 */
        bottom: 0; /* 底部对齐，如果是右上则不需要 */
    }
    
    .btn-area {
        width: 100%;
        display: flex;
        justify-content: left;
    }
    
    .image-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    
    .image-box-img {
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    .mobile {
        height: 300px;
        border: 20px solid #dddddd;
        width: 650px;
        border-radius: 70px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        background-color: #fff;
        z-index: 99;
    }
    
    
    
    .mobile-head {
        width: 30px;
        height: 180px;
        background-color: #dddddd;
        margin-top: 60px;
        border-radius: 0px 40px 40px 0px;
    }
    
    
    .mobile-btn {
        width: 120px;
        height: 10px;
        border-radius: 10px 10px 0px 0px;
        background-color: #dddddd;
        position: absolute;
        left: 150px;
        top: -28px;
    }
    
    .mobile-main-box {
        padding: 10px;
        height: 280px;
        background-color: #000;
        position: absolute;
        width: 97%;
        top: 0;
        border-radius: 50px;
        z-index: -1;
        overflow: hidden;
    }
    
    .play-icon {
        font-size: 60px;
        color: #ccc;
        opacity: .6;
        margin-top: 110px;
    }
    
    .video-watermark {
        position: absolute;
        left: 50px;
        top: 30px;
    }
    
</style>