<template>
    <div class="top-header-box">
        <i class="el-icon-arrow-left" @click="$router.back()" v-if="isBak"></i>
        <span class="top-header-box-title">
            {{title}}
        </span>
    </div>
</template>

<script>
    export default {
      props: {
        title: {
            type: String,
            default:""
        },
        isBak: {
            type: Boolean,
            default: false
        }
      }
    }
</script>

<style>
    .top-header-box {
        border-bottom: 1px solid #ccc;
        height: 60px;
        line-height: 40px;
        text-align: left;
        font-size: 22px;
        text-indent: 10px;
        font-weight: bold;
    }
    
    .el-icon-arrow-left {
        margin-right: 20px;
        font-size: 24px;
        cursor: pointer;
    }
</style>