<template>
    <div class="content">
        <top-nav :title="gamemode == 9 ? '查看成员' : navTitle" :isBak="true"></top-nav>
        <!-- <div class="top-btns" @click="addMember">
            <div class="ripple"></div>
            <i class="el-icon-plus"></i>
        </div> -->
        <div class="top-btns1">
            <el-button type="primary" icon="el-icon-plus" @click="addMember">{{gamemode == 9 ? '添加成员' : '添加球员'}}</el-button>
            <el-button split-button type="warning" @click="showExportDialog = true" style="margin-left: 20px;">
              
			  {{gamemode == 9 ? '导出成员表' : '导出队员表'}}
            </el-button>
            <el-button type="success" @click.native="showExportPngDialog = true" style="margin-left: 20px;background-color: #1eca8f;"> {{gamemode == 9 ? '导出项目图照' : '导出球队图照'}}</el-button>
        </div>
        
            
           
        
        <div class="main-box" v-if="members.length > 0">
            
            <div class="member-item" v-for="(item,index) in members" :key="index" @click.stop="showGrade(item.id,item.name)">
                <div class="member-item-l" :class="item.state == 0?'runBg':'stopBg'">
                    <div class="member-item-l-box">
                        <img @click="showPre(item.portrait)" class="member-item-face" :src="item.portrait || require('@/assets/images/nohead.png')" alt="" />
                        <div class="member-item-sex" :class="item.sex == 1 ? 'nan-class' : 'nv-class'">
                            <i class="el-icon-male" v-if="item.sex == 1"></i>
                            <i class="el-icon-female" v-else></i>
                        </div>
                    </div>
                    <span class="state" :class="item.state == 0?'run':'stop'">{{item.state == 0 ? "出赛中" : "禁赛中"}}</span>
                </div>
                <div class="member-item-r">
                    <div class="edit-box">
                        <el-dropdown  trigger="click" @click.native="dropdownClickHandler">
                          <span class="el-dropdown-link" style="color: #666;">
                            编辑<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item style="text-align: center;" @click.native="stopMatch(item.name,item.id,item.state)">{{item.state == 0?'禁赛':'出赛'}}</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-edit" divided @click.native="editMember(item.id)">编辑</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-delete" @click.native="delMember(item.id)">删除</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <span class="member-item-name">
                        {{item.name}}
                        <span class="member-item-number">#{{item.playno}}</span>
                    </span>
                    <span class="member-item-info">
                        <span class="member-item-info-type">{{getTypeName(item.playtype)}} · {{calculateAge(item.sfzh)}}</span>
                        <span class="member-item-info-inline"  v-if="gamemode != 9">
                            <i class="iconfont icon-shenfenzheng"></i>
                            {{item.sfzh}}
                        </span>
                        <div class="member-item-info-inline">
                            <span class="member-item-info-short">
                                <i class="iconfont icon-iconsg"></i>
                                {{item.height}}cm
                            </span>
                            &emsp;
                            <span class="member-item-info-short">
                                <i class="iconfont icon-icontz"></i>
                                {{item.weight}}kg
                            </span>
                        </div>
                        
                        <span class="member-item-info-inline">
                            <i class="iconfont icon-shoujihao"></i>
                            {{item.tel}}
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div v-else style="color: #666;line-height: 400px;">
            
			{{gamemode == 9 ? '暂无成员，添加一个吧' : '暂无球员，添加一个吧'}}
        </div>
        <el-dialog :title="memberType == 'add' ? gamemode == 9 ? '添加成员' : '添加球员' : gamemode == 9 ? '编辑成员' : '编辑球员'" :visible.sync="dialogAddVisible" width="550px">
            
              <el-form :model="addform" label-width="100px" class="demo-ruleForm" :rules="rules">
                <el-form-item label="头像" prop="face">
                  <el-upload
                    class="avatar-uploader"
                    action="''"
                    drag
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleChangeUpload">
                    <img v-if="addform.portrait" :src="addform.portrait" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <template #tip>
                        <div class="el-upload__tip">请上传jpg/png格式图片，300*300像素，2M以下图片</div>
                    </template>
                  </el-upload>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addform.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="addform.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                
                <el-form-item label="身份证号" prop="sfzh" v-if="gamemode == 1|| gamemode == 11">
                    <el-input v-model="addform.sfzh" placeholder="请输入身份证号码"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="24">
                        <el-form :model="addform" inline :rules="rules">
                          <!-- 行内表单部分 -->
                          <el-form-item label="身高" prop="height" style="margin-left: 30px;">
                            <el-input v-model="addform.height" style="width: 150px;">
                                <template #append>cm</template>
                            </el-input>
                          </el-form-item>
                          
                          <el-form-item label="体重" prop="weight" style="margin-left: 0px;">
                            <el-input v-model="addform.weight" style="width: 150px;">
                                <template #append>kg</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
                <el-row v-if="gamemode == 1|| gamemode == 11">
                    <el-col :span="24">
                        <el-form :model="addform" inline :rules="rules">
                            <el-form-item label="身份类型" prop="type" style="margin-left: 20px;">
                                <el-select v-model="addform.playtype" placeholder="请选择身份类型" style="width: 150px;">
                                    <el-option label="队员" :value="0"></el-option>
                                    <el-option label="队长" :value="1"></el-option>
                                    <el-option label="教练兼队员" :value="2"></el-option>
                                    <el-option label="领队兼队员" :value="3"></el-option>
                                    <el-option label="教练(不上场)" :value="4"></el-option>
                                    <el-option label="领队(不上场)" :value="5"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="gamemode == 9 ? '编号' : '球号'" prop="number" style="margin-left: 20px;">
                                <el-input v-model="addform.playno" style="width: 130px;" :placeholder="gamemode == 9 ? '请输入编号' : '请输入球号'"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
                
                <el-form-item label="联系方式" prop="mobile">
                    <el-input v-model="addform.tel" placeholder="请输入联系方式"></el-input>
                </el-form-item>
              </el-form>
              
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="memberAdd()" v-if="memberType == 'add'">添 加</el-button>
                <el-button type="primary" @click="memberEdit(addform.id)" v-else>修 改</el-button>
              </div>
        </el-dialog>
        <el-dialog
              title="导出选项"
              :visible.sync="showExportDialog"
              width="30%"
            >
              <el-form>
                  <div class="checkbox-group">
                    <el-checkbox
                      v-for="(value, key) in fieldMapping"
                      :key="key"
                      v-model="selectedFields[key]"
                      @change="handleCheckboxChange(key, $event)"
                    >{{ value }}</el-checkbox>
                  </div>
                </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="showExportDialog = false">取消</el-button>
                <el-button type="primary" @click="exportData">导出</el-button>
              </div>
        </el-dialog>
        <el-dialog
              title="导出预览"
              :visible.sync="showExportPngDialog"
              width="1020px"
            >
              <div id="exportTemplate" v-if="members.length > 0">
                  <div class="exportTemplate-nav">
                      <el-avatar :size="90" :src="members[0].teamlogo"></el-avatar>
                      <span>{{members[0].teamname}}</span>
                  </div>
                  
                  <div class="export-png-box">
                      <div v-for="(item, key) in sortedItems" :key="key" class="export-png-box-item">
                          <div class="export-png-box-item-face">
                              <img :src="item.portrait ||  require('@/assets/images/nohead.png')" alt="" />
                          </div>
                          <div class="export-png-box-item-info">
                              <div class="export-png-box-item-name">
                                  {{item.name}}&emsp;<span v-if="item.playtype != 4 && item.playtype != 5">{{item.playno}}号</span>
                              </div>
                              <div class="export-png-box-item-type">
                                     <span style="font-weight: bold;">{{getTypeName(item.playtype)}}</span>
                              </div>
                              <div class="export-png-box-item-type">
                                   <span style="font-weight: bold;margin-right: 15px;">身高</span> {{item.height}} CM
                              </div>
                              <div class="export-png-box-item-type">
                                   <span style="font-weight: bold;margin-right: 15px;">体重</span> {{item.weight}} KG
                              </div>
                          </div>
                          
                      </div>
                  </div>
                </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="showExportPngDialog = false">取消</el-button>
                <el-button type="primary" @click="exportToPng">导出</el-button>
              </div>
        </el-dialog>
		<el-dialog
		  :title="memberGradeTitle"
		  :visible.sync="memberGrade"
		  width="30%"
		  lock-scroll="true"
		  >
			<el-timeline style="text-align: left;height: 100%;overflow-y: scroll;" v-if="gamemode == 1 || gamemode == 11">
				<el-timeline-item :timestamp="formatFullDate(item.gametime)" placement="top" v-for="item in memberData" :key="item.id">
					<el-card>
					  <h3>{{item.livename}}</h3>
					  <h4><font color="red">{{item.homename}}</font>({{item.homescore}})
					  <font color="orange" style="margin: 0px 5px;">vs</font>
					  <font color="blue">{{item.guestname}}</font>({{item.guestscore}})
					  </h4>
					  <div class="scoreBox">
						  <span>失误：{{item.fault}}</span>
						  <span>罚篮：{{item.penalt}}</span>
						  <span>犯规：{{item.foul}}</span>
						  <span>篮板：{{item.offrebound}}</span>
						  <span>盖帽：{{item.blockshot}}</span>
						  <span>二分：{{item.layup}}</span>
						  <span>三分：{{item.threepoint}}</span>
						  <span>技犯：{{item.technicalfoul}}</span>
						  <span>助攻：{{item.assists}}</span>
						  <span>抢断：{{item.steals}}</span>
					  </div>
					</el-card>
				</el-timeline-item>
		    </el-timeline>
			<el-timeline style="text-align: left;height: 100%;overflow-y: scroll;" v-else>
				<el-timeline-item placement="top" v-for="item in memberDataClimb" :key="item.id">
					<el-card>
						<span class="item_style">开始时间：{{item.time}}</span>
						<span class="item_style">登顶时间：{{item.finish}}</span>
						<span class="item_style">间隔秒数：{{item.gap}}</span>
						<span class="item_style">{{item.finish == '' ? '登顶失败' : '成功登顶'}}</span>
					</el-card>
				</el-timeline-item>
			</el-timeline>
		</el-dialog>
        <my-cropper :showDialog="dialogFaceVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog"></my-cropper>
        <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
    </div>
</template>

<script>
    import ExcelJS from 'exceljs';
    import { saveAs } from 'file-saver';
    import domtoimage from 'dom-to-image';
    
    export default {
        mounted() {
            this.navTitle = "查看球员 - "+this.$route.params.t
            this.addform.teamId = this.$route.params.d
            
        },
        created() {
            // 在组件创建时初始化selectedFields
            this.initializeSelection();
            this.fetchData();
            this.addform.teamId = this.$route.params.d
			this.$api.post('api/Main/GetGame').then(response => {
			    if (response.data.code == 0) {
			        this.$message({
			          type: 'error',
			          message: response.data.data
			        });
			        this.$router.push({ path: '/login'});
			        return;
			    } else {
			        this.gamemode = response.data.data.otype
					this.liveid = response.data.data.id
					this.oid = response.data.data.oid
			    }
			}).catch(error => {
			    console.error("There was an error!", error);
			}) 
        },
        data() {
            return {
                team_id: 0,
                preimg: "",
                is_show: false,
                navTitle: "查看球员",
                cropperImage: "",
                dialogAddVisible: false,
                memberType: "add",
                dialogFaceVisible: false,
                showExportPngDialog: false,
				memberGrade: false,
				memberGradeTitle: "个人数据",
				liveid: '',
				oid: '',
                addform: {
                    addtime: "",
                    height: "",
                    id: 0,
                    logo: "",
                    name: "",
                    playno: "",
                    playtype: "",
                    portrait: "",
                    sex: 0,
                    sfzh: "",
                    teamId: 0,
                    tel: "",
                    weight: "",
                    state: 0
                },
                fieldMapping: {
                  id: '序号',
                  portrait: '头像',
                  name: '名称',
                  playno: '球号',
                  sfzh: '身份证号',
                  playtype: '身份类型',
                  sex: '性别',
                  height: '身高',
                  weight: '体重',
                  state: '状态',
                  tel: '手机号',
                },
                showExportDialog: false,
				gamemode: 1,
                selectedFields: {}, // 用于跟踪哪些字段被选中
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                        { min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
                    ],
                    sfzh: [
                        { required: true, message: '请输入身份证号码', trigger: 'blur' },
                        { min: 18, max: 18, message: '长度为18个字符', trigger: 'blur' }
                    ],
                    height: [
                        { required: true, message: '请填写身高', trigger: 'blur' }
                    ],
                    weight: [
                        { required: true, message: '请填写体重', trigger: 'blur' }
                    ],
                    tel: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        { min: 11, max: 11, message: '长度为11个字符', trigger: 'blur' }
                    ],
                    type: [
                        { required: true, type: 'array', message: '请选择一个身份', trigger: 'blur1' }
                    ],
                    sex: [
                        { required: true, message: '请选择性别', trigger: 'change' }
                    ],
                    playno: [
                        { required: true, message: '请输入球号', trigger: 'blur' }
                    ],
                    face: [
                        { required: true, validator: this.avatarUploadCheck, trigger: 'change' }
                    ]
                },
                members: [
            
                ],
				memberData: [
					
				],
				memberDataClimb: [
					{
						id: 1,
						time: '2024-08-04 19:55:16',
						finish: '01:02:56(分/秒/毫秒 100)',
						gap: '62.56'
					},
					{
						id: 2,
						time: '2024-08-04 20:51:04',
						finish: '01:01:11(分/秒/毫秒 100)',
						gap: '61.47',
					}
				]

            }
        },
        computed: {
            sortedItems() {
                return this.members.slice().sort((a, b) => {
                    if (a.playtype > b.playtype) return -1;
                    if (a.playtype < b.playtype) return 1;
                    return a.playno - b.playno;
                });
            }
        },
        methods: {
			dropdownClickHandler(event) {
			    event.stopPropagation();
			},
            fetchData() {
                this.$api.get('api/Team/Players', {
                  params: {
                    limit: -1,
                    teamid: this.$route.params.d,
                  }
                })
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    console.log(response.data)
                    this.members = response.data.rows
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            
            },
            stopMatch(name,id,state) {
                let msg = ""
                state == 0 ? msg = "出赛" : msg = "禁赛"
                this.$confirm('确定要将'+name+msg+'吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('id', id);
                    formData.append('state', Math.abs(state - 2));
                    this.$api.post('api/Team/StopPlayer', formData).then(response => {
                        if (response.data.code == 1) {
                            const item = this.members.find(item => item.id === id);
                            if (item) {
                              item.state = Math.abs(item.state - 2); // 取反操作，或者设置为特定的值
                            }
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            this.$router.push({ path: '/login'});
                        }
                    })
                    
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            addMember() {
                this.dialogAddVisible = true
                this.memberType = "add"
                this.addform= {
                    addtime: "",
                    height: "",
                    id: 0,
                    logo: "",
                    name: "",
                    playno: "",
                    playtype: "",
                    portrait: "",
                    sex: 0,
                    sfzh: "",
                    teamId: this.$route.params.d,
                    tel: "",
                    weight: "",
                    state: 0
                }
            },
            memberAdd() {
                console.log(this.addform)
                this.$confirm('确认添加?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.post('api/Team/SavePlayer', this.addform ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            this.members.push(this.addform);
                            this.$message({
                                type: 'success',
                                message: '添加成功!'
                            });
                            this.fetchData()
                            this.dialogAddVisible = false
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                        }
                    })
                    
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });          
                });
            },
            memberEdit(id) {
                console.log(this.addform)
                console.log(id)
                this.$confirm('确认修改?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.post('api/Team/SavePlayer', this.addform ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            this.members.push(this.addform);
                            this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                            this.fetchData()
                            this.dialogAddVisible = false
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                        }
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消修改'
                    });          
                });
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.loading = false
                    this.dialogFaceVisible = true
                })
            },
            handleLogoUpdate(croppedImageUrl) {
                
                let blobImage = this.base64ToBlob(croppedImageUrl);
                let formData = new FormData();
                formData.append('file', blobImage, +new Date+'.jpg');
                this.$api.post('api/Team/playerupload', formData).then(response => {
                    this.addform.portrait = response.data.data;
                })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            closeDialog() {
                this.dialogFaceVisible = false
            },
            avatarUploadCheck(rule, value, callback) {
                if (!this.addform.face || this.addform.face === '') {
                    return callback(new Error('请上传头像'));
                }
                callback();
            },
            editMember(id) {
                this.memberType = "edit"
                this.dialogAddVisible = true
                this.$api.get(`api/Team/GetPlayer/${id}`)
                .then(response => {
                    
                    let reult = response.data.data
                    this.addform = reult
                    console.log(reult);
                    
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            },
            delMember(id) {
                this.$confirm('确定要将该球员删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.get(`api/Team/DelPlayer/${id}`)
                    .then(response => {
                        if(response.data.code == 1) {
                            this.members = this.members.filter(item => item.id !== id);
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                        }
                      
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                }); 
            },
            exportToCSV() {
                const csvRows = [];
                // 获取数据的标题头
                const headers = { id: '序号',
                  portrait: '头像',
                  name: '名称',
                  playno: '球号',
                  sfzh: "身份证号",
                  playtype: "身份类型",
                  sex: "性别",
                  height: "身高",
                  weight: "体重",
                  state: "状态",
                  tel: "手机号",
                  };
                
                    // 将表头转换为CSV格式，并添加到csvRows中
                const headerTitles = Object.values(headers); // 获取中文表头
                csvRows.push(headerTitles.join(','));
            
                // 遍历数据，将每一项转换为CSV格式
                this.members.forEach(item => {
                  const values = Object.keys(headers).map(key => {
                    let value = item[key] == null ? "" : item[key];
                    if (key === 'state') {
                      value = item[key] === 0 ? '出赛中' : '禁赛中';
                    }
                    const escaped = (''+value).replace(/"/g, '\\"');
                    return `"${escaped}"`;
                  });
                  csvRows.push(values.join(','));
                });
            
                // 将所有行合并成一个字符串，并添加UTF-8的BOM标记以确保中文等字符在Excel中能正确显示
                const csvString = '\uFEFF' + csvRows.join('\n');
            
                // 创建一个隐藏的a标签来模拟下载
                const a = document.createElement('a');
                a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
                a.target = '_blank';
                a.download = 'export.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            async exportExcel() {
                  // 创建一个新的工作簿
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');
            
                  // 定义表头
                worksheet.columns = [
                    { header: '序号', key: 'id', width: 10 },
                    { header: '头像', key: 'portrait', width: 10 },
                    { header: '名称', key: 'name', width: 10 },
                    { header: '球号', key: 'playno', width: 10 },
                    { header: '身份证号', key: 'sfzh', width: 10 },
                    { header: '身份类型', key: 'playtype', width: 30 },
                    { header: '性别', key: 'sex', width: 10 },
                    { header: '身高(cm)', key: 'height', width: 10 },
                    { header: '体重(kg)', key: 'weight', width: 10 },
                    { header: '状态', key: 'state', width: 10 },
                    { header: '手机号', key: 'tel', width: 10 },
                ];
            
                  // 添加数据行
                this.members.forEach(item => {
                    const row = {
                        id: item.id,
                        face: item.portrait,
                        number: item.playno,
                        idnum: item.sfzh,
                        type: this.getTypeName(item.playtype),
                        sex: item.sex === 1 ? '男' : '女',
                        height: item.height,
                        weight: item.weight,
                        state: item.state === 1 ? '出赛中' : '禁赛中',
                        mobile: item.tel
                    };
                    
                    
                      
                    const newRow = worksheet.addRow(row);
                    
            
                    // 根据状态设置字体颜色
                    newRow.getCell('state').font = {
                        color: { argb: item.state === 1 ? 'FF00FF00' : 'FFFF0000' }, // 绿色或红色
                    };
                });
            
                  // 使用file-saver保存文件
                const buffer = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'export.xlsx');
            },
            initializeSelection() {
                  // 假设所有字段默认都是选中的
                  if (this.members.length > 0) {
                    Object.keys(this.members[0]).forEach(key => {
                      this.selectedFields[key] = true;
                    });
                  }
            },
            handleCheckboxChange(key, value) {
                console.log(this.selectedFields)
                  this.$set(this.selectedFields, key, value);
                  this.$forceUpdate();
            },
            async exportData() {
                  // 创建一个新的工作簿
                  let workbook = new ExcelJS.Workbook();
                  let worksheet = workbook.addWorksheet('My Sheet');
                  
                  
                    
                const fieldMapping = { 
                    id: '序号',
                    portrait: '头像',
                    name: '名称',
                    playno: '球号',
                    sfzh: "身份证号",
                    playtype: "身份类型",
                    sex: "性别",
                    height: "身高",
                    weight: "体重",
                    state: "状态",
                    tel: "手机号",
                };
            
                  // 创建一个标题行
                  let titles = [];
                  let dataKeys = [];
                  for (const key in this.selectedFields) {
                    if (this.selectedFields[key]) {
                      titles.push(fieldMapping[key] || key);
                      dataKeys.push(key);
                    }
                  }
                  worksheet.addRow(titles);
                  
            
                  // 增加数据行
                  
                  this.members.forEach((row) => {
                    let rowData = dataKeys.map(key => {
                          if (key === 'state') {
                            // 根据状态设置单元格的值和样式
                            let cellValue = row[key] === 0 ? '出赛中' : '禁赛中';
                            return { value: cellValue, style: { font: { color: { argb: row[key] === 0 ? 'FF008000' : 'FFFF0000' } } } };
                          } else if(key === 'sex'){
                              let cellValue = row[key] === 1 ? '男' : '女';
                              return { value: cellValue };
                            } else if(key === 'playtype'){
                              let cellValue = this.getTypeName(row[key]);
                              return { value: cellValue };
                            } else {
                            return row[key];
                          }
                          
                        });
                        let newRow = worksheet.addRow(rowData.map(cell => cell.value || cell));
                        // 应用样式
                        rowData.forEach((cell, index) => {
                          if (cell.style) {
                            newRow.getCell(index + 1).font = cell.style.font;
                          }
                        });
                  });
            
                  // 使用exceljs导出数据
                  const buffer = await workbook.xlsx.writeBuffer();
                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                  const fileExtension = '.xlsx';
            
                  const blob = new Blob([buffer], {type: fileType});
                  const filename = this.$route.params.t+"_球员列表"
                  // 保存文件
                  saveAs(blob, filename+`${fileExtension}`);
                    
                  // 关闭弹出层
                  this.showExportDialog = false;
            },
            async exportToPng() {
                console.log(1547)
                const node = document.getElementById('exportTemplate');
                try {
                    const blob = await domtoimage.toBlob(node);
                    const filename = this.$route.params.t+"_球员列表"
                    saveAs(blob, filename+'.png');
                    this.showExportPngDialog = false
                } catch (err) {
                    console.error('Oops, something went wrong!', err);
                }
            },
            showPre(url) {
                this.preimg = url
                this.is_show = true
            },
            getTypeName(type) {
                switch (type) {
                  case 0: return '队员';
                  case 1: return '队长';
                  case 2: return '教练兼队员';
                  case 3: return '领队兼队员';
                  case 4: return '教练';
                  case 5: return '领队';
                  default: return '未知';
                }
            },
            calculateAge(idNumber) {
                if (idNumber && idNumber.length === 18) {
                  // 获取出生年月日
                  const birthYear = idNumber.substring(6, 10);
                  const birthMonth = idNumber.substring(10, 12);
                  const birthDay = idNumber.substring(12, 14);
                  
                  // 创建出生日期
                  const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
                  const now = new Date();
                  
                  // 计算年龄
                  let age = now.getFullYear() - birthDate.getFullYear();
                  const m = now.getMonth() - birthDate.getMonth();
                  
                  // 如果当前月份小于出生月份，或者月份相同但当前日期小于出生日期，则年龄减1
                  if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
                    age--;
                  }
                  
                  return age+"岁";
                }
                return "未知"; // 如果身份证号不合法，返回null
            },
			showGrade(id,name) {
				console.log(id)
				this.memberGrade = true
				this.memberGradeTitle = "个人数据("+name+")"
				let formData = new FormData();
				formData.append('oid', this.oid);
				formData.append('liveid', this.liveid);
				formData.append('player', id);
				this.$api.post('m/match/playerdata', formData).then(response => {
				    this.memberData = response.data.data;
				})
				
			},
			formatFullDate(value) {
			    const date = new Date(value);
			    const options = { year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit', second: '2-digit' };
			    return date.toLocaleString('default', options);
			},
            
        }
    }
</script>

<style scoped>
    
    .main-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5px; /* 根据需要调整间隙 */
        overflow-x: scroll;
        padding: 0px 5px;
    }
    
    .member-item {
        width: 370px;
        height: 240px;
        background-color: #fff8f5;
        border-radius: 10px;
        display: flex; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
        margin: 15px 0px;
        cursor: pointer;
        margin-right: 25px;
    }
    
    .member-item:nth-child(4n) {
        margin-right: 0px;
    }
    
    .member-item:hover {
        box-shadow: 0px 0px 0px 4px #1296db;
    }
    
    .member-item-l {
        
        width: 80px;
        position: relative;
        border-right: 2px dashed #fff;
        border-radius: 10px 0px 0px 10px;
    }
    
    .member-item-l-box {
        width: 100px;
        height: 100px;
        border: 5px solid #f0f0f0;
        border-radius: 100px;
        position: absolute;
        right: -50px;
        top: 30px;
        
    }
    
    .member-item-face {
        width: 100%;
        height: 100%;
        border-radius: 100px;
    }
    
    .member-item-sex {
        width: 35px;
        height: 35px;
        background-color: #fff;
        text-align: center;
        line-height: 35px;
        border-radius: 35px;
        font-size: 20px;
        color: #1296db;
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid #f0f0f0;
    }
    
    .nan-class {
        color: #1296db;
    }
    
    .nv-class {
        color: #ff679c;
    }
    
    .el-icon-male {
        font-weight: bolder;
        
    }
    
    .member-item-r {
        padding-left: 70px;
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        text-align: left;
        position: relative;
        pointer-events: none;
    }
    
    .member-item-name {
        font-size: 24px;
        font-weight: bold;
    }
    
    .member-item-number {
        padding-left: 10px;
        font-weight: 400;
        color: #1296db;
    }
    
    .member-item-info {
        display: flex;
        flex-direction: column;
    }
    
    .member-item-info-inline {
        height: 30px;
        line-height: 30px;
        color: #666;
    }
    
    .member-item-info-short .iconfont{
        font-size: 20px;
    }
    .member-item-info-inline .iconfont {
        font-size: 16px;
    }
    
    .member-item-info-type {
        height: 50px;
        line-height: 50px;
        
    }
    
    .state {
        display: block;
        position: absolute;
        bottom: 20px;
        text-align: center;
        
        background-color: #fff;
        padding: 3px 5px;
        border-radius: 20px;
        margin: 0 auto;
        font-size: 14px;
        left: 0;
        right: 0;
        width: 50px;
    }
    
    .run {
        color: #2dca5c;
        border: 1px solid #2dca5c;
    }
    
    .stop {
        color: #ca2b2b;
        border: 1px solid #ca2b2b;
    }
    
    .runBg {
        background-color: #e2f0d9;
    }
    
    .stopBg {
        background-color: #fbe5d6;
    }
    
    .edit-box {
        position: absolute;
        color: #1296db;
        right: -10px;
        top: 20px;
        pointer-events: auto;
    }
    
    .edit-box:hover {
        color: #0a88db;
    }
    
    .top-btns {
        line-height: 80px;
        text-align: center;
        position: fixed;
        right: 50px;
        bottom: 50px;
        background-color: #0a88db;
        width: 80px;
        height: 80px;
        color: #fff;
        font-size: 50px;
        z-index: 999;
        border-radius: 80px;
        cursor: pointer;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .top-btns1 {
        line-height: 60px;
        text-align: left;
    }
    
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
    
    .top-btns {
      animation: pulse 2s infinite;
    }
    
    .top-btns:hover {
      background-color: #0477db; /* 选择一个与原始颜色对比度更高的颜色 */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 轻微的阴影效果 */
      transform: scale(1.1);
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
      
    }
    
    .ripple {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple-animation 1s linear infinite;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(91, 206, 241, .3);
    }
    
    @keyframes ripple-animation {
      0% {
        transform: scale(0);
        opacity: 0.5;
      }
      50% {
        opacity: 0.75;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
    
    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }
    
    .checkbox-group .el-form-item {
        margin-right: 20px;
    }
    
    .export-png-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }
    
    .export-png-box:after {
      content: "";
      width: calc(100% / 3 - 30px);
    }
    
    .export-png-box-item {
        display: flex;
        width: calc(100% / 4 - 30px);
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 40px;
        flex-direction: column;
    }
    
    .export-png-box-item-face {
        width: 120px;
        height: 120px;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        border: 1px solid #f7f7f7;
    }
    
    .export-png-box-item-face img {
        width: 100%;
        height: 100%;
    }
    
    .export-png-box-item-no {
        position: absolute;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        background-color: #f00;
        left: 0;
        top: 0;
        color: #fff;
        border-radius: 0px 0px 15px 0px;
        font-weight: bold;
    }
    
    .export-png-box-item-info {
        margin-left: 10px;
        width: 120px;
        text-align: left;
    }
    
    .export-png-box-item-name {
        
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
    }
    
    .export-png-box-item-type {
        margin-top: 5px;
        color: #666;
        font-size: 16px;
        font-weight: bold
    }
    
    .export-png-box-item-num {
        font-size: 24px;
        font-weight: bold;
        line-height: 80px;
        padding-left: 20px;
        color: #0477db;
    }
    
    #exportTemplate {
        width: 950px;
        background: #fff;
        padding: 20px;
        /* visibility: hidden; */
        /* position: absolute; */
        /* top: -10000px; */
        /* left: 0; */
        
    }
    
    .exportTemplate-nav {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 40px;
    }
    
    .exportTemplate-nav span {
        display: inline-block;
        font-size: 30px;
        margin-left: 30px;
        font-weight: bold;
    }
	
	.scoreBox {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 10px; 
	}
	
	.item_style {
		display: block;
		margin-top: 5px;
		font-size: 16px;
		font-weight: 600;
	}

</style>