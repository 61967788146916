<template>
    <div class="content">
       <!-- <div class="topbox" style="background-color: #fff;">
            <top-nav class="topnav" title="赛程表"></top-nav>
            
        </div> -->
        <div class="main-box">
            
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>分组循环赛程表</span>
                    <div style="text-align: left;margin: 10px 0px;">
                        <el-button type="warning" icon="el-icon-refresh" @click="resetschedules()">重置赛程表</el-button>
                    </div>
                </div>
                
                <div id="tags-container">
                    <div
                      v-for="tag in uniqueTags"
                      :key="tag"
                      class="tag"
                      :class="{ active: activeTag === tag }"
                      @click="setActiveTag(tag)"
                    >
                      {{ tag }}
                    </div>
                    <div
                      class="tag last-tag"
                      :class="{ active: activeTag === '单场添加' }"
                      @click="setActiveTag('单场添加')"
                    >
                      单场添加
                    </div>
                </div>
                <el-table
                  :data="groupsData"
                  style="width: 100%"
                  
                  v-show="is_view != 'all' && is_active_ids.includes(check_active(activeTag))"
                  >
                  <el-table-column
                    prop="team_wave"
                    label=""
                    width="180"
                    align="center"
                    >
                    <template slot-scope="scope">
                        第{{scope.row.rounds}}轮
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label=""
                    align="left"
                    >
                    <template slot-scope="scope">
                        <span style="font-weight: bold;">{{scope.row.homename}}</span> 
                        <span style="color: #f00;"> VS </span> 
                        <span style="font-weight: bold;">{{scope.row.guestname}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="tools"
                    label=""
                    width="180"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <el-button @click="addTeam(scope.row)" :type="is_active_ids.includes(scope.row.id) ? 'info' : 'primary'" size="small" :disabled="is_active_ids.includes(scope.row.id)">添加</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button style="margin-top: 20px;" type="error" icon="el-icon-s-promotion" @click="createschedules(activeTag)" v-show="is_view != 'all' &&  !is_active_ids.includes(check_active(activeTag))">生成组对阵表</el-button>
                <el-table
                  :data="teamsData"
                  style="width: 100%"
                  v-show="is_view == 'all'"
                  >
                  <el-table-column
                    prop="teamgroup"
                    label=""
                    width="180"
                    align="center"
                    >
                    <template slot-scope="scope">
                        {{scope.row.teamgroup}}组
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label=""
                    align="left"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="tools"
                    label=""
                    width="360"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <el-button @click="addhome(scope.row)" type="danger" size="small">添加主场</el-button>
                        <el-button @click="addaway(scope.row)" type="primary" size="small">添加客场</el-button>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>赛程表操作区</span>
                    <div style="text-align: left;margin: 10px 0px;">
                        <!-- <el-button type="primary" icon="el-icon-download" @click="exportSignExcel()">生成EXCEL赛程表</el-button> -->
                        <el-button type="primary" icon="el-icon-download" @click="exportExcel()">生成EXCEL赛程表</el-button>
                        <!-- <el-button type="success" icon="el-icon-download">下载比赛表</el-button> -->
                    </div>
                </div>
                
                <el-table
                  :data="schedulesData"
                  style="width: 100%;"
                  border
                  >
                  <el-table-column
                    prop="id"
                    label="场序"
                    align="center"
                    width="50"
                    type="index"
                    >
                  </el-table-column>
                  <!-- <el-table-column
                    prop="yjkssj"
                    label="日期"
                    align="center"
                     width="230"
                    >
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.yjkssj"
                            type="datetime"
                            placeholder="选择日期时间"
                            size="mini"
                            style="width: 68%;"
                            format="yyyy/MM HH:mm"
                             @blur="updateTime(scope.row)" @focus="oldTime = scope.row.yjkssj"
                            >
                        </el-date-picker>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    prop="rounds"
                    label="轮次"
                    align="center"
                    width="50"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="matchgroup"
                    label="组别"
                    align="center"
                    width="50"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="teamgroup"
                    label="队伍"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <span :style="scope.row.homename == '待添加主场' ? 'color: #ff8205' : ''">{{scope.row.homename}}</span>
                        <span style="color: #f00;"> VS </span>
                        <span :style="scope.row.guestname == '待添加客场' ? 'color: #ff8205' : ''">{{scope.row.guestname}}</span>
                    </template>
                  </el-table-column>
                  
                  
                  
                  <el-table-column
                    prop="tools"
                    label="操作"
                    width="50"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <!-- <el-dropdown trigger="click" v-if="scope.row.status !== -1">
                            <span class="el-dropdown-link">
                                <i class="el-icon-arrow-down el-icon-more"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-time" @click.native="matchTime(scope.row)">单节时长</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-edit" divided @click.native="save(scope.row)">保存</el-dropdown-item>
                                <el-dropdown-item style="color: #F56C6C;" icon="el-icon-delete" @click.native="del(scope.row.id)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->
                        <span @click="del(scope.row.id)" style="color: #f00;cursor: pointer;">取消</span>
                    </template>
                  </el-table-column>
                </el-table>
                <el-dialog
                  title="设置单节时长"
                  :visible.sync="dialogVisible"
                  width="15%"
                  :before-close="cancelDialog">
                  <el-select v-model="singleTime" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelDialog()">取 消</el-button>
                    <el-button type="primary" @click="submitDialog()">确 定</el-button>
                  </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import ExcelJS from "exceljs";
    import saveAs from "file-saver";
    export default {
        data() {
            return {
                dialogVisible: false,
                dialogData: [],
                options: [{
                  value: '12',
                  label: '12分钟'
                }, {
                  value: '10',
                  label: '10分钟'
                }, {
                  value: '6',
                  label: '6分钟'
                }],
                singleTime: "",
                teamsData: [],
                groupsData: [],
                schedulesData: [],
                activeTag: "",
                is_view: "all",
                oldPlace: "",
                oldTime: "",
                tmpTeam: {
                    id: "",
                    liveid: "",
                    rounds: 1,
                    homeid: "",
                    guestid: "",
                    scheduleid: 0,
                    homename: "待添加主场",
                    guestname: "待添加客场",
                    teamgroup: "",
                    yjkssj: "",
                    singlesection: "",
                    status: 0,
                    is_save:false,
                    is_tmp: true
                },
                tmpTeamGroup: {
                    id: "",
                    team_wave: "",
                    team_first: "",
                    team_second: "",
                    teamgroup: "",
                    single_time: "",
                    is_save:false,
                },
                is_active_ids: [],
                liveid: "",
                gamename: ""
            }
        },
        computed: {
            uniqueTags() {
                const tagSet = new Set(this.teamsData
                  .map(item => {
                    if (item.teamtag === null || item.teamtag === undefined) {
                      return `${item.teamgroup}组`;
                    } else {
                      return `${item.teamtag}/${item.teamgroup}组`;
                    }
                  })
                );
        
              return [ ...Array.from(tagSet)];
            },
            filteredTableData() {
              if (this.activeTag === '单场添加') {
                return this.teamsData;
              } else {
                return this.teamsData.filter(item => {
                  const tag = item.teamgroup;
                  return tag === this.activeTag;
                });
              }
            }
        },
        created() {
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.tmpTeam.liveid = response.data.data.id
                    this.gamename = response.data.data.name
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } 
            this.fetchTeamData()
        },
        methods: {
            async exportExcel() {
                  // 创建一个新的工作簿
                  const workbook = new ExcelJS.Workbook();
                  // 添加一个新的工作表
                  const worksheet = workbook.addWorksheet('My Sheet');
            
                  // 样式配置
                  const titleRowStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEBF1DE' } };
                  const headerRowStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFDE9D9' } };
                  const subHeaderRowStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFCD5B4' } };
                  const sceneColumnStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFDE9D9' } };
                  const dateColumnStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDAEEF3' } };
                  const timeColumnStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE4DFEC' } };
                  const defaultCellStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
            
                  // 设置标题行 A1-I1
                  worksheet.mergeCells('A1:I1');
                  const titleRow = worksheet.getRow(1);
                  titleRow.height = 27; // 设置整行的高度
                  const titleCell = worksheet.getCell('A1');
                  titleCell.value = this.gamename+"赛程表"; // 这里设置你的标题
                  titleCell.fill = titleRowStyle;
                  titleCell.alignment = { vertical: 'middle', horizontal: 'center' };
                  titleCell.font = {
                      bold: true,
                      size: 18
                  }
                  
                  worksheet.getColumn('F').width = 20;
                  worksheet.getColumn('H').width = 20;
                  worksheet.getColumn('A').font = {bold: true,  size: 12};
            
                  // 设置表头 A2-I2
                  const headers = ['场序', '日期', '时间', '组别', '轮次', '主队', 'VS', '客队', '备注'];
                  const headerRow = worksheet.getRow(2);
                  headers.forEach((header, index) => {
                    const cell = headerRow.getCell(index + 1);
                    cell.value = header;
                    cell.fill = headerRowStyle;
                    cell.border = {
                      top: { style: 'thin' },
                      left: { style: 'thin' },
                      bottom: { style: 'thin' },
                      right: { style: 'thin' }
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    cell.font = {
                        bold: true,
                        size: 12
                    }
                  });
                  headerRow.height = 27;
            
                  // 设置副标题行 A3-I3
                  worksheet.mergeCells('A3:I3');
                  const subHeaderRow = worksheet.getRow(3);
                  subHeaderRow.height = 27; // 设置整行的高度
                  const subHeaderCell = worksheet.getCell('A3');
                  subHeaderCell.fill = subHeaderRowStyle;
                  subHeaderCell.border = {
                      top: { style: 'thin' },
                      left: { style: 'thin' },
                      bottom: { style: 'thin' },
                      right: { style: 'thin' }
                    };
            
                  // 设置数据行的样式
                  // 假设只有一行数据，可以根据实际情况循环添加多行数据
                  this.schedulesData.forEach((item,index) => {
                      let dataRow = worksheet.getRow(index+4);
                      dataRow.height = 27;
                      dataRow.getCell(1).fill = sceneColumnStyle; // 场序列背景色
                      dataRow.getCell(1).border = {
                          top: { style: 'thin' },
                          left: { style: 'thin' },
                          bottom: { style: 'thin' },
                          right: { style: 'thin' }
                        };
                      dataRow.getCell(2).fill = dateColumnStyle; // 日期列背景色
                      dataRow.getCell(2).alignment = { vertical: 'middle', horizontal: 'center' }
                      dataRow.getCell(2).border = {
                          top: { style: 'thin' },
                          left: { style: 'thin' },
                          bottom: { style: 'thin' },
                          right: { style: 'thin' }
                        };
                      dataRow.getCell(3).fill = timeColumnStyle; // 时间列背景色
                      dataRow.getCell(3).alignment = { vertical: 'middle', horizontal: 'center' }
                      dataRow.getCell(3).border = {
                          top: { style: 'thin' },
                          left: { style: 'thin' },
                          bottom: { style: 'thin' },
                          right: { style: 'thin' }
                        };
                                  
                      // 设置主队、客队等其他单元格的样式
                      for (let i = 4; i <= 9; i++) {
                        dataRow.getCell(i).fill = defaultCellStyle;
                        dataRow.getCell(i).border = {
                          top: { style: 'thin' },
                          left: { style: 'thin' },
                          bottom: { style: 'thin' },
                          right: { style: 'thin' }
                        };
                        dataRow.getCell(i).alignment = { vertical: 'middle', horizontal: 'center' }
                      }
                                  
                      const jsonData = item
                                  
                      // 填充数据到表格
                      dataRow.getCell(1).value = index+1; // 场序
                      dataRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' }
                      dataRow.getCell(4).value = jsonData.matchgroup; // 组别
                      dataRow.getCell(5).value = jsonData.rounds; // 轮次
                      dataRow.getCell(6).value = jsonData.homename; // 主队
                      dataRow.getCell(6).width = 20;
                      dataRow.getCell(6).alignment = { vertical: 'middle', horizontal: 'left' }
                      dataRow.getCell(7).value = 'VS'; // VS
                      dataRow.getCell(8).value = jsonData.guestname; // 客队
                      dataRow.getCell(8).alignment = { vertical: 'middle', horizontal: 'left' }
                      dataRow.getCell(8).width = 20;
                      dataRow.getCell(9).value = '';
                  })
            
                  // 导出 Excel 文件
                  const buffer = await workbook.xlsx.writeBuffer();
                  saveAs(new Blob([buffer]), this.gamename+'-赛程表.xlsx');
            },
            fetchTeamData() {
                this.$api.get('api/Team/Teams', {
                  params: {
                    limit: -1,
                    PageIndex: 1,
                  }
                })
                .then(response => {
                    this.teamsData = response.data.rows
                    this.activeTag = this.uniqueTags[0]
                    this.is_view = this.uniqueTags[0]
                    
                    this.initScheduleData()
					this.fetchScheduleData(this.check_active(this.uniqueTags[0]).replace('组', ''))

                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            initScheduleData() {
                this.is_active_ids = []
                const uniqueTeamGroups = new Set(this.teamsData.map(item => item.teamgroup));
                uniqueTeamGroups.forEach(teamgroup => {
                    this.fetchScheduleData(teamgroup);
                });
				
                
            },
            fetchScheduleData(group) {
                let formData = new FormData();
                formData.append('liveid', this.liveid);
                formData.append('teamgroup', group);
                formData.append('pagesize', -1);
                this.$api.post('app/match/schedulelist', formData).then(response => {
                    if (response.data.code == 1) {
                        if (!this.is_active_ids.includes(group+"组")) {
                            this.is_active_ids.push(group+"组")
                        }
						console.log("存入数据",this.is_active_ids)
                        this.groupsData = response.data.data.rows.filter(item => item.homename !== null && item.guestname !== null)
                    }
					
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            fetchVersusData() {
                this.$api.get('api/Match/List', {
                  params: {
                    limit: -1,
                    PageIndex: 1,
                    status: -7
                  }
                })
                .then(response => {
                    this.schedulesData = response.data.rows
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            setActiveTag(tag) {
                this.activeTag = tag; // 设置当前激活的标签
                if (this.activeTag === '单场添加') {
                    this.is_view = "all"
                } else {
                   this.is_view = tag
                   this.fetchScheduleData(this.check_active(tag).replace('组', ''))
                }
            },
            updatePlace(row) {
                let id = row.id
                this.$confirm('确认修改场地?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    let item = this.schedulesData.find(item => item.id === id)
                    item.place = this.oldPlace
                    this.$message({
                        type: 'info',
                        message: '已取消修改'
                    });          
                });
                
            },
            updateTime(row) {
                let id = row.id
                this.$confirm('确认修改日期?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    let item = this.schedulesData.find(item => item.id === id)
                    item.yjkssj = this.oldTime
                    this.$message({
                        type: 'info',
                        message: '已取消修改'
                    });          
                });
            },
            del(id) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.schedulesData = this.schedulesData.filter(item => item.id !== id)
                    this.is_active_ids = this.is_active_ids.filter((item) => item !== id);
                    // let formData = new FormData();
                    // formData.append('id', id);
                    // this.$api.post('app/match/delschedule', formData).then(response => {
                    //     if (response.data.code == 1) {
                    //         this.initScheduleData()
                    //         this.schedulesData = this.schedulesData.filter(item => item.id !== id)
                    //         this.singleTime = ""
                    //        this.$message({
                    //            type: 'success',
                    //            message: '已删除!'
                    //        }); 
                    //     } else {
                    //         this.$message({
                    //             type: 'error',
                    //             message: response.data.data
                    //         });
                    //     }
                    // })
                    // .catch(error => {
                    //   console.error("There was an error!", error);
                    // });
                }).catch(() => {
                             
                });   
            },
            save(row) {
                console.log(row)
                if (row.yjkssj === 0 || row.yjkssj === "" || row.yjkssj === null) {
                    this.$message({
                        type: 'warning',
                        message: '请先选择日期!'
                    });
                    return;
                }
                this.$confirm('确认要保存吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    if (row.is_tmp === true) {
                        formData.append('id', 0);
                    } else {
                        formData.append('id', row.id);
                    }
                    
                    formData.append('liveid', row.liveid);
                    formData.append('homeid', row.homeid);
                    formData.append('guestid', row.guestid);
                    formData.append('tempid', row.scheduleid);
                    formData.append('yjkssj', row.yjkssj);
                    formData.append('singlesection', row.singlesection);
                    this.$api.post('app/match/saveschedule', formData).then(response => {
                        if (response.data.code == 1) {
                            this.fetchVersusData()
                           this.$message({
                               type: 'success',
                               message: '保存成功!'
                           }); 
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                }).catch(() => {
                             
                });
            },
            addhome(row) {
                if(this.tmpTeam.id === "") {
                    this.tmpTeam.id = +new Date()
                }
                if (this.tmpTeam.guestname == row.name) {
                    this.$message({
                        type: 'error',
                        message: '已经是客场队伍了!'
                    });
                    return false;
                }
                this.tmpTeam.homename = row.name
                this.tmpTeam.homeid = row.id
                const exists = this.schedulesData.some(item => item.id === this.tmpTeam.id);
                if (exists) {
                    let item = this.schedulesData.find(item => item.id === this.tmpTeam.id)
                    item.homename = row.name
                    item.matchgroup = "A"
                    if (item.guestname !== "待添加客场") {
                        this.tmpTeam = {
                            id: "",
                            liveid: "",
                            rounds: 1,
                            homeid: "",
                            guestid: "",
                            scheduleid: 0,
                            homename: "待添加主场",
                            guestname: "待添加客场",
                            teamgroup: "",
                            yjkssj: "",
                            singlesection: "",
                            status: 0,
                            is_save:false,
                            is_tmp: true,
                        }
                    }
                    
                } else {
                    this.schedulesData.push(this.tmpTeam)
                }
                this.$message({
                    type: 'success',
                    message: '已添加主场!'
                });
                
            },
            addaway(row) {
                if(this.tmpTeam.id === "") {
                    this.tmpTeam.id = +new Date()
                }
                if (this.tmpTeam.homename == row.name) {
                    this.$message({
                        type: 'error',
                        message: '已经是主场队伍了!'
                    });
                    return false;
                }
                this.tmpTeam.guestname = row.name
                this.tmpTeam.guestid = row.id
                const exists = this.schedulesData.some(item => item.id === this.tmpTeam.id);
                if (exists) {
                    let item = this.schedulesData.find(item => item.id === this.tmpTeam.id)
                    item.guestname = row.name
                    item.matchgroup = "A"
                    if (item.homename !== "待添加主场") {
                        this.tmpTeam = {
                            id: "",
                            liveid: "",
                            rounds: 1,
                            homeid: "",
                            guestid: "",
                            scheduleid: 0,
                            homename: "待添加主场",
                            guestname: "待添加客场",
                            teamgroup: "",
                            yjkssj: "",
                            singlesection: "",
                            status: 0,
                            is_save:false,
                            is_tmp: true,
                        }
                    }
                } else {
                    this.schedulesData.push(this.tmpTeam)
                }
                this.$message({
                    type: 'success',
                    message: '已添加客场!'
                });
            },
            matchTime(row) {
                this.dialogData = row
                this.dialogVisible = true
            },
            cancelDialog() {
                this.dialogVisible = false
            },
            submitDialog() {
                this.dialogVisible = false
                this.$confirm('确认要保存吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '已保存!'
                    });
                }).catch(() => {
                    
                });
            },
            resetschedules() {
                this.$confirm('确认要重置赛程表吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.schedulesData = []
                    let formData = new FormData();
                    formData.append('liveid', this.liveid);
                    this.$api.post('app/match/resetschedule', formData).then(response => {
                        if (response.data.code == 1) {
                            this.initScheduleData()
                            this.$message({
                                type: 'success',
                                message: '重置成功!'
                            });
                            
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                    
                }).catch(() => {
                    
                });
                
            },
            createschedules() {
                let formData = new FormData();
                formData.append('liveid', this.liveid);
                formData.append('teamgroup', this.check_active(this.activeTag).replace('组', ''));
                this.$api.post('app/match/createschedule', formData).then(response => {
                    if (response.data.code == 1) {
                        this.fetchScheduleData(this.check_active(this.activeTag).replace('组', ''))
                        this.$message({
                            type: 'success',
                            message: '生成成功!'
                        });
                        
                        this.is_active_ids.push(this.activeTag)
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            addTeam(row) {
                // let formData = new FormData();
                // formData.append('tempid', id);
                // this.$api.post('app/match/addschedule', formData).then(response => {
                //     if (response.data.code == 1) {
                //         this.fetchScheduleData(this.activeTag.slice(0, -1))
                //         // this.fetchVersusData()
                //         this.$message({
                //             type: 'success',
                //             message: '添加成功!'
                //         });
                //     } else {
                //         this.$message({
                //             type: 'error',
                //             message: response.data.data
                //         });
                //     }
                // })
                // .catch(error => {
                //   console.error("There was an error!", error);
                // });
                const exists = this.schedulesData.some(item => item.homename === row.homename && item.guestname === row.guestname);
                if (exists) {
                    this.$message({
                        type: 'error',
                        message: '已经添加过了!'
                    });
                } else {
                    this.is_active_ids.push(row.id)
                    console.log(this.schedulesData)
                    this.schedulesData.push(row)
                }
            },
			check_active(tag) {
				if(tag.includes('/')) {
					return tag.split('/')[1]
				} else {
					return tag
				}
			}
        }
    }
</script>

<style scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 - 50px);
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 - 50px);
        margin: 10px;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
      flex-wrap: wrap;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: 60px;
      height: 20px;
      position: relative;
      bottom: -6px;
      border-bottom: 1px solid #ddd;
      margin-top: 3px;
    }
    
    .last-tag {
        position: absolute;
        right: 0;
        bottom: 0px;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    
    .last-tag.active {
        position: absolute;
        right: 0;
        bottom: -1px;
        border-bottom: none;
    }
    
    .topbox {
        padding: 20px;
        margin: 10px;
        border-radius: 15px;
    }
    
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    

    
</style>