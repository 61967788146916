<template>
    <div class="content">
        <div class="topbox" style="background-color: #fff;">
            <top-nav title="详细数据" :isBak="true"></top-nav>
            <div class="btn-area">
                <el-button type="success" icon="el-icon-video-play" v-if="mainData.videonum > 0" @click="showVideo(allVideo)">观看回放</el-button>
                <el-button type="primary" icon="el-icon-download" @click="dialogPngVisible = true">生成记录表</el-button>
            </div>
            <div class="card-box top-grade-box">
                    <div class="team-logo-box" style="flex-direction: column;">
                        <el-avatar @click.native="showPre(homeLogo)" style="margin: 0 auto;" shape="circle" :size="80" :src="homeLogo"></el-avatar>
                        <span class="bold-font">{{mainData.homename}}</span>
                    </div>
                    <div class="bold-font scale">{{mainData.homescore}} : {{mainData.guestscore}}</div>
                    <div class="team-logo-box" style="flex-direction: column;">
                        <el-avatar  @click.native="showPre(awayLogo)"  style="margin: 0 auto;" shape="circle" :size="80" :src="awayLogo"></el-avatar>
                        <span class="bold-font">{{mainData.guestname}}</span>
                    </div>
            </div>
        </div>
        <div class="main-box">
            
            <div class="card-box top-grade-info-box" :style="show_more ? 'height: auto' : 'height: 300px'">
                <div class="table-box">
                    <el-table
                        :data="homeData"
                        style="width: 100%">
                        <el-table-column
                        prop="name"
                        label="球员"
                        >
                        <template slot-scope="scope">
                            <div class="team-logo-box">
                                
                                <div class="team-logo">
                                    <div class="block">
                                        <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="60" :src="scope.row.portrait"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info">
                                    <router-link :to="`/teammembers/${scope.row.name}`">
                                    <span class="team-info-name">{{scope.row.name}}</span>
                                    </router-link>
                                    <div style="display: flex;">
                                        <!-- <span class="team-info-score" v-for="(item,index) in scope.row.tags" :key="index" :title="item">{{item}}</span> -->
                                        <span class="team-info-score" v-if="homeStarter.includes(scope.row.id)">首发</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="penalt"
                        label="罚篮"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="perimetershot"
                        label="二分"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="threepoint"
                        label="三分"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="foul"
                        label="犯规"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="technicalfoul"
                        label="技犯"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="fault"
                        label="失误"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="defrebound"
                        label="篮板"
                        width="50"
                        align="center"
                        >
                        <template slot-scope="scope">
                            {{ scope.row.defrebound + scope.row.offrebound}}
                        </template>
                        
                        </el-table-column>
                        <el-table-column
                        prop="blockshot"
                        label="盖帽"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="assists"
                        label="助攻"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="steals"
                        label="抢断"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table-box">
                    <el-table
                        :data="awayData"
                        style="width: 100%">
                        
                        <el-table-column
                        prop="penalt"
                        label="罚篮"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="perimetershot"
                        label="二分"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="threepoint"
                        label="三分"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="foul"
                        label="犯规"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="technicalfoul"
                        label="技犯"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="fault"
                        label="失误"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="defrebound"
                        label="篮板"
                        width="50"
                        align="center"
                        >
                        <template slot-scope="scope">
                            {{ scope.row.defrebound + scope.row.offrebound}}
                        </template>
                        
                        </el-table-column>
                        <el-table-column
                        prop="blockshot"
                        label="盖帽"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="assists"
                        label="助攻"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="steals"
                        label="抢断"
                        width="50"
                        align="center"
                        >
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="球员"
                        align="right"
                        >
                        <template slot-scope="scope">
                            <div class="team-logo-box-right">
                                <div class="team-info" style="text-align: right;">
                                    <router-link :to="`/teammembers/${scope.row.name}`">
                                    <span class="team-info-name">{{scope.row.name}}</span>
                                    </router-link>
                                    <div style="display: flex;justify-content: right;">
                                        <!-- <span class="team-info-score" v-for="(item,index) in scope.row.tags" :key="index">{{item}}</span> -->
                                        <span class="team-info-score" v-if="awayStarter.includes(scope.row.id)">首发</span>
                                    </div>
                                </div>
                                <div class="team-logo">
                                    <div class="block">
                                        <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="60" :src="scope.row.portrait"></el-avatar>
                                    </div>
                                </div>
                            </div>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-link class="show-more" :underline="false" @click="show_more = !show_more">{{ show_more ? "收起" : "查看更多" }}<i class=" el-icon--right" :class="show_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i> </el-link>
            </div>
            
            <div class="top-grade-detail-box">
                <div class="detail-left detail-item">
                    <div class="card-box card-item">
                        <div class="item-title">球队统计</div>
                        <div class="item-main">
                            <div class="team-top-nav">
                                <el-avatar @click.native="showPre(homeLogo)" shape="circle" :size="50" :src="homeLogo"></el-avatar>
                                <span class="team-top-nav-name">{{mainData.homename}}</span>
                                <span class="scale-vs">vs</span>
                                <span class="team-top-nav-name">{{mainData.guestname}}</span>
                                <el-avatar @click.native="showPre(awayLogo)" shape="circle" :size="50" :src="awayLogo"></el-avatar>
                            </div>
                            <div class="team-process-item" v-for="(item,index) in teamData" :key="index">
                                <div class="team-process-header">
                                    <div>{{item.home}}</div>
                                    <span>{{item.name}}</span>
                                    <div>{{item.away}}</div>
                                </div>
                                <div class="team-process-line">
                                    <div class="line-item">
                                        <el-progress class="custom-progress" :percentage="(item.home / item.total) * 100" :color="item.home >= item.away ? '#00f' : '#ccc'" :show-text="false" stroke-linecap="butt" ></el-progress>
                                    </div>
                                    <div class="line-item">
                                        <el-progress  :percentage="(item.away / item.total) * 100" :color="item.away >= item.home ? '#f00' : '#ccc'" :show-text="false" stroke-linecap="butt"></el-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-box card-item">
                        <div class="item-title">各项最佳</div>
                        <div class="item-main">
                            <div v-for="(item,index) in otherDate" :key="index">
                                <div class="team-top-nav">
                                    <el-avatar @click.native="showPre(item.homeface)" shape="circle" :size="50" :src="item.homeface"></el-avatar>
                                    <span class="team-top-nav-name">{{item.homename}}</span>
                                    <span class="scale-vs">vs</span>
                                    <span class="team-top-nav-name">{{item.awayname}}</span>
                                    <el-avatar @click.native="showPre(item.awayface)" shape="circle" :size="50" :src="item.awayface"></el-avatar>
                                </div>
                                <div class="team-process-item">
                                    <div class="team-process-header">
                                        <div>{{item.home}}</div>
                                        <span>{{item.name}}</span>
                                        <div>{{item.away}}</div>
                                    </div>
                                    <div class="team-process-line">
                                        <div class="line-item">
                                            <el-progress class="custom-progress" :percentage="(item.home / item.total) * 100" :color="item.home >= item.away ? '#00f' : '#ccc'" :show-text="false" stroke-linecap="butt" ></el-progress>
                                        </div>
                                        <div class="line-item">
                                            <el-progress  :percentage="(item.away / item.total) * 100" :color="item.away >= item.home ? '#f00' : '#ccc'" :show-text="false" stroke-linecap="butt"></el-progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-right detail-item">
                    <div class="card-box card-item">
                        <div class="item-title">赛事裁判</div>
                        <div class="item-main-ref">
                            <div class="ref-item" v-for="(item,index) in refData" :key="index">
                                <el-avatar  @click.native="showPre(item.portrait)" shape="circle" :size="80" :src="item.portrait"></el-avatar>
                                <span class="ref-name">{{item.name}}</span>
                                <span class="ref-type">{{ reftype(item.grade) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-box card-item">
                        <div class="item-title">时间节点</div>
                        <div class="item-main-log">
                            <el-tabs class="tabs-box" v-model="activeName" @tab-click="handleClick">
                                <el-tab-pane label="第一节" name="first">
                                    <div class="scroll-box">
                                        <el-timeline>
                                            <el-timeline-item :timestamp="formatDate(item.datetime)" placement="top" v-for="(item,index) in MatchLog1" :key="index">
                                              <el-card v-if="item.playername === '' || item.playername === null">
                                                <h4 class="name-box">{{item.type == 7 && item.typenum != 3 ? "第一节" : item.teamtype == 0 ? "主队" : "客队" }}</h4>
                                                <p>{{item.type == 7 && item.typenum == 3 ? "请求暂停" : item.typenum == 1 ? "开始比赛" : "结束比赛" }}</p>
                                              </el-card>
                                              <el-card v-else>
                                                <h4 class="name-box">
                                                    <div style="display: flex;position: relative;align-items: center;">
                                                        <div class="team-name">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</div>
                                                        <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                        {{item.playername}}&emsp;
                                                        {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}

                                                    </div>
                                                    <el-button round v-if="item.video !== null" @click="showVideo(item.video)">查看回放</el-button>
                                                </h4>
                                              </el-card>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="第二节" name="second">
                                    <div class="scroll-box">
                                        <el-timeline>
                                            <el-timeline-item :timestamp="formatDate(item.datetime)" placement="top" v-for="(item,index) in MatchLog2" :key="index">
                                              <el-card v-if="item.playername === '' || item.playername === null">
                                                <h4 class="name-box">{{item.type == 7 && item.typenum != 3 ? "第二节" : item.teamtype == 0 ? "主队" : "客队" }}</h4>
                                                <p>{{item.type == 7 && item.typenum == 3 ? "请求暂停" : item.typenum == 1 ? "开始比赛" : "结束比赛" }}</p>
                                              </el-card>
                                              <el-card v-else>
                                                <h4 class="name-box">
                                                    <div style="display: flex;position: relative;align-items: center;">
                                                        <div class="team-name">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</div>
                                                        <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                        {{item.playername}}&emsp;
                                                        {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                    </div>
                                                    <el-button round v-if="item.video !== null" @click="showVideo(item.video)">查看回放</el-button>
                                                </h4>
                                              </el-card>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="第三节" name="third">
                                    <div class="scroll-box">
                                        <el-timeline>
                                            <el-timeline-item :timestamp="formatDate(item.datetime)" placement="top" v-for="(item,index) in MatchLog3" :key="index">
                                              <el-card v-if="item.playername === '' || item.playername === null">
                                                <h4 class="name-box">{{item.type == 7 && item.typenum != 3 ? "第三节" : item.teamtype == 0 ? "主队" : "客队" }}</h4>
                                                <p>{{item.type == 7 && item.typenum == 3 ? "请求暂停" : item.typenum == 1 ? "开始比赛" : "结束比赛" }}</p>
                                              </el-card>
                                              <el-card v-else>
                                                <h4 class="name-box">
                                                    <div style="display: flex;position: relative;align-items: center;">
                                                        <div class="team-name">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</div>
                                                        <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                        {{item.playername}}&emsp;
                                                        {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                    </div>
                                                    <el-button round v-if="item.video !== null" @click="showVideo(item.video)">查看回放</el-button>
                                                </h4>
                                              </el-card>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="第四节" name="fourth">
                                    <div class="scroll-box">
                                        <el-timeline>
                                            <el-timeline-item :timestamp="formatDate(item.datetime)" placement="top" v-for="(item,index) in MatchLog4" :key="index">
                                              <el-card v-if="item.playername === '' || item.playername === null">
                                                <h4 class="name-box">{{item.type == 7 && item.typenum != 3 ? "第四节" : item.teamtype == 0 ? "主队" : "客队" }}</h4>
                                                <p>{{item.type == 7 && item.typenum == 3 ? "请求暂停" : item.typenum == 1 ? "开始比赛" : "结束比赛" }}</p>
                                              </el-card>
                                              <el-card v-else>
                                                <h4 class="name-box">
                                                    <div style="display: flex;position: relative;align-items: center;">
                                                        <div class="team-name">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</div>
                                                        <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                        {{item.playername}}&emsp;
                                                        {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                    </div>
                                                    <el-button round v-if="item.video !== null" @click="showVideo(item.video)">查看回放</el-button>
                                                </h4>
                                              </el-card>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                              </el-tabs>
                        </div>
                    </div>
                    <div class="card-box card-item">
                        <div class="item-title">比分</div>
                        <div>
                           <el-table
                                 :data="bfData"
                                 style="width: 100%">
                                 <el-table-column
                                   prop="date"
                                   label=""
                                   align="center"
                                   width="180">
                                   <template slot-scope="scope">
                                       <div class="team-logo">
                                           <div class="block">
                                               <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="60" :src="scope.row.logo"></el-avatar>
                                           </div>
                                       </div>
                                   </template>
                                 </el-table-column>
                                 <el-table-column
                                   prop="one"
                                   align="center"
                                   label="第一节">
                                 </el-table-column>
                                 <el-table-column
                                   prop="two"
                                   align="center"
                                   label="第二节">
                                 </el-table-column>
                                 <el-table-column
                                   prop="three"
                                   align="center"
                                   label="第二节">
                                 </el-table-column>
                                 <el-table-column
                                   prop="four"
                                   align="center"
                                   label="第四节">
                                 </el-table-column>
                               </el-table> 
                        </div>
                    </div>
                    <div class="card-box card-item">
                        <div class="item-title">犯规</div>
                        <div>
                            <el-table
                              :data="fgData"
                              style="width: 100%">
                              <el-table-column
                                prop="date"
                                label=""
                                align="center"
                                width="180">
                                <template slot-scope="scope">
                                    <div class="team-logo">
                                        <div class="block">
                                            <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="60" :src="scope.row.logo"></el-avatar>
                                        </div>
                                    </div>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="one"
                                align="center"
                                label="第一节">
                              </el-table-column>
                              <el-table-column
                                prop="two"
                                align="center"
                                label="第二节">
                              </el-table-column>
                              <el-table-column
                                prop="three"
                                align="center"
                                label="第二节">
                              </el-table-column>
                              <el-table-column
                                prop="four"
                                align="center"
                                label="第四节">
                              </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="card-box card-item">
                        <div class="item-title">暂停</div>
                        <div>
                            <el-table
                              :data="ztData"
                              style="width: 100%">
                              <el-table-column
                                prop="date"
                                label=""
                                align="center"
                                width="180">
                                <template slot-scope="scope">
                                    <div class="team-logo">
                                        <div class="block">
                                            <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="60" :src="scope.row.logo"></el-avatar>
                                        </div>
                                    </div>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="one"
                                align="center"
                                label="第一节">
                              </el-table-column>
                              <el-table-column
                                prop="two"
                                align="center"
                                label="第二节">
                              </el-table-column>
                              <el-table-column
                                prop="three"
                                align="center"
                                label="第二节">
                              </el-table-column>
                              <el-table-column
                                prop="four"
                                align="center"
                                label="第四节">
                              </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
          title="观看回放"
          :visible.sync="dialogVisible"
          width="50%"
          @close="closeVideo">
          <!-- <d-player ref="player" id="player" @play="handlePlay" @pause="handlePause" :options="options"></d-player> -->
          <video style="width: 100%;" :src="options.video.url" autoplay controls controlslist="nodownload" disablePictureInPicture></video>
        </el-dialog>
        <el-dialog
          title="导出预览"
          :visible.sync="dialogPngVisible"
          width="80%">
          <div id="exportTemplate" style="background-color: #fff;">
          <h1>{{mainData.gamename}}记录表</h1>
          <div class="card-box top-grade-box">
                  <div class="team-logo-box" style="flex-direction: column;">
                      <el-avatar @click.native="showPre(homeLogo)" style="margin: 0 auto;" shape="circle" :size="80" :src="homeLogo"></el-avatar>
                      <span class="bold-font">{{mainData.homename}}</span>
                  </div>
                  <div class="bold-font scale">{{mainData.homescore}} : {{mainData.guestscore}}</div>
                  <div class="team-logo-box" style="flex-direction: column;">
                      <el-avatar  @click.native="showPre(awayLogo)"  style="margin: 0 auto;" shape="circle" :size="80" :src="awayLogo"></el-avatar>
                      <span class="bold-font">{{mainData.guestname}}</span>
                  </div>
                  <div class="time-box">
                      {{formatFullDate(mainData.starttime)}}
                  </div> 
          </div>
            
          <div class="main-box">
              <div class="top-grade-detail-box">
                  <div class="detail-left detail-item" style="width: 70%;">
                      <div class="card-box top-grade-info-box" style="height: auto">
                          <div class="table-box" style="width: 50%;">
                              <el-table
                                  :data="homeData"
                                  style="width: 100%">
                                  <el-table-column
                                  prop="name"
                                  label="球员"
                                  width="120"
                                  >
                                  <template slot-scope="scope">
                                      <div class="team-logo-box" style="padding-left: 0px;">
                                          
                                          <div class="team-logo">
                                              <div class="block">
                                                  <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="40" :src="scope.row.portrait"></el-avatar>
                                              </div>
                                          </div>
                                          <div class="team-info">
                                              <span class="team-info-name" style="font-size: 12px;">{{scope.row.name}}</span>
                                              <div style="display: flex;">
                                                  <!-- <span class="team-info-score" v-for="(item,index) in scope.row.tags" :key="index" :title="item">{{item}}</span> -->
                                                  <span class="team-info-score"  style="font-size: 10px;" v-if="homeStarter.includes(scope.row.id)">首发</span>
                                              </div>
                                          </div>
                                      </div>
                                  </template>
                                  </el-table-column>
                                  <el-table-column
                                  prop="penalt"
                                  label="罚篮"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="perimetershot"
                                  label="二分"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="threepoint"
                                  label="三分"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="foul"
                                  label="犯规"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="technicalfoul"
                                  label="技犯"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="fault"
                                  label="失误"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="defrebound"
                                  label="篮板"
                                  width="34"
                                  align="center"
                                  >
                                  <template slot-scope="scope">
                                      {{ scope.row.defrebound + scope.row.offrebound}}
                                  </template>
                                  
                                  </el-table-column>
                                  <el-table-column
                                  prop="blockshot"
                                  label="盖帽"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="assists"
                                  label="助攻"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="steals"
                                  label="抢断"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                              </el-table>
                          </div>
                          <div class="table-box" style="width: 50%;">
                              <el-table
                                  :data="awayData"
                                  style="width: 100%;overflow-x: hidden!important;"
                                  
                                  >
                                  
                                  <el-table-column
                                  prop="penalt"
                                  label="罚篮"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="perimetershot"
                                  label="二分"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="threepoint"
                                  label="三分"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="foul"
                                  label="犯规"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="technicalfoul"
                                  label="技犯"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="fault"
                                  label="失误"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="defrebound"
                                  label="篮板"
                                  width="34"
                                  align="center"
                                  >
                                  <template slot-scope="scope">
                                      {{ scope.row.defrebound + scope.row.offrebound}}
                                  </template>
                                  
                                  </el-table-column>
                                  <el-table-column
                                  prop="blockshot"
                                  label="盖帽"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="assists"
                                  label="助攻"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="steals"
                                  label="抢断"
                                  width="34"
                                  align="center"
                                  >
                                  </el-table-column>
                                  <el-table-column
                                  prop="name"
                                  label="球员"
                                  align="right"
                                  width="120"
                                  >
                                  <template slot-scope="scope">
                                      <div class="team-logo-box-right" style="padding-right: 0px;">
                                          <div class="team-info" style="text-align: right;">
                                              <span class="team-info-name" style="font-size: 12px;">{{scope.row.name}}</span>
                                              <div style="display: flex;justify-content: right;">
                                                  <!-- <span class="team-info-score" v-for="(item,index) in scope.row.tags" :key="index">{{item}}</span> -->
                                                  <span class="team-info-score" style="font-size: 10px;" v-if="awayStarter.includes(scope.row.id)">首发</span>
                                              </div>
                                          </div>
                                          <div class="team-logo">
                                              <div class="block">
                                                  <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="40" :src="scope.row.portrait"></el-avatar>
                                              </div>
                                          </div>
                                      </div>
                                  </template>
                                  </el-table-column>
                              </el-table>
                          </div>
                          
                      </div>
                      <div class="card-box card-item">
                          <div class="item-title">时间节点</div>
                          <div class="item-main-log" style="height: 500px;margin-top: 30px;">
                              <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-card class="box-card" style="margin-top: 10px;">
                                      <div slot="header" class="clearfix">
                                        <span style="font-size: 18px;font-weight: bold;">第一节</span>
                                      </div>
                                      <div v-for="(item,index) in MatchLog1" :key="index">
                                          <el-card shadow="never"  v-if="item.playername !== '' && item.playername !== null" style="margin-top: 5px;height: 70px;">
                                            <div style="font-size: 16px;display: flex;justify-content: space-between;align-items: center">
                                                <div style="display: flex;align-items: center;">
                                                    <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                    {{item.playername}}(<font style="font-size: 14px;" :style="item.teamtype == 0 ? 'color: #f00' : 'color: #00f'">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</font>)&emsp;
                                                    {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                </div>
                                                <span style="display: inline-block;">{{formatDate(item.datetime)}}</span>
                                            </div>
                                          </el-card>
                                      </div>
                                    </el-card>
                                </el-col>
                                <el-col :span="12">
                                    <el-card class="box-card" style="margin-top: 10px;">
                                      <div slot="header" class="clearfix">
                                        <span style="font-size: 18px;font-weight: bold;">第二节</span>
                                      </div>
                                      <div v-for="(item,index) in MatchLog2" :key="index">
                                          <el-card shadow="never"  v-if="item.playername !== '' && item.playername !== null" style="margin-top: 5px;height: 70px;">
                                            <div style="font-size: 16px;display: flex;justify-content: space-between;align-items: center">
                                                <div style="display: flex;align-items: center">
                                                    <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                    {{item.playername}}(<font style="font-size: 14px;" :style="item.teamtype == 0 ? 'color: #f00' : 'color: #00f'">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</font>)&emsp;
                                                    {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                </div>
                                                <span style="display: inline-block;">{{formatDate(item.datetime)}}</span>
                                            </div>
                                          </el-card>
                                      </div>
                                    </el-card>
                                </el-col>
                                <el-col :span="12">
                                    <el-card class="box-card" style="margin-top: 10px;">
                                      <div slot="header" class="clearfix">
                                        <span style="font-size: 18px;font-weight: bold;">第三节</span>
                                      </div>
                                      <div v-for="(item,index) in MatchLog3" :key="index">
                                          <el-card shadow="never"  v-if="item.playername !== '' && item.playername !== null" style="margin-top: 5px;height: 70px;">
                                            <div style="font-size: 16px;display: flex;justify-content: space-between;align-items: center">
                                                <div style="display: flex;align-items: center">
                                                    <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                    {{item.playername}}(<font style="font-size: 14px;" :style="item.teamtype == 0 ? 'color: #f00' : 'color: #00f'">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</font>)&emsp;
                                                    {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                </div>
                                                <span style="display: inline-block;">{{formatDate(item.datetime)}}</span>
                                            </div>
                                          </el-card>
                                      </div>
                                    </el-card>
                                </el-col>
                                <el-col :span="12">
                                    <el-card class="box-card" style="margin-top: 10px;">
                                      <div slot="header" class="clearfix">
                                        <span style="font-size: 18px;font-weight: bold;">第四节</span>
                                      </div>
                                      <div v-for="(item,index) in MatchLog4" :key="index">
                                          <el-card shadow="never"  v-if="item.playername !== '' && item.playername !== null" style="margin-top: 5px;height: 70px;">
                                            <div style="font-size: 16px;display: flex;justify-content: space-between;align-items: center">
                                                <div style="display: flex;align-items: center">
                                                    <el-avatar :size="40" :src="item.playericon || require('@/assets/images/nohead.png')" style="margin-right: 25px;"></el-avatar>
                                                    {{item.playername}}(<font style="font-size: 14px;" :style="item.teamtype == 0 ? 'color: #f00' : 'color: #00f'">{{item.teamtype == 0 ? mainData.homename : mainData.guestname}}</font>)&emsp;
                                                    {{getType(item.type)}} {{item.typenum > 0 ? "("+item.typenum+"分)" : ""}}
                                                </div>
                                                <span style="display: inline-block;">{{formatDate(item.datetime)}}</span>
                                            </div>
                                          </el-card>
                                      </div>
                                    </el-card>
                                </el-col>
                              </el-row>
                          </div>
                      </div>
                  </div>
                  <div class="detail-right detail-item" style="width: 30%;">
                      <div class="card-box card-item">
                          <div class="item-title">赛事裁判</div>
                          <div class="item-main-ref">
                              <div class="ref-item" v-for="(item,index) in refData" :key="index">
                                  <el-avatar  @click.native="showPre(item.portrait)" shape="circle" :size="80" :src="item.portrait"></el-avatar>
                                  <span class="ref-name">{{item.name}}</span>
                                  <span class="ref-type">{{ reftype(item.grade) }}</span>
                              </div>
                          </div>
                      </div>
                      
                      <div class="card-box card-item">
                          <div class="item-title">比分</div>
                          <div style="overflow: hidden;">
                             <el-table
                                   :data="bfData"
                                   style="width: 100%;">
                                   <el-table-column
                                     prop="date"
                                     label=""
                                     align="center"
                                     width="60">
                                     <template slot-scope="scope">
                                         <div class="team-logo">
                                             <div class="block">
                                                 <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="40" :src="scope.row.logo"></el-avatar>
                                             </div>
                                         </div>
                                     </template>
                                   </el-table-column>
                                   <el-table-column
                                     prop="one"
                                     align="center"
                                     label="第一节"
                                     width="78"
                                     >
                                   </el-table-column>
                                   <el-table-column
                                     prop="two"
                                     align="center"
                                     width="78"
                                     label="第二节">
                                   </el-table-column>
                                   <el-table-column
                                     prop="three"
                                     align="center"
                                     width="78"
                                     label="第二节">
                                   </el-table-column>
                                   <el-table-column
                                     prop="four"
                                     align="center"
                                     width="78"
                                     label="第四节">
                                   </el-table-column>
                                 </el-table> 
                          </div>
                      </div>
                      <div class="card-box card-item">
                          <div class="item-title">犯规</div>
                          <div>
                              <el-table
                                :data="fgData"
                                style="width: 100%">
                                <el-table-column
                                  prop="date"
                                  label=""
                                  align="center"
                                  width="60">
                                  <template slot-scope="scope">
                                      <div class="team-logo">
                                          <div class="block">
                                              <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="40" :src="scope.row.logo"></el-avatar>
                                          </div>
                                      </div>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="one"
                                  align="center"
                                  width="78"
                                  label="第一节">
                                </el-table-column>
                                <el-table-column
                                  prop="two"
                                  align="center"
                                  width="78"
                                  label="第二节">
                                </el-table-column>
                                <el-table-column
                                  prop="three"
                                  align="center"
                                  width="78"
                                  label="第三节">
                                </el-table-column>
                                <el-table-column
                                  prop="four"
                                  align="center"
                                  width="78"
                                  label="第四节">
                                </el-table-column>
                              </el-table>
                          </div>
                      </div>
                      <div class="card-box card-item">
                          <div class="item-title">暂停</div>
                          <div>
                              <el-table
                                :data="ztData"
                                style="width: 100%">
                                <el-table-column
                                  prop="date"
                                  label=""
                                  align="center"
                                  width="60">
                                  <template slot-scope="scope">
                                      <div class="team-logo">
                                          <div class="block">
                                              <el-avatar @click.native="showPre(scope.row.logo)" shape="circle" :size="40" :src="scope.row.logo"></el-avatar>
                                          </div>
                                      </div>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="one"
                                  align="center"
                                  width="78"
                                  label="第一节">
                                </el-table-column>
                                <el-table-column
                                  prop="two"
                                  align="center"
                                  width="78"
                                  label="第二节">
                                </el-table-column>
                                <el-table-column
                                  prop="three"
                                  align="center"
                                  width="78"
                                  label="第三节">
                                </el-table-column>
                                <el-table-column
                                  prop="four"
                                  align="center"
                                  width="78"
                                  label="第四节">
                                </el-table-column>
                              </el-table>
                          </div>
                      </div>
                      <div class="card-box card-item">
                              <div class="item-title">球队统计</div>
                              <div class="item-main">
                                  <div class="team-top-nav">
                                      <el-avatar @click.native="showPre(homeLogo)" shape="circle" :size="50" :src="homeLogo"></el-avatar>
                                      <span class="team-top-nav-name">{{mainData.homename}}</span>
                                      <span class="scale-vs">vs</span>
                                      <span class="team-top-nav-name">{{mainData.guestname}}</span>
                                      <el-avatar @click.native="showPre(awayLogo)" shape="circle" :size="50" :src="awayLogo"></el-avatar>
                                  </div>
                                  <div class="team-process-item" v-for="(item,index) in teamData" :key="index">
                                      <div class="team-process-header">
                                          <div>{{item.home}}</div>
                                          <span>{{item.name}}</span>
                                          <div>{{item.away}}</div>
                                      </div>
                                      <div class="team-process-line">
                                          <div class="line-item">
                                              <el-progress class="custom-progress" :percentage="(item.home / item.total) * 100" :color="item.home >= item.away ? '#00f' : '#ccc'" :show-text="false" stroke-linecap="butt" ></el-progress>
                                          </div>
                                          <div class="line-item">
                                              <el-progress  :percentage="(item.away / item.total) * 100" :color="item.away >= item.home ? '#f00' : '#ccc'" :show-text="false" stroke-linecap="butt"></el-progress>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="card-box card-item">
                              <div class="item-title">各项最佳</div>
                              <div class="item-main">
                                  <div v-for="(item,index) in otherDate" :key="index">
                                      <div class="team-top-nav">
                                          <el-avatar @click.native="showPre(item.homeface)" shape="circle" :size="50" :src="item.homeface"></el-avatar>
                                          <span class="team-top-nav-name">{{item.homename}}</span>
                                          <span class="scale-vs">vs</span>
                                          <span class="team-top-nav-name">{{item.awayname}}</span>
                                          <el-avatar @click.native="showPre(item.awayface)" shape="circle" :size="50" :src="item.awayface"></el-avatar>
                                      </div>
                                      <div class="team-process-item">
                                          <div class="team-process-header">
                                              <div>{{item.home}}</div>
                                              <span>{{item.name}}</span>
                                              <div>{{item.away}}</div>
                                          </div>
                                          <div class="team-process-line">
                                              <div class="line-item">
                                                  <el-progress class="custom-progress" :percentage="(item.home / item.total) * 100" :color="item.home >= item.away ? '#00f' : '#ccc'" :show-text="false" stroke-linecap="butt" ></el-progress>
                                              </div>
                                              <div class="line-item">
                                                  <el-progress  :percentage="(item.away / item.total) * 100" :color="item.away >= item.home ? '#f00' : '#ccc'" :show-text="false" stroke-linecap="butt"></el-progress>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogPngVisible = false">取消</el-button>
            <el-button type="primary" @click="exportToPng">导出</el-button>
          </div>
        </el-dialog>
        <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';
    import domtoimage from 'dom-to-image';
    export default {
          data() {
            return {
            activeName: 'first',
            dialogVisible: false,
            dialogPngVisible: false,
            preimg: "",
            is_show: false,
            show_more: false,
            homeLogo: "",
            awayLogo: "",
            homeData: [],
            awayData: [],
            teamData: [
                { id: 1, name: '罚球', home: 0, away: 0, total: 0 },
                { id: 2, name: '中投', home: 0, away: 0, total: 0 },
                { id: 3, name: '三分', home: 0, away: 0, total: 0 },
                { id: 4, name: '犯规', home: 0, away: 0, total: 0 },
            ],
            allVideo: "",
            otherDate: [],
              refData: [],
              bfData: [],
              fgData: [],
              ztData: [],
              mainData: [],
              homeStarter: [],
              awayStarter: [],
              MatchLog1: [],
              MatchLog2: [],
              MatchLog3: [],
              MatchLog4: [],
              player: null,
              options: {
                container: document.getElementById("dplayer"), //播放器容器
                mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
                theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
                loop: false, // 是否自动循环
                lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
                screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
                hotkey: true, // 是否支持热键，调节音量，播放，暂停等
                preload: "auto", // 自动预加载
                volume: 0.7, // 初始化音量
                playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度，可自定义
                logo:
                    require("@/assets/images/logo.png"), // 在视频左上角上打一个logo
                video: {
                    url:
                      "", // 播放视频的路径
                    defaultQuality: 0, // 默认是高清
                  },
                  highlight: [
                    {
                      text: "10M",
                      time: 6
                    },
                    {
                      text: "20M",
                      time: 12
                    }
                  ]
                }
                }
            },
            mounted() {  
               
            },
            methods: {
                async exportToPng() {
                    const node = document.getElementById('exportTemplate');
                    try {
                        const loading = this.$loading({
                            lock: true,
                            text: '图片生成中',
                            spinner: 'el-icon-loading',
                            background: 'rgba(255, 255, 255, 0.5)'
                        });
                        const blob = await domtoimage.toBlob(node);
                        const filename = this.mainData.gamename+"记录表"
                        saveAs(blob, filename+'.png');
                        loading.close();
                        this.dialogPngVisible = false
                    } catch (err) {
                        console.error('Oops, something went wrong!', err);
                    }
                },
                showVideo(video) {
                    let url = video.split(';')[0];
                    this.options.video.url = url
                    this.dialogVisible = true;
                },
                closeVideo() {
                    this.options.video.url = ""
                },
                handleClick(tab, event) {
                        console.log(tab, event);
                },
                showPre(url) {
                    this.preimg = url
                    this.is_show = true
                },
                reftype(n) {
                    let type = ""
                    switch(n) {
                        case 0: type = "三级裁判";break;
                        case 1: type = "二级裁判";break;
                        case 2: type = "一级裁判";break;
                        case 4: type = "国际级裁判";break;
                        default: type = "一级裁判";break;
                    }
                    
                    return type;
                        
                },
                getType(type) {
                    let typename = ""
                    switch(type) {
                        case 1: typename = "中投";break;
                        case 2: typename = "三分";break;
                        case 3: typename = "罚球";break;
                        case 4: typename = "犯规";break;
                        case 5: typename = "技术犯规";break;
                        default: typename = "未知";break;  
                    }
                    return typename
                },
                fetchData(id) {
                    let formData = new FormData();
                    formData.append('id', id);
                    this.$api.post('m/match/getmatch', formData).then(response => {
                        let result = response.data.data
                        this.mainData = result
                        this.homeStarter = result.homestarter.split(',').map(item => parseInt(item, 10));
                        this.awayStarter = result.gueststarter.split(',').map(item => parseInt(item, 10));
                        this.homeLogo = result.homelogo
                        this.awayLogo = result.guestlogo
                        this.homeData = result.homeplayers
                        this.awayData = result.guestplayers
                        this.bfData = [
                            {
                                logo: result.homelogo,
                                one: result.homesecsum.split(',')[0],
                                two: result.homesecsum.split(',')[1],
                                three: result.homesecsum.split(',')[2],
                                four: result.homesecsum.split(',')[3]
                                
                            },
                            {
                                logo: result.guestlogo,
                                one: result.guestsecsum.split(',')[0],
                                two: result.guestsecsum.split(',')[1],
                                three: result.guestsecsum.split(',')[2],
                                four: result.guestsecsum.split(',')[3]
                                
                            },
                        ]
                        
                        this.fgData = [
                            {
                                logo: result.homelogo,
                                one: result.homesecfoul.split(',')[0],
                                two: result.homesecfoul.split(',')[1],
                                three: result.homesecfoul.split(',')[2],
                                four: result.homesecfoul.split(',')[3]
                                
                            },
                            {
                                logo: result.guestlogo,
                                one: result.guestsecfoul.split(',')[0],
                                two: result.guestsecfoul.split(',')[1],
                                three: result.guestsecfoul.split(',')[2],
                                four: result.guestsecfoul.split(',')[3]
                                
                            },
                        ]
                        
                        this.ztData = [
                            {
                                logo: result.homelogo,
                                one: result.homesecpause.split(',')[0],
                                two: result.homesecpause.split(',')[1],
                                three: result.homesecpause.split(',')[2],
                                four: result.homesecpause.split(',')[3]
                                
                            },
                            {
                                logo: result.guestlogo,
                                one: result.guestsecpause.split(',')[0],
                                two: result.guestsecpause.split(',')[1],
                                three: result.guestsecpause.split(',')[2],
                                four: result.guestsecpause.split(',')[3]
                                
                            },
                        ]
                        this.fetchRefData()
                        this.calculateStats();
                        this.fetchMatchLog()
                        this.otherDate = this.buildComparisonArray(this.homeData, this.awayData);
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                },
                fetchMatchLog() {
                    this.$api.get(`api/Match/GameLog`,{
                      params: {
                        matchid: this.mainData.id
                      }
                    }).then(response => {
                        const sectionLogs = {
                              1: this.MatchLog1,
                              2: this.MatchLog2,
                              3: this.MatchLog3,
                              4: this.MatchLog4,
                        };
                        response.data.rows.forEach(item => {
                            const logArray = sectionLogs[item.sectionnum];
                            if (logArray) {
                                logArray.push(item);
                            }
                        })
                        
                        response.data.rows.forEach(item => {
                            if(item.video !== null) {
                                this.allVideo = item.video.split(';')[0]
                                return
                            }
                        })
                      
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                },
                fetchRefData() {
                    
                    this.$api.get(`app/match/referees`,{
                      params: {
                        PageIndex: 1,
                        liveid: this.mainData.liveid,
                        matchid: this.mainData.id
                      }
                    }).then(response => {
                        this.refData = response.data.data.rows
                      
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                },
                calculateStats() {
                    const statsMapping = {
                      penalt: '罚球',
                      perimetershot: '中投',
                      threepoint: '三分',
                      foul: '犯规',
                    };
                
                    Object.keys(statsMapping).forEach((key) => {
                      const homeSum = this.homeData.reduce((sum, player) => sum + (player[key] || 0), 0);
                      const awaySum = this.awayData.reduce((sum, player) => sum + (player[key] || 0), 0);
                      
                      const statItem = this.teamData.find((item) => item.name === statsMapping[key]);
                      if (statItem) {
                        statItem.home = homeSum;
                        statItem.away = awaySum;
                        statItem.total = homeSum + awaySum;
                      }
                    });
                    
                },
                calculateTotalScore(player) {
                    const penaltyPoints = player['penalt'] || 0;
                    const perimeterShotPoints = (player['perimetershot'] || 0) * 2;
                    const threePointPoints = (player['threepoint'] || 0) * 3;
                    
                    return penaltyPoints + perimeterShotPoints + threePointPoints;
                },
                getMaxScorer(data) {
                    return data.reduce((max, player) => {
                        player.totalScore = this.calculateTotalScore(player);
                        return player.totalScore > max.totalScore ? player : max;
                      }, { totalScore: 0 });
                },
                getMaxPenalty(data) {
                  if (!data || data.length === 0) return null;
                    return data.reduce((max, player) => {
                      const penaltyPoints = player.penalt || 0;
                      return penaltyPoints > (max.penalt || 0) ? player : max;
                    }, data[0]); // 默认返回第一个球员的数据
                },
                getMaxPerimeterShot(data) {
                  if (!data || data.length === 0) return null;
                    return data.reduce((max, player) => {
                      const perimeterShotPoints = (player.perimetershot || 0) * 2; // 中投得分*2
                      const maxPerimeterShotPoints = (max.perimetershot || 0) * 2;
                      return perimeterShotPoints > maxPerimeterShotPoints ? player : max;
                    }, data[0]); // 默认返回第一个球员的数据
                },
                getMaxThreePoint(data) {
                  if (!data || data.length === 0) return null;
                    return data.reduce((max, player) => {
                      const threePointPoints = (player.threepoint || 0) * 3; // 三分得分*3
                      const maxThreePointPoints = (max.threepoint || 0) * 3;
                      return threePointPoints > maxThreePointPoints ? player : max;
                    }, data[0]); // 默认返回第一个球员的数据
                },
                getMaxFoul(data) {
                  if (!data || data.length === 0) return null;
                    return data.reduce((max, player) => {
                      const foulPoints = player.foul || 0;
                      return foulPoints > (max.foul || 0) ? player : max;
                    }, data[0]); // 默认返回第一个球员的数据
                },
                buildComparisonArray(homeData, awayData) {
                  // 先计算每个球员的总得分
                  homeData.forEach(player => player.totalScore = this.calculateTotalScore(player));
                  awayData.forEach(player => player.totalScore = this.calculateTotalScore(player));
                
                  // 找到得分最高的球员
                  const homeMaxScorer = this.getMaxScorer(homeData);
                  const awayMaxScorer = this.getMaxScorer(awayData);
                  
                  const homeMaxPenalty = this.getMaxPenalty(homeData);
                  const awayMaxPenalty = this.getMaxPenalty(awayData);
                  
                  const homeMaxPerimeterShot = this.getMaxPerimeterShot(homeData);
                  const awayMaxPerimeterShot = this.getMaxPerimeterShot(awayData);
                  
                  const homeMaxThreePoint = this.getMaxThreePoint(homeData);
                  const awayMaxThreePoint = this.getMaxThreePoint(awayData);
                  
                  const homeMaxFoul = this.getMaxFoul(homeData);
                  const awayMaxFoul = this.getMaxFoul(awayData);
                
                  // 构建 otherData 数组
                  const otherData = [
                    {
                      id: 1,
                      homename: homeMaxScorer.name, // 假设球员的姓名是 'name' 属性
                      homeface: homeMaxScorer.portrait, // 假设球员的头像是 'face' 属性
                      awayname: awayMaxScorer.name,
                      awayface: awayMaxScorer.portrait,
                      name: "得分",
                      home: homeMaxScorer.totalScore,
                      away: awayMaxScorer.totalScore,
                      total: homeMaxScorer.totalScore + awayMaxScorer.totalScore
                    },
                    {
                        id: 2,
                        homename: homeMaxPenalty.name,
                        homeface: homeMaxPenalty.portrait,
                        awayname: awayMaxPenalty.name,
                        awayface: awayMaxPenalty.portrait,
                        name: "罚球",
                        home: homeMaxPenalty.penalt,
                        away: awayMaxPenalty.penalt,
                        total: homeMaxPenalty.penalt + awayMaxPenalty.penalt
                      },
                      {
                        id: 3,
                        homename: homeMaxPerimeterShot.name,
                        homeface: homeMaxPerimeterShot.portrait,
                        awayname: awayMaxPerimeterShot.name,
                        awayface: awayMaxPerimeterShot.portrait,
                        name: "中投",
                        home: homeMaxPerimeterShot.perimetershot * 2,
                        away: awayMaxPerimeterShot.perimetershot * 2,
                        total: homeMaxPerimeterShot.perimetershot*2 + awayMaxPerimeterShot.perimetershot*2
                      },
                      {
                        id: 4,
                        homename: homeMaxThreePoint.name,
                        homeface: homeMaxThreePoint.portrait,
                        awayname: awayMaxThreePoint.name,
                        awayface: awayMaxThreePoint.portrait,
                        name: "三分",
                        home: homeMaxThreePoint.threepoint*3,
                        away: awayMaxThreePoint.threepoint*3,
                        total: homeMaxThreePoint.threepoint*3 + awayMaxThreePoint.threepoint*3
                      },
                      {
                        id: 5,
                        homename: homeMaxFoul.name,
                        homeface: homeMaxFoul.portrait,
                        awayname: awayMaxFoul.name,
                        awayface: awayMaxFoul.portrait,
                        name: "犯规",
                        home: homeMaxFoul.foul,
                        away: awayMaxFoul.foul,
                        total: homeMaxFoul.foul + awayMaxFoul.foul
                      }
                    
                  ];
                
                  // 返回构建好的数组
                  return otherData;
                },
                formatFullDate(value) {
                    const date = new Date(value);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit', second: '2-digit' };
                    return date.toLocaleString('default', options);
                },
                formatDate(value) {
                    const date = new Date(value);
                    const options = {hour: '2-digit', minute: '2-digit', second: '2-digit' };
                    return date.toLocaleString('default', options);
                },
                handlePlay(){
                    console.log('播放');
                    this.is_zz=true
                },
                handlePause(){
                    console.log('暂停');
                },
                
            },
            created() {
                this.fetchData(this.$route.params.t)
                
            },
            

        }
</script>

<style scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
    }
    
    .main-box {
        padding: 15px;
    }
    
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
    }
    .topbox {
        padding: 20px;
        margin: 15px;
        border-radius: 15px;
        margin-bottom: 0px;
        position: relative;
    }
    
    .btn-area {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    
    .card-box {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
    }
    
    .top-grade-box {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        position: relative;
    }
    
    .time-box {
        position: absolute;
        bottom: 0px
    }
    
    .team-logo-box {
        display: flex;
        text-align: center;
    }
    
    .team-logo-box-right {
        display: flex;
        justify-content: right;
    }
    
    .bold-font {
        font-weight: bold;
        font-size: 26px;
        margin-top: 20px;
    }
    
    .scale {
        line-height: 160px;
        font-size: 60px;
        margin: 0px 80px;
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 30px;
        text-align: left;
        
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: center;
        font-size: 12px;
        background-color: #b3b3b3;
        line-height: 20px;
        height: 20px;
        color: #fff;
        border-radius: 5px;
        margin-right: 5px;
        padding: 0px 5px;
        min-width: 15px;
        text-overflow: ellipsis;
        /* white-space: nowrap; */
        overflow: hidden;
        cursor: pointer;
        
    }
    
    .top-grade-info-box {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-bottom: 50px;
        overflow: hidden;
    }
    
    .show-more {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        background-color: #fff;
    }
    
    .top-grade-detail-box {
        display: flex;
        justify-content: space-between;
    }
    
    .table-box {
        width: calc(100% / 2 - 60px);
        padding: 15px 15px;
    }
    
    .detail-item {
        width: calc(100% / 2 - 5px);
    }
    
    .card-item {
        padding: 20px 30px;
    }
    
    .item-title {
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
    }
    
    .team-top-nav {
        height: 60px;
        line-height: 60px;
        display: flex;
        flex-direction: center;
        justify-content: center;
        padding-top: 15px;
    }
    
    .team-top-nav-name {
        margin: 0px 20px;
        font-weight: 24px;
        font-weight: bold;
    }
    
    .scale-vs {
        font-size: 35px;
    }
    
    .team-process-header {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
    }
    
    .team-process-line {
        display: flex;
        justify-content: space-between;
    }
    
    .line-item {
        width: 50%;
    }
    
    .custom-progress {
       transform: scaleX(-1);
    }
    
    :deep(.el-progress-bar__outer) ,
    :deep(.el-progress-bar__inner)  {
        border-radius: inherit ;
    }
    
    .team-process-item {
        height: 60px;
    }
    
    .ref-item {
        display: flex;
        flex-direction: column;
        width: 180px;
        justify-content: center;
        text-align: center;
        align-items: center;
        
    }
    
    .ref-name {
        height: 40px;
        line-height: 40px;
        font-weight: bold;
    }
    
    .ref-type {
        background-color: #04b9fa;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 20px;
        padding: 2px 8px;
        border-radius: 5px;
        height: 20px;
    }
    
    .item-main-ref {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
    
    .tabs-box {
        width: 100%;
    }
    
    .item-main-log {
        text-align: left;
       
    }
    
    .scroll-box {
        height: 500px;
        overflow-y: scroll;
    }
    
    .name-box {
        display: flex;
        align-items: center;
        font-size: 18px;
        justify-content: space-between;
    }
    
    .team-name {
        position: absolute;
        left: 0px;
        top: -30px;
        color: #444;
        font-size: 14px;
        font-weight: 400;
    }
    

</style>