<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left">
                <div class="title_area">
                    <span class="title_area_tip">历史赛事名称：</span>
                    <span class="title_area_title">{{matchTitle}}</span>
                </div>
                <div class="match_box">
                    <div class="match-box-item">
                        <img class="match-box-img" :src="firstImg" alt="" />
                    </div>
                    <div class="match-box-item">
                        <img class="match-box-img" :src="secondImg" alt="" />
                    </div>
                </div>
                
            </div>
            <el-dialog
              title="用户报名流程图"
              :visible.sync="dialogLCVisible"
              width="30%"
              :before-close="handleClose">
              <div class="join_box" id="exportTemplate">
                  <span class="join_box_title">{{matchTitle}}</span>
                  <img class="join_match_img" src="@/assets/images/join_match.jpg" alt="">
                  <img class="match_img" :src="firstImg" alt="">
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogLCVisible = false">关 闭</el-button>
                <el-button type="primary" @click="exportToPng()">下 载</el-button>
              </span>
            </el-dialog>
            <my-cropper
             :showDialog="dialogVisible"
             :image="cropperImage" 
             :width="cropperWidth"
             :height="cropperHeight"
             @update-logo="handleLogoUpdate" 
             @cancelDialog="closeDialog"
            ></my-cropper>
        </div>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';
    import domtoimage from 'dom-to-image';
    export default {
        directives: {
            'only-digits': {
              bind(el) {
                el.addEventListener('input', (event) => {
                    const inputValue = event.target.value;
                    event.target.value = inputValue.replace(/[^0-9]/g, '');
                        if (inputValue !== event.target.value) {
                            event.target.dispatchEvent(new Event('input'));
                        }
                    });
                },
            },
        },
        data() {
            return {
                options: [{
                  value: '0',
                  label: '免费'
                }, {
                  value: '1',
                  label: '报一个队伍收一次费用'
                }, {
                  value: '2',
                  label: '报几个队伍收几次费用'
                }],
                value: '0',
                isEditTitle: false,
                matchTitle: "斗战篮球赛事测试",
                tempTitle: '',
                dialogVisible: false,
                dialogLCVisible: false,
                cropperImage: "",
                cropperWidth: 500,
                cropperHeight: 250,
                firstImg: require("@/assets/images/1.jpg"),
                secondImg: require("@/assets/images/slider.jpg"),
                chooseImgBox: "",
                carouseId: 0,
                liveid: "",
                fansNum: 0,
                refsNum: 0,
                playersNum: 0,
                teamsNum: 0,
                is_signup: 1,
                is_Auth: 0,
                is_create: 0,
                is_scoreend: 0,
                is_liveend: false,
                params: "name,teamsname,playtype,playno,height,weight,sfzh,tel,portrait,pregfee,unregfee",
                price: "",
                payType: "0",
                oldprice: 0,
                members: "",
                oldmembers: '',
                userid: 0
            }
        },
        mounted() {
            this.setPY();
        },
        created() {
            const userinfo = JSON.parse(localStorage.getItem('userinfo'));
            this.liveid = this.$route.params.d
            this.userid = userinfo.id
            
            let formData = new FormData()
            formData.append('status', 'History');
            formData.append('oid', this.userid);
            formData.append('pagesize', 10000);
            this.$api.post('/app/match/gamelist',formData).then(response => {
                this.historyList = response.data.data.rows
                let item = response.data.data.rows.find(item =>item.id == this.liveid)
                console.log(item)
                this.matchTitle = item.name
                this.firstImg = item.logo
            }).catch(error => {
                console.error("There was an error!", error);
            }) 
        },
        computed: {
            // 使用计算属性来定义imgs
            imgs() {
              return [
                {
                    id: 0,
                    url: this.firstImg,
                },
                {
                    id: 1,
                    url: this.secondImg,
                },
                {
                    id: 2,
                    url: require("@/assets/images/slider2.jpg")
                },
              ];
            }
        },
        methods: {
            toTeam() {
                this.$router.push({ path: '/teammgmt'});
            },
            toRef() {
                this.$router.push({ path: '/refmgmt'});
            },
            startEdit() {
                this.tempTitle = this.matchTitle;
                this.isEditTitle = true
            },
            changeSignup(t) {
                let url = ""
                if (t == 0) {
                    url = "api/Main/StopSignup"
                } else {
                    url = "api/Main/StartSignup"
                }
                this.$api.post(url).then(response => {
                    if (response.data.code == 1) {
                        this.is_signup = Math.abs(this.is_signup - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changeAuth(t) {
                let url = ""
                if (t == 1) {
                    url = "api/Main/idCardUnAuth"
                } else {
                    url = "api/Main/idCardAuth"
                }
                this.$api.post(url).then(response => {
                    if (response.data.code == 1) {
                        this.is_Auth = Math.abs(this.is_Auth - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changeCreate(t) {
                let url = ""
                if (t == 1) {
                    url = "api/Main/StopCreate"
                } else {
                    url = "api/Main/StartCreate"
                }
                this.$api.post(url).then(response => {
                    if (response.data.code == 1) {
                        this.is_create = Math.abs(this.is_create - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changeScore() {
                this.$api.post('api/Main/SetScoreEnd').then(response => {
                    if (response.data.code == 1) {
                        this.is_scoreend = Math.abs(this.is_scoreend - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            exportSignExcel() {
                this.$api.get(`/api/Main/execlplayers/${this.liveid}/${this.params}`,{
                    responseType: 'blob',
                }).then(response => {
                    let temp = response.headers["content-disposition"].split(";")[1].split("=")[1];
                    let iconv = require("iconv-lite");
                    let fileName = iconv.decode(temp, "gbk");
                    
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.ms-excel;charset=UTF-8",
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    
                    
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetFansList() {
                this.$api.get(`api/Main/favoritelist`,{
                  params: {
                    PageIndex: 1,
                  }
                }).then(response => {
                    this.fansNum = response.data.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetRefsList() {
                this.$api.get(`app/match/referees`,{
                  params: {
                    PageIndex: 1,
                    liveid: this.liveid
                  }
                }).then(response => {
                    this.refsNum = response.data.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetPlayersList() {
                this.$api.get(`api/Team/Players`,{
                  params: {
                    PageIndex: 1,
                    liveid: this.liveid,
                    teamid: 0
                  }
                }).then(response => {
                    this.playersNum = response.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetTeamsList() {
                this.$api.get('api/Team/Teams', {
                  params: {
                    limit: -1,
                    PageIndex: 1,
                  }
                })
                .then(response => {
                    this.teamsNum = response.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            editTitle() {
                this.$confirm('确定要將赛事名称修改为“'+this.matchTitle+'”吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.isEditTitle = false
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    this.isEditTitle = false
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            cancelEditTitle() {
                this.matchTitle = this.tempTitle;
                this.isEditTitle = false
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            },
            handleChangeUpload(file,i) {
                console.log(i)
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    this.chooseImgBox = i
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    console.log("成功", URL.createObjectURL(file.raw))
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.loading = false
                    this.dialogVisible = true
                })
            },
            handleLogoUpdate(croppedImageUrl) {
                if (this.chooseImgBox == "first") {
                    this.firstImg = croppedImageUrl
                } else {
                    this.secondImg = croppedImageUrl
                }
            },
            closeDialog() {
                this.dialogVisible = false
            },
            carouselChange:function(now,old){
                console.log(now+"，"+old);
                this.carouseId = now;
                this.setPY();
            },
            setPY:function(){
                var _carouseId = this.carouseId;
                let element = ""
                if(_carouseId == 0){
                    element = document.getElementById('0');
                    element.style.left = '0%';
                    element.nextElementSibling.style.left = "25%";				
                    element.nextElementSibling.nextElementSibling.style.left = "-25%";
                }else if(_carouseId == 1){
                    element = document.getElementById('1');
                    element.style.left = '0%';
                    element.previousElementSibling.style.left = "-25%";
                    element.nextElementSibling.style.left = "25%";
                }else{
                    element = document.getElementById('2');
                    element.style.left = '-0%';
                    element.previousElementSibling.style.left = "-25%";		
                    element.previousElementSibling.previousElementSibling.style.left = "25%";
                }
            },
            changePay(t) {
                console.log(t)
                let msg = ""
                switch(t) {
                    case "0": 
                        msg = "确认将报名收费方式改为免费吗？";
                        break;
                    case "1":
                        msg = "确认将报名收费方式改为报一个队伍收一次费用吗？";
                        break;
                    case "2":
                        msg = "确认将报名收费方式改为报几个队伍收几次费用吗？";
                        break;
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.payType = t
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {

                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
                
            },
            changePrice() {
                this.$confirm("确定将报名费用设置为"+this.price+"元吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    this.price = this.oldprice
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            changeMembers() {
                this.$confirm("确定将报名人数限制为"+this.members+"人吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    this.members = this.oldmembers
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            async exportToPng() {
                const node = document.getElementById('exportTemplate');
                try {
                    const blob = await domtoimage.toBlob(node);
                    this.dialogLCVisible = false
                    const filename = this.matchTitle+"报名流程图"
                    saveAs(blob, filename+'.png');
                    
                } catch (err) {
                    console.error('Oops, something went wrong!', err);
                }
            },
        }
        
    }
</script>

<style scoped>
    
    /* General styles */
    body {
        font-family: 'Arial', sans-serif;
        background-color: #f0f0f0;
        color: #333;
        line-height: 1.6;
        margin: 0;
        padding: 0;
    }
 
    .view-wrapper {
        background-color: transparent!important
    }
    
    .content {
        margin: 20px auto;
        padding: 20px;
    }
    
    /* Title Area */
    .title_area {
        
        text-align: left;
        background-color: #fff;
        height: 60px;
        line-height: 60px;
        margin: 0px 20px;
        margin-bottom: 20px;
        border-radius: 5px;
        text-indent: 2em;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .title_area_tip,
    .title_area_title {
        font-size: 18px;
        display: inline; 
        /* font-weight: bold; */
        color: #666;
    }
    
    .title_area_title {
        color: #000;
        margin-top: 5px;
    }
    
    /* Match Box */
    
    .match-box-item {
        width: calc(100% / 2 - 40px);
        margin: 0px 20px;
        position: relative;
        border-radius: 5px;
        background-color: #fff;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
        
    }
    
    .select-mask {
        position: absolute;
        background-color: rgba(0, 0, 0, .6);
        width: 100%;
        height: 0%;
        border-radius: 5px;
        transition: height 0.2s ease;
        cursor: pointer;
        text-align: center;
        line-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    
    .match-box-item:hover .select-mask {
      height: 100%; /* 当鼠标悬停在父div上时，子div的高度变为100px */
    }
    
    .match-box-img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border-radius: 3px solid #fff;
        
    }
    
    .match-box-info {
        display: flex;
        justify-content: space-around;
        width: 600px;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 20px;
        
    }
    
    .match-box-info-item {
        text-align: center;
        width: 100px;
        cursor: pointer;
    }
    
    .line {
        width: 1px;
        border-left: 1px solid #ccc;
        height: 50px;
        margin: 0px 20px;
        margin-top: 5px;
    }
    
    .match-box-info-item-num {
        font-size: 34px;
        color: #000;
        font-weight: bold;
    }
    
    .match-box-info-item-name {
        display: block;
        font-size: 16px;
        color: #666;
    }
    
    /* State Box */
    .state_box {
        background: #fff;
        padding: 10px 0px;
        margin: 20px;
        border-radius: 5px;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
    }
    
    .state_box-item {
        margin-bottom: 10px;
        text-align: left;
        /* background-color: #f2f2f2; */
        padding: 10px 0px;
        
        /* height: 40px; */
        line-height: 40px;
        padding-right: 10px;
        border-top: 1px solid #f0f0f0;
        
        
    }
    
    .state_box-item-title {
        font-size: 15px;
        display: block; /* Improves readability */
        margin-bottom: 5px;
        
        color: #666;
        margin-left: 50px;
        width: 140px;
        text-align: left;
    }
    
    .state_box-item-state {
        width: 50%;
        text-align: left;
        font-size: 16px;
        margin-bottom: 5px;
    }
    
    .btn-shadow {
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .state_box-item-btn {
        background-color: #007bff;
        margin-right: 20px;
        border: none;
        color: white;
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s;
        border-radius: 5px; /* Adds rounded corners to buttons */
    }
    
    .state_box-item-btn:hover {
        background-color: #0056b3;
    }
    
    .btn-area {
        display: flex;
        justify-content: left;
        text-align: left;
        
        width: 90%;
        padding-left: 50px;
    }
    
    .title-icon {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
        font-weight: bold!important;
    }
    
    .el-icon-check {
        color: #00a300;
    }
    
    .el-icon-close {
        color: red;
    }
    
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-left {
        /* background-color: #666; */
        width: 100%;
    }
    
    .main-box-right {
        width: 30%;
    }
    
    .mobile {
        height: 730px;
        border: 20px solid #dddddd;
        width: 360px;
        border-radius: 70px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        background-color: #fff;
        /* overflow: hidden; */
    }
    
    
    
    .mobile-head {
        width: 180px;
        height: 30px;
        background-color: #dddddd;
        margin: 0 auto;
        border-radius: 0px 0px 40px 40px;
    }
    
    
    .mobile-btn {
        width: 10px;
        height: 120px;
        border-radius: 0px 10px 10px 0px;
        background-color: #dddddd;
        position: absolute;
        right: -28px;
        top: 150px;
    }
    
    .mobile-main-box {
        padding: 10px;
        height: 670px;
        overflow-y: scroll;
    }
    
    .mobile-main-box::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
    }
    
    /* 针对Firefox */
    .mobile-main-box {
        scrollbar-width: none; /* Firefox */
    }

    
    .match_box {
        display: flex;
        justify-content: space-between;
    }
    
    .change-icon {
        color: #fff;
        font-size: 20px;
    }
    
    .mobile-top-nav {
        display: flex;
        height: 40px;
        line-height: 40px;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .block-box-title {
        font-size: 18px;
        font-weight: bold;
    }
    
    .btn-area-link {
        color: #007bff;
        cursor: pointer
    }
    
    .btn-area-link:hover {
        color: #009dff;
    }
    
    .join_box {
        width: 100%;
        background-color: #fff;
        padding-top: 50px;
        
    }
    
    .join_box_title {
        font-size: 30px;
        font-weight: bold;
        color: #444;
        font-family: '宋体';
    }
    
    .join_match_img {
        width: 100%;
        margin-top: 20px;
    }
    
    .match_img {
        width: 100%;
    }
    
    /* Adding responsiveness */
    @media (max-width: 768px) {
        .content {
            width: 95%;
            margin: 10px auto;
        }
    
        .match_box {
            flex-direction: column;
            align-items: start;
        }
    
        .match-box-img {
            margin-bottom: 20px;
        }
    
        .match-box-info {
            flex-direction: column;
        }
    
        .match-box-info-item {
            margin-bottom: 10px;
        }
        
        
    }


</style>