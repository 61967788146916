<template>
  <div>
    <video ref="videoPlayer" muted controls style="width: 100%; max-width: 640px; height: auto;"></video>
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.createPlayer();
  },
  watch: {
    src: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.createPlayer();
        }
      },
    },
  },
  methods: {
    createPlayer() {
      if (Hls.isSupported()) {
        const video = this.$refs.videoPlayer;
        const hls = new Hls();
        hls.loadSource(this.src);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          video.play();
        });
      } else if (this.$refs.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
        this.$refs.videoPlayer.src = this.src;
        this.$refs.videoPlayer.addEventListener('loadedmetadata', function() {
          this.play();
        });
      }
    },
  },
  beforeDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
  },
};
</script>
