<template>
  <div class="home">
    <my-header></my-header>
    <div class="body">
      <side-bar :id="liveid"></side-bar>
      <main-content></main-content>
    </div>
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeaderHistory.vue';
import SideBar from '@/components/SideBarHistory.vue';
import MainContent from '@/components/MainContentHistory.vue';

export default {
  name: 'HomePage',
  components: {
    MyHeader,
    SideBar,
    MainContent
  },
  data() {
      return {
          liveid: ""
      }
  },
  created() {
      this.liveid = this.$route.params.d
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  display: flex;
  flex: 1;
}
</style>
