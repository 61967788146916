<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: left;align-items: center;">
                    <i class="el-icon-arrow-left" @click="backHome()" v-if="type == 'edit'"></i>&emsp;
                    <span>{{title}}</span>
                </div>
                <div style="width: 100%;text-align: left;color: #f00;"><i class="el-icon-warning"></i>&emsp;活动开始之后将在六小时后自动结束该活动，请注意时间！</div>
                <Toolbar
                    style="border-bottom: 1px solid #ccc;height: auto;padding-top: 20px"
                    :editor="editor"
                    :defaultConfig="toolbarConfig"
                    :mode="mode"
                />
                <div class="main-box-editor" style="margin-top: 20px;">
                    
                    <el-form :rules="rules" label-width="100px" class="demo-ruleForm">
                      <el-form-item prop="name" label-width="0px">
                        <el-input v-model="editBody.title" placeholder="请输入活动标题" class="notice-title"></el-input>
                      </el-form-item>
                      <el-form-item prop="delivery" style="font-weight: normal;" label-width="0px">
                        
                        <Editor
                            style="height: 500px; overflow-y: hidden;font-size: 20px;border-bottom: 1px solid #ccc;"
                            v-model="editBody.introduce"
                            :defaultConfig="editorConfig"
                            :mode="html"
                            @onCreated="onCreated"
                        />
                      </el-form-item>
                      <el-form-item label="选择封面" prop="region">
                        <div class="imgs-item">
                            <el-upload
                              class="avatar-uploader"
                              action="''"
                              drag
                              :auto-upload="false"
                              :show-file-list="false"
                              :on-change="handleChangeUpload">
                              <img v-if="editBody.logo" :src="editBody.logo" class="avatar">
                              <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                      </el-form-item>
                      <el-form-item label="开始时间" prop="region">
                        <el-date-picker
                            v-model="editBody.yjkssj"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="选择机位" prop="region">
                        <el-select v-model="editBody.seatid" placeholder="请选择机位">
                            <el-option
                              v-for="item in cameraList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="观看条件" prop="region">
                        <el-radio v-model="editBody.looktype" label="0" border>按赛事条件</el-radio>
                        <el-radio v-model="editBody.looktype" label="1" border>球员</el-radio>
                      </el-form-item>
                      
                      <el-form-item>
                        <el-button type="primary" v-if="type == 'add'" @click="add()">立即发布</el-button>
                        <el-button type="primary" v-else @click="onEdit()">立即修改</el-button>
                        <el-button @click="reset()">重置</el-button>
                      </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>活动列表</span>
                </div>
                
                <el-table
                  :data="teamData"
                  style="width: 100%;"
                  border
                  >
                  <el-table-column
                    prop="id"
                    label="序号"
                    align="center"
                    width="50"
                    type="index"
                    >
                  </el-table-column>
                  <el-table-column
                    label="活动"
                    align="center"
                    width="350"
                    >
                    <template slot-scope="scope">
                        <div class="team-logo-box">
                            <div class="team-logo">
                                <div class="block">
                                    <el-image
                                         @click.native="showPre(scope.row.logo)"
                                         fit="cover"
                                          style="width: 110px; height: 80px"
                                          :src="scope.row.logo || defaultlogo"
                                          ></el-image>
                                </div>
                            </div>
                            <div class="team-info">
                                <span class="team-info-name">{{scope.row.title}}</span>
                                <span class="team-info-score">开始：{{scope.row.yjkssj}}</span>
                                <span class="team-info-score">结束：{{scope.row.yjzzsj}}</span>
                            </div>
                        </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="活动机位"
                    align="center"
                    width="80"
                    >
                    <template slot-scope="scope">
                        {{getCameraName(scope.row.seatid)}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="观看条件"
                    align="center"
                    width="80"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.looktype == 0 ? "按赛事条件" : "球员"}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="tools"
                    label="操作"
                    align="center"
                    fixed="right"
                    width="260px"
                    >
                    <template slot-scope="scope">
                        <div class="btn-area">   
                            <el-button class="btn-area-btn" size="mini" @click="edit(scope.row.id)">编辑</el-button>
                            <el-button class="btn-area-btn" size="mini" @click="closeActive(scope.row.id)" type="warning">关闭活动</el-button>
                            <el-button class="btn-area-btn" size="mini" @click="delActive(scope.row.id)" type="danger">删除</el-button>
                        </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="footer-page">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total"
                      :page-size="limit"
                      @current-change="changepage"
                      :hide-on-single-page="true"
                      >
                    </el-pagination>
                </div>
            </div>
         </div>
        <!-- vueCropper 剪裁图片实现-->
        <my-cropper :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="500" :height="320"></my-cropper>
    </div>
</template>

<script>
    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    export default {
        components: { Editor, Toolbar },
        data() {
          return {
            title: "创建活动",
            editor: null,
            toolbarConfig: { },
            html: '',
            mode: 'default',
            rules: {
              name: [
                { required: false, message: '', trigger: 'blur' },
              ],
            },
            editorConfig: {
                placeholder: '请输入活动内容',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    ['uploadImage'] : {
                        fieldName: 'file',
                        server: 'api/api/Cms/Articleupload',
                        customInsert(res, insertFn) {
                            insertFn(res.data, "", "")
                        },
                    },
                    ['uploadVideo'] : {
                        fieldName: 'file',
                        server: 'api/api/Cms/Articleupload',
                        customInsert(res, insertFn) { 
                            insertFn(res.data, "", "")
                        },
                    }
                }
            },
            
            editBody: {
                id: 0,
                introduce: "",
                title: "",
                logo: "",
                yjkssj: "",
                looktype: "0",
                seatid: "",
                seatList: []
            },
            isHistory: false,
            type: "add",
            editId: 0,
            isPreview: false,
            dialogVisible: false,
            cropperImage: "",
            previewImg: '', // 预览图片地址
            teamData: [],
            usedlogo: [
                {
                    id: 1, url: "http://www.douzhan.club/resources/teamlogo/431e6e88-f35f-4b63-8558-4a46671cdf4d.png"
                }
            ],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            pageIndex: 1,
            limit: 5,
            liveid: "",
            oid: "",
            teamname: "",
            total: 0,
            cameraList: []
          }
        },
        created() {
            let query = this.$route.query
            if (query.id) {
                this.type = "edit"
                this.title = "编辑活动"
                this.editId = query.id
                this.$api.get(`api/Match/GetActivity/${query.id}`)
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    let result = response.data.data
                    this.editBody = result
                    this.editBody.looktype = this.editBody.looktype.toString() 
                    
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }
            
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.oid = response.data.data.oid
                    this.fetchTeamData()
                    this.getCameraList()
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } else {
                this.fetchTeamData()
            }
        },
        watch: {  
            '$route.query.id'(newVal) {  
              // 当路由的查询参数id发生变化时，执行这里的代码  
              if (newVal) {  
                // 如果新值存在，则进入编辑模式  
                this.type = "edit";  
                this.title = "编辑活动";  
                this.editId = newVal;  
                this.$api.get(`api/Match/GetActivity/${newVal}`)
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    let result = response.data.data
                    this.editBody = result
                    this.editBody.looktype = this.editBody.looktype.toString() 
                    
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });  
              } else {  
                // 如果新值为空，则可能进入添加模式或其他默认模式  
                this.type = "add"; // 或设置为默认模式  
                this.title = "创建活动"; // 或设置为默认标题  
                this.editId = null;  

                this.reset()
              }  
            },  
          },
      beforeDestroy() {
          // 销毁编辑器
          this.editor.destroy();
          this.editor = null;
      },
        methods: {
            onCreated(editor) {
                this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            },
            getCameraList() {
                let formData = new FormData();
                formData.append('oid', this.oid);
                this.$api.post('app/user/organizerseats', formData).then(response => {
                    this.cameraList = response.data.data;
                    this.editBody.seatList = response.data.data;
                })
            },
            getCameraName(id) {
                const item = this.cameraList.find(item=> item.id === id)
                if (item) {
                    return item.name
                } else {
                    return ""
                } 
            },
            fetchTeamData() {
                
                this.$api.get('api/Match/Activitys', {
                    params: {
                      PageIndex: 1,
                    }
                }).then(response => {
                    this.teamData = response.data.rows;
                    // this.total = response.data.total;
                })
            },
            changepage(p) {
                this.pageIndex = p
                this.fetchTeamData()
            },
            closeActive(id) {
                this.$confirm('确认关闭该活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.get(`api/Match/CloseActivity/${id}`)
                    .then(response => {
                        if (response.data.code == 0) {
                            this.$message({
                              type: 'error',
                              message: response.data.data
                            });
                            this.$router.push({ path: '/login'});
                            return;
                        } else {
                            this.$message({
                                type: 'error',
                                message: '已关闭该活动'
                            });
                            this.fetchTeamData()
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });  
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            delActive(id) {
                this.$confirm('确认删除该活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.get(`api/Match/DelActivity/${id}`)
                    .then(response => {
                        if (response.data.code == 0) {
                            this.$message({
                              type: 'error',
                              message: response.data.data
                            });
                            this.$router.push({ path: '/login'});
                            return;
                        } else {
                            this.$message({
                                type: 'error',
                                message: '已删除该活动'
                            });
                            this.teamData = this.teamData.filter(item=>item.id === id)
                            this.fetchTeamData()
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });  
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            add() {
                if (this.editBody.title == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动名称'
                    });
                    return;
                }
                
                if (this.editBody.introduce == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动内容'
                    });
                    return;
                }
                
                if (this.editBody.logo == "") {
                    this.$message({
                        type: 'warning',
                        message: '请上传封面图片'
                    });
                    return;
                }
                
                if (this.editBody.yjkssj == "") {
                    this.$message({
                        type: 'warning',
                        message: '请选择开始时间'
                    });
                    return;
                }
                
                if (this.editBody.seatid == "") {
                    this.$message({
                        type: 'warning',
                        message: '请选择机位'
                    });
                    return;
                }

                this.$confirm('确认创建活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.post('api/Match/SaveActivity', this.editBody ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                      console.log(response.data);
                        if (response.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '发布成功!'
                            });
                            this.fetchTeamData()
                            this.reset()
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            // this.$router.push({ path: '/login'});
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            edit(id) {
                this.$router.push({ path: '/activelive', query: { id:  id} });
            },
            backHome() {
                this.$router.push({ path: '/activelive' });
            },
            onEdit() {
                if (this.editBody.title == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动名称'
                    });
                    return;
                }
                
                if (this.editBody.introduce == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动内容'
                    });
                    return;
                }
                
                if (this.editBody.logo == "") {
                    this.$message({
                        type: 'warning',
                        message: '请上传封面图片'
                    });
                    return;
                }
                
                if (this.editBody.yjkssj == "") {
                    this.$message({
                        type: 'warning',
                        message: '请选择开始时间'
                    });
                    return;
                }
                
                if (this.editBody.seatid == "") {
                    this.$message({
                        type: 'warning',
                        message: '请选择机位'
                    });
                    return;
                }
                
                this.editBody.looktype = parseInt(this.editBody.looktype)
                
                this.$confirm('确定要修改吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                    this.$api.post('api/Match/SaveActivity', this.editBody ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.$router.push({ path: '/activelive'});
                            this.fetchTeamData()
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            // this.$router.push({ path: '/login'});
                        }
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            reset() {
                this.editBody = {
                    id: 0,
                    introduce: "",
                    title: "",
                    logo: "",
                    yjkssj: "",
                    looktype: "0",
                    seatid: "",
                    seatList: this.cameraList
                }
            },
            handleAvatarSuccess(res, file) {
                this.form.logo = URL.createObjectURL(file.raw);
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    console.log("成功", URL.createObjectURL(file.raw))
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.dialogVisible = true
                })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            handleLogoUpdate(croppedImageUrl) {
                // this.editBody.cover = croppedImageUrl
                let blobImage = this.base64ToBlob(croppedImageUrl);
                let formData = new FormData();
                formData.append('file', blobImage, +new Date+'.jpg');
                this.$api.post('api/Cms/Articleupload', formData).then(response => {
                    this.editBody.logo = response.data.data;
                })
            },
            closeDialog() {
                this.dialogVisible = false
            },
            
            
        }
      }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang='less' scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(45% - 50px);
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(55% - 50px);
        margin: 10px;
    }
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .btn-area-btn {
        width: 120px;
        margin-bottom: 10px;
    }
    
    .search-input {
        width: 200px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid #ccc;
        text-indent: 1em;
    }
    
    .el-icon-search {
        position: absolute;
        right: 10px;
        top: 60%;
        color: #666;
    }
    
    .create_submit {
        width: 220px;
    }

    .main-box-1 {
        display: flex;
        justify-content: left;
    }
    
    .el-icon-arrow-left {
        margin-right: 0px!important;
    }
    
    .content {
        width: 100%;
        height: 100%;
    }
    
    .form-box {
        width: 380px;
        margin: 20px;
        font-size: 20px;
    }
    
    .el-form-item {
        font-weight: bold;
        text-align: left;
    }
    
    .el-upload-dragger {
        width: 100px!important;
        height: 100px!important;
    }
    
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        line-height: 110px;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 30px;
        color: #8c939d;
        /* width: 100px; */
        /* height: 100px; */
        text-align: center;
      }
      .avatar {
        width: 100%;
        height: 100%;
        display: block;
      }
      
      .cropper-content {
          .cropper {
              width: auto;
              height: 350px;
              margin-bottom: 10px;
          }
       }
       
       .historyLogos {
           width: calc(100% - 80px);
           margin: 0 auto;
           font-size: 18px;
           font-weight: bold;
           margin-top: 50px;
       }
       
       .logos {
           display: flex;
           flex-wrap: wrap;
           padding-top: 20px;
       }
       
       .logos img {
           width: 100px;
           height: 100px;
           border: 1px solid #ccc;
           border-radius: 5px;
           margin-right: 20px;
           margin-bottom: 20px;
           cursor: pointer;
       }
       
       .logos img:hover {
           border: 1px solid #13b4ff
       }
       
       .upload-demo {
           width: auto;
           margin-right: 10px;
           float: left;
       }
       
       .footer-page {
           margin-top: 20px;
       }
       
       .notice-title {
           height: 80px;
           line-height: 80px;
           border-bottom: 1px solid #ccc;
           
       }
       
       .notice-title {
           :deep(.el-input__inner) {
               border: 0px;
               font-size: 26px;
           }
       }
       
       .upload-file {
           width: 80px;
           height: 80px;
           border: 1px dashed #ccc;
           text-align: center;
           line-height: 80px;
           font-size: 26px;
       }
       
       .team-logo-box {
            display: flex;
            padding-left: 20px;
        }
        
        .team-info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            line-height: 30px;
            text-align: left;
        }
        
        .team-info-name {
            font-size: 18px;
            font-weight: bold;
        }
        
        .team-info-score {
            text-align: left;
            font-size: 14px;
            line-height: 20px;
        }
       
       
    
    
</style>