<template>
    <div class="content">
        <top-nav title="积分成绩"></top-nav>
        <el-input
            class="search-box"
            placeholder="搜索成绩"
            prefix-icon="el-icon-search"
            v-model="searchKey">
        </el-input>
        <!-- <div id="tags-container">
            <div
              v-for="tag in uniqueTags"
              :key="tag"
              class="tag"
              :class="{ active: activeTag === tag }"
              @click="setActiveTag(tag)"
            >
              {{ tag }}
            </div>
        </div> -->
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="starttime"
            label="比赛日期"
            align="right"
            width="100"
            :formatter="formatDate"
            >
            
          </el-table-column>
          <el-table-column
            prop="starttime"
            label="比赛时间"
            align="left"
            width="300"
            :formatter="formatTime"
            >
          </el-table-column>
          <el-table-column
            prop="team"
            label="积分成绩"
            width="800"
            align="center"
            >
            <template slot-scope="scope">
                <div class="team-logo-box">
                    <div class="team-box">
                        <div class="team-logo">
                            <div class="block">
                                <el-avatar @click.native="showPre(scope.row.homelogo)" shape="circle" :size="60" :src="scope.row.homelogo || defaultLogo"></el-avatar>
                            </div>
                        </div>
                        <div class="team-info">
                            <router-link  :to="`/history/${liveid}/teammembers/${scope.row.homename}/${scope.row.homeid}`">
                            <span class="team-info-name">{{scope.row.homename}}</span>
                            </router-link>
                            <span class="team-info-score">{{scope.row.homename}}</span>
                        </div>
                    </div>
                    
                    <div class="team-vs">
                        <div class="team-grade font-red">{{scope.row.homescore}}</div>
                        VS
                        <div class="team-grade font-blue">{{scope.row.guestscore}}</div>
                    </div>
                    
                    <div class="team-box" style="justify-content: right;">
                        <div class="team-info" style="text-align: right;">
                            <router-link  :to="`/history/${liveid}/teammembers/${scope.row.guestname}/${scope.row.guestid}`">
                                <span class="team-info-name">{{scope.row.guestname}}</span>
                            </router-link>
                            <span class="team-info-score" style="text-align: right;">{{scope.row.guestname}}</span>
                        </div>
                        <div class="team-logo">
                            <div class="block" style="margin-left: 10px;">
                                <el-avatar @click.native="showPre(scope.row.guestlogo)" shape="circle" :size="60" :src="scope.row.guestlogo || defaultLogo" fit="cover"></el-avatar>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
          </el-table-column>
          
          
          <el-table-column
            prop="tools"
            label="更多"
            align="left"
            width="300"
            fixed="right"
            >
            <template slot-scope="scope">
                
                <router-link :to="`/history/${liveid}/pointsdetail/${scope.row.id}`">
                    <el-button style="margin-left: 30px" type="primary" size="mini">查看数据</el-button>
                </router-link>
                <el-button style="margin: 0px 15px;background-color: #1eca8f;" size="mini" type="success" icon="el-icon-video-play" v-if="scope.row.videonum > 0" @click="showVideo(scope.row.id)">观看回放</el-button>
                
            </template>
          </el-table-column>
        </el-table>
        <div class="footer-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              @current-change="changepage"
              :hide-on-single-page="true"
              >
            </el-pagination>
        </div>
        <el-dialog
          title="观看回放"
          :visible.sync="dialogVisible"
          width="50%"
          @close="closeVideo"
          >
          <!-- <d-player ref="player" id="player" @play="handlePlay" @pause="handlePause" :options="options"></d-player> -->
          <video style="width: 100%;" :src="allVideo" autoplay controls controlslist="nodownload" disablePictureInPicture></video>
        </el-dialog>
        <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchKey: "",
                preimg: "",
                is_show: false,
                tableData: [],
                activeTag: '比赛成绩',
                pageIndex: 1,
                total: 0,
                pageSize: 10,
                allVideo: "",
                dialogVisible: false,
                liveid: "",
                defaultLogo: require("@/assets/images/defaultlogo.jpg")
            }
        },
        computed: {
            uniqueTags() {
              const tagSet = new Set(this.tableData
                .map(item => `${item.team_tag}/${item.team_group}`));
              return ['比赛成绩',...Array.from(tagSet),'积分排名'];
            },
            filteredTableData() {
              if (this.activeTag === '全部比赛') {
                return this.tableData;
              } else {
                return this.tableData.filter(item => {
                  const tag = `${item.team_tag}/${item.team_group}`;
                  return tag === this.activeTag;
                });
              }
            }
        },
        created() {
            this.liveid = this.$route.params.d
            this.fetchData()
        },
        methods: {
            changepage(p) {
                this.pageIndex = p
                this.fetchData()
            },
            fetchData() {
                let formData = new FormData();
                formData.append('pageindex', this.pageIndex);
                formData.append('pagesize', this.pageSize);
                formData.append('liveid', this.liveid);
                formData.append('status', -1);
                
                this.$api.post('/app/match/list', formData)
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        // this.$router.push({ path: '/login'});
                        // return;
                    }
                    this.tableData = response.data.data.rows
                    this.total = response.data.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            
            },
            viewStats(id) {
                console.log(id)
                this.$router.push({ path: '/pointdetail', query: { id:  id} });
            },
            showPre(url) {
                this.preimg = url
                this.is_show = true
            },
            setActiveTag(tag) {
                this.activeTag = tag; // 设置当前激活的标签
            },
            formatDate(row, column, value) {
                const date = new Date(value);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                return date.toLocaleDateString('default', options);
            },
            formatTime(row, column, value) {
                const date = new Date(value);
                const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
                return date.toLocaleString('default',options);
            },
            showVideo(id) {
                this.fetchMatchLog(id)
                this.dialogVisible = true;
            },
            fetchMatchLog(id) {
                this.$api.get(`api/Match/GameLog`,{
                  params: {
                    matchid: id
                  }
                }).then(response => {
                    response.data.rows.forEach(item => {
                        if(item.video !== null) {
                            this.allVideo = item.video.split(';')[0]
                            return
                        }
                    })
                    
                  
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            closeVideo() {
                this.allVideo = ""
            },
        }
    }
</script>

<style scoped>
    .content {
        position: relative;
    }
    
    .search-box {
        position: absolute;
        top: 20px;
        left: 160px;
    }
    .team-logo-box {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;
    }
    
    .team-box {
        display: flex;
        width: 400px;
    }
    
    .team-vs {
        width: 200px;
        font-weight: bold;
        font-size: 20px;
        line-height: 60px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
    }
    
    .font-blue {
        color: #009dff;
        font-size: 34px
    }
    
    .font-red {
        color: #ff0004;
        font-size: 34px
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: 60px;
      height: 20px;
      position: relative;
      bottom: -6px;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    
    .search-box {
        width: 200px;
        height: 40px;
    }
    
    ::v-deep .el-input__inner {
      background: #f0f0f0;
      border-radius: 40px;
      border: 0px;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
</style>