<template>
    <div title="图片剪裁" v-show="showDialog" class="crop-dialog">
        <div class="cropper-content">
            <div class="cropper" style="text-align:center">
                <vueCropper
                    ref="cropper"
                    :img="option.img"
                    :outputSize="option.size"
                    :outputType="option.outputType"
                    :info="true"
                    :full="option.full"
                    :canMove="option.canMove"
                    :canMoveBox="option.canMoveBox"
                    :original="option.original"
                    :autoCrop="option.autoCrop"
                    :fixed="option.fixed"
                    :fixedNumber="option.fixedNumber"
                    :centerBox="option.centerBox"
                    :infoTrue="option.infoTrue"
                    :fixedBox="option.fixedBox"
                    :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight"
                    @cropMoving="cropMoving"
                />
            </div>
        </div>
        <!-- <div class="action-box">
            <el-upload class="upload-demo"
                       action=""
                       :auto-upload="false"
                       :show-file-list="false"
                       :on-change='handleChangeUpload'>
                <el-button class="action-bt" icon="el-icon-refresh" type="primary" plain>更换图片</el-button>
            </el-upload>

            <el-button class="action-bt" icon="el-icon-refresh-right" type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
            <el-button class="action-bt" icon="el-icon-refresh-left" type="primary" plain @click="rotateRightHandle">右旋转</el-button>
            <el-button class="action-bt" icon="el-icon-zoom-in" type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
            <el-button class="action-bt" icon="el-icon-zoom-out" type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
        </div> -->
        <div slot="footer" class="dialog-footer">
            <el-button class="foot-btn" @click="closeDialog" icon="el-icon-back">取 消</el-button>
            <el-button class="foot-btn" type="primary" @click="finish" :loading="loading" icon="el-icon-check">确认</el-button>
        </div>
    </div>
</template>

<script>
    import VueCropper from "vue-cropper"
    export default {
        
        components: {VueCropper},
        props: {
            image: String ,// 接收传递过来的图片URL
            showDialog: Boolean,
            width: Number,
            height: Number
        },
        watch: {
            image: {
                immediate: true, // 立即观察变化
                handler(newVal) {
                    if (newVal) {
                      this.option.img = newVal
                    }
                }
            },
            width: {
                immediate: true, // 立即观察变化
                handler(newVal) {
                    if (newVal) {
                        this.option.autoCropWidth = newVal;
                    }
                }
            },
            height: {
                immediate: true, // 立即观察变化
                handler(newVal) {
                    if (newVal) {
                        this.option.autoCropHeight = newVal;
                    }
                }
            },
        },
        data() {
            return {
                previewImg: '',
                option: {
                    img: '', // 裁剪图片的地址
                    info: true, // 裁剪框的大小信息
                    outputSize: 1, // 裁剪生成图片的质量
                    outputType: 'png', // 裁剪生成图片的格式
                    canScale: true, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    canMoveBox: true, // 截图框能否拖动
                    autoCropWidth: 300, // 默认生成截图框宽度
                    autoCropHeight: 300, // 默认生成截图框高度
                    fixedBox: true, // 固定截图框大小 不允许改变
                    fixed: false, // 是否开启截图框宽高固定比例
                    fixedNumber: [1, 1], // 截图框的宽高比例
                    full: false, // 是否输出原图比例的截图
                    original: false, // 上传图片按照原始比例渲染
                    centerBox: true, // 截图框是否被限制在图片里面
                    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                },
                loading: false,
                visib: true
            }
            
        },
        methods: {
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    this.option.img = URL.createObjectURL(file.raw)
                    this.loading = false
                    this.dialogVisible = true
                })
            },
            // 放大/缩小
            changeScaleHandle(num) {
                num = num || 1;
                this.$refs.cropper.changeScale(num);
            },
            // 左旋转
            rotateLeftHandle() {
                this.$refs.cropper.rotateLeft();
            },
            // 右旋转
            rotateRightHandle() {
                this.$refs.cropper.rotateRight();
            },
            // 清理图片
            clearImgHandle() {
                this.option.img = ''
            },
            // 截图框移动回调函数
            cropMoving(data) {
                // 截图框的左上角 x，y和右下角坐标x，y
                let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
                console.log(cropAxis)
            },
            finish() {
                // 获取截图的 blob 数据
                // this.$refs.cropper.getCropBlob((blob) => {
                //     let file = new FormData()
                //     var abc = new File([blob], this.fileInfo.name, {type: 'image/jpeg', lastModified: Date.now()});
                    // file.append('file', abc)
                    // 这里根据自己需要的格式上传服务器
                    // this.$axios.post(this.$server + '/upload/uploadImg', file, {
                    //   headers: {
                    //      'Content-Type': 'multipart/form-data',
                    //       Authorization: this.token,
                    //       submitToken: this.uid }
                    //    }).then(res => {
                    //   const {code, data, message} = res.data
                    //   if (code === '0') {
                    //     this.dialogVisible = false
                    //     console.log(data)
                    //   } else {
                    //     this.$message.error(message)
                    //   }
                    // })
                // })
                // 获取截图的 base64 数据
                let that = this
                that.$refs.cropper.getCropData(data => {
                    that.$emit('cancelDialog', false);
                    // this.form.logo = data
                    this.$emit('update-logo', data);
                })
            },
            closeDialog() {
                this.$emit('cancelDialog', false);
            }
        }
    }
</script>

<style scoped>
    .crop-dialog {
        position: fixed;
        background-color: #000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    .dialog-footer {
        position: fixed;
        bottom: 30px;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    
    .foot-btn {
        width: 40%;
    }
    
    .action-box {
        display: flex;
        flex-wrap: wrap;
    }
    
    .cropper-content {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 50%;
    }
</style>