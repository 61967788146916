<template>
    <div class="content">
        <top-nav title="通知管理"></top-nav>
        <div class="main-box">
            <el-table
              :data="noticesData"
              style="width: 100%">
              <el-table-column
                prop="ctime"
                label="发布时间"
                width="200"
                align="center"
                :formatter="formatDate"
                >
                
              </el-table-column>
              <el-table-column
                prop="title"
                label="通知标题"
                align="center"
                >
                
              </el-table-column>
              <el-table-column
                prop="tools"
                label="操作"
                width="300"
                align="center"
                fixed="right"
                >
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row.articleId)" type="primary" size="small">编辑</el-button>
                    <el-button @click="del(scope.row.articleId)" type="danger" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            
            <div class="footer-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  @current-change="changepage"
                  :hide-on-single-page="true"
                  >
                </el-pagination>
            </div>
           
            
           
            
        </div>
        <div class="bigImgmask" v-if="isBig" @click="isBig = false">
            <img :src="chooseImg" alt="" />
        </div>
        
    </div>
</template>

<script>
    export default {
      data() {
        return {
            chooseImg: "",
            isBig: false,
            noticesData: [],
            total: 0,
            pageSize: 5,
            pageIndex: 1,
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        edit(id) {
            this.$router.push({ path: '/pubnotice', query: { id:  id} });
        },
        del(id) {
            this.$confirm("确定要删除吗?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.get(`api/Cms/DelArticle/${id}`)
                .then(response => {
                    if(response.data.code == 1) {
                        this.noticesData = this.noticesData.filter(item => item.articleId !== id)
                        this.fetchData()
                        this.$message({
                            type: 'success',
                            message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                  
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        formatDate(row, column, value) {
            // 假设 value 是一个时间戳
            const date = new Date(value);
            return date.toLocaleString(); // 或者使用其他格式化方法
        },
        fetchData() {
            this.$api.get('api/Cms/Articles', {
              params: {
                PageIndex: this.pageIndex,
                limit: this.pageSize
              }
            })
            .then(response => {
              if (response.data.code == 0) {
                  this.$message({
                    type: 'error',
                    message: response.data.data
                  });
                  this.$router.push({ path: '/login'});
                  return;
              }
              this.noticesData = response.data.rows
              this.total = response.data.total
              this.pageSize = response.data.PageSize
              console.log(response.data);
              console.log(this.total);
              console.log(this.pageSize);
            })
            .catch(error => {
              console.error("There was an error!", error);
            });

        },
        changepage(p) {
            this.pageIndex = p
            this.fetchData()
        }
        
      }
    }
  </script>

<style scoped>
    ::v-deep .el-icon-arrow-left {
        margin-right: 0!important;
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    
    .idimg {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .hukou {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .bigImgmask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .4);
        z-index: 9999;
    }
    
    .bigImgmask img {
        width: 60%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }






    
</style>