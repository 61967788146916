<template>
    <div class="main-box" @click="closeImg" v-show="show_pre">
        <el-image
            class="preimg"
            :src="url"
            fit="contain"
            >
        </el-image>
    </div>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                default:""
            },
            show_pre: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            closeImg() {
                this.$emit('update:show_pre', false);
            }
        }
    }
</script>

<style scoped>
    .main-box {
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
    }
    
    .preimg {
        height: 700px;
        width: 700px;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        
    }
    
    
</style>