<template>
    <div class="content">
        <top-nav title="裁判管理"></top-nav>
        <div class="main-box">
            <el-table
              :data="refsData"
              style="width: 100%"
              >
              <el-table-column
                prop="team"
                label="裁判"
                width="180"
                align="center"
                >
                <template slot-scope="scope">
                    <div class="team-logo">
                        <div class="block">
                            <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="60" :src="scope.row.portrait"></el-avatar>
                        </div>
                    </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="裁判名称"
                width="180"
                align="center"
                >
                
              </el-table-column>
              
              <el-table-column
                prop="grade"
                label="裁判级别"
                width="180"
                align="center"
                >
                <template slot-scope="scope">
                    <span :style="scope.row.isBold ? 'font-weight: bold;color: #000' : '' ">{{ getLevel(scope.row.grade) }}</span>
                </template>
              </el-table-column>
              
              <el-table-column
                prop="rjob"
                label="裁判类型"
                width="200"
                align="center"
                >
                <template slot-scope="scope">
                    <span :style="scope.row.isBold ? 'font-weight: bold;color: #000' : '' ">{{ getType(scope.row.rjob) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="sfzh"
                label="身份证号码"
                width="240"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="tel"
                label="电话"
                width="190"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="tools"
                label="操作"
                width="400"
                align="center"
                fixed="right"
                >
                <template slot-scope="scope">
                    <el-dropdown style="margin-right: 10px;">
                      <el-button size="small" type="primary">
                        更改身份<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="changeType(0,scope.row.id,scope.rowIndex)">普通</el-dropdown-item>
                        <el-dropdown-item @click.native="changeType(1,scope.row.id,scope.rowIndex)">技术代表</el-dropdown-item>
                        <el-dropdown-item @click.native="changeType(2,scope.row.id,scope.rowIndex)">副裁判长</el-dropdown-item>
                        <el-dropdown-item @click.native="changeType(3,scope.row.id,scope.rowIndex)">裁判长</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button v-if="scope.row.rbackvideo == 1" @click="freeView(scope.row.id,scope.row.rbackvideo)" type="warning" size="small" icon="el-icon-circle-close">解除免费</el-button>
                    <el-button v-else @click="freeView(scope.row.id,scope.row.rbackvideo)" style="background-color: #1eca8f;" type="success" size="small" icon="el-icon-video-play">免费观看回放</el-button>
                    <el-button @click="del(scope.row.id)" type="danger" size="small" icon="el-icon-delete">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            
            <!-- <div class="footer-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  @current-change="changepage"
                  :hide-on-single-page="true"
                  >
                </el-pagination>
            </div> -->
           
            
           
            
        </div>

        <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            loading: true,
            total: 0,
            preimg: "",
            is_show: false,
            refsData: [],
            liveid: "",
            PageIndex: 1,
            pagesize: -1,
            boldRowIndex: -1
            
        }
    },
    created() {
        if (this.liveid == "") {
            this.$api.post('api/Main/GetGame').then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                this.liveid = response.data.data.id
                this.fetchData()
            }).catch(error => {
                console.error("There was an error!", error);
            })
        } else {
            this.fetchData()
        }
        
        
    },
    methods: {
        fetchData() {
            console.log(this.PageIndex)
            this.$api.get(`app/match/referees`,{
              params: {
                PageIndex: this.PageIndex,
                liveid: this.liveid,
                pagesize: this.pagesize
              }
            }).then(response => {
                // this.loading = false
                this.refsData = response.data.data.rows
                this.total = response.data.data.total
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        changepage(p) {
            
            this.PageIndex = p
            
            this.fetchData()
        },
        changeType(type, id, index) {
            
            let type_name = ""
            switch(type) {
                case 0:
                    type_name = '普通';break;
                case 1:
                    type_name = '技术代表';break;
                case 2:
                    type_name = '副裁判长';break;
                case 3:
                    type_name = '裁判长';break;
            }
            this.$confirm("确定要更改为【"+type_name+"】吗?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let formdata = new FormData();
                formdata.append('id', id)
                formdata.append('liveid', this.liveid)
                formdata.append('job', type)
                this.$api.post('app/match/setRJob',formdata).then(response => {
                    if (response.data.code == 1) {
                        this.boldRowIndex = index;
                        this.refsData.forEach((item, i) => {
                            item.isRjobBold = i === index; // 设置是否要加粗的标志位
                        });
                        this.$message({
                            type: 'success',
                            message: '已更新！'
                        });
                        let item = this.refsData.find(item => item.id === id)
                        item.rjob = type
                        item.isBold = true
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                }).catch(error => {
                    console.error("There was an error!", error);
                })
                
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        getType(type) {
            let type_name = ""
            switch(type) {
                case 0:
                    type_name = '普通';break;
                case 1:
                    type_name = '技术代表';break;
                case 2:
                    type_name = '副裁判长';break;
                case 3:
                    type_name = '裁判长';break;
                default: type_name = '普通';break;
            }
            return type_name;
        },
        getLevel(type) {
            let type_name = ""
            switch(type) {
                case 0:
                    type_name = '三级裁判';break;
                case 1:
                    type_name = '二级裁判';break;
                case 2:
                    type_name = '一级裁判';break;
                case 4:
                    type_name = '国际级裁判';break;
                default: type_name = '裁判';break;
            }
            return type_name;
        },
        del(id) {
            this.$confirm("确定删除该裁判吗?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.get(`/api/Match/DelReferee/${id}/${this.liveid}`)
                .then(response => {
                    let reult = response.data
                    if (reult.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        this.refsData = this.refsData.filter(item => item.id !== id)
                    } else {
                        this.$message({
                            type: 'error',
                            message: reult.data
                        });
                    }
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        viewImg(img) {
            this.chooseImg = img
            this.isBig = true
        },
        freeView(id, set) {
            let msg = ""
            if (set == 0) {
                msg = "确定设置该裁判为免费观看吗?"
            }else {
                msg = "确定取消该裁判免费观看吗?"
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let formdata = new FormData();
                formdata.append('id', id)
                formdata.append('liveid', this.liveid)
                formdata.append('backvideo', Math.abs(set - 1))
                this.$api.post('app/match/setRVideo',formdata).then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '设置成功！'
                        });
                        let item = this.refsData.find(item => item.id === id)
                        item.rbackvideo = Math.abs(set - 1)
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                }).catch(error => {
                    console.error("There was an error!", error);
                })
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        showPre(url) {
            this.preimg = url
            this.is_show = true
        }
        
      }
    }
  </script>

<style scoped>
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    
    .idimg {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .hukou {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .bigImgmask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .4);
        z-index: 9999;
    }
    
    .bigImgmask img {
        width: 60%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }






    
</style>