<template>
	<div class="content">
		<!-- <div class="board-box">
			<div class="player-item" v-for="(item,index) in player" :key="index">
				{{item.num}}
			</div>
		</div> -->
		<div class="top_menu_nav">
			<div class="btns-box">
				<el-button class="menu_btn my_tactic">我的战术<i class="el-icon--right el-icon-caret-bottom"></i></el-button>
				<el-button class="menu_btn export">导出</el-button>
			</div>
		</div>
		<canvas class="canvas-board" id="Mycanvas" ref="canvasBoard">
			
		</canvas>
		<!-- <div ref="ball" class="ball" @mousedown="startDrag" @mouseup="stopDrag"></div> -->
		<div v-show="if_home">	
			<div
				v-for="(ball, index) in homePlayers" 
				:key="ball.id" 
				class="ball" 
				:style="'left: '+ball.currentPos.x+'px; top: '+ball.currentPos.y+'px;'+ball.style" 
				@touchstart="startDrag($event, index)" 
				@touchend="stopDrag($event, index)" 
				@touchmove="setStep == 1 ? setBallPos($event, index) : ball.hasBall ? drawWavyPath($event, index) : drawPath($event, index)">
				{{ball.num}}
				<span class="basketball_icon iconfont icon-lanqiu" v-if="ball.hasBall"></span>
			</div>
		</div>
		<div v-show="if_guest">
			<div
				v-for="(ball, index) in guestPlayers" 
				:key="ball.id" 
				class="ball balldefined" 
				:style="'left: '+ball.currentPos.x+'px; top: '+ball.currentPos.y+'px;'+ball.style" 
				@touchstart="startDrag($event, index+5)" 
				@touchend="stopDrag($event, index+5)" 
				@touchmove="setStep == 1 ? setBallPos($event, index+5) : ball.hasBall ? drawWavyPath($event, index+5) : drawPath($event, index+5)">
				{{ball.num}}
				<span class="basketball_icon iconfont icon-lanqiu" v-if="ball.hasBall"></span>
			</div>
		</div>
		<div class="btn_box" :style="buttonPos" v-if="buttonShow">
			<button class="board_btn" @click="setLineMode(pathNowIndex)" v-show="player[pathNowIndex].hasBall === false">{{player[pathNowIndex].pathMode == 1 ? '掩护' : '移动'}}</button>
			<button class="board_btn" @click="drawPassline(pathNowIndex)" v-show="showPass && player[pathNowIndex].hasBall">{{player[pathNowIndex].passMode == 1 ? '传球' : '运球'}}</button>
			<button class="board_btn" @click="drawPassline(pathNowIndex)" v-show="showThrow && player[pathNowIndex].hasBall">{{player[pathNowIndex].passMode == 1 ? '投篮' : '运球'}}</button>
			<button class="board_btn" @click="deletePath(pathNowIndex)">删除</button>
		</div>
		<div class="middle_menu_nav">
			<div class="middle_menu_item" @click="if_home = !if_home">
				<i class="iconfont icon-attacker"></i>
				<span>攻防</span>
			</div>
			<div class="middle_menu_item" @click="if_guest = !if_guest">
				<i class="iconfont icon-fangshou"></i>
				<span>守方</span>
			</div>
			<div class="middle_menu_item">
				<i class="iconfont icon-lanqiu1"></i>
				<span>篮球</span>
			</div>
			<div class="middle_menu_item">
				<i class="iconfont icon-project_result_"></i>
				<span>2-3联防</span>
			</div>
			<div class="middle_menu_item">
				<i class="iconfont icon-project_result_"></i>
				<span>3-2联防</span>
			</div>
			<div class="middle_menu_item" @click="setPlayer()">
				<i class="iconfont icon-gongxiao"></i>
				<span>{{playernum}} X {{playernum}}</span>
			</div>
		</div>
		<div class="middle_time_nav">
			<div class="time_box">
				00:00 / 00:18
			</div>
			<div class="play_box">
				<i class="iconfont " :class="isPlay ? 'icon-shuanglie' : 'icon-bofang1'" @click="moveBallAlongPath()"></i>
			</div>
			<div class="tool_box">
				<i class="iconfont icon-chexiao"></i>
				<i class="iconfont icon-zhongzuo"></i>
			</div>
		</div>
		<div class="bottom_time_nav">
			<div class="time_line">
				<div class="time_item" v-for="item in timeLine" :key="item">{{item}}</div>
			</div>
			<div class="scroll_box">
				<div class="scroll_item" v-for="(item,index) in timeLine" :key="index">
					<div class="scroll_line" v-show="timeIndex === index"></div>
				</div>
			</div>
		</div>
	</div>
		
</template>

<script>
// import { Timeline } from 'element-ui';
	export default {
		data() {
			return {
				timeLine: ['00:00','00:01','00:02','00:03','00:04'],
				timeIndex: 0,
				if_home: false,
				if_guest: false,
				player: [
				  {
				    id: 1,
				    num: 1,
				    playerSite: "PG",
				    posX: 120, 
				    posY: 240,
				    path: [],
				    dragging: false,
				    style: '',
				    currentPos: { x: 180, y: 240 },
					pathMode: 1,
					passMode: 1,
					hasBall: true,
					isBasket: false,
					ishome:  true,
					isguest: false
				  },
				  {
				    id: 2,
				    num: 2,
				    playerSite: "SG",
				    posX: 240,
				    posY: 120,
				    path: [],
				    dragging: false,
				    style: '',
				    currentPos: { x: 320, y: 200 },
						  pathMode: 1,
						  passMode: 1,
						  hasBall: false,
						  isBasket: false,
						  ishome:  true,
						  isguest: false
					},
					{
					  id: 3,
					  num: 3,
					  playerSite: "SF",
					  posX: 300,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 50, y: 160 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  true,
					  isguest: false
					},
					{
					  id: 4,
					  num: 4,
					  playerSite: "PF",
					  posX: 300,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 110, y: 60 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  true,
					  isguest: false
					},
					{
					  id: 5,
					  num: 5,
					  playerSite: "C",
					  posX: 200,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 250, y: 60 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  true,
					  isguest: false
					},
					{
						id: 6,
						num: 1,
						playerSite: "PG",
						posX: 120, 
						posY: 240,
						path: [],
						dragging: false,
						style: '',
						currentPos: { x: 180, y: 280 },
						pathMode: 1,
						passMode: 1,
						hasBall: false,
						isBasket: false,
						ishome:  false,
						isguest: true
					},
					{
					  id: 7,
					  num: 2,
					  playerSite: "SG",
					  posX: 240,
					  posY: 120,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 320, y: 240 },
						pathMode: 1,
						passMode: 1,
						hasBall: false,
						isBasket: false,
						ishome:  false,
						isguest: true
					},
					{
					  id: 8,
					  num: 3,
					  playerSite: "SF",
					  posX: 300,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 50, y: 200 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  false,
					  isguest: true
					},
					{
					  id: 9,
					  num: 4,
					  playerSite: "PF",
					  posX: 300,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 110, y: 100 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  false,
					  isguest: true
					},
					{
					  id: 10,
					  num: 5,
					  playerSite: "C",
					  posX: 200,
					  posY: 160,
					  path: [],
					  dragging: false,
					  style: '',
					  currentPos: { x: 250, y: 100 },
					  pathMode: 1,
					  passMode: 1,
					  hasBall: false,
					  isBasket: false,
					  ishome:  false,
					  isguest: true
					},
					{
						id: 11,
						num: 6,
						playerSite: "框",
						posX: 200,
						posY: 160,
						path: [],
						dragging: false,
						style: 'opacity: 0;',
						currentPos: { x: 170, y: 60 },
						pathMode: 1,
						passMode: 1,
						hasBall: false,
						isBasket: true,
						ishome:  true,
						isguest: false
					}
				],
				formationList: [
					{
					  id: 0,
					  name: "默认落位",
					  pos: [
						  { x: 180, y: 240 },
						  { x: 320, y: 200 },
						  { x: 50, y: 160 },
						  { x: 110, y: 60 },
						  { x: 250, y: 60 },
					  ]
					},
					{
					  id: 1,
					  name: "牛角",
					  pos: [
						  { x: 187, y: 229 },
						  { x: 306, y: 155 },
						  { x: 141, y: 145 },
						  { x: 295, y: 48 },
						  { x: 93, y: 54 },
					  ]
					},
						  
				],
				canvasWidth: 0,
				canvasHeight: 0,
				ctx: null,
				initialized: false,
				pathBtn: false,
				pathBtnPos: { x: 0, y: 0 },
				buttonPos: 'left: 0px; top: 0px;',
				buttonShow: false,
				pathNowIndex: 0,
				showPass: false,
				showThrow: false,
				setStep: 2,
				isPlay: false,
				timer: null,
				playernum: 5,
				frameTime: [
					3,
					5
				]
			}
		},
		computed: {
		    homePlayers() {
		      // 返回过滤后的数组
				const filteredPlayers = this.player.filter(ball => ball.ishome === true);
				return filteredPlayers.slice(0, this.playernum);
		    },
			guestPlayers() {
			  // 返回过滤后的数组
			  const filteredPlayers = this.player.filter(ball => ball.isguest === true);
			  return filteredPlayers.slice(0, this.playernum);
			}
		},
		mounted() {
			this.adjustCanvasSize();
			window.addEventListener('resize', this.adjustCanvasSize);
		},
		methods: {
			adjustCanvasSize() {
				if (this.initialized) return;
				const canvas = this.$refs.canvasBoard;
			        if (canvas) {
						const rect = canvas.getBoundingClientRect();
						this.canvasWidth = rect.width;
						this.canvasHeight = rect.height;

						canvas.width = this.canvasWidth;
						canvas.height = this.canvasHeight;

						const ctx = canvas.getContext('2d');
						this.ctx = ctx;
						ctx.strokeStyle = 'red';
						ctx.lineWidth = 2;
						ctx.stroke(); // 初始化画布

				}
			  this.initialized = true;
			},
			startDrag(event, index) {
				
				this.player[index].dragging = true;
				this.player[index].pathMode = 1
				this.pathNowIndex = index
				this.player[index].path = [];
			  
			},
			stopDrag(event, index) {
			  this.player[index].dragging = false;
			},
			setBallPos(event, index) {
				if (!this.player[index].dragging || this.player[index].isBasket) return;
				const touch = event.touches[0];
				const mouseX = touch.clientX;
				const mouseY = touch.clientY - 20;
				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const rect = canvas.getBoundingClientRect();
					const adjustedX = mouseX - rect.left;
					const adjustedY = mouseY - rect.top;
					this.player[index].currentPos = { x: adjustedX, y: adjustedY };
				}
			},
			drawPath(event, index) {
				
				if (!this.player[index].dragging || this.player[index].isBasket) return;
				
				const touch = event.touches[0];
				
				
				const mouseX = touch.clientX;
				const mouseY = touch.clientY;
				const canvas = this.$refs.canvasBoard;
			        if (canvas) {
			          const rect = canvas.getBoundingClientRect();
			          const adjustedX = mouseX - rect.left;
			          const adjustedY = mouseY - rect.top;
			  
			          const ctx = this.ctx;
			          ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
			  
			          this.player[index].path.push({ x: adjustedX, y: adjustedY });
			  
			          this.player.forEach((ball, Findex) => {
			            ctx.strokeStyle = 'red';
			            ctx.lineWidth = 2;
			            if (ball.path.length > 0 && !ball.hasBall) {
			              ctx.beginPath();
			              ctx.moveTo(ball.path[0].x, ball.path[0].y);
			              for (const point of ball.path) {
			                ctx.lineTo(point.x, point.y);
			              }
			              ctx.stroke();
			  
			              if (ball.path.length > 1) {
			                const arrowStart = ball.path[ball.path.length - 2];
			                const arrowEnd = ball.path[ball.path.length - 1];
			  
			                if (ball.pathMode === 2) {
			                  this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			                } else {
			                  this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			                }
			              }
			            } else if (ball.path.length > 0 && ball.hasBall) {
			              this.setBallLine(Findex);
			            }
			          });
			  
			          this.buttonPos = `left: ${adjustedX - 25}px; top: ${adjustedY + 60}px;`;
			          this.buttonShow = true;
			    }
			  
			},
			deletePath(index) {
			    this.buttonShow = false;
			    const canvas = this.$refs.canvasBoard;
			    if (canvas) {
			      // const rect = canvas.getBoundingClientRect();
			      const ctx = this.ctx;
			      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
			      this.player[index].path = [];
			      this.player.forEach((ball, ballidx) => {
			        if (ball.path.length > 0) {
			          if (ball.hasBall) {
			            this.setBallLine(ballidx);
			          } else {
			            ctx.beginPath();
			            ctx.moveTo(ball.path[0].x, ball.path[0].y);
			            for (const point of ball.path) {
			              ctx.lineTo(point.x, point.y);
			            }
			            ctx.stroke();
			            if (ball.path.length > 1) {
			              const arrowStart = ball.path[ball.path.length - 2];
			              const arrowEnd = ball.path[ball.path.length - 1];
			              if (ball.pathMode === 2) {
			                this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			              } else {
			                this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			              }
			            }
			          }
			        }
			      });
			    }
			},
			moveBall(x, y, index) {
			  const style = `left: ${x - 12}px; top: ${y + 30}px;`;
			  this.$set(this.player[index], 'style', style);
			  this.player[index].currentPos = { x, y };
			},
			moveBallAlongPath() {
				this.isPlay = true
				const time_lenth  = this.timeLine.length
				let  that = this
				this.timer = setInterval(() => {
				    that.timeIndex++
					console.log(that.timeIndex)
					if (that.timeIndex == time_lenth) {
						this.timeIndex = 0
						this.isPlay = false
						clearInterval(this.timer);
					}
				}, 1000);
				this.buttonShow = false
			  this.player.forEach((ball, index) => {
			    let i = 0;
			    const move = () => {
			      if (i >= ball.path.length) {
					  
					  return;
				  }
				  
			      ball.currentPos = ball.path[i];
			      this.moveBall(ball.path[i].x, ball.path[i].y, index);
			      i++;
			      setTimeout(move, 16);
			    };
			    move();
			  });
			},
			drawArrow(ctx, fromx, fromy, tox, toy) {
				const headlen = 10;
				const angle = Math.atan2(toy - fromy, tox - fromx);
				ctx.beginPath();
				ctx.moveTo(tox, toy);
				ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
				ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
				ctx.closePath();
				ctx.fillStyle = 'red';
				ctx.fill();
			
				
			},
			setLineMode(index) {
				this.player[index].pathMode == 1 ? this.player[index].pathMode = 2 : this.player[index].pathMode = 1
				let ctx = this.ctx
				ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
				this.player.forEach((ball,index) => {
				    ctx.strokeStyle = 'red';
				    ctx.lineWidth = 2;
				    if (ball.path.length > 0 && ball.hasBall === false) {
				        ctx.beginPath();
				        ctx.moveTo(ball.path[0].x, ball.path[0].y);
				        for (const point of ball.path) {
				            ctx.lineTo(point.x, point.y);
				        }
				        ctx.stroke();
				        if (ball.path.length > 1) {
				        	const arrowStart = ball.path[ball.path.length - 2];
				        	const arrowEnd = ball.path[ball.path.length - 1];
				        	if (ball.pathMode == 2) {
				        		this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
				        	} else {
				        		this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
				        	}
				        	
				        }
				    } else if(ball.path.length > 0 && ball.hasBall) {
						this.setBallLine(index)
					}
				});
				
				
			},
			drawShortLine(ctx, fromx, fromy, tox, toy) {
				const headlen = 10;
				const angle = Math.atan2(toy - fromy, tox - fromx);
				ctx.beginPath();
				const lineAngle = angle + Math.PI / 2; // 垂直于线的角度
				const tx1 = tox - headlen * Math.cos(lineAngle);
				const ty1 = toy - headlen * Math.sin(lineAngle);
				const tx2 = tox + headlen * Math.cos(lineAngle);
				const ty2 = toy + headlen * Math.sin(lineAngle);
				ctx.moveTo(tx1, ty1);
				ctx.lineTo(tx2, ty2);
				ctx.strokeStyle = 'red';
				ctx.lineWidth = 2;
				ctx.stroke();
			},
			drawWavyPath(event, index) {
			    if (!this.player[index].dragging) return;
			
			    const touch = event.touches[0];
			    const mouseX = touch.clientX;
			    const mouseY = touch.clientY;
			
			    const canvas = this.$refs.canvasBoard;
			    if (canvas) {
			      const rect = canvas.getBoundingClientRect();
			      const adjustedX = mouseX - rect.left;
			      const adjustedY = mouseY - rect.top;
			      const ctx = this.ctx;
			
			      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
			
			      let path = this.player[index].path;
			      this.player.forEach((ball) => {
			        ctx.strokeStyle = 'red';
			        ctx.lineWidth = 2;
			
			        if (ball.path.length > 0 && ball.hasBall === false) {
			          ctx.beginPath();
			          ctx.moveTo(ball.path[0].x, ball.path[0].y);
			          for (const point of ball.path) {
			            ctx.lineTo(point.x, point.y);
			          }
			          ctx.stroke();
			
			          if (ball.path.length > 1) {
			            const arrowStart = ball.path[ball.path.length - 2];
			            const arrowEnd = ball.path[ball.path.length - 1];
			            if (ball.pathMode === 2) {
			              this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			            } else {
			              this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
			            }
			          }
			        }
			      });
			
			      path.push({ x: adjustedX, y: adjustedY });
			
			      ctx.beginPath();
			      let currentStart = path[0];
			      for (let i = 0; i < path.length; i++) {
			        const currentPoint = path[i];
			        const dx = currentPoint.x - currentStart.x;
			        const dy = currentPoint.y - currentStart.y;
			        const distance = Math.sqrt(dx * dx + dy * dy);
			
			        if (distance > 20) {
			          this.drawSineCurve(currentStart, currentPoint);
			          currentStart = currentPoint;
			        }
			      }
			
			      if (path.length > 1) {
			        const lastPoint = path[path.length - 1];
			        const secondLastPoint = path[path.length - 2];
			        this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
			      }
			
			      const lastPoint = path[path.length - 1];
			      const ballRadius = 25;
			
			      for (let i = 0; i < this.player.length; i++) {
			        const ball = this.player[i];
			        const dx = ball.currentPos.x + ballRadius - lastPoint.x;
			        const dy = ball.currentPos.y - ballRadius - lastPoint.y;
			        const distance = Math.sqrt(dx * dx + dy * dy);
			
			        if (distance <= ballRadius && i !== this.pathNowIndex && !ball.isBasket) {
			          this.showPass = true;
			          this.showThrow = false;
			          break;
			        } else if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket) {
			          this.showPass = false;
			          this.showThrow = true;
			          break;
			        } else if (distance > ballRadius && i !== this.pathNowIndex) {
			          this.showPass = false;
			          this.showThrow = false;
			        }
			      }
			
			      ctx.stroke();
			      this.buttonPos = `left: ${adjustedX + 25}px; top: ${adjustedY + 60}px;`;
			
			      this.buttonShow = true;
			      this.player[index].passMode = 1;
			    }
			},
			drawPassline(index, ev = true) {
				
				if (this.player[index].passMode == 1) {
					const path = this.player[index].path
					const startPos = path[0]
					const endPos = path[path.length - 1]
					const ctx = this.ctx
					const lastPoint = path[path.length - 1];
					const secondLastPoint = path[path.length - 2];
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					ctx.beginPath();
					ctx.setLineDash([5, 5]);  // 设置虚线样式
					ctx.moveTo(startPos.x, startPos.y);
					ctx.lineTo(endPos.x, endPos.y);
					
					ctx.stroke();
					this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					ctx.setLineDash([]);
					
					this.player.forEach((ball) => {
					    ctx.strokeStyle = 'red';
					    ctx.lineWidth = 2;
								  
								  
					    if (ball.path.length > 0 && ball.hasBall === false) {
					        ctx.beginPath();
					        ctx.moveTo(ball.path[0].x, ball.path[0].y);
					        for (const point of ball.path) {
					            ctx.lineTo(point.x, point.y);
					        }
					        ctx.stroke();
					
					        if (ball.path.length > 1) {
					            const arrowStart = ball.path[ball.path.length - 2];
					            const arrowEnd = ball.path[ball.path.length - 1];
								if (ball.pathMode == 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}
					            
					        }
					    }
					});
					if (ev) {
						this.player[index].passMode = 2
					}
					
				} else {
					const canvas = this.$refs.canvasBoard;
					    if (canvas) {
					      const ctx = this.ctx;
					      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					      let path = this.player[index].path;
					
					      this.player.forEach((ball) => {
					        ctx.strokeStyle = 'red';
					        ctx.lineWidth = 2;
					
					        if (ball.path.length > 0 && ball.hasBall === false) {
					          ctx.beginPath();
					          ctx.moveTo(ball.path[0].x, ball.path[0].y);
					          for (const point of ball.path) {
					            ctx.lineTo(point.x, point.y);
					          }
					          ctx.stroke();
					
					          if (ball.path.length > 1) {
					            const arrowStart = ball.path[ball.path.length - 2];
					            const arrowEnd = ball.path[ball.path.length - 1];
					            if (ball.pathMode === 2) {
					              this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
					            } else {
					              this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
					            }
					          }
					        }
					      });
					
					      ctx.beginPath();
					      let currentStart = path[0];
					      for (let i = 0; i < path.length; i++) {
					        const currentPoint = path[i];
					        const dx = currentPoint.x - currentStart.x;
					        const dy = currentPoint.y - currentStart.y;
					        const distance = Math.sqrt(dx * dx + dy * dy);
					
					        if (distance > 12) {
					          this.drawSineCurve(currentStart, currentPoint);
					          currentStart = currentPoint;
					        }
					      }
					
					      if (path.length > 1) {
					        const lastPoint = path[path.length - 1];
					        const secondLastPoint = path[path.length - 2];
					        this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					      }
					
					      const lastPoint = path[path.length - 1];
					      const ballRadius = 12; // 假设每个小球的半径是 10
					
					      for (let i = 0; i < this.player.length; i++) {
					        const ball = this.player[i];
					        const dx = ball.currentPos.x + 12 - lastPoint.x;
					        const dy = ball.currentPos.y - 12 - lastPoint.y;
					        const distance = Math.sqrt(dx * dx + dy * dy);
					
					        if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket === false) {
					          this.showPass = true;
					          this.showThrow = false;
					          break;
					        } else if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket === true) {
					          this.showPass = false;
					          this.showThrow = true;
					          break;
					        } else if (distance > ballRadius && i !== this.pathNowIndex) {
					          this.showPass = false;
					          this.showThrow = false;
					        }
					      }
					
					      ctx.stroke();
					      this.buttonShow = true;
					}
					if (ev) {
						this.player[index].passMode = 1
					}
				}
				
			},
			setBallLine(index) {
				if (this.player[index].passMode == 2) {
					const path = this.player[index].path
					const startPos = path[0]
					const endPos = path[path.length - 1]
					const ctx = this.ctx
					const lastPoint = path[path.length - 1];
					const secondLastPoint = path[path.length - 2];
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					ctx.beginPath();
					ctx.setLineDash([5, 5]);  // 设置虚线样式
					ctx.moveTo(startPos.x, startPos.y);
					ctx.lineTo(endPos.x, endPos.y);
					
					ctx.stroke();
					this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					ctx.setLineDash([]);
					
					this.player.forEach((ball) => {
					    ctx.strokeStyle = 'red';
					    ctx.lineWidth = 2;
								  
								  
					    if (ball.path.length > 0 && ball.hasBall === false) {
					        ctx.beginPath();
					        ctx.moveTo(ball.path[0].x, ball.path[0].y);
					        for (const point of ball.path) {
					            ctx.lineTo(point.x, point.y);
					        }
					        ctx.stroke();
					
					        if (ball.path.length > 1) {
					            const arrowStart = ball.path[ball.path.length - 2];
					            const arrowEnd = ball.path[ball.path.length - 1];
								if (ball.pathMode == 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}
					            
					        }
					    }
					});
					
				} else {
					const canvas = this.$refs.canvasBoard;
					    if (canvas) {
					      // const rect = canvas.getBoundingClientRect();
					      const ctx = this.ctx;
					
					      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					
					      let path = this.player[index].path;
					
					      this.player.forEach((ball) => {
					        ctx.strokeStyle = 'red';
					        ctx.lineWidth = 2;
					
					        if (ball.path.length > 0 && ball.hasBall === false) {
					          ctx.beginPath();
					          ctx.moveTo(ball.path[0].x, ball.path[0].y);
					          for (const point of ball.path) {
					            ctx.lineTo(point.x, point.y);
					          }
					          ctx.stroke();
					
					          if (ball.path.length > 1) {
					            const arrowStart = ball.path[ball.path.length - 2];
					            const arrowEnd = ball.path[ball.path.length - 1];
					            if (ball.pathMode === 2) {
					              this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
					            } else {
					              this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
					            }
					          }
					        }
					      });
					
					      ctx.beginPath();
					      let currentStart = path[0];
					      for (let i = 0; i < path.length; i++) {
					        const currentPoint = path[i];
					        const dx = currentPoint.x - currentStart.x;
					        const dy = currentPoint.y - currentStart.y;
					        const distance = Math.sqrt(dx * dx + dy * dy);
					
					        if (distance > 20) {
					          this.drawSineCurve(currentStart, currentPoint);
					          currentStart = currentPoint; // 更新起点
					        }
					      }
					
					      // 在路径的最后一个点上绘制箭头
					      if (path.length > 1) {
					        const lastPoint = path[path.length - 1];
					        const secondLastPoint = path[path.length - 2];
					        this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					      }
					
					      ctx.stroke();
					
					      this.buttonShow = true;
					    }
				}
			},
			
			drawSineCurve(start, end) {
			  const startPoint = start;
			  const endPoint = end;
			  const waveHeight = 5;  // 正弦波的振幅
			  const waveLength = 1; // 每个波长的长度
			
			  const ctx = this.ctx;
			
			  const dx = endPoint.x - startPoint.x;
			  const dy = endPoint.y - startPoint.y;
			  const distance = Math.sqrt(dx * dx + dy * dy);
			  const angle = Math.atan2(dy, dx);
			
			  const steps = Math.ceil(distance / waveLength);
			
			  ctx.beginPath();
			  ctx.moveTo(startPoint.x, startPoint.y);
			
			  for (let i = 1; i <= steps; i++) {
			    const t = i / steps;
			    const x = startPoint.x + t * dx;
			    const yOffset = Math.sin(t * Math.PI * 2) * waveHeight;
			
			    // 旋转偏移量
			    const rotatedX = x + yOffset * Math.sin(angle);
			    const y = startPoint.y + t * dy - yOffset * Math.cos(angle);
			
			    ctx.lineTo(rotatedX, y);
				// const ball = this.player[this.pathNowIndex]
				// const arrowStart = ball.path[ball.path.length - 2];
				// const arrowEnd = ball.path[ball.path.length - 1];
				// 
			  }
			  ctx.stroke();
			},
			setFormation(index) {
				const item = this.formationList[index]
				const pos = item.pos
				for (let i = 0; i < pos.length; i++) {
					this.player[i].currentPos = pos[i]
				}
				
			},
			setPlayer() {
				
				if (this.playernum == 3) {
					this.playernum = 5
				} else {
					this.playernum -= 1
				}
			}
		}
	}
</script>
<style>
	
	
</style>
<style scoped>
	@import url('@/assets/css/color/iconfont.css');
	body {
		overflow:hidden; 
	}
	.content {
		background-color: #141414;
		min-height: 100vh;
		touch-action: pan-y;
	}
	.player-item {
	  border-radius: 50%;
	  width: 60px;
	  height: 60px;
	  background-color: #000;
	  color: #fff;
	  text-align: center;
	  line-height: 60px;
	}
	
	.board-box {
	  width: 90%;
	  height: 450px;
	  border: 1px solid #000;
	  margin: auto;
	}
	
	.ball {
	  width: 30px;
	  height: 30px;
	  background-color: #000;
	  color: #fff;
	  text-align: center;
	  line-height: 30px;
	  border-radius: 50%;
	  position: absolute;
	  top: 100px; /* 初始位置 */
	  left: 100px; /* 初始位置 */
	  cursor: pointer;
	  font-size: 14px;
	}
	
	.basketball_icon {
		position: relative;
		right: -30px;
		bottom: 20px;
		display: block;
		width: 15px;
		height: 15px;
		z-index: 999;
		font-size: 22px;
	}
	
	.play_button {
	  width: 200px;
	  height: 90px;
	  margin: auto;
	  font-size: 35px;
	}
	
	.btn_box {
		width: 60px;
		position: absolute;
		display: flex;
		flex-direction: column;
	}
	
	.board_btn {
		height: 30px;
		line-height: 30px;
		font-size: 13px;
		border-radius: 7px;
		margin-bottom: 2px;
		border: 1px solid #ccc;
		
	}
	
	.formation_box {
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-around;
	}
	
	.formation_box_item {
		width: 100rpx;
		height: 100rpx;
		text-align: center;
		line-height: 100rpx;
		border-radius: 10rpx;
		border: 2rpx solid orange;
		font-size: 26rpx;
	}
	
	.canvas-board {
		width: calc(100vw - 2px);
		/* height: 40vh; */
		background-image: url('../../assets/images/board.jpg');
		background-size: 100% 100%;
		aspect-ratio: 4/3.2;
		
	}
	
	.top_menu_nav {
		display: flex;
		justify-content: right;
	}
	
	.menu_btn {
		margin: 6px;
		color: #fff;
		border-radius: 10px;
		font-weight: bold;
		border: 0;
	}
	
	.my_tactic {
		background-color: #2a2a2a;
	}
	
	.export {
		background-color: #fe2b54;
		width: 80px;
	}
	
	.middle_menu_nav {
		color: #c0c0c0;
		display: flex;
		justify-content: space-around;
		background-color: #1c1c1c;
	}
	
	.middle_menu_item {
		display: flex;
		flex-direction: column;
		padding-top:8px;
		padding-bottom:6px;
	}
	
	.middle_menu_item i  {
		font-size: 22px;
	}
	
	.middle_menu_item span {
		font-size: 12px;
		margin-top: 10px;
	}
	
	.middle_time_nav {
		display: flex;
		color: #c0c0c0;
		align-items: center;
		background-color: #141414;
		height: 50px;
	}
	
	.middle_time_nav div{
		width: 33%;
	}
	
	.tool_box i {
		font-size: 22px;
	}
	
	.tool_box {
		display: flex;
		justify-content: right;
	}
	
	.time_box {
		font-size: 15px;
		font-weight: bold;
	}
	
	.bottom_time_nav {
		background-color: #1c1c1c;
		height: 210px;
	}
	
	.btn_box {
		width: 120rpx;
		position: absolute;
	}
	
	.board_btn {
		height: 60rpx;
		line-height: 60rpx;
		font-size: 26rpx;
		border-radius: 15rpx;
		margin-bottom: 5rpx;
		border: 1px solid #ccc;
		
	}
	
	.time_line {
		display: flex;
		padding-top: 10px;
	}
	
	.time_item {
		width: 60px;
		color: #c0c0c0;
		font-size: 10px;
		
	}
	
	.scroll_line {
		width: 2px;
		height: 100%;
		background-color: #c0c0c0;
	}
	
	.scroll_box {
		display: flex;
		height: calc(100% - 40px);
		
	}
	
	.scroll_item {
		width: 60px;
	}
	
	.scroll_item .scroll_line {
		margin: 0 auto;
		margin-top: 10px;
	}
	
	.balldefined {
		background-color: #fe2b54;
	}
	
</style>