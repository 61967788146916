<template>
    <div class="content">
        <top-nav :title="navTitle" :isBak="true"></top-nav>
        <!-- <div class="top-btns" @click="addMember">
            <div class="ripple"></div>
            <i class="el-icon-plus"></i>
        </div> -->
        
        <div class="main-box">
            <div class="state_box-item" style="border: 0;">
                <div style="display: flex;">
                    <span class="state_box-item-title">观看设置: </span>
                    
                </div>
                <div class="btn-area">
                    <span class="state_box-item-state">设置观看比赛的方式及收费金额</span>
                   <div style="width: 100%;text-align: right;display: flex;justify-content: right;">
                       <div>
                           <el-select v-model="payType" placeholder="请选择">
                               <el-option
                                 v-for="item in options"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"
                                  @click.native="changePay(item.value)"
                                 >
                               </el-option>
                           </el-select>
                       </div>
                       <el-input @focus="oldprice = price" @blur="changePrice" v-only-digits v-if="payType !== '0'" v-model="price" placeholder="收费金额" style="width: 200px;height: 40px;margin-left: 20px;"><template slot="append">￥</template></el-input>
                   </div>
               </div>
            </div>
            
            <div class="state_box-item" style="border: 0;">
                <div style="display: flex;">
                    <span class="state_box-item-title">角标设置: </span>
                    
                </div>
                <div class="btn-area">
                    <span class="state_box-item-state">设置比赛视频的角标</span>
                   <div style="width: 100%;text-align: right;display: flex;justify-content: right;">
                       <el-upload
                         class="avatar-uploader"
                         action="''"
                         drag
                         :auto-upload="false"
                         :show-file-list="false"
                         :on-change="handleChangeUpload">
                         <img class="video-logo" :src="gamedata.videologo || defaultLogo" alt="">
                       </el-upload>
                   </div>
               </div>
            </div>
            <div class="main-box-1">
                <div class="camera-box-item" v-for="(item,index) in cameraList" :key="index">
                    <div class="camera-name">{{item.name}}</div>
                    <!-- <i class="camera-icon el-icon-video-camera-solid"></i> -->
                    <hls-video :src="item.pullstreamurlm"></hls-video>

                </div>
            </div>
        </div>
        <my-cropper :showDialog="dialogFaceVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog"></my-cropper>
        <!-- <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage> -->
    </div>
</template>

<script>
    import HlsVideo from '@/components/HlsVideo.vue';
    export default {
        components: {
            HlsVideo,
        },
        mounted() {
            
            
        },
        created() {
            this.navTitle = "机位设置 - "+this.$route.params.t
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.oid = response.data.data.oid
					this.gamedata = response.data.data
					this.getCameraList()
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } else {
                this.getCameraList()
                
            }
        },
        data() {
            return {
                options: [{
                  value: '0',
                  label: '转发朋友圈'
                }, {
                  value: '1',
                  label: '观看收费'
                }],
                payType: "0",
                price: "",
                defaultLogo: require("@/assets/images/defaultlogo.jpg"),
                cropperImage: "",
                dialogAddVisible: false,
                dialogFaceVisible: false,
                cameraList: [],
                liveid: "",
                oid: "",
				gamedata: []
            }
        },
        methods: {
            
            getCameraList() {
                let formData = new FormData();
                formData.append('oid', this.oid);
                this.$api.post('app/user/organizerseats', formData).then(response => {
                    this.cameraList = response.data.data;
                })
            },
            showPre(url) {
                this.preimg = url
                this.is_show = true
            },
            changePay(t) {
                console.log(t)
                let msg = ""
                switch(t) {
                    case "0": 
                        msg = "确认将观看方式改为转发朋友圈吗？";
                        break;
                    case "1":
                        msg = "确认将观看方式改为观看收费吗？";
                        break;
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.payType = t
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
            
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
                
            },
            changePrice() {
                this.$confirm("确定将观看费用设置为"+this.price+"元吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                }).catch(() => {
                    this.price = this.oldprice
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.dialogFaceVisible = true
                })
            },
            handleLogoUpdate(croppedImageUrl) {
				this.$confirm("确定要修改视频角标吗？", '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
					let blobImage = this.base64ToBlob(croppedImageUrl);
					let formData = new FormData();
					formData.append('file',blobImage, +new Date+'.jpg');
					this.$api.post('api/Cms/Articleupload', formData).then(response => {
						console.log(response)
						let formData = new FormData();
						formData.append('name', this.gamedata.name);
						formData.append('logo', this.gamedata.ologo);
						formData.append('img', this.gamedata.logo);
						formData.append('videologo', response.data.data);
						this.$api.post('api/Main/resetgamename', formData).then(response => {
						    if (response.data.code == 1) {
								this.gamedata.videologo = croppedImageUrl
								this.$message({
								    type: 'success',
								    message: '修改成功!'
								});
							} else {
								this.$message({
								    type: 'error',
								    message: response.data.data
								});
							}
						})
					})
				    
				}).catch(() => {
				    this.price = this.oldprice
				    this.$message({
				        type: 'info',
				        message: '取消操作'
				    });          
				});
                
                // let blobImage = this.base64ToBlob(croppedImageUrl);
                // let formData = new FormData();
                // formData.append('file', blobImage, +new Date+'.jpg');
                // this.$api.post('api/Team/playerupload', formData).then(response => {
                //     this.addform.portrait = response.data.data;
                // })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            closeDialog() {
                this.dialogFaceVisible = false
            },
            editCameraName(id) {
                const data = this.cameraList.find(item => item.id === id)
                this.$prompt('请输入机位名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: data.name,
                    closeOnClickModal: false
                }).then(({ value }) => {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    });
                    let item = this.cameraList.find(item => item.id === id)
                    item.name = value
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });       
                });
            },
            
        }
    }
</script>

<style scoped>
    
    .state_box {
        background: #fff;
        padding: 10px 0px;
        margin: 20px;
        border-radius: 5px;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
    }
    
    .state_box-item {
        margin-bottom: 10px;
        text-align: left;
        /* background-color: #f2f2f2; */
        padding: 10px 0px;
        
        /* height: 40px; */
        line-height: 40px;
        padding-right: 10px;
        border-top: 1px solid #f0f0f0;
        
        
    }
    
    .state_box-item-title {
        font-size: 15px;
        display: block; /* Improves readability */
        margin-bottom: 5px;
        
        color: #666;
        margin-left: 50px;
        width: 140px;
        text-align: left;
    }
    
    .state_box-item-state {
        width: 50%;
        text-align: left;
        font-size: 16px;
        margin-bottom: 5px;
    }
    
    .btn-shadow {
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .state_box-item-btn {
        background-color: #007bff;
        margin-right: 20px;
        border: none;
        color: white;
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s;
        border-radius: 5px; /* Adds rounded corners to buttons */
    }
    
    .state_box-item-btn:hover {
        background-color: #0056b3;
    }
    
    .btn-area {
        display: flex;
        justify-content: left;
        text-align: left;
        
        width: 90%;
        padding-left: 50px;
    }
    
    .title-icon {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
        font-weight: bold!important;
    }
    
    .video-logo {
        width: 50px;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 10px;
        cursor: pointer;
    }
    
    .avatar-uploader  /deep/ .el-upload{
        border: 0px!important;
    }
    
    .avatar-uploader  /deep/ .el-upload-dragger{
        border: 0px!important;
    }
    
    .camera-box-item {
        width: 200px;
        height: 180px;
        border-radius: 15px;
        border: 1px solid #666;
        margin: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        white-space: nowrap
    }
    
    .camera-icon {
        font-size: 80px;
        position: absolute;
        top: 40px;
    }
    
    .camera-name {
        font-size: 12px;
        padding-left: 20px;
        width: 100%;
        text-align: left;
        margin-top: 10px;
        height: 30px;
    }
    
    .brn-area {
        display: flex;
        justify-content: space-between;
        width: 90%;
        position: absolute;
        bottom: 10px;
        font-size: 14px;
        color: #0e95ee;
    }
    
    .brn-area div {
        cursor: pointer;
    }
    
    .main-box-1 {
        display: flex;
        justify-content: left;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: scroll;
        margin-top: 200px;
    }

</style>