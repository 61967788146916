<template>
    <div class="content">
        <top-nav title="报名管理"></top-nav>
        <div class="main-box">
            
            <div class="top-box">
                <div class="top-data">
                    <div class="top-data-item">
                        <span class="top-data-item-num">{{payNum}}</span>
                        <span class="top-data-item-text">缴费人数</span>
                    </div>
                    <div class="top-data-item">
                        <span class="top-data-item-num">{{leaderNum}}</span>
                        <span class="top-data-item-text">领队教练人数</span>
                    </div>
                    <div class="top-data-item">
                        <span class="top-data-item-num">{{allPriceNum}}</span>
                        <span class="top-data-item-text">收费金额</span>
                    </div>
                </div>
                <div class="btns-area">
                    <div style="text-align: left;margin: 10px 0px;position: relative;">
                        <input type="text" class="search-input" placeholder="搜索球员" v-model="teamname" @keydown.enter="searchTeam()">
                        <i class="el-icon-search" @click="searchTeam()"></i>
                    </div>
                    <el-button icon="el-icon-download" @click="exportSignExcel()">导出EXCEL</el-button>
                    <el-button icon="el-icon-picture" @click="dialogPngVisible = true">导出PNG</el-button>
                </div>
            </div>
            <div class="main-table">
                <el-table
                      :data="tableData"
                      style="width: 100%">
                      <el-table-column
                        prop="name"
                        label="姓名"
                        align="center"
                        width="180">
                        <template slot-scope="scope">
                            <div class="team-logo-box">
                                
                                <div class="team-logo">
                                    <div class="block">
                                        <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="60" :src="scope.row.portrait || defaultlogo"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info">
                                    <span class="team-info-name">{{scope.row.name}}</span>
                                    <span class="team-info-score">{{getTypeName(scope.row.playtype)}}</span>
                                </div>
                            </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="teamname"
                        label="球队"
                        align="center"
                        width="">
                      </el-table-column>
                      <el-table-column
                        prop="playno"
                        align="center"
                        label="球号">
                      </el-table-column>
                      <el-table-column
                        prop="height"
                        align="center"
                        label="身高">
                      </el-table-column>
                      <el-table-column
                        prop="weight"
                        align="center"
                        label="体重">
                      </el-table-column>
                      <el-table-column
                        prop="sfzh"
                        align="center"
                        label="身份证号">
                      </el-table-column>
                      <el-table-column
                        prop="tel"
                        align="center"
                        label="电话">
                      </el-table-column>
                      <el-table-column
                        prop="pay"
                        align="center"
                        label="缴费">
                        <template slot-scope="scope">
                            <span style="color: #f00;" v-if="scope.row.isfee == 2">未缴费</span>
                            <span style="color: #1eca8f;" v-if="scope.row.isfee == 1">已缴费 {{scope.row.pregfee}}</span>
                            <span v-if="scope.row.isfee == 0">无需缴费</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="unregfee"
                        align="center"
                        label="缴费未绑定">
                      </el-table-column>
                </el-table>
                
                <div class="footer-page">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total"
                      :page-size="limit"
                      @current-change="changepage"
                      :hide-on-single-page="true"
                      >
                    </el-pagination>
                </div>
            </div>
            <el-dialog
              title="导出预览"
              :visible.sync="dialogPngVisible"
              width="80%">
              <div slot="title" class="dialog-footer" style="display: flex;justify-content: space-between;">
                    <span>导出预览</span>
                    <div style="padding-right: 40px;">
                        <el-button @click="dialogPngVisible = false">取消</el-button>
                        <el-button type="primary" @click="exportToPng">导出</el-button>
                    </div>
              </div>
              <el-table
                    v-loading="loading"
                    id="exportTemplate"
                    :data="alltableData"
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="姓名"
                      align="center"
                      width="180">
                      <template slot-scope="scope">
                          <div class="team-logo-box">
                              
                              <div class="team-logo">
                                  <div class="block">
                                      <el-avatar @click.native="showPre(scope.row.portrait)" shape="square" :size="60" :src="scope.row.portrait || defaultlogo"></el-avatar>
                                  </div>
                              </div>
                              <div class="team-info">
                                  <span class="team-info-name">{{scope.row.name}}</span>
                                  <span class="team-info-score">{{getTypeName(scope.row.playtype)}}</span>
                              </div>
                          </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="teamname"
                      label="球队"
                      align="center"
                      width="">
                    </el-table-column>
                    <el-table-column
                      prop="playno"
                      align="center"
                      label="球号">
                    </el-table-column>
                    <el-table-column
                      prop="height"
                      align="center"
                      label="身高">
                    </el-table-column>
                    <el-table-column
                      prop="weight"
                      align="center"
                      label="体重">
                    </el-table-column>
                    <el-table-column
                      prop="sfzh"
                      align="center"
                      label="身份证号">
                    </el-table-column>
                    <el-table-column
                      prop="tel"
                      align="center"
                      label="电话">
                    </el-table-column>
                    <el-table-column
                      prop="pay"
                      align="center"
                      label="缴费">
                      <template slot-scope="scope">
                          <span style="color: #f00;" v-if="scope.row.isfee == 2">未缴费</span>
                          <span style="color: #1eca8f;" v-if="scope.row.isfee == 1">已缴费 {{scope.row.pregfee}}</span>
                          <span v-if="scope.row.isfee == 0">无需缴费</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="unregfee"
                      align="center"
                      label="缴费未绑定">
                    </el-table-column>
              </el-table>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogPngVisible = false">取消</el-button>
                <el-button type="primary" @click="exportToPng">导出</el-button>
              </div>
            </el-dialog>
            <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
        </div>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';
    import domtoimage from 'dom-to-image';
    export default {
        data() {
            return {
                preimg: "",
                is_show: false,
                defaultlogo: require('@/assets/images/defaultlogo.jpg'),
                tableData: [
                   
                ],
                liveid: 0,
                params: "name,teamsname,playtype,playno,height,weight,sfzh,tel,portrait,pregfee,unregfee",
                PageIndex: 1,
                limit: 10,
                total: 0,
                payNum: 0,
                leaderNum: 0,
                allPriceNum: 0,
                dialogPngVisible: false,
                alltableData: [],
                isloading: "",
				teamname: ""
                
            }
        },
        created() {
           this.$api.post('api/Main/GetGame').then(response => {
               if (response.data.code == 0) {
                   this.$message({
                     type: 'error',
                     message: response.data.data
                   });
                   this.$router.push({ path: '/login'});
                   return;
               }
               this.liveid = response.data.data.id
               this.fetchData()
               this.fetchDataTotal()
           }).catch(error => {
               console.error("There was an error!", error);
           }) 
        },
        methods: {
            showPre(url) {
                this.preimg = url
                this.is_show = true
            },
            exportSignExcel() {
                this.$api.get(`/api/Main/execlplayers/${this.liveid}/${this.params}`,{
                    responseType: 'blob',
                }).then(response => {
                    let temp = response.headers["content-disposition"].split(";")[1].split("=")[1];
                    let iconv = require("iconv-lite");
                    let fileName = iconv.decode(temp, "gbk");
                    
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.ms-excel;charset=UTF-8",
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    
                    
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
            
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            fetchData() {
                this.$api.get('api/Team/Players', {
                  params: {
                    limit: this.limit,
                    PageIndex: this.PageIndex,
                    liveid: this.liveid,
					search: this.teamname
                  }
                })
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.tableData = response.data.rows
                    this.total = response.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            fetchDataTotal() {
                this.$api.get('api/Team/Players', {
                  params: {
                    liveid: this.liveid,
                    limit: -1,
                  }
                })
                .then(response => {
                    this.alltableData = response.data.rows
                    const mainData = response.data.rows
                    this.payNum = mainData.filter(item => item.isfee === 1).length;
                    this.leaderNum = mainData.filter(item => [4, 5].includes(item.playtype)).length;
                    this.allPriceNum = mainData.reduce((total, item) => {
                        return total + item.pregfee;
                    }, 0);
                    
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changepage(p) {
                this.PageIndex = p
                this.fetchData()
            },
            getTypeName(type) {
                switch (type) {
                  case 0: return '队员';
                  case 1: return '队长';
                  case 2: return '教练兼队员';
                  case 3: return '领队兼队员';
                  case 4: return '教练';
                  case 5: return '领队';
                  default: return '未知';
                }
            },
            openLoading(callback) {
                this.isloading = this.$loading({
                      lock: true,
                      text: '图片生成中...',
                      spinner: 'el-icon-loading',
                      background: 'rgba(0, 0, 0, 0.7)'
                });
                callback();
            },
            closeLoading() {
                this.isloading.close();
            },
            exportToPng() {
                this.openLoading(() => {
                    setTimeout(() => {
                        this.exportToPngSync()
                    }, 100); 
                })
                
            },
            async exportToPngSync() {
                const node = document.getElementById('exportTemplate');
                try {
                    const blob = await domtoimage.toBlob(node);
                    const filename = "报名列表"
                    saveAs(blob, filename+'.png');
                    this.showExportPngDialog = false
                    this.closeLoading()
                } catch (err) {
                    console.error('Oops, something went wrong!', err);
                }
            },
            searchTeam() {
                this.fetchData()
                this.PageIndex = 1
            }
        }
    }
</script>

<style scoped>
    
    .btns-area {
        display: flex;
        height: 40px;
        align-items: center;
    }
    .top-data {
        display: flex;
        
    }
    
    .top-data-item {
        display: flex;
        flex-direction: column;
        width: 200px;
        margin: 30px 0px;
    }
    
    .top-data-item-num {
        font-size: 50px;
        font-weight: bold;
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .top-box {
        display: flex;
        border-bottom: 1px solid #ccc;
        justify-content: space-between;
        align-items: center;
    }
    
    .search-input {
        width: 200px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid #ccc;
        text-indent: 1em;
        margin-right: 20px;
    }
    
    .el-icon-search {
        position: absolute;
        right: 30px;
        top: 25%;
        color: #666;
    }
    
</style>