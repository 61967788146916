<template>
    <div class="content">
        <top-nav title="修改密码"></top-nav>
        <el-form label-position="right" label-width="100px" :model="formLabelAlign" class="form-style">
            <el-form-item label="旧密码">
                <el-input v-model="formLabelAlign.oldpwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input v-model="formLabelAlign.newpwd"></el-input>
            </el-form-item>
            <el-form-item label="重复新密码">
                <el-input v-model="formLabelAlign.querypwd"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('formLabelAlign')">修改密码</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: 1,
                formLabelAlign: {
                    oldpwd: '',
                    newpwd: '',
                    querypwd: ''
                }
            }
        },
        methods: {
            submitForm() {
                this.$api.get('api/Main/ResetPWD', {
                  params: this.formLabelAlign
                })
                .then(response => {
                  if (response.data.code == 1) {
                      this.$message({
                        type: 'success',
                        message: response.data.data
                      });
                      this.$router.push({ path: '/login'});
                  } else {
                      this.$message({
                        type: 'error',
                        message: response.data.data
                      });
                      this.$router.push({ path: '/login'});
                  }
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            }
        }
    }
</script>

<style>
    .form-style {
        width: 300px;
        
        margin: 0 auto;
        margin-top: 60px;
    }
</style>