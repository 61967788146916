<template>
	<div class="content" v-if="mode == 'hot'">
		<div class="image-grid">
			<div class="image" style="overflow: hidden;" :class="['item' + (index + 1), { 'normal-item': index > 3 }]"  v-for="(item,index) in photosPreTen" :key="index">
				<i class="iconfont icon-zhijiaojiaobiao" :style="index > 2 ? 'color: rgba(0, 0, 0, .6)' : 'color: #f7ce01'">
					<span>{{index + 1}}</span>
				</i>
				<el-image :src="item.src" :alt="item.title" class="photo-image" :class="{'loaded':item.load}" fit="cover" lazy :preview-src-list="preList"></el-image>
			</div>
		</div>
		<el-row :gutter="10" class="photo-grid" style="margin-top: 10px;">
			<el-col :span="8" v-for="(photo, index) in sortedPhotos" :key="index" class="photo-item">
				<div class="photo-container">
					<el-image :src="photo.src" :alt="photo.title" class="photo-image" :class="{'loaded':photo.load}" fit="cover" lazy :preview-src-list="preList"></el-image>
				</div>
			</el-col>
		</el-row>
	</div>
	<div class="content" v-else>
		<div class="other_item">
			<el-image class="item_img" src="http://www.douzhan.club/resources/teamlogo/bf7a215e-4e6d-40aa-beb6-5dfa3d7ece9a.jpg"></el-image>
			<div class="item-box">
				<span class="item_title">2024年安阳市全民健身月 第二届“茅台王子杯”职工篮球联赛颁奖晚宴</span>
				<span class="item-info">时间：2024.7.27</span>
				<span class="item-info">地点：河北-承德</span>
			</div>
		</div>
		<div class="other_item">
			<el-image class="item_img" src="http://www.douzhan.club/resources/teamlogo/bf7a215e-4e6d-40aa-beb6-5dfa3d7ece9a.jpg"></el-image>
			<div class="item-box">
				<span class="item_title">2024年安阳市全民健身月 第一届“茅台王子杯”职工篮球联赛颁奖晚宴1啊数据库的公交卡圣诞贺卡</span>
				<span class="item-info">时间：2024.7.27</span>
				<span class="item-info">地点：河北-承德</span>
			</div>
		</div>
	</div>
	
  
</template>

<script>
export default {
	props: {
	    mode: {
	      type: String,
	      required: true
	    },
		sort: {
		  type: String,
		  required: true
		}
	},
	watch: {
	    mode() {
	      // 这里可以放置响应逻辑
			this.photos.forEach(photo => {
	            photo.load = false;
	        });
			this.photos.forEach((photo, index) => {
			  const image = new Image();
			  image.src = photo.src;
			  
			  image.onload = () => this.onImageLoad(index);
			});
	    }
	},
	data() {
	  return {
		photosPreTen: [
			{
							  title: '1',
							  src: require('@/assets/images/img/1.jpeg'),
							  load: false,
							  time: "2024-07-24 06:00:00"
			},
			{
							  title: '2',
							  src: require('@/assets/images/img/2.jpg'),
							  load: false,
							  time: "2024-07-24 06:30:00"
			},
			{
							  title: '3',
							  src: require('@/assets/images/img/3.jpeg'),
							  load: false,
							  time: "2024-07-24 06:30:00"
			},
			{
							  title: '4',
							  src: require('@/assets/images/img/4.jpg'),
							  load: false,
							  time: "2024-07-24 06:30:00"
			},
			{
							  title: '5',
							  src: require('@/assets/images/img/5.jpg'),
							  load: false,
							  time: "2024-07-24 06:35:00"
			},
			{
							  title: '6',
							  src: require('@/assets/images/img/6.jpeg'),
							  load: false,
							  time: "2024-07-25 08:35:00"
			},
			{
				title: '7',
				src: require('@/assets/images/img/7.jpeg'),
				load: false,
				time: "2024-07-25 09:35:00"
			},
			{
				  title: '8',
				  src: require('@/assets/images/img/8.jpeg'),
				  load: false,
							  time: "2024-07-26 09:35:00"
			},
			{
					title: '9',
					src: require('@/assets/images/img/9.jpeg'),
					load: false,
								time: "2024-07-26 10:35:00"
			},
			{
				title: '10',
				src: require('@/assets/images/img/1.jpeg'),
				load: false,
				time: "2024-07-24 06:00:00"
			}
		],
		photos: [
			  {
				  title: '1',
				  src: require('@/assets/images/img/1.jpeg'),
				  load: false,
				  time: "2024-07-24 06:00:00"
			  },
			  {
				  title: '2',
				  src: require('@/assets/images/img/2.jpg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '3',
				  src: require('@/assets/images/img/3.jpeg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '4',
				  src: require('@/assets/images/img/4.jpg'),
				  load: false,
				  time: "2024-07-24 06:30:00"
			  },
			  {
				  title: '5',
				  src: require('@/assets/images/img/5.jpg'),
				  load: false,
				  time: "2024-07-24 06:35:00"
			  },
			  {
				  title: '6',
				  src: require('@/assets/images/img/6.jpeg'),
				  load: false,
				  time: "2024-07-25 08:35:00"
			  },
			  {
				  title: '7',
				  src: require('@/assets/images/img/7.jpeg'),
				  load: false,
				  time: "2024-07-25 09:35:00"
			  },
			  {
			  	  title: '8',
			  	  src: require('@/assets/images/img/8.jpeg'),
			  	  load: false,
				  time: "2024-07-26 09:35:00"
			  },
			  {
			  		title: '9',
			  		src: require('@/assets/images/img/9.jpeg'),
			  		load: false,
					time: "2024-07-26 10:35:00"
			  }
		],
		preList: [
			require('@/assets/images/img/1.jpeg'),
			require('@/assets/images/img/2.jpg'),
			require('@/assets/images/img/3.jpeg'),
			require('@/assets/images/img/4.jpg'),
			require('@/assets/images/img/5.jpg'),
			require('@/assets/images/img/6.jpeg'),
			require('@/assets/images/img/7.jpeg'),
			require('@/assets/images/img/8.jpeg'),
			require('@/assets/images/img/9.jpeg'),
			require('@/assets/images/img/1.jpeg'),
			require('@/assets/images/img/1.jpeg'),
			require('@/assets/images/img/2.jpg'),
			require('@/assets/images/img/3.jpeg'),
			require('@/assets/images/img/4.jpg'),
			require('@/assets/images/img/5.jpg'),
			require('@/assets/images/img/6.jpeg'),
			require('@/assets/images/img/7.jpeg'),
			require('@/assets/images/img/8.jpeg'),
			require('@/assets/images/img/9.jpeg'),
		]
	  }
  },
  methods: {
	onImageLoadTen(index) {
	  	this.$nextTick(() => {
	  	    let item = this.photosPreTen[index]
	  		item.load = true
	  	});
	},
    onImageLoad(index) {
		this.$nextTick(() => {
		    let item = this.photos[index]
			item.load = true
		});
    },
	groupPhotosByDateTime(photos) {
	  const grouped = {};
	
	  photos.forEach(photo => {
	    const [date, time] = photo.time.split(' ');
	    const hour = time.split(':')[0];
	
	    if (!grouped[date]) {
	      grouped[date] = {};
	    }
	
	    if (!grouped[date][hour]) {
	      grouped[date][hour] = [];
	    }
	
	    grouped[date][hour].push(photo);
	  });
	
	  return grouped;
	}
  },
  computed: {
		columns() {
			// 将照片分为两列
			const columns = [[], []];
			this.sortedPhotos.forEach((photo, index) => {
				columns[index % 2].push(photo);
			});
			return columns;
		},
		sortedPhotos() {
			
	        return this.sort == 'desc' ? this.photos : this.photos.slice().reverse();
		},
		sortedPreList() {
			return this.sort == 'desc' ? this.preList : this.preList.slice().reverse();
		},
		timePhotos() {
			return this.groupPhotosByDateTime(this.sortedPhotos)
		}
    },
  mounted() {
	this.photosPreTen.forEach((photo, index) => {
	    const image = new Image();
	    image.src = photo.src;
	    
	    image.onload = () => this.onImageLoadTen(index);
	    
	});
    this.photos.forEach((photo, index) => {
      const image = new Image();
      image.src = photo.src;
	  
      image.onload = () => this.onImageLoad(index);
	  
    });
  }
}
</script>

<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 三列 */
    grid-template-rows: repeat(4, 1fr); /* 行数根据需要调整 */
    gap: 10px; /* 图片之间的间距 */
}

.image {
    background-color: #ccc; /* 为了可视化效果，可替换为图片背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
	width: 100%;
	height: 100%;
	position: relative;
}

/* 第一张图片，占满三列和两行 */
.item1 {
    grid-column: 1 / 4; /* 从第一列到第三列 */
    grid-row: 1 / 3; /* 从第一行到第二行 */
	aspect-ratio: 3 / 1;
}

/* 第二张图片，占两列和两行 */
.item2 {
    grid-column: 1 / 3; /* 从第一列到第二列 */
    grid-row: 3 / 5; /* 从第三行到第四行 */
	aspect-ratio: 1 / 1;
}

/* 第三张图片，占一列，放在第二张右侧 */
.item3 {
    grid-column: 3; /* 第三列 */
    grid-row: 3; /* 第三行 */
	aspect-ratio: 1 / 1;
}

/* 第四张图片，占一列，放在第三张下方 */
.item4 {
    grid-column: 3; /* 第三列 */
    grid-row: 4; /* 第四行 */
	aspect-ratio: 1 / 1;
}

/* 剩余的图片正常排列 */
.item5 {
    grid-column: 1; /* 第一列 */
    grid-row: 5; /* 第四行 */
	aspect-ratio: 1 / 1;
}

.item6 {
    grid-column: 2; /* 第二列 */
    grid-row: 5; /* 第四行 */
	aspect-ratio: 1 / 1;
}

.normal-item {
	aspect-ratio: 1 / 1;
}

.normal-item .iconfont {
	color: rgba(0, 0, 0, .4)
}

.photo-image {
	aspect-ratio: 1 / 1;
	width: 100%;
	height: 100%;
}

.iconfont {
	position: absolute;
	left: -10px;
	top: -4px;
	font-size: 50px;
	z-index: 9;
}

.iconfont span {
	font-size: 16px;
	position: absolute;
	left: 10px;
	color: #fff;
	top: 5px;
}

.photo-image {
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  transition: transform 0.2s ease-out;
  margin-bottom: 5px;
}

.photo-image.loaded {
  transform: scale(1);
}

.other_item {
	width: calc(100% - 30px);
	height: 80px;
	display: flex;
	align-items: center;
	padding: 15px;
	flex-wrap: wrap;
	/* border-top: 1px solid #ccc; */
	border-bottom: 1px solid #ccc;
	
}

.item_img {
	width: 80px;
	height: 80px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.item_title {
	font-weight: bold;
	text-align: left;
	margin-left: 20px;
	width: 100%;
	display: -webkit-box;          /* 使元素成为弹性盒子模型 */
	-webkit-box-orient: vertical; /* 垂直排列盒子子元素 */
	-webkit-line-clamp: 2;        /* 限制显示的行数为2 */
	overflow: hidden;              /* 隐藏超出的内容 */
	text-overflow: ellipsis;      /* 显示省略号 */
	height: 3em;                  /* 行高乘以行数（2行） */
	line-height: 1.5em;           /* 设置行高 */
}

.item-box {
	width: calc(100% - 102px);
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	text-align: left;
}

.item-info {
	font-size: 14px;
	color: #666;
	margin-left: 20px;
}

</style>
