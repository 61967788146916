<template>
    <div class="content">
        <top-nav title="实名认证"></top-nav>
        <div class="main-box">
            <el-table
              :data="membersData"
              style="width: 100%">
              <el-table-column
                prop="team"
                label="球员"
                width="300"
                align="center"
                >
                <template slot-scope="scope">
                    <div class="team-logo-box">
                        
                        <div class="team-logo">
                            <div class="block">
                                <el-avatar shape="square" :size="80" :src="scope.row.playerlogo" @click.native="showPre(scope.row.playerlogo)"></el-avatar>
                            </div>
                        </div>
                        <div class="team-info">
                            <span class="team-info-name">{{scope.row.playername}}</span>
                            <span class="team-info-score">{{getTypeName(scope.row.type)}}</span>
                            <span class="team-info-score">{{scope.row.teamname}}</span>
                        </div>
                    </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="pheight"
                label="身高(cm)"
                width="80"
                align="center"
                >
                
              </el-table-column>
              <el-table-column
                prop="pweight"
                label="体重(kg)"
                width="120"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="msfz"
                label="身份证号码"
                
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="mphone"
                label="电话"
                width="190"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="idimg"
                label="身份证/户口簿照片"
                width="200"
                align="center"
                >
                <template slot-scope="scope">
                    <img :src="scope.row.portrait" alt="" class="idimg" @click="showPre(scope.row.portrait)"/>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="hukou"
                label="照片"
                width="200"
                align="center"
                >
                <template slot-scope="scope">
                    <img :src="scope.row.hukou" alt=""  class="hukou" @click="showPre(scope.row.hukou)"/>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="other"
                label="其他照片"
                width=""
                align="center"
                >
                <template slot-scope="scope">
                    <img v-if="scope.row.hukou" :src="scope.row.hukou" alt=""  class="hukou" @click="showPre(scope.row.hukou)"/>
                </template>
              </el-table-column>
              <el-table-column
                prop="tools"
                label="操作"
                width="300"
                align="center"
                fixed="right"
                >
                <template slot-scope="scope">
                    <!-- <el-button @click="pass(scope.row)" type="primary" size="small">通过认证</el-button> -->
                    <el-button @click="reject(scope.row)" type="danger" size="small">拒绝认证</el-button>
                </template>
              </el-table-column>
            </el-table>
            
            <div class="footer-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :hide-on-single-page="true"
                  :total="0">
                </el-pagination>
            </div>
           
            
           
            
        </div>
        <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            preimg: "",
            is_show: false,
            chooseImg: "",
            isBig: false,
            liveid: "",
            PageIndex: 1,
            limit: 10,
            membersData: [
                // {
                //     id:5,
                //     face: "http://www.douzhan.club/resources/playerlogo/3f5c4d9b-9462-4edb-b7cc-fd6558cd638e.jpg",
                //     name: "周五",
                //     number: 24,
                //     idnum: '130711189211205412',
                //     type: '队员',
                //     sex: '男',
                //     height: '182',
                //     weight: '98',
                //     state: 1,
                //     mobile: "18011112222",
                //     idimg: 'https://img2.baidu.com/it/u=509469012,4272413443&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=264',
                //     hukou: 'https://i01.c.aliimg.com/img/ibank/2013/278/152/987251872_1223694336.jpg'
                // }
            ],
            
        }
    },
    created() {
        this.$api.post('api/Main/GetGame').then(response => {
            if (response.data.code == 0) {
                this.$message({
                  type: 'error',
                  message: response.data.data
                });
                this.$router.push({ path: '/login'});
                return;
            }
            this.liveid = response.data.data.id
            this.fetchData()
            
        }).catch(error => {
            console.error("There was an error!", error);
        })
      
    },
    methods: {
        fetchData() {
            let formData = new FormData();
            formData.append('liveid', this.liveid);
            formData.append('limit', -1);
            formData.append('PageIndex', this.PageIndex);
            this.$api.post('api/Team/applyinfos', formData).then(response => {
                console.log(response.data)
                this.membersData = response.data.rows
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        pass(row) {
            this.$confirm("确定通过【"+row.name+"】的认证请求吗?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                let formData = new FormData();
                formData.append('id', row.id);
                formData.append('status', 'succ');
                this.$api.post('api/Team/idcardauth', formData).then(response => {
                    console.log(response.data)
                    this.$message({
                        type: 'success',
                        message: '认证已通过！'
                    });
                    this.fetchData()
                    // this.membersData = this.membersData.filter(item => item.id !== row.id)
                })
                .catch(error => {
                  console.error("There was an error!", error);
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        reject(row) {
            this.$confirm("确定拒绝【"+row.name+"】的认证请求吗?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                let formData = new FormData();
                formData.append('id', row.id);
                formData.append('status', 'fail');
                this.$api.post('api/Team/idcardauth', formData).then(response => {
                    console.log(response.data)
                    this.$message({
                        type: 'error',
                        message: '认证已拒绝！'
                    });
                    // this.membersData = this.membersData.filter(item => item.id !== row.id)
                    this.fetchData()
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        viewImg(img) {
            this.chooseImg = img
            this.isBig = true
        } ,
        showPre(url) {
            this.preimg = url
            this.is_show = true
        },
        getTypeName(type) {
            switch (type) {
              case 0: return '队员';
              case 1: return '队长';
              case 2: return '教练兼队员';
              case 3: return '领队兼队员';
              case 4: return '教练';
              case 5: return '领队';
              default: return '未知';
            }
        },
        
      }
    }
  </script>

<style scoped>
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    
    .idimg {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .hukou {
        width: 140px;
        height: 95px;
        cursor: pointer;
    }
    
    .bigImgmask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .4);
        z-index: 9999;
    }
    
    .bigImgmask img {
        width: 60%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }






    
</style>