<template>
	<div class="header">
		<div class="top-nav">
            <div class="top-nav-l">
                <div class="top-nav-logo">
                    <router-link to="/" class="no-active">
                    <img class="top-nav-logo-img" :src="headLogo" alt="" />
                    </router-link>
                </div>
                <div class="top-nav-search">
                    <i class="iconfont icon-sousuo"></i>
                    <input class="top-nav-search-input" :class="inputClass" type="text" placeholder="搜索队员" v-model="searchKey" @keydown.enter="search"/>
                    <el-dropdown class="serch-btn-box">
                      <el-button type="" round class="serch-btn" size="small" v-show="searchKey != ''" @click="search">{{searchType == 'player' ? '队员' : searchType == 'team' ? '球队' : '比分'}}<i class="el-icon-caret-bottom"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="changeType('player')">队员</el-dropdown-item>
                        <el-dropdown-item @click.native="changeType('team')">球队</el-dropdown-item>
                        <el-dropdown-item @click.native="changeType('score')">比分</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    
                    
                </div>
            </div>
            <div class="top-nav-r" style="display: flex; align-items: center;justify-content: space-between;margin-right: 60px;">
                <div class="notice-btn" style="margin-right: 100px;cursor: pointer;" @mouseenter="showMessage = true" @mouseleave="showMessage = false">
                    <i class="el-icon-s-promotion" style="margin-right: 10px;width: 40px;height: 40px;color: #fff;background: #4c80fb;border-radius: 40px;line-height: 40px;text-align: center;"></i><span class="notice-label">全员通知</span>
                    <transition name="fade">
                        <div class="notice-box" v-show="showMessage">
                            <div class="notice-box-l">
                                <div class="notice-box-icon" style="background-color: #1eca8f;">
                                    <i class="iconfont icon-24gf-userGroup2"></i>
                                </div>
                                <span class="notice-box-title">全员通知</span>
                                <span class="notice-box-info">发布通知后，将全部通知本主办方下的所有成员和粉丝</span>
                             
                                <el-button type="success" class="notice-send-btn" style="background-color: #1eca8f;" @click="$router.push('/pubnotice')">发布通知</el-button>
                            
                            </div>
                            <div class="notice-box-r">
                                <div class="notice-box-icon" style="background-color: #0192ff;">
                                    <i class="iconfont icon-24gf-city"></i>
                                </div>
                                <span class="notice-box-title">全市通知</span>
                                <span class="notice-box-info">发布后将通知本地市所有攀岩爱好者，如需发布请联系官方客服。</span>
                                <el-button type="primary" class="notice-send-btn" style="background-color: #0192ff;" @click="dialogQrVisible = true">发布通知</el-button>
                            </div>
                        </div>
                    </transition>
                </div>
                <el-dialog
                  title="发布全市通知"
                  :visible.sync="dialogQrVisible"
                  width="20%"
                  >
                  <div class="join-box">
                      <img src="@/assets/images/gt_qrcode.png" alt="" />
                      <span class="join-box-title">郭涛</span>
                      <span class="join-box-tel">15090007950</span>
                  </div>
                </el-dialog>
                <div class="top-nav-qrcode "  @mouseenter="showQr = true" @mouseleave="showQr = false">
                    <div>
                        
                        <div style="align-items: center;display: flex;font-size: 16px;" >
                            
                            <el-avatar style="margin-right: 20px;" :size="40" :src="userlogo"></el-avatar>
                            {{username}}<i class="el-icon-caret-bottom"></i>
                            <div class="user-drop-box item" v-show="showQr">
                                <!-- <transition name="fade"> -->
                                    <div class="custom-item">
                                        <div class="custom-item-left">
                                            创建新赛事后<br>
                                            将覆盖当前赛事
                                        </div>
                                        <div class="custom-item-right">
                                            <div class="custom-item-btn" @click="createNewMatch">创建</div>
                                        </div>
                                    </div>
                                    <div class="user-drop-item divider">
                                        <i class="el-icon-picture-outline-round"></i>
                                        修改主办方头像
                                    </div>
                                    <div class="user-drop-item">
                                        <i class="el-icon-s-custom"></i>
                                        修改主办方名称
                                    </div>
                                    <div class="user-drop-item" @click="resetPwd()">
                                        <i class="el-icon-s-tools"></i>
                                        修改密码
                                    </div>
                                    <div class="user-drop-item divider user-drop-item-more">
                                        <i class="el-icon-time"></i>
                                        历届赛事
                                        
                                    </div>
                                    <div class="user-drop-item user-drop-box2">
                                        <div class="user-drop-item" v-for="(item,index) in historyList" :key="index" @click="openNewTab(item.id)">{{item.name}}</div>
                                    </div>
                                    <div class="user-drop-item divider" @click="logout">
                                        <i class="el-icon-switch-button"></i>
                                        退出登录
                                    </div>
                                    
                                <!-- </transition> -->
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <el-dialog title="创建赛事" :visible.sync="dialogAddVisible">
                <div class="title_area">
                    <span class="title_area_tip">创建新赛事名称：</span>
                    <el-input v-model="matchTitle" placeholder="请输入赛事名称" style="width: 260px;"></el-input>
                </div>
                <div class="match_box">
                    <div class="match-box-item">
                        <div class="select-mask" :style="'background:url('+firstImg+')'">
                            <el-upload
                              class="upload-demo"
                              action=""
                              :auto-upload="false"
                              :show-file-list="false"
                              :on-change="event => handleChangeUpload(event,'first')">
                              <i class="el-icon-plus change-icon">&nbsp;添加主图</i>
                            </el-upload>
                        </div>
                    </div>
                    <div class="match-box-item">
                        <div class="select-mask" :style="'background:url('+secondImg+')'">
                            <el-upload
                              class="upload-demo"
                              action=""
                              :auto-upload="false"
                              :show-file-list="false"
                              :on-change="event => handleChangeUpload(event,'second')">
                              <i class="el-icon-plus change-icon">&nbsp;添加副图</i>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogAddVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogAddVisible = false">确定创建</el-button>
                </div>
            </el-dialog>
            <my-cropper
             :showDialog="dialogVisible"
             :image="cropperImage" 
             :width="cropperWidth"
             :height="cropperHeight"
             @update-logo="handleLogoUpdate" 
             @cancelDialog="closeDialog"
            ></my-cropper>
		</div>
	</div>
</template>

<script>
export default {
    name: 'MyHeader',
    data() {
        return {
            showQr: false,
            showMessage: false,
            dialogQrVisible: false,
            username: "",
            userlogo: "",
            userid: "",
            dialogAddVisible: false,
            dialogVisible: false,
            firstImg: require("@/assets/images/slider1.jpg"),
            secondImg: require("@/assets/images/slider1.jpg"),
            cropperImage: "",
            cropperWidth: 500,
            cropperHeight: 250,
            matchTitle: "",
            headLogo: require("@/assets/images/logo_climb.png"),
            searchKey: "",
            searchType: "player",
            liveid: 0,
            historyList: []
        };
    },
    computed: {
        // 计算属性，用于控制输入框的类
        inputClass: function() {
          return {
            'has-text': this.searchKey.trim().length > 0
          };
        }
    },
    created() {
        const userinfo = JSON.parse(localStorage.getItem('userinfo'));
        this.username = userinfo.name
        this.userlogo = userinfo.logourl
        this.userid = userinfo.id
        this.$api.post('api/Main/GetGame').then(response => {
            if (response.data.code == 0) {
                this.$message({
                  type: 'error',
                  message: response.data.data
                });
                this.$router.push({ path: '/login'});
                return;
            } else {
                this.liveid = response.data.data.id
                this.getHistory()
            }
        }).catch(error => {
            console.error("There was an error!", error);
        }) 
        
        
    },
    methods: {
        resetPwd() {
            this.$router.push({ path: '/resetpwd'});
        },
        createNewMatch() {
            this.$confirm('创建新赛事后，当前赛事将结束，可在历史赛事中查询，是否创建新赛事?', '提示', {
                confirmButtonText: '确定创建',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.dialogAddVisible = true
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        handleLogoUpdate(croppedImageUrl) {
            if (this.chooseImgBox == "first") {
                this.firstImg = croppedImageUrl
            } else {
                this.secondImg = croppedImageUrl
            }
        },
        closeDialog() {
            this.dialogVisible = false
        },
        handleChangeUpload(file,i) {
            console.log(i)
            const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传头图片大小不能超过 2MB!');
                return false
            }
            // 上传成功后将图片地址赋值给裁剪框显示图片
            this.$nextTick(async () => {
                this.chooseImgBox = i
                // base64方式
                // this.option.img = await fileByBase64(file.raw)
                console.log("成功", URL.createObjectURL(file.raw))
                this.cropperImage = URL.createObjectURL(file.raw)
                this.loading = false
                this.dialogVisible = true
            })
        },
        logout() {
            this.$router.replace('/login');
        },
        search() {
            if( this.searchKey.trim() == "") {
                this.$message({
                    type: 'error',
                    message: '请输入搜索内容'
                });
                return;
            }
            this.$router.push({ path: `/search/${this.searchKey}/${this.searchType}`});
        },
        changeType(type) {
            this.searchType = type
            this.search()
        },
        getHistory() {
            let formData = new FormData()
            formData.append('status', 'History');
            formData.append('oid', this.userid);
            formData.append('pagesize', 10000);
            this.$api.post('/app/match/gamelist',formData).then(response => {
                this.historyList = response.data.data.rows
            }).catch(error => {
                console.error("There was an error!", error);
            }) 
        },
        openNewTab(id) {
            const newTabUrl = `${window.location.origin}${window.location.pathname}#/history/`+id;
            window.open(newTabUrl, '_blank');
        }
    }
}
</script>

<style scoped>
.header {
  height: 100px;
  background-color: #fff;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.top-nav {
	display: flex;
	align-items: center;
	height: 100px;
    justify-content: space-between;
	background-image: url('@/assets/images/climb_banner.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.top-nav-logo-img {
	width: auto;
	height: 50px;
	margin-left: 20px;
}

.top-nav-logo-text {
	font-size: 26px;
}

.top-nav-search {
    position: relative;
    line-height: 40px;
    margin-left: 80px;
    display: flex;
    align-items: center;
}

.serch-btn {
    /* position: absolute; */
    /* right: 10px; */
}

.serch-btn-box {
    position: absolute;
    right: 10px;
}

.top-nav-search-input {
    width: 150px;
    height: 40px;
    padding-left: 45px;
    border: 0;
    font-size: 16px;
    border-radius: 40px;
    transition: 0.2s ease;
	opacity: .4;
}

.top-nav-search-input:focus {
  outline: none;
  background-color: #f7f7f7;
  opacity: .8;
  width: 300px;
}

.top-nav-search-input.has-text {
  outline: none;
  background-color: #f7f7f7;
  width: 300px;
}

.icon-sousuo {
    position: absolute;
    font-size: 20px;
    top: 2px;
    bottom: 0;
    margin: auto;
    left: 15px;
    
}

.top-nav-l {
    display: flex;
    align-items: center;
}

.top-nav-r {
    display: flex;
    padding-right: 30px;
}

.top-nav-msg {
    margin: 0 50px 0 80px;
    position: relative;
}

.icon-message,.icon-exit {
    font-size: 20px;
    font-weight: bold;
    color: #666;
}

.top-nav-icon {
    cursor: pointer;
    font-size: 24px;
}

.top-nav-qrcode {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    height: 80px;
    display: flex;
    align-items: center;
}

.qrcode-box {
    position: absolute;
    background-color: #fff;
    display: flex;
    /* justify-content: space-between; */
    width: 400px;
    height: 140px;
    right: 0;
    bottom: -170px;
    padding: 10px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .1);
}

.qrcode-box-img {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.qrcode-box-img img{
    width: 110px;
    height: 110px;
}

.qrcode-box-text-title {
    margin-top: 20px;
    height: 30px;
    line-height: 20px;
    font-size: 20px;
    color: #000;
}

.qrcode-box-text-info {
    color: #999;
}

.qrcode-box-text {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.message-box {
    position: absolute;
    width: 140px;
    right: -60px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .1);
    padding: 10px 0px;
    top: 30px;
    
}

.message-box-item {
    height: 40px;
    font-size: 15px;
}

.router-link-active {
    background-color: transparent; /* 激活状态的背景色 */
}
.no-active {
        border-left: 0px;
    }
    
    .title_area {
        
        text-align: left;
        background-color: #fff;
        height: 60px;
        line-height: 60px;
        margin: 0px 20px;
        margin-left: 0px;
        margin-bottom: 20px;
        border-radius: 5px;
        text-indent: 2em;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .title_area_tip,
    .title_area_title {
        font-size: 18px;
        display: inline; 
        font-weight: bold;
        color: #666;
    }
    
    .title_area_title {
        color: #000;
        margin-top: 5px;
    }
    
    /* Match Box */
    
    .match-box-item {
        width: calc(100% / 2 - 40px);
        margin: 0px 20px;
        position: relative;
        border-radius: 5px;
        background-color: #fff;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
        
    }
    
    .select-mask {
        background-color: rgba(0, 0, 0, .6);
        width: 100%;
        height: 200px;
        border-radius: 5px;
        transition: height 0.2s ease;
        cursor: pointer;
        text-align: center;
        line-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    
    
    .match-box-img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border-radius: 3px solid #fff;
        
    }
    
    .match-box-info {
        display: flex;
        justify-content: space-around;
        width: 600px;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 20px;
        
    }
    
    .match-box-info-item {
        text-align: center;
        width: 100px;
    }
    
    .line {
        width: 1px;
        border-left: 1px solid #ccc;
        height: 50px;
        margin: 0px 20px;
        margin-top: 5px;
    }
    
    .match-box-info-item-num {
        font-size: 34px;
        color: #000;
        font-weight: bold;
    }
    
    .match-box-info-item-name {
        display: block;
        font-size: 16px;
        color: #666;
    }
    
    .match_box {
        display: flex;
        justify-content: space-between;
    }
    
    .change-icon {
        color: #fff;
        font-size: 20px;
    }
    
    .user-drop-box {
        position: absolute;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        width: 300px;
        top: 80px;
        left: -80px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        height: auto;
        border-radius: 10px;
        padding: 20px 0px 10px 0px;
        z-index: 999;
    }
    
    .user-drop-item {
        min-height: 50px;
        text-align: left;
        color: #686868;
        padding: 0px 30px;
        line-height: 50px;
    }
    
    .user-drop-box2 {
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
    .user-drop-box2>.user-drop-item {
        width: 220px;
        text-overflow: ellipsis;
        white-space: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
        padding-top: 10px;
        font-size: 14px;
        
    }
    
    .user-drop-item:hover {
        background-color: #ecf5ff;
        color: #247ecc;
    }
    
    .user-drop-item i {
        margin-right: 10px;
    }
    
    .divider {
        border-top: 1px solid #ccc;
        padding-top: 10px;
        margin-top: 10px;
    }
    
    .custom-item {
        border: 1px solid #fcac4c;
        border-radius: 10px;
        width: 75%;
        margin: 0 auto;
        margin-bottom: 20px;
        background-color: #fff5f2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px;
        font-weight: bold;
        color: #854141;
        line-height: 30px;
        text-align: left;
    }
    
    .custom-item-btn:hover {
        background: linear-gradient(to left,#ff356a,#fa6963);
    }
    
    .custom-item-btn {
        width: 60px;
        height: 30px;
        border-radius: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        background: linear-gradient(to right,#ff356a,#fa6963);
    }
    
    .notice-btn {
        position: relative;
        z-index: 999;
        /* overflow: hidden; */
        border-radius: 40px;
        padding-right: 10px;
        transition: 0.3s ease-in-out;
        padding-bottom: 50px;
        margin-top: 50px;
    }
    
    .notice-btn:before {
        content: '';
        height: 40px;
        background-color: #4c80fb;
        /* background-color: #f00; */
        border-radius: 40px;
        padding-right: 10px;
        position: absolute;
        left: 0px;
        top: 0;
        z-index: -1;
        width: 30px;
        transition: 0.2s ease;
        padding-right: 10px;
        
    }
    
    .notice-btn:hover:before {
        width: 100%;
        border-radius: 30px;
    }
    
    .notice-btn:hover .notice-label{
        color: #fff;
    }
    
    .notice-box {
        position: absolute;
        top: 50px;
        width: 580px;
        left: -200%;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        transition: 0.2s ease-in-out;
    }
    
    .notice-box-l,.notice-box-r {
        display: flex;
        flex-direction: column;
        padding: 30px;
        text-align: center;
        align-items: center;
    }
    
    .notice-box-r {
        background-color: #f9f9f9;
    }
    
    .notice-box-title {
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        font-size: 18px;
    }
    
    .notice-box-info {
        color: #868686;
        font-size: 15px;
    }
    
    .notice-send-btn {
        margin-top: 15px;
        width: 100px;
    }
    
    .notice-box-icon {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        background-color: #f00;
        line-height: 80px;
        text-align: center;
        color: #fff;
        
    }
    
    .notice-box-icon i {
        font-size: 40px;
    }
    
    .join-box {
        height: 350px;
        display: flex;
        flex-direction: column;
    }
    
    .join-box img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        border-radius: 10px;
    }
    
    .join-box-title {
        height: 80px;
        font-size: 36px;
        line-height: 80px;
        color: #000;
    }
    
    .join-box-tel {
        font-size: 20px;
        color: #000;
    }
    
    
    

</style>