<template>
	<div class="content">
		<top-nav title="打印证书"></top-nav>
		<div class="main-box">
			<div class="cert-item" v-for="(item,index) in certList" :key="index">
				<el-image
					class="cert-img"
				      :src="item.preImg"
				      fit="contain"></el-image>
				<span class="cert-name">{{item.name}}</span>
				<div class="mask-btn">
					<el-button type="primary" round icon="el-icon-s-promotion" class="mask-btn-btn" size="mini" @click="selectCert(index)">使用此模板</el-button>
				</div>
			</div>
		</div>
		<el-dialog
		  title="选择球队"
		  :visible.sync="selectTeamVisible"
		  width="70%"

		  >
		  
		  <div class="team-box">
			  <div class="team-item" v-for="(item,index) in teamData" :key="index" :class="{'choose-team':item.id === chooseTeamId}" @click="selectTeam(item.id)">
					<el-image
				        style="width: 100px; height: 100px;margin: 0 auto"
				        :src="item.logo || defaultLogo"
				        fit="fill"
						></el-image>
					<span>{{item.name}}</span>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="selectTeamVisible = false">取 消</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog
		  title="选择球员"
		  :visible.sync="selectPlayerVisible"
		  width="70%">
		  
		  <div class="team-box">
			  <div class="team-item" v-for="(item,index) in playerData" :key="index" :class="{'choose-team': choosePlayerId.includes(item.id)}" @click="choosePlayer(item.id)">
					<el-image
				        style="width: 100px; height: 100px;margin: 0 auto"
				        :src="item.portrait || defaultLogo"
				        fit="fill"
						></el-image>
					<span>{{item.name}}</span>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="back(1)">上一步</el-button>
		    <el-button type="primary" @click="createCert">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog
			title="制作证书"
			:visible.sync="createCertVisible"
			v-if="createCertVisible"
			:key="chooseIndex"
			:width="(certData.width+40)+'px'">
		  
		  <div class="cert-box" style="" @click.self="hideBorder" v-if="playerData != null && choosePlayerId != null" id="exportTemplate" :style="{width: certData.width+'px',height:certData.height+'px',background: 'url('+matchHttp(certData.background)+')',backgroundSize: '100% 100%'}">
			<div
				class="title-div" 
			  	v-for="(item,index) in certData.text" 
			  	:key="'text'+index" 
			  	:style="{fontSize: item.font_size+'px',color:item.color,top: item.positon_y+'px',left: item.positon_x+'px',fontWeight: item.font_weight,fontFamily: item.font_family}"
			>
			  	{{item.value}}
			  </div>
			  
			  <div
			  	class="title-div" 
			    	v-for="(item,index) in certData.line" 
			    	:key="'text'+index" 
			    	:style="{width: item.width+'px',position: 'relative',height: '20px',top: item.positon_y+'px',left: item.positon_x+'px'}"
			  >
			    	<div :style="{width: item.width+'px',borderBottom: item.weight+'px solid '+item.color,height: '10px'}">
			    		
			    	</div>
			    </div>
			  
			  <div
			    ref="divItems"
				class="title-div" 
			  	v-for="(item,index) in certData.varText" 
			  	:key="'vartext'+index" 
				v-show="draggingIndex == null"
				@dblclick.stop="dbeditText(index)"
			  	:style="{width: item.width+'%',textAlign: item.text_align,fontSize: item.font_size+'px',color:item.color,top: item.positon_y+'px',left: item.positon_x+'px',fontWeight: item.font_weight,fontFamily: item.font_family}"
			  >
			  	{{matchVar(item.name) || item.name}}
			  </div>
			  
			  <input
			  :style="{width: certData.varText[draggingIndex].width+'%',textAlign: certData.varText[draggingIndex].text_align,fontSize: certData.varText[draggingIndex].font_size+'px',color:certData.varText[draggingIndex].color,top: certData.varText[draggingIndex].positon_y+'px',left: certData.varText[draggingIndex].positon_x+'px',fontWeight: certData.varText[draggingIndex].font_weight,fontFamily: certData.varText[draggingIndex].font_family}"
			  ref="input"
			  class="editInput"
			  type="text" 
			  v-model="certData.varText[draggingIndex].name"   
			  v-if="draggingIndex != null && editText == true"
			  />
			  
			  
			  <div 
				class="title-div" 
			  	v-for="(item,index) in certData.img" 
			  	:key="'img'+index" 
			  	:style="{width: item.width+'px',height: item.height == 'auto' ? item.height : item.height+'px',opacity: item.opacity, top: item.positon_y+'px',left: item.positon_x+'px',transform: 'rotate('+item.transform+'deg)'}">
			  	<img :src="matchHttp(item.img)" alt="" class="img-move" style="width: 100%;height: 100%;" />
			  </div>
			  
			  <div 
				class="title-div" 
			  	v-for="(item,index) in certData.varImg" 
			  	:key="'varimg'+index" 
			  	:style="{width: item.width+'px',height: item.height == 'auto' ? item.height : item.height+'px', top: item.positon_y+'px',left: item.positon_x+'px'}">
			  	<img :src="matchHttp(matchVar(item.name))" alt="" style="width: 100%;height: 100%;">
			  </div>
			  <div class="btns-area" v-if="choosePlayerId.length > 1">
			  		<div class="prev-btn cert-controll-btn el-icon-arrow-left" @click="chooseIndex = chooseIndex - 1" v-if="chooseIndex > 0"></div>
			  		<div class="next-btn cert-controll-btn el-icon-arrow-right" @click="chooseIndex = chooseIndex + 1" v-if="chooseIndex < choosePlayerId.length - 1"></div>
			  </div>
		  </div>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="back(2)">上一步</el-button>
			<el-button>转到队员空间</el-button>
		    <el-button type="primary" @click="downloadCert">下载证书</el-button>
		    <el-button type="success"  v-print="'#exportTemplate'">打印证书</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import domtoimage from 'dom-to-image';
	import { saveAs } from 'file-saver';
	export default {
		data() {
			return {
				certList: [],
				selectTeamVisible: false,
				selectPlayerVisible: false,
				createCertVisible: false,
				teamData: [],
				playerData: [],
				defaultLogo: require("@/assets/images/defaultlogo.jpg"),
				chooseTeamId: 0,
				choosePlayerId: [],
				certId: 0,
				certData: [],
				memberData: [],
				draggingIndex: null,
				editText: false,
				chooseIndex: 0
			}
		},
		created() {
			this.fetchData()
			this.fetchCertData()
		},
		methods: {
			fetchData() {
				let params = {
					PageIndex: 1,
					limit: -1,
				}
			    
			    this.$api.get('api/Team/Teams', {
			      params: params
			    })
			    .then(response => {
			        if (response.data.code == 0) {
			            this.$message({
			              type: 'error',
			              message: response.data.data
			            });
			            this.$router.push({ path: '/login'});
			            return;
			        }
			        
			        this.teamData = response.data.rows
			      
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			
			},
			fetchCertData() {
				this.$api.get('api/certbook/List')
				.then(response => {
					this.certList = []
					// let count = 0
					const result = response.data.rows
					this.loadingNum = result.length
					result.forEach(item=>{
						this.$api.get(`api/certbook/Get/${item.id}`)
						.then(response => {
						    if(response.data.code == 1) {
								let obj = JSON.parse(response.data.data.content)
								obj.id = item.id
								this.certList.push(obj)
								
						    } else {
						        this.$message({
						            type: 'error',
						            message: response.data.data
						        });
						    }
							// count++
							// if (count == result.length) {
							// 	this.loading = false
							// }
						  
						})
						.catch(error => {
						  console.error("There was an error!", error);
						});
					})
				})
				.catch(error => {
				  console.error("There was an error!", error);
				});
			},
			selectTeam(id) {
				this.chooseTeamId = id
				this.fetchPlayersData()
				
			},
			selectCert(id) {
				this.certId = id
				this.selectTeamVisible = true
				this.certData = this.certList[id]
			},
			back(step){
				if(step == 1) {
					this.selectTeamVisible = true
					this.selectPlayerVisible = false
				} else {
					this.selectPlayerVisible = true
					this.createCertVisible = false
				}
			},
			createCert() {
				this.createCertVisible = true
				this.memberData = this.playerData.filter(item => this.choosePlayerId.includes(item.id));
				
			},
			matchVar(val) {
				let value = ""
				switch(val) {
					case "队员姓名":
						value = this.memberData[this.chooseIndex].name;
						break;
					case "身份证号":
						value = this.memberData[this.chooseIndex].sfzh;
						break;
					case "队伍名称":
						value = this.memberData[this.chooseIndex].teamname;
						break;
					case "头像":
						value = this.memberData[this.chooseIndex].portrait;
						break;
				}
				return value;
			},
			fetchPlayersData() {
			    this.$api.get('api/Team/Players', {
			      params: {
			        limit: -1,
			        teamid: this.chooseTeamId,
			      }
			    })
			    .then(response => {
			        if (response.data.code == 0) {
			            this.$message({
			              type: 'error',
			              message: response.data.data
			            });
			            this.$router.push({ path: '/login'});
			            return;
			        }
			        this.playerData = response.data.rows
					this.selectTeamVisible = false
					this.selectPlayerVisible = true
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			
			},
			async downloadCert() {
				this.editText = false
				this.draggingIndex = null
				const node = document.getElementById('exportTemplate');
				try {
				    const blob = await domtoimage.toBlob(node);
				    const filename = "证书"
				    saveAs(blob, filename+'.png');
				    
				} catch (err) {
				    console.error('Oops, something went wrong!', err);
				}
			},
			getBase64 (file) {
			    return new Promise((resolve, reject) => {
			        let reader = new FileReader();
			        let imgResult = "";
			        reader.readAsDataURL(file);
			        reader.onload = function () {
			          imgResult = reader.result;
			        };
			        reader.onerror = function (error) {
			          reject(error);
			        };
			        reader.onloadend = function () {
			          resolve(imgResult);
			        };
			    });
			},
			matchHttp(url) {
				if (url) {
					const protocolEndIndex = url.indexOf('://');
					if (protocolEndIndex !== -1) {
						const p = url.substring(0, protocolEndIndex).toLowerCase();
						if (p == "http") {
							return url.replace(/^http:\/\//i, 'https://')
						}
						return url
					}
					return url
				} 
				return null
			},
			dbeditText(index) {
				let Varname = this.certData.varText[index].name
				let varList = ['队员姓名','身份证号','队伍名称','头像']
				if (!varList.includes(Varname)) {
					this.$nextTick(() => {
					    this.inputWidth = this.$refs.divItems[index].offsetWidth;
						this.draggingIndex = index;
						let item = this.certData.varText[index];
						
						item.border = "3px solid #2c6300";
						this.editText = true
						console.log('索引',this.draggingIndex)
						console.log('是否编辑',this.editText)
						setTimeout(() => {
							this.$refs.input.focus();
							this.$refs.input.select();
						}, 0);
					    
					});	
				}
				
			},
			hideBorder() {
				this.draggingIndex = null;
				this.varIndex = null;
				this.draggingMode = null;
				this.varMode = null;
				this.titleText.forEach((item) => {
					item.border = "0px"
				})
				this.imgList.forEach((item) => {
					item.border = "0px"
				})
				this.textVarList.forEach((item) => {
					item.border = "0px"
				})
				this.imgVarList.forEach((item) => {
					item.border = "1px dashed #ccc"
				})
				this.editText = false
			},
			choosePlayer(id) {
				if(this.choosePlayerId.includes(id)) {
					this.choosePlayerId.splice(this.choosePlayerId.indexOf('id'), 1);
				} else {
					this.choosePlayerId.push(id)
				}
			},
			printCert() {
				
			}
		}
		
	}
</script>

<style scoped>
	.main-box {
		display: flex;
		flex-wrap: wrap;
	}
	
	.cert-item {
		display: flex;
		flex-direction: column;
		border: 1px solid #ccc;
		height: 180px;
		width: 140px;
		margin-right: 20px;
		margin-top: 20px;
	}
	
	.cert-item:hover .mask-btn{
		height: 180px;
	}
	
	.cert-img {
		width: 140px;
		height: 140px;
		background-color: #eeeeee;
		position: relative;
		
	}
	
	.cert-name {
		height: 40px;
		line-height: 40px;
		
	}
	
	.mask-btn {
		position: absolute;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 140px;
		height: 0px;
		background-color: rgba(0, 0, 0, .4);
		overflow: hidden;
	}
	
	
	
	.mask-btn-btn {
		margin: 0 auto;
	}
	
	.team-box {
		display: flex;
		justify-content: start;
		flex-wrap: wrap;
		align-content: start;
		height: 500px;
		overflow-y: scroll;
		
	}
	
	.team-item {
		display: flex;
		flex-direction: column;
		width: 140px;
		height: 180px;
		justify-content: center;
		margin-right: 20px;
		margin-bottom: 20px;
		border: 1px solid #ccc;
		border-radius: 5px;
		cursor: pointer;
	}
	
	.team-item:hover {
		border: 1px solid #0055ff;
	}
	
	.choose-team {
		border: 1px solid #0055ff;
	}
	
	.cert-box {
		border: 1px solid #ccc;
		position: relative;
		background-size: 100% 100%;
	}
	
	.title-div {
		position: absolute;
		user-select: none;
	}
	
	.editInput {
		position: absolute;
		width: auto;
		outline: none;
		border: 1px solid #0055ff;
	}
	
	.btns-area {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		pointer-events: none;
	}
	
	.cert-controll-btn {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #fff;
		text-align: center;
		line-height: 50px;
		font-size: 20px;
		cursor: pointer;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
		pointer-events: auto;
		position: absolute;
	}
	
	.prev-btn {
		
	}
	
	.next-btn {
		right: 0;
	}
</style>