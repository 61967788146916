<template>
  <div class="main-content">
      <router-view class="view-wrapper"></router-view>
  </div>
</template>

<script>
export default {
	name: 'MainContent',
	mounted() {
      this.scrollToTop();
    },
    methods: {
      scrollToTop() {
        this.$refs.mainContent.scrollTop = 0; // 滚动到顶部
      }
    }
}
</script>

<style scoped>
/* 样式待完善 */
.main-content {
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: scroll;
  margin-left: 250px;
  height: calc(100vh - 100px);
  min-width: 500px;
  
}

.view-wrapper {
  margin: 10px;
  padding: 20px;
  width: calc(100% - 60px);
  min-width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  min-height: calc(100vh - 160px);
  
  /* 其他想要应用到每个页面视图的样式 */
}
</style>
