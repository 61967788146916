<template>
	<div class="content">
		<top-nav title="图片管理"></top-nav>
		<el-input
		    class="search-box"
		    placeholder="搜索图片"
		    prefix-icon="el-icon-search"
			@keydown.enter.native="searchphoto()"
		    v-model="searchKey">
		</el-input>
		<div class="tool-box">
			<el-button type="primary" @click="allCheck">全选</el-button>
			<el-button @click="sortphotoList">按比赛排序</el-button>
			<el-button @click="sortphotoListbyTime">按时间排序</el-button>
			<el-button type="danger" v-if="chooseList.length != 0" @click="deleteChoose">删除选中</el-button>
		</div>
		<el-checkbox-group v-model="chooseList">
			
			<el-skeleton :loading="loading" animated :count="1">
				
				<template slot="template">
					<div style="
						display: grid;
						grid-template-columns: repeat(6, 1fr);
						gap: 16px;
						grid-template-rows: repeat(auto-fill, 300px);
					">
						<div class="skeleton-item" style="width: 250px" v-for="(item,index) in photoList" :key="index">
							<el-skeleton-item
								variant="image"
								style="width: 250px;height: 200px;"
							/>
							<div style="padding: 14px;">
								<el-skeleton-item variant="h3" style="width: 50%;" />
								<div
									style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
								>
									<el-skeleton-item variant="text" style="margin-right: 16px;" />
									<el-skeleton-item variant="text" style="width: 30%;" />
								</div>
							</div>
						</div>
					</div>
				</template>
			</el-skeleton>
			<div class="main-box" v-show="!loading">
					<div class="photo-item" v-for="(item,index) in photoList" :key="index" :class="{isChoose : chooseList.includes(item.id)}">
						<el-checkbox class="photo-checkBox" :label="item.id"></el-checkbox>
						<div v-if="item.photo" class="posterImage" @click="playphoto(item.id)">
						  <el-image
								class="poster-image"
								:src="item.photo"
								@load="generatePoster()"
								@error="generatePoster()"
								:preview-src-list="preList"
								fit="scale-down"></el-image>
						</div>
						<span class="matchTitle">{{item.match}}</span>
						<span class="matchTime">上传时间：{{item.time}}</span>
					</div>
			</div>
			
		</el-checkbox-group>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				posterImage: '',
				photoList: [
					{
						id: 1,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2012-08-01 16:41:00",
						photo: require('@/assets/images/img/1.jpeg'),
						poster: "",
						isChoose: false
					},
					{
						id: 2,
						match: "年十八中杯赛",
						time: "2017-08-01 16:41:00",
						photo: require('@/assets/images/img/2.jpg'),
						poster: "",
						isChoose: false
					},
					{
						id: 3,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2021-08-01 16:41:00",
						photo: require('@/assets/images/img/3.jpeg'),
						poster: "",
						isChoose: false
					},
					{
						id: 4,
						match: "年十八中杯赛",
						time: "2027-08-01 16:41:00",
						photo: require('@/assets/images/img/4.jpg'),
						poster: "",
						isChoose: false
					},
					{
						id: 5,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2020-08-01 16:41:00",
						photo: require('@/assets/images/img/5.jpg'),
						poster: "",
						isChoose: false
					},
					{
						id: 6,
						match: "十八中杯赛",
						time: "2010-08-01 16:41:00",
						photo: require('@/assets/images/img/6.jpeg'),
						poster: "",
						isChoose: false
					},
					{
						id: 7,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2018-08-01 16:41:00",
						photo: require('@/assets/images/img/7.jpeg'),
						poster: "",
						isChoose: false
					},
					{
						id: 8,
						match: "十八中杯赛",
						time: "2017-08-01 16:41:00",
						photo: require('@/assets/images/img/8.jpeg'),
						poster: "",
						isChoose: false
					},
					{
						id: 9,
						match: "十八中杯赛",
						time: "2016-08-01 16:41:00",
						photo: require('@/assets/images/img/9.jpeg'),
						poster: "",
						isChoose: false
					}
				],
				preList: [
					
				],
				chooseList: [],
				loading: true,
				loadCount: 0,
				playUrl: '',
				searchKey: ''
			}
		},
		created() {
			this.preList = this.photoList.map(item => item.photo)
		},
		watch: {
			photoList() {
				this.preList = this.photoList.map(item => item.photo)
			}
		},
		methods: {
			generatePoster() {
				console.log("加载了")
			    this.loadCount++
			    if(this.loadCount == this.photoList.length) {
			    	this.loading = false
			    }
				
			},
			allCheck() {
				if (this.chooseList.length == this.photoList.length) {
					this.chooseList = []
				} else {
					this.chooseList = []
					this.chooseList = this.photoList.map(photo => photo.id);
				}
				
				console.log(this.chooseList)
			},
			playphoto(id) {
				const item = this.photoList.find(item => item.id === id)
				this.playUrl = item.photo
				console.log(this.playUrl)
			},
			deleteChoose() {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					this.photoList = this.photoList.filter(item => !this.chooseList.includes(item.id));
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.chooseList = []
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
				
			},
			sortphotoList() {
			    this.photoList.sort((a, b) => {
					return a.match.localeCompare(b.match);
			    });
			},
			sortphotoListbyTime() {
			    this.photoList.sort((a, b) => {
					return b.time.localeCompare(a.time);
			    });
			},
			searchphoto() {
				
			}
		},
		mounted() {
		    
		}
		
	}
</script>

<style scoped>
	.search-box {
	    position: absolute;
	    top: 20px;
	    right: 60px;
		width: 250px;
		height: 40px;
	}
	
	::v-deep .el-input__inner {
	  background: #f0f0f0;
	  border-radius: 40px;
	  border: 0px;
	}
	
	.content {
	    position: relative;
	}
	
	.main-box {
		/* display: flex; */
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 16px;
		grid-template-rows: repeat(auto-fill, 300px);
	}
	
	.photo-item {
		position: relative;
		width: calc(100% - 30px);
		height: 270px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		cursor: pointer;
	}
	
	.photo-item:hover {
		background-color: #e7f0fa;
	}
	
	.photo-item:hover .photo-checkBox {
		display: block;
	}
	
	.isChoose .photo-checkBox {
		display: block;
	}
	
	.isChoose {
		background-color: #e7f0fa;
	}
	
	.posterImage {
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		position: relative;
	}
	
	.posterImage i {
		position: absolute;
		margin: auto;
		top: 45%;
		bottom: 0;
		left: 0;
		right: 0;
		font-size: 30px;
		color: #ccc;
	}
	
	.poster-image {
		border-radius: 10px;
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		height:150px;
	}
	
	.tmp-photo {
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		
	}
	
	.tool-box {
		text-align: left;
		height: 80px;
		line-height: 80px;
	}
	
	.matchTitle {
		height: 40px;
		line-height: 20px;
		font-size: 14px;
		display: -webkit-box;          /* 使元素成为弹性盒子模型 */
		-webkit-box-orient: vertical; /* 垂直排列盒子子元素 */
		-webkit-line-clamp: 2;        /* 限制显示的行数为2 */
		overflow: hidden;              /* 隐藏超出的内容 */
		text-overflow: ellipsis;
	}
	
	.matchTime {
		font-size: 12px;
		color: #ccc;
		margin-top: 15px;
	}
	
	.photo-checkBox {
		position: absolute;
		left: 10px;
		top: 10px;
		display: none;
	}
	
	/deep/ .el-checkbox__inner {
		width: 20px;
		height: 20px;
	}
	
	/deep/ .el-checkbox__inner::after {
		left: 6px;
		height: 11px;
		width: 5px;
	}
	
	/deep/ .el-checkbox__label {
		display: none;
	}
	
	.photoMask {
		position: fixed;
		background-color: rgba(0, 0, 0, .6);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 9999;
		display: flex;
		justify-content: center;
		line-height: center;
	}
	
	.photoMask photo {
		width: 800px;
		height: 800px;
		margin: auto;
	}
	
	.closeBtn {
		position: absolute;
		right: calc(50% - 500px);
		top: 60px;
		color: #fff;
		font-size: 60px;
		width: 60px;
		height: 60px;
		border: 2px dashed #fff;
		cursor: pointer;
	}
</style>