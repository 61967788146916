<template>
	<div class="content">
		<top-nav title="文件管理"></top-nav>
		<el-input
		    class="search-box"
		    placeholder="搜索文件"
		    prefix-icon="el-icon-search"
			@keydown.enter.native="searchfile()"
		    v-model="searchKey">
		</el-input>
		<div class="tool-box">
			<el-button type="primary" @click="allCheck">全选</el-button>
			<el-button @click="sortfileList">按比赛排序</el-button>
			<el-button @click="sortfileListbyTime">按时间排序</el-button>
			<el-button type="danger" v-if="chooseList.length != 0" @click="deleteChoose">删除选中</el-button>
		</div>
		<el-checkbox-group v-model="chooseList">
			<div class="main-box">
					<div class="file-item" v-for="(item,index) in fileList" :key="index" :class="{isChoose : chooseList.includes(item.id)}">
						<el-checkbox class="file-checkBox" :label="item.id"></el-checkbox>
						<i class="el-icon-download file-download" @click="downloadFile(item.file,item.filename,item.ext)"></i>
						<div v-if="item.file" class="posterImage">
						  <el-image
								class="poster-image"
								:src="getImageSrc(item.ext)"
								fit="scale-down"></el-image>
						</div>
						<span class="matchTitle" style="font-weight: bold;">{{item.filename}}</span>
						<span class="matchTitle" style="color: #666;">{{item.match}}</span>
						<span class="matchTime">上传时间：{{item.time}}</span>
					</div>
			</div>
			
		</el-checkbox-group>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				posterImage: '',
				fileList: [
					{
						id: 1,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2012-08-01 16:41:00",
						file: '@/assets/images/file/赛前演示文档.ppt',
						filename: "赛前演示文档",
						ext: 'ppt',
						poster: "",
						isChoose: false
					},
					{
						id: 2,
						match: "年十八中杯赛",
						time: "2017-08-01 16:41:00",
						file: '@/assets/images/file/比赛文件.rar',
						filename: "比赛文件",
						ext: 'rar',
						poster: "",
						isChoose: false
					},
					{
						id: 3,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2021-08-01 16:41:00",
						file: '@/assets/images/file/报名申请表模板.doc',
						filename: "报名申请表模板",
						ext: 'doc',
						poster: "",
						isChoose: false
					},
					{
						id: 4,
						match: "年十八中杯赛",
						time: "2027-08-01 16:41:00",
						file: '@/assets/images/file/海报设计稿 一版.psd',
						filename: "海报设计稿 一版",
						ext: 'psd',
						poster: "",
						isChoose: false
					},
					{
						id: 5,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2020-08-01 16:41:00",
						file: '@/assets/images/file/组委会重要文件.pdf',
						filename: "组委会重要文件",
						ext: 'pdf',
						poster: "",
						isChoose: false
					},
					{
						id: 6,
						match: "十八中杯赛",
						time: "2010-08-01 16:41:00",
						file: '@/assets/images/file/队员报名表.xlsx',
						filename: "队员报名表",
						ext: 'xlsx',
						poster: "",
						isChoose: false
					},
					{
						id: 7,
						match: "十八中杯赛",
						time: "2010-08-01 16:41:00",
						file: '@/assets/images/file/非重要文件.rtf',
						filename: "非重要文件",
						ext: 'rtf',
						poster: "",
						isChoose: false
					}
				],
				chooseList: [],
				loading: true,
				loadCount: 0,
				playUrl: '',
				searchKey: '',
				deafultIcon: require('@/assets/images/ext/unknow.png')
			}
		},
		created() {

		},
		methods: {
			allCheck() {
				if (this.chooseList.length == this.fileList.length) {
					this.chooseList = []
				} else {
					this.chooseList = []
					this.chooseList = this.fileList.map(file => file.id);
				}
				
				console.log(this.chooseList)
			},
			getImageSrc(ext) {
				try {
					return require(`@/assets/images/ext/${ext}.png`);
				} catch (error) {
					return require('@/assets/images/ext/unknow.png'); // 返回一个默认文件
				}
			},
			deleteChoose() {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					this.fileList = this.fileList.filter(item => !this.chooseList.includes(item.id));
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.chooseList = []
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
				
			},
			sortfileList() {
			    this.fileList.sort((a, b) => {
					return a.match.localeCompare(b.match);
			    });
			},
			sortfileListbyTime() {
			    this.fileList.sort((a, b) => {
					return b.time.localeCompare(a.time);
			    });
			},
			searchfile() {
				
			},
			downloadFile(filePath,filename,ext) {
			    const link = document.createElement('a');
			    link.href = filePath;
			    link.download = filename+"."+ext;
			    document.body.appendChild(link);
			    link.click();
			    document.body.removeChild(link);
			}
		},
		mounted() {
		    
		}
		
	}
</script>

<style scoped>
	.search-box {
	    position: absolute;
	    top: 20px;
	    right: 60px;
		width: 250px;
		height: 40px;
	}
	
	::v-deep .el-input__inner {
	  background: #f0f0f0;
	  border-radius: 40px;
	  border: 0px;
	}
	
	.content {
	    position: relative;
	}
	
	.main-box {
		/* display: flex; */
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 16px;
		grid-template-rows: repeat(auto-fill, 300px);
	}
	
	.file-item {
		position: relative;
		width: calc(100% - 30px);
		height: 270px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		cursor: pointer;
	}
	
	.file-item:hover {
		background-color: #e7f0fa;
	}
	
	.file-item:hover .file-checkBox {
		display: block;
	}
	
	.file-item:hover .file-download {
		display: block;
	}
	
	.file-download {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 20px;
		height: 20px;
		font-size: 20px;
		display: none;
	}
	
	.file-download:hover {
		color: #878787;
	}
	
	.isChoose .file-download {
		display: block;
	}
	
	.isChoose .file-checkBox {
		display: block;
	}
	
	.isChoose {
		background-color: #e7f0fa;
	}
	
	.posterImage {
		width: calc(50% - 30px);
		aspect-ratio: 1/1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		position: relative;
	}
	
	.posterImage i {
		position: absolute;
		margin: auto;
		top: 45%;
		bottom: 0;
		left: 0;
		right: 0;
		font-size: 30px;
		color: #ccc;
	}
	
	.poster-image {
		border-radius: 10px;
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
	}
	
	.tmp-file {
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		
	}
	
	.tool-box {
		text-align: left;
		height: 80px;
		line-height: 80px;
	}
	
	.matchTitle {
		height: 40px;
		line-height: 20px;
		font-size: 14px;
		display: -webkit-box;          /* 使元素成为弹性盒子模型 */
		-webkit-box-orient: vertical; /* 垂直排列盒子子元素 */
		-webkit-line-clamp: 2;        /* 限制显示的行数为2 */
		overflow: hidden;              /* 隐藏超出的内容 */
		text-overflow: ellipsis;
	}
	
	.matchTime {
		font-size: 12px;
		color: #ccc;
		margin-top: 15px;
	}
	
	.file-checkBox {
		position: absolute;
		left: 10px;
		top: 10px;
		display: none;
	}
	
	/deep/ .el-checkbox__inner {
		width: 20px;
		height: 20px;
	}
	
	/deep/ .el-checkbox__inner::after {
		left: 6px;
		height: 11px;
		width: 5px;
	}
	
	/deep/ .el-checkbox__label {
		display: none;
	}
	
	.fileMask {
		position: fixed;
		background-color: rgba(0, 0, 0, .6);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 9999;
		display: flex;
		justify-content: center;
		line-height: center;
	}
	
	.fileMask file {
		width: 800px;
		height: 800px;
		margin: auto;
	}
	
	.closeBtn {
		position: absolute;
		right: calc(50% - 500px);
		top: 60px;
		color: #fff;
		font-size: 60px;
		width: 60px;
		height: 60px;
		border: 2px dashed #fff;
		cursor: pointer;
	}
</style>