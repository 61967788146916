<template>
	<div v-if="gameType == 9">
		<div class="home">
			<my-header-climb></my-header-climb>
			<div class="body">
				<side-bar-climb></side-bar-climb>
				<main-content-climb ref="mainContentClimb"></main-content-climb>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="home">
			<my-header></my-header>
			<div class="body">
				<side-bar></side-bar>
				<main-content ref="mainContent"></main-content>
			</div>
		</div>
	  
	</div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue';
import SideBar from '@/components/SideBar.vue';
import MainContent from '@/components/MainContent.vue';

import MyHeaderClimb from '@/components/MyHeaderClimb.vue';
import SideBarClimb from '@/components/SideBarClimb.vue';
import MainContentClimb from '@/components/MainContentClimb.vue';

export default {
	name: 'HomePage',
	components: {
		MyHeader,
		SideBar,
		MainContent,
		MyHeaderClimb,
		SideBarClimb,
		MainContentClimb
	},
	data() {
	  return {
	    gameType: 0
	  };
	},
	created() {
	    this.$api.post('api/Main/GetGame').then(response => {
		   if (response.data.code == 0) {
			   this.$message({
				 type: 'error',
				 message: response.data.data
			   });
			   this.$router.push({ path: '/login'});
			   return;
		   }
		   this.gameType = response.data.data.otype   //1.篮球  9.攀岩  11.篮球史
		   
	    }).catch(error => {
		   console.error("There was an error!", error);
	    }) 
	},
    methods: {
		scrollToMainContent() {
		    this.$refs.mainContent.scrollTop = 0;
		    this.$refs.mainContentClimb.scrollTop = 0;
		},
    }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  display: flex;
  flex: 1;
}
</style>
