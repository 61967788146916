<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>{{title}}</span>
                </div>
                <div class="main-box main-box-1">
                    <div class="camera-box-item" v-for="(item,index) in cameraList" :key="index">
                        <div class="camera-name"><span class="live-label">名称：</span>{{item.name}}</div>
                        <!-- <i class="camera-icon el-icon-video-camera-solid"></i> -->
                        <div class="camera-name"><span class="live-label">拉流地址(PLAY)：</span>{{item.pullstreamurlm}}</div>
                        <div class="camera-name"><span class="live-label">拉流地址(M、HLS)：</span>{{item.pullstreamurlm}}</div>
                        <div class="camera-name"><span class="live-label">观看地址(UPLIVE)：</span>{{item.mobilephoneurl}}</div>
                        <div class="brn-area">
                            <div @click="editCameraName(item.id)">修改名称</div>
                            <div><router-link :to="`/camerasetting/${item.name}/${item.id}`">机位设置</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>比赛列表</span>
                </div>
                <div class="main-box main-box-1">
                    <div class="empty-box" v-if="!teamData">暂无未开始或进行中比赛</div>
                    <div class="camera-box-item" v-for="(item,index) in teamData" :key="index">
                        <div class="team-time">{{formatTime(item.yjkssj)}}</div>
                        <div class="team-logo-box">
                            <div class="team-box">
                                <div class="team-logo">
                                    <div class="block">
                                        <el-avatar @click.native="showPre(item.homelogo)" shape="circle" :size="60" :src="item.homelogo"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info">
                                    <span class="team-info-name">{{item.homename}}</span>
                                </div>
                            </div>
                            
                            <div class="team-vs">
                                VS
                            </div>
                            
                            <div class="team-box" style="justify-content: right;">
                                <div class="team-logo">
                                    <div class="block" style="margin-left: 10px;">
                                        <el-avatar @click.native="showPre(item.guestlogo)" shape="circle" :size="60" :src="item.guestlogo" fit="cover"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info" style="text-align: right;">
                                    <span class="team-info-name">{{item.guestname}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="team-scan" @click="openLiveAddress(item)">扫码观看比赛</div>
                    </div>
                </div>
                
                <div class="footer-page">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total"
                      :page-size="limit"
                      @current-change="changepage"
                      :hide-on-single-page="true"
                      >
                    </el-pagination>
                </div>
            </div>
         </div>
         <el-dialog
           title="直播地址"
           :visible.sync="dialogVisible"
           width="30%"
           >
           <div class="live-main-box">
               <img class="main-image" :src="liveAddress.img" alt="" />
               <span class="main-gamename">{{liveAddress.gamename}}</span>
               <span class="main-gametime">{{formatTime(liveAddress.starttime)}}</span>
               <div class="teams-score">
                   <img class="main-teamlogo" :src="liveAddress.homelogo" alt="" />
                   <span class="main-teamname">{{liveAddress.homename}}</span>
                   <!-- <span class="main-teamscore">{{liveAddress.homescore}}</span> -->
                   VS
                   <!-- <span class="main-teamscore">{{liveAddress.guestscore}}</span> -->
                   <span class="main-teamname">{{liveAddress.guestname}}</span>
                   <img class="main-teamlogo" :src="liveAddress.guestlogo" alt="" />
               </div>
               <img class="main-qrcode" :src="liveAddress.qrcode" alt="" />
               <span class="main-tips">扫码观看这场比赛</span>
           </div>
           
         </el-dialog>
        <!-- vueCropper 剪裁图片实现-->
        
    </div>
</template>

<script>
    export default {
        data() {
          return {
            title: "机位管理",
            type: "add",
            teamData: [],
            cameraList: [],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            pageIndex: 1,
            limit: 5,
            liveid: "",
            oid: "",
            total: 0,
            dialogVisible: false,
            liveAddress: {
                img: "",
                gamename: "",
                homelogo: "",
                guestlogo: "",
                homename: "",
                guestname: "",
                homescore: "",
                guestscore: "",
                qrcode: "",
                starttime: ""
            }
          }
        },
        created() {
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.oid = response.data.data.oid
                    this.fetchTeamData()
                    this.getCameraList()
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } else {
                this.fetchTeamData()
                this.getCameraList()
                
            }
        },
        methods: {
            getCameraList() {
                let formData = new FormData();
                formData.append('oid', this.oid);
                this.$api.post('app/user/organizerseats', formData).then(response => {
                    this.cameraList = response.data.data;
                })
            },
            fetchTeamData() {
                let formData = new FormData();
                formData.append('liveid', this.liveid);
                formData.append('pageindex', this.pageIndex);
                formData.append('pagesize', this.limit);
                this.$api.post('/app/match/list', formData).then(response => {
                    this.teamData = response.data.data.rows;
                    this.total = response.data.data.total;
                })
            },
            changepage(p) {
                this.pageIndex = p
                this.fetchTeamData()
            },
            editCameraName(id) {
                const data = this.cameraList.find(item => item.id === id)
                this.$prompt('请输入机位名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: data.name,
                    closeOnClickModal: false
                }).then(({ value }) => {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    });
                    let item = this.cameraList.find(item => item.id === id)
                    item.name = value
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });       
                });
            },
            openLiveAddress(row) {
                this.liveAddress = {
                    img: row.gamelogo,
                    gamename: row.gamename,
                    homelogo: row.homelogo,
                    guestlogo: row.guestlogo,
                    homename: row.homename,
                    guestname: row.guestname,
                    homescore: 0,
                    guestscore: 0,
                    qrcode: require("@/assets/images/wm_weixin.jpg"),
                    starttime: row.addtime
                }
                this.dialogVisible = true
            },
            formatTime(value) {
                const date = new Date(value);
                const options = {  year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit', second: '2-digit' };
                return date.toLocaleString('default',options);
            },
        }
      }
</script>
<style scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: calc(100% - 20px);
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(50% - 50px);
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(50% - 50px);
        margin: 10px;
    }
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .btn-area-btn {
        width: 120px;
        margin-bottom: 10px;
    }

    .grade-box {
        background-image: url('../../assets/images/gradeTemp.jpg');
        background-size: 100% 100%;
        width: 400px;
        height: 250px;
        margin-left: 50px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    
    .teamname {
        font-size: 30px;
        /* font-weight: bold; */
        color: #f00;
        margin-top: 20px;
        margin-left: 40px;
        /* font-family: 'Courier New' */
        height: 35px;
    }
    
    .teamnick {
        margin-left: 40px;
        color: #f00;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Consolas';
        letter-spacing: 5px;
    }
    
    
    .main-box-1 {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }
    
    .el-icon-arrow-left {
        margin-right: 0px!important;
    }
    
    .content {
        width: 100%;
        height: 100%;
    }
    
    .form-box {
        width: 380px;
        margin: 20px;
        font-size: 20px;
    }
    
    .el-form-item {
        font-weight: bold;
        text-align: left;
    }
    
    .el-upload-dragger {
        width: 100px!important;
        height: 100px!important;
    }
    
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        line-height: 110px;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 30px;
        color: #8c939d;
        /* width: 100px; */
        /* height: 100px; */
        text-align: center;
      }
      .avatar {
        width: 100%;
        height: 100%;
        display: block;
      }
      
      .cropper-content {
          .cropper {
              width: auto;
              height: 350px;
              margin-bottom: 10px;
          }
       }
       
       .historyLogos {
           width: calc(100% - 80px);
           margin: 0 auto;
           font-size: 18px;
           font-weight: bold;
           margin-top: 50px;
       }
       
       .logos {
           display: flex;
           flex-wrap: wrap;
           padding-top: 20px;
       }
       
       .logos img {
           width: 100px;
           height: 100px;
           border: 1px solid #ccc;
           border-radius: 5px;
           margin-right: 20px;
           margin-bottom: 20px;
           cursor: pointer;
       }
       
       .logos img:hover {
           border: 1px solid #13b4ff
       }
       
       .upload-demo {
           width: auto;
           margin-right: 10px;
           float: left;
       }
       
       .footer-page {
           margin-top: 20px;
       }
       
       .camera-box-item {
           width: calc(100% / 2 - 20px);
           height: 220px;
           border-radius: 15px;
           border: 1px solid #666;
           margin: 8px;
           display: flex;
           flex-direction: column;
           align-items: center;
           position: relative;
       }
       
       .camera-icon {
           font-size: 80px;
           position: absolute;
           top: 40px;
       }
       
       .camera-name {
           font-size: 12px;
           padding-left: 20px;
           width: 100%;
           text-align: left;
           margin-top: 10px;
           height: 30px;
       }
       
       .brn-area {
           display: flex;
           justify-content: space-between;
           width: 90%;
           position: absolute;
           bottom: 10px;
           font-size: 14px;
           color: #0e95ee;
       }
       
       .brn-area div {
           cursor: pointer;
       }
       
       .live-main-box {
           display: flex;
           flex-direction: column;
       }
       
       .main-image {
           width: 100%;
           height: 240px
       }
       
       .main-gamename {
           font-size: 22px;
           font-weight: bold;
           margin: 10px 0px;
       }
       
       .teams-score {
           display: flex;
           justify-content: center;
           align-items: center;
           font-weight: bold;
       }
       
       .main-teamlogo {
           width: 80px;
           height: 80px;
           border-radius: 80px;
           margin: 0px 10px;
       }
       
       .main-teamname {
           font-size: 18px;
           font-weight: bold;
           margin: 0px 10px;
           
       }
       
       .main-teamscore {
           font-size: 30px;
           font-weight: bold;
           margin: 0px 10px;
       }
       
       .main-qrcode {
           width: 50%;
           height: auto;
           margin: 0 auto;
       }
       
       .live-label {
           color: #0192ff;
       }
       
       .team-logo-box {
           display: flex;
           justify-content: space-between;
           padding-left: 0px!important;
           width: 100%;
       }
       
       .team-box {
           display: flex;
           flex-direction: column;
           /* width: 400px; */
           justify-content: center;
           width: 40%;
       }
       
       .team-vs {
           font-weight: bold;
           font-size: 40px;
           line-height: 60px;
           padding: 0px 10px;
           display: flex;
           justify-content: center;
           width: 20%;
           flex-direction: column;
       }
       
       .team-time {
           font-size: 14px;
           font-weight: 400;
           height: 40px;
           line-height: 40px;
       }
       
       .font-blue {
           color: #009dff;
           font-size: 34px
       }
       
       .font-red {
           color: #ff0004;
           font-size: 34px
       }
       
       .team-info {
           display: flex;
           flex-direction: column;
           margin-left: 10px;
           line-height: 30px;
           text-align: left;
       }
       
       .team-info-name {
           font-size: 14px;
           text-align: center;
       }
       
       .team-info-score {
           text-align: left;
           font-size: 14px;
       }
       
       .team-scan {
           margin-top: 20px;
           color: #0192ff;
           cursor: pointer;
       }
       
       .empty-box {
           text-align: center;
           line-height: 150px;
           color: #ccc;
           width: 100%;
       }
       
       
    
    
</style>