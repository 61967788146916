<template>
	<div class="content" @click="gridmenu = false">
		<div class="open-box" v-if="showOpen">
			<div class="open-box-time">{{countdown}}s</div>
			<el-image
				class="image-to-zoom"
			    style="width: 100%; height: 100%"
			    src="https://img.tuguaishou.com/ips_templ_preview/92/46/1a/lg_3553242_1606910844_5fc7837cbc35d.jpg!w1024_w?auth_key=2277579513-0-0-1cddc97bb964a2abb226bf2ba59e81eb"
			    fit="cover"></el-image>
			<div class="open-box-btn" @click="showOpen = false">进入相册</div>
		</div>
		<div class="main-box" v-else>
			<el-image
			    style="aspect-ratio: 750 / 420;"
			    src="https://img-u-3.51miz.com/Templet/00/21/10/58/211058_be6e468df780ccb6506b47e9e20086ae.jpg-0.jpg"
			    fit="cover"></el-image>
			<div class="info-box">
				<span class="info-box-title">2024年安阳市全民健身月  第三届“茅台王子杯”职工篮球联赛颁奖晚宴</span>
				<div class="info-box-detail">
					<div class="info-box-detail-l">
						<span class="info-box-detail-l-view">
							<span class="info-box-detail-l-view-num">10200</span>
							次照片浏览
						</span>
						<span class="info-box-detail-l-t">
							<i class="el-icon-time"></i>
							<span>2024-06-02</span>
						</span>
						<span class="info-box-detail-l-t">
							<i class="el-icon-location-outline"></i>
							<span>河南-安阳</span>
						</span>
					</div>
					<div class="info-box-detail-r">
						<div class="info-box-detail-r-btn" @click="toHome">主页</div>
						<div class="info-box-detail-r-btn" @click="drawer = true">分享</div>
					</div>
				</div>
			</div>
			<div style="width: 100%;height: 60px;" v-show="isSticky" ref="navBoxHide"></div>
			<div class="nav-box" :class="{'nav-box-tag': true, 'sticky': isSticky}" ref="navBox">
				<div class="nav-box-tag">
					<span class="tags" :class="{ 'tag_active': activeTag === 'photo' }" @click="setActiveTag('photo')">照片直播</span>
					<span class="tags" :class="{ 'tag_active': activeTag === 'hot' }" @click="setActiveTag('hot')">热门</span>
					<span class="tags" :class="{ 'tag_active': activeTag === 'other' }" @click="setActiveTag('other')">其他</span>
				</div>
				<div class="nav-box-model">
					<span class="nav-box-model-btn" @click.stop="gridmenu = true" v-if="activeTag == 'photo'">照片布局<i class="el-icon-caret-bottom"></i></span>
					<div class="grid-menu" v-show="gridmenu">
						<div class="grid-menu-item" :class="{ 'active': viewMode === 'fall' }" @click="setViewModel('fall')">
							<i class="iconfont icon-a-pubuliumian"></i>瀑布流
						</div>
						<div class="grid-menu-item" :class="{ 'active': viewMode === 'grid' }" @click="setViewModel('grid')">
							<i class="iconfont icon-fangge"></i>方格图
						</div>
						<div class="grid-menu-item" :class="{ 'active': viewMode === 'time' }" @click="setViewModel('time')">
							<i class="iconfont icon-shijianzhou"></i>时间轴
						</div>
						<div class="grid-menu-item-line"></div>
						<div class="grid-menu-item" :class="{ 'active': viewSort === 'asc' }" @click="setViewSort('asc')">
							<i class="iconfont icon-shijianzhengxu"></i>正序
						</div>
						<div class="grid-menu-item" :class="{ 'active': viewSort === 'desc' }" @click="setViewSort('desc')">
							<i class="iconfont icon-shijiandaoxu"></i>倒序
						</div>
					</div>
				</div>
			</div>
			
			<!-- <div class="nav-box">
				<div class="nav-box-tag">
					<span class="tags-class">颁奖晚宴</span>
					<span class="tags-class">开幕式</span>
				</div>
			</div> -->
			<photoShow :mode="viewMode" :sort="viewSort" v-if="activeTag == 'photo'"></photoShow>
			<photoHot :mode="activeTag" :sort="viewSort" v-else></photoHot>
			<el-drawer
			  title=""
			  size="13%"
			  direction="btt"
			  :visible.sync="drawer"
			  :with-header="false">
			  <div class="drawer-box-icon">
				  <i class="el-icon-arrow-down"></i>
			  </div>
			  <div class="drawer-box">
				  <div class="share-item" @click="showQr = true">
					  <i class="iconfont icon-erweima"></i>
					  <span>二维码分享</span>
				  </div>
				  <div class="share-item" v-clipboard:copy="Qrcode" v-clipboard:success="copyLink" v-clipboard:error="copyLink">
					  <i class="iconfont icon-lianjie"></i>
					  <span>链接分享</span>
				  </div>
				  <div class="share-item" @click="showShare = true">
					  <i class="iconfont icon-weixin"></i>
					  <span>微信分享</span>
				  </div>
				  <div class="share-item">
					  <i class="iconfont icon-haibao" @click="generatePoster"></i>
					  <span>海报分享</span>
				  </div>
			  </div>
			</el-drawer>
			<el-dialog
			  :visible.sync="showQr"
			  width="60%">
			  <span style="font-size: 16px;font-weight: bold;color: #000;">相册二维码</span>
			  <QrCodeGenerator :url="Qrcode" />
			  <span style="font-size: 14px;">长按保存相册二维码</span>
			</el-dialog>
			<el-dialog
			  :visible.sync="showQr"
			  width="80%">
			  <span style="font-size: 16px;font-weight: bold;color: #000;">相册二维码</span>
			  <img src="" alt="">
			  <QrCodeGenerator :url="Qrcode" />
			  <span style="font-size: 14px;">长按识别二维码，观看现场照片直播</span>
			</el-dialog>
			<div class="mask-share" v-show="showShare">
				<img src="@/assets/images/share.png" alt="" />
				<button class="share-button" @click="showShare = false;drawer = false">我知道了</button>
			</div>
			
			<div class="poster-container" v-show="showPoster"> 
				<div class="poster-box" ref="contentToCapture">
					<div style="display: flex;flex-direction: column;" v-if="!posterDataUrl">
						<img src="https://www.douzhan.club/resources/play/static/img/slider.c5c26cd3.jpg" alt="海报">
						<span class="poster-title">2024年安阳市全民健身月 第三届“茅台王子杯”职工篮球联赛颁奖晚宴</span>
						<span class="poster-date">2024-06-02</span>
						<span class="poster-site">河南-洛阳</span>
						<el-divider></el-divider>
						<QrCodeGenerator class="poster-qr" :url="Qrcode" />
						<span class="poster-tips">长按识别二维码，观看现场照片直播</span>
					</div>
					<img :src="posterDataUrl" alt="" style="width: 100%;">
				</div>
				
				<span style="color: #fff;margin: 10px 0px;display: inline-block;">长按图片保存分享图</span>
				<div><i class="el-icon-circle-close" style="color: #fff;font-size: 40px;" @click="showPoster =false;"></i></div>
			</div>
		</div>
	</div>
</template>

<script>
	import photoShow from '@/components/photoShow.vue'
	import photoHot from '@/components/photoShowHot.vue'
	import QrCodeGenerator from '@/components/qrcode.vue'
	import html2canvas from 'html2canvas';
	import wx from 'weixin-js-sdk';
	export default {
		components: {
		    photoShow,
			photoHot,
			QrCodeGenerator
		},
		data() {
			return {
				countdown: 5,
				timer: null,
				showOpen: true,
				activeTag: 'photo',
				gridmenu: false,
				viewMode: 'grid',
				viewSort: 'asc',
				isSticky: false,
				wechatConfig: null,
				drawer: false,
				Qrcode: location.href,
				showQr: false,
				showShare: false,
				posterDataUrl: "",
				showPoster: false
			}
		},
		methods: {
			startCountdown() {
				this.timer = setInterval(() => {
					if (this.countdown > 0) {
					  this.countdown--;
					} else {
					  clearInterval(this.timer);
					  this.showOpen = false;
					}
				}, 1000);
			},
			setActiveTag(tag) {
			    this.activeTag = tag;
			},
			setViewModel(mode) {
				this.viewMode = mode
				this.gridmenu = false
			},
			setViewSort(sort) {
				this.viewSort = sort
				this.gridmenu = false
			},
			handleScroll() {
			    
				if (this.isSticky) {
					const navBox = this.$refs.navBoxHide;
					const offsetTop = navBox.offsetTop;
					this.isSticky = window.scrollY >= offsetTop;
				} else {
					const navBox = this.$refs.navBox;
					const offsetTop = navBox.offsetTop;
					this.isSticky = window.scrollY >= offsetTop;
				}
			    
				
			},
			share() {
				// let params = {
				//     url: location.href.split('#')[0] // 只需要传当前页面地址
				// }
				let formData = new FormData();
				formData.append('url', location.href.split('#')[0]);
				this.$api.post('/m/dz/getWxConfig',formData).then(response => {
					this.wechatConfig = response.data
					this.initWechatJsSDK()
				}).catch(error => {
				    console.error("There was an error!", error);
				}) 

			},
			initWechatJsSDK () {
			    wx.config({
			        debug: false,
			        appId: this.wechatConfig.appId,
			        timestamp: this.wechatConfig.timestamp,
			        nonceStr: this.wechatConfig.noncestr,
			        signature: this.wechatConfig.signature,
			        jsApiList: [
			          'updateAppMessageShareData',
			        ]
			    })
			    wx.ready(() => {
			        wx.updateAppMessageShareData({
			          title: "2024年安阳市全民健身月  第三届“茅台王子杯”职工篮球联赛颁奖晚宴",
			          desc: '时间：2024.7.4\r\n地点：北京-东城',
			          link: location.href,
			          imgUrl: "https://img-u-3.51miz.com/Templet/00/21/10/58/211058_be6e468df780ccb6506b47e9e20086ae.jpg-0.jpg",
			          success: () => {
			            // alert("分享成功")
			          },
			          cancel: () => {
			            // 用户取消分享的回调函数
						alert("取消成功")
			          }
			        })
			    })
			 
			    // 在配置出错的情况下调用错误处理函数
			    wx.error((err) => {
			        console.error(err)
			    })
			},
			copyLink() {
				alert('已复制')
				this.drawer = false
			},
			generatePoster() {
				this.showPoster = true
				this.$nextTick(() => {
				    this.exportToPng();
				});
			},
			exportToPng() {
				console.log("导出图片执行了");
			    const element = this.$refs.contentToCapture;
				html2canvas(element,{
					useCORS: true, // 允许跨域
				}).then(canvas => {
					let imageUrl = canvas.toDataURL('image/png');
					this.posterDataUrl = imageUrl
					this.$forceUpdate()
				});
			},
			urlToBase64(url) {
			    return new Promise((resolve, reject) => {
			        // 使用 fetch 获取图片
			        fetch(url)
			            .then(response => {
			                // 检查响应状态
			                if (!response.ok) {
			                    throw new Error('Network response was not ok');
			                }
			                return response.blob(); // 将响应转换为 Blob
			            })
			            .then(blob => {
			                const reader = new FileReader(); // 创建 FileReader 实例
			                reader.onloadend = () => {
			                    resolve(reader.result); // 解析为 Base64 URL
			                };
			                reader.onerror = () => {
			                    reject(new Error('Failed to convert Blob to Base64'));
			                };
			                reader.readAsDataURL(blob); // 读取 Blob 数据为 Base64 URL
			            })
			            .catch(error => {
			                reject(error); // 捕获并返回错误
			            });
			    });
			},
			url2Base64(url) {
				this.urlToBase64(url)
				.then(base64 => {
					return base64;
				})
				.catch(error => {
					console.error('Error:', error);
				});
			},
			toHome() {
				let url = 'https://www.douzhan.club/resources/organ/#/?id=57&mid=314949'
				window.location.href = url;
				
			}

		},
		mounted() {
		    this.startCountdown(); 
			this.$nextTick(() => {
			    const imageElement = this.$el.querySelector('.image-to-zoom');
			    if (imageElement) {
			        imageElement.classList.add('zoom-out');
			    }
			});
			window.addEventListener('scroll', this.handleScroll);
			this.share()
		},
		beforeDestroy() {
		    if (this.timer) {
		      clearInterval(this.timer);
		    }
			window.removeEventListener('scroll', this.handleScroll);
		}
	}
</script>
<style>
	body{
		padding-right: 0px!important;
	}
</style>
<style scoped>
	.content {
		overflow: hidden
	}
	
	.open-box {
		width: 100vw;
		height: 100vh;
	}
	
	.open-box-time {
		position: fixed;
		right: 20px;
		top: 20px;
		color: #fff;
		background: rgba(0, 0, 0, .4);
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		z-index: 9;
		border-radius: 30px;
	}
	
	@keyframes zoomOutAnimation {
	  from {
	    transform: scale(1.2);
	  }
	  to {
	    transform: scale(1);
	  }
	}
	
	.zoom-out {
	  animation: zoomOutAnimation 1.5s ease forwards;
	}
	
	.open-box-btn {
		width: 120px;
		height: 30px;
		border-radius: 30px;
		text-align: center;
		line-height: 30px;
		position: fixed;
		margin: 0 auto;
		bottom: 50px;
		left: 0;
		right: 0;
		color: #fff;
		background: rgba(0, 0, 0, .4);
		border: 1px solid #fff;
		font-size: 14px;
		box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.4);
		cursor: pointer;
	}
	
	.info-box {
		padding: 15px 15px;
		text-align: left;
		font-size: 18px;
		font-weight: bold;
	}
	
	.info-box-detail {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
	
	.info-box-detail-l {
		display: flex;
		flex-direction: column;
		font-size: 15px;
		font-weight: 400;
		color: #7c7c7c;
	}
	
	.info-box-detail-l-view-num {
		color: #ffd917;
	}
	
	.info-box-detail-l-view {
		margin-bottom: 15px;
	}
	
	.info-box-detail-l-t {
		font-size: 14px;
		margin-bottom: 3px;
	}
	
	.info-box-detail-l-t i {
		margin-right: 5px;
	}
	
	.info-box-detail-r {
		display: flex;
	}
	
	.info-box-detail-r-btn {
		width: 50px;
		height: 23px;
		border-radius: 5px;
		font-size: 14px;
		border: 1px solid #666;
		text-align: center;
		font-weight: 400;
		line-height: 23px;
		cursor: pointer;
		margin-left: 10px;
	}
	
	.nav-box {
		display: flex;
		justify-content: space-between;
		padding: 15px;
		margin-bottom: 10px;
	}
	
	.tags {
		margin-right: 20px;
		position: relative;
	}
	
	.tag_active {
		font-weight: bold;
	}
	
	.tag_active::after {
	  content: '';
	  position: absolute;
	  left: 50%;
	  bottom: -8px;
	  transform: translateX(-50%);
	  width: 50%;
	  height: 2px;
	  background-color: #ffbc10; /* 你可以根据需要调整颜色 */
	  z-index: 50;
	}
	
	.tags-class {
		font-size: 14px;
		margin-right: 20px;
	}
	
	.grid-menu {
		display: flex;
		flex-direction: column;
		text-align: left;
		width: 100px;
		box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .2);
		font-size: 14px;
		padding: 6px;
		background-color: #e6e6e6;
		margin-top: 10px;
		
	}
	
	.grid-menu-item {
		height: 35px;
		line-height: 35px;
		display: flex;
	}
	
	.grid-menu-item i {
		font-size: 15px;
	}
	
	.grid-menu-item.active {
		background-color: #fff;
		color: #ffbc10
	}
	
	.nav-box-model {
		position: relative;
	}
	
	.grid-menu {
		position: absolute;
		z-index: 10;
		right: 5px;
	}
	
	.grid-menu-item-line {
		width: 90%;
		margin: 5px auto;
		border-bottom: 1px solid #ccc;
	}
	
	.nav-box-model-btn {
		font-size: 14px;
	}
	
	.nav-box {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1000;
	}
	
	.sticky {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: calc(100% - 30px);
	  right: 0;
	}
	
	.drawer-box {
		display: flex;
		justify-content: space-around;
	}
	
	.share-item {
		text-align: center;
		display: flex;
		flex-direction: column;
	}
	
	.share-item i {
		font-size: 32px;
	}
	
	.share-item span {
		font-size: 12px;
		margin-top: 8px;
	}
	
	.drawer-box-icon {
		text-align: center;
		height: 30px;
		line-height: 30px;
		font-size: 22px;
	}
	
	.mask-share {
		position: fixed;
		background-color: rgba(0, 0, 0, .8);
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 99999;
	}
	.mask-share img {
		width: 100%;
	}
	
	.share-button {
		width: 170px;
		height: 50px;
		border-radius: 15px;
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #fff;
		color: #fff;
		text-align: center;
		line-height: 50px;
		font-size: 18px;
		margin-top: 50px;
	}
	
	
	.poster-container img {
	  max-width: 100%;
	  height: auto;
	}
	
	.poster-container {
		position: fixed;
		background-color: rgba(0, 0, 0, .8);
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 99999;
	}
	
	.poster-box {
		width: 80%;
		background-color: #fff;
		position: fidex;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		margin-top: 100px;
		display: flex;
		flex-direction: column;
	}
	
	.poster-title {
		width: 80%;
		margin: 15px auto 10px auto;
		font-size: 14px;
	}
	
	.poster-date,.poster-site {
		font-size: 12px;
		color: #9a9a9a;
		margin-bottom: 5px;
	}
	
	/deep/ .el-divider {
		width: 80%;
		margin: 10px auto;
	}
	
	.poster-qr {
		width: 50%;
		margin: 0 auto;
	}
	
	.poster-tips {
		width: 80%;
		height: 30px;
		margin: 15px auto;
		font-size: 12px;
		background-color: #666;
		color: #fff;
		text-align: center;
		line-height: 30px;
	}
	
	
</style>