<template>
    <div class="content">
        <top-nav title="管理球队"></top-nav>
        <div class="main-box">
            <!-- <div id="tags-container">
                <div
                  v-for="tag in uniqueTags"
                  :key="tag"
                  class="tag"
                  :class="{ active: activeTag === tag }"
                  @click="setActiveTag(tag)"
                >
                  {{ tag.indexOf("null") !== -1 ?  tag.split('/')[1] : tag }}
                </div>
            </div> -->
            <el-table
              :data="tableData"
              style="width: 100%">
              <el-table-column
                prop="team"
                label="球队"
                width="350"
                align="center"
                >
                <template slot-scope="scope">
                    <div class="team-logo-box">
                        
                        <div class="team-logo">
                            <div class="block">
                                <el-avatar @click.native="showPre(scope.row.logo)" shape="square" fit="scale-down" :size="60" :src="scope.row.logo || defaultlogo"></el-avatar>
                            </div>
                        </div>
                        <div class="team-info">
                            <router-link :to="`/history/${liveid}/teammembers/${scope.row.name}/${scope.row.id}`">
                            <span class="team-info-name">{{scope.row.name}}</span>
                            </router-link>
                            <span class="team-info-score">{{scope.row.shortnick}}</span>
                        </div>
                    </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="playernum"
                label="成员人数"
                width="130"
                align="center"
                >
                
              </el-table-column>
              <el-table-column
                label="战绩"
                width="300"
                align="center"
                >
                <template slot-scope="scope">
                    <span class="score-win">{{scope.row.winnum}}</span> 胜 :
                    <span class="score-lose">{{scope.row.gamenum - scope.row.winnum}}</span> 负
                </template>
              </el-table-column>
              <el-table-column
                label="积分"
                width="180"
                align="center"
                >
                <template slot-scope="scope">
                    {{scope.row.homegetscore + scope.row.guestgetscore}}
                </template>
              </el-table-column>
              <el-table-column
                label="分组"
                align="center"
                >
                <template slot-scope="scope">
                    <span class="team-tag" v-if="scope.row.teamtag">{{scope.row.teamtag}} / </span>
                    <span class="team-group" v-if="scope.row.teamgroup">{{scope.row.teamgroup}} </span>
                    <span class="team-seq" v-if="scope.row.seq !== '' && scope.row.seq !== 0">/ {{scope.row.seq}}</span>
                </template>
              </el-table-column>
              
            </el-table>
            
            <div class="footer-page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  @current-change="changepage"
                  :hide-on-single-page="true"
                  >
                </el-pagination>
            </div>
            
            <el-dialog title="年龄限制" :visible.sync="dialogFormVisible">
                <el-row>
                  <el-col :span="24">
                      <el-slider
                          class="slideBox"
                          v-model="value"
                          range
                          show-stops
                          :marks="marks"
                          :max="100"
                          @change="setAge($event)"
                          >
                      </el-slider>
                  </el-col>
                  <el-col :span="24" style="text-align: left;margin-top: 50px;margin-left: 30px;">
                        <el-date-picker
                            v-model="editForm.ageMonth"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                        &emsp;出生以前可报名
                  </el-col>
                </el-row>
                
               
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAge()">取 消</el-button>
                <el-button type="primary" @click="applyAge()">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="设定分组" :visible.sync="dialogGroupVisible">
                <el-select v-model="editForm.teamtag" placeholder="请选择" style="margin-left: 20px;">
                    <el-option
                      v-for="item in options1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                      >
                    </el-option>
                </el-select>
                <el-select v-model="editForm.teamgroup" placeholder="请选择" style="margin-left: 20px;">
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                      >
                    </el-option>
                </el-select>
                <el-select v-model="editForm.seq" placeholder="请选择" style="margin-left: 20px;">
                    <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                      >
                    </el-option>
                </el-select>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAge()">取 消</el-button>
                <el-button type="primary" @click="applyGroup()">保存分组</el-button>
              </div>
            </el-dialog>
            <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
        </div>
        
    </div>
</template>

<script>
    export default {
      data() {
        return {
            total: 0,
            pageSize: 10,
            pageIndex: 1,
            preimg: "",
            is_show: false,
            value: [0, 0],
            marks: {
                0: '无限制',
                10: '10岁',
                20: '20岁',
                30: '30岁',
                40: '40岁',
                50: '50岁',
                60: '60岁',
                70: '70岁',
                80: '80岁',
                90: '90岁',
            },
            chooseId: 0,
            minAge: 0,
            maxAge: 0,
            ageMonth: "",
            dialogFormVisible: false,
            dialogGroupVisible: false,
            dialogTableVisible: false,
            dialogFaceVisible: false,
            cropperImage: "",
            dialogAddVisible: false,
            isPreview: false,
            dialogVisible: false,
            memberType: "add",
            activeTag: '全部',
            teamtag: "",
            teamgroup: "",
            options1: [{
                  value: '1',
                  label: '全部'
                }, {
                  value: '2',
                  label: '甲组'
                }, {
                  value: '3',
                  label: '乙组'
                }, {
                  value: '4',
                  label: '公开组'
                }, {
                  value: '5',
                  label: '幼儿组'
                }, {
                  value: '6',
                  label: '未成年组'
                }, {
                  value: '7',
                  label: '成年组'
                }, {
                  value: '8',
                  label: '青少年组'
                }, {
                  value: '9',
                  label: '青壮年组'
                }, {
                  value: '10',
                  label: '壮年组'
                }, {
                  value: '11',
                  label: '老年组'
                }, {
                  value: '12',
                  label: '小学组'
                }, {
                  value: '13',
                  label: '初中组'
                }, {
                  value: '14',
                  label: '高中组'
                }, {
                  value: '15',
                  label: '女篮'
                }, {
                  value: '16',
                  label: '小学女篮'
                }, {
                  value: '17',
                  label: '初中女篮'
                }, {
                  value: '18',
                  label: '高中女篮'
                }, {
                  value: '19',
                  label: 'U6'
                }, {
                  value: '20',
                  label: 'U7'
                }, {
                  value: '21',
                  label: 'U8'
                }, {
                  value: '22',
                  label: 'U9'
                }, {
                  value: '23',
                  label: 'U10'
                }, {
                  value: '24',
                  label: 'U11'
                }, {
                  value: '25',
                  label: 'U12'
                }, {
                  value: '26',
                  label: 'U13'
                }, {
                  value: '27',
                  label: 'U14'
                }, {
                  value: '28',
                  label: 'U15'
                },  {
                  value: '29',
                  label: 'U16'
                }
            ],
            options2: [{
                  value: '1',
                  label: 'A'
                }, {
                  value: '2',
                  label: 'B'
                }, {
                  value: '3',
                  label: 'C'
                }, {
                  value: '4',
                  label: 'D'
                }, {
                  value: '5',
                  label: 'E'
                },
                {
                  value: '6',
                  label: 'F'
                },
                {
                  value: '7',
                  label: 'G'
                },
                {
                  value: '8',
                  label: 'H'
                },
                {
                  value: '9',
                  label: 'I'
                },
            ],
            options3: [{
                  value: '0',
                  label: '0'
                }, {
                  value: '1',
                  label: '1'
                }, {
                  value: '2',
                  label: '2'
                }, {
                  value: '3',
                  label: '3'
                }, {
                  value: '4',
                  label: '4'
            }],
            tableData: [],
            tableTagData: [],           
            editForm: [{
                 ageMonth: "",
            }],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            liveid: ""
        }
    },
    computed: {
        uniqueTags() {
          const tagSet = new Set(this.tableTagData
            .map(item => `${item.teamtag}/${item.teamgroup}`));
          return ['全部', ...Array.from(tagSet)];
        },
        filteredTableData() {
          if (this.activeTag === '全部') {
            return this.tableTagData;
          } else {
            return this.tableTagData.filter(item => {
              const tag = `${item.teamtag}/${item.teamgroup}`;
              return tag === this.activeTag;
            });
          }
        }
    },
    created() {
        this.liveid = this.$route.params.d
        this.fetchData()
    },
    methods: {
        fetchData() {
            // let params = {}
            let formData = new FormData();
            if (this.teamtag == "null" || this.teamtag == null || this.teamtag == "") {
                formData.append('pageindex', this.pageIndex);
                formData.append('pagesize', this.pageSize);
                formData.append('teamgroup', this.teamgroup);
                formData.append('liveid', this.liveid);
            } else {
                formData.append('pageindex', this.pageIndex);
                formData.append('pagesize', this.pageSize);
                formData.append('teamtag', this.teamtag);
                formData.append('teamgroup', this.teamgroup);
                formData.append('liveid', this.liveid);
            }
            
            
            this.$api.post('/app/match/teams', formData)
            .then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                // this.fetchTagData()
                this.tableData = response.data.data.rows
                this.tableData = this.tableData.map(item => {
                  this.$set(item, 'playerboth', '');
                  return item;
                });
                this.total = response.data.data.total
                this.pageSize = response.data.data.PageSize
              
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        
        },
        fetchTagData() {
            let formData = new FormData();
            if (this.teamtag == "null" || this.teamtag == null || this.teamtag == "") {
                formData.append('PageIndex', this.pageIndex);
                formData.append('pagesize', -1);
                formData.append('teamgroup', this.teamgroup);
                formData.append('liveid', this.liveid);
            } else {
                formData.append('PageIndex', this.pageIndex);
                formData.append('pagesize', -1);
                formData.append('teamtag', this.teamtag);
                formData.append('teamgroup', this.teamgroup);
                formData.append('liveid', this.liveid);
            }
            this.$api.post('/app/match/teams', formData)
            .then(response => {
                this.tableTagData = response.data.data.rows.map(item => ({
                    'teamtag': item.teamtag,
                    'teamgroup': item.teamgroup
                }));
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        getTeamData(id) {
            this.$api.get(`api/Team/GetTeam/${id}`)
            .then(response => {
                let reult = response.data.data
                this.editForm = reult
                this.value = [reult.playerminage,reult.playerage]
            })
            .catch(error => {
              console.error("There was an error!", error);
            }); 
        },
        saveTeamData() {
            this.$api.post('api/Team/SaveTeam', this.editForm ,{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.dialogFormVisible = false
                    this.dialogGroupVisible = false
                    this.fetchData()
                    
                } else {
                    this.$message({
                        type: 'error',
                        message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                }
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        changepage(p) {
            this.pageIndex = p
            this.fetchData()
        },
        stopReg(id) {
            this.getTeamData(id)
            let message = ""
            let item = this.tableData.find(item => item.id === id)
            if (item.isJoin === 0) {
                message = '确定要单独截止此球队报名吗?'
            } else {
                message = '确定要单独开启此球队报名吗?'
            }
            this.$confirm(message, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.editForm.isJoin = Math.abs(this.editForm.isJoin - 1)
                this.saveTeamData()
                item.isJoin  = Math.abs(item.isJoin - 1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        setAgelimit(id) {
            this.dialogFormVisible = true
            this.chooseId = id
            this.getTeamData(id)
        },
        setAge(val) {
            this.editForm.playerminage = val[0]
            this.editForm.playerage = val[1]

        },
        applyAge() {
            let msg = ''
            let ageMsg = ''
            if (this.editForm.ageMonth == "" || this.editForm.ageMonth == null) {
                ageMsg = "出生日期设为无限制"
            } else {
                ageMsg = "出生日期限制为"+this.editForm.ageMonth+"之前"
            }
            if(this.editForm.playerage == 0) {
                msg = '确定要将此球队年龄设定无限制，'+ageMsg+'吗?'
            }else{
                msg = '确定要将此球队年龄限制为'+this.editForm.playerminage+'-'+this.editForm.playerage+'岁,'+ageMsg+'吗?'
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveTeamData()
                let item = this.tableData.find(item => item.id === this.editForm.id)
                item.playerminage = this.editForm.playerminage
                item.playerage = this.editForm.playerage
                item.playerboth = this.editForm.ageMonth
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        cancelAge() {
            this.dialogFormVisible = false
            this.dialogGroupVisible = false
        },
        setGroup(id) {
            this.getTeamData(id)
            this.dialogGroupVisible = true
            this.chooseId = id
        },
        applyGroup() {
            if (!this.editForm.teamgroup) {
                this.$message({
                  showClose: true,
                  message: '不能为空',
                  type: 'error'
                });
                return;
            }
            this.saveTeamData()
            // this.$confirm('确定要将此球队分组设定为【'+this.editForm.teamtag+' / '+this.editForm.teamgroup+' / '+this.editForm.seq+'】吗?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     this.saveTeamData()
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '已取消操作'
            //     });          
            // });
            
        },
        orderTeam(id) {
            this.getTeamData(id)
            this.$prompt('数字越大，位置越靠前', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                this.editForm.seq = value
                this.saveTeamData()
                let item = this.tableData.find(item => item.id === id)
                item.seq = value
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消设置'
                });       
            });
        },
        del(id) {
           this.$confirm('确定要将此球队删除吗?', '提示', {
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning'
           }).then(() => {
               this.$api.get(`api/Team/DelTeam/${id}`)
               .then(response => {
                   if(response.data.code == 1) {
                       
                       this.tableData = this.tableData.filter(item => item.id !== id)
                       
                       this.$message({
                           type: 'success',
                           message: '删除成功!'
                       });
                       this.fetchData()
                   } else {
                       this.$message({
                           type: 'error',
                           message: response.data.data
                       });
                   }
                 
               })
               .catch(error => {
                 console.error("There was an error!", error);
               });
               
               
           }).catch(() => {
               this.$message({
                   type: 'info',
                   message: '已取消删除'
               });          
           }); 
        },
        edit(id) {
            this.$router.push({ path: '/createTeam', query: { id:  id} });
        },
        setActiveTag(tag) {
            this.activeTag = tag; // 设置当前激活的标签
            let tagarr = tag.split('/');
            this.teamtag = tagarr[0]
            this.teamgroup = tagarr[1]
            if (tag === "全部") {
                this.teamtag = ""
                this.teamgroup = ""
            }
            this.pageIndex = 1
            this.fetchData()
        },
        showPre(url) {
            this.preimg = url
            this.is_show = true
        },
        
        
      }
    }
  </script>

<style scoped>
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    .redFont {
        color: red;
    }
    
    .greenFont {
        color: green;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: auto;
      height: 20px;
      position: relative;
      bottom: -6px;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    







    
</style>