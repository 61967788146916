<template>
    <div class="content">
        <div class="nav-top">
            <div style="width: 1200px;display: flex;align-items: center;justify-content: space-between;">
                <div style="display: flex;align-items: center;">
                <div class="nav-top-logo">
                </div>
                <div class="nav-top-menu">
                    <!-- <span>首页</span> -->
                    <!-- <span class="level1-btn drop-btn">二级菜单<i class="el-icon-arrow-down"></i>
                        <div class="level1-item">
                            <div class="level1-item-l">
                                <div class="level1-item-l-menu" v-for="(item,index) in level1Menu" :key="index">
                                    <img class="level1-item-l-menu-logo" :src="item.logo" alt="" />
                                    <div class="level1-item-l-menu-info">
                                        <div class="level1-item-l-menu-info-title">{{item.title}}</div>
                                        <div class="level1-item-l-menu-info-detail">{{item.detail}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="level1-item-r">
                                <div class="level1-item-r-box" v-for="(item,index) in level1Btns" :key="index">
                                    <div class="level1-item-r-box-title">{{item.title}}</div>
                                    <div class="level1-item-r-box-info">
                                        <div class="level1-item-r-box-info-item" v-for="(item2,index2) in item.child" :key="index2">
                                            <div class="level1-item-r-box-info-item-title">{{item2.title}}</div>
                                            <div class="level1-item-r-box-info-item-detail">{{item2.detail}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>   -->  
                    <span @click="scrollToElement(`targetElement`)">平台数据</span>
                    <span @click="scrollToElement(`targetInfo`)">功能简介</span>
                    <span @click="scrollToElement(`targetChannel`)">合作共赢</span>
                    <span @click="scrollToElement(`targetTeam`)">团队介绍</span>
                    <span @click="scrollToElement(`targetCity`)">城市合伙人</span>
                    <span @click="scrollToElement(`targetBrand`)">合作品牌</span>
                    <!-- <span class="custom-btn">&nbsp;</span>
                    <span class="drop-btn level2-btn">下拉菜单<i class="el-icon-arrow-down"></i>
                        <div class="level2-item">
                            <div class="level2-item-menu">菜单1</div>
                            <div class="level2-item-menu">菜单2</div>
                        </div>
                    </span>
                    <span>一级菜单菜</span>
                    <span class="drop-btn level2-btn">下拉菜单<i class="el-icon-arrow-down"></i>
                        <div class="level2-item">
                            <div class="level2-item-menu">菜单1</div>
                            <div class="level2-item-menu">菜单2</div>
                        </div>
                    </span>
                    <span class="drop-btn level2-btn">下拉菜单<i class="el-icon-arrow-down"></i>
                        <div class="level2-item">
                            <div class="level2-item-menu">菜单1</div>
                            <div class="level2-item-menu">菜单2</div>
                            <div class="level2-item-menu">菜单3</div>
                            <div class="level2-item-menu">菜单4</div>
                        </div>
                    </span>
                    <span>一级菜单菜</span> -->
                    
                </div>
                </div>
                <div class="nav-top-btns">
                    <div class="btn login-btn level2-btn drop-btn" @click="isLoginVisible = true">后台登录
                        <div class="login-box" v-if="isLoginVisible">
                            <el-form ref="form" :model="form" label-width="0px">
                              <el-form-item label="">
                                <el-input v-model="form.username" placeholder="用户名" @keydown.enter.native="onLogin"></el-input>
                              </el-form-item>
                              <el-form-item label="">
                                <el-input v-model="form.password"  placeholder="密码" show-password @keydown.enter.native="onLogin"></el-input>
                              </el-form-item>
                              <el-form-item>
                                <el-button type="primary" @click="onLogin">立即登录</el-button>
                                <el-button @click.stop="isLoginVisible = false">取消</el-button>
                              </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="btn free-btn" @click="onFreeLogin">免费试用</div>
                </div>
            </div>
            
        </div>
        <div class="mian-top" style="margin-top: 80px;">
            <div class="main-banner">
                <div class="banner-content" v-for="(item,index) in bannerList" :key="index">
                    <transition name="fade">
                        <img v-show="pointIndex == index" :src="item.img" alt="" />
                    </transition>
                    <i class="banner-btn el-icon-arrow-left" @click="changebanner('prev')"></i>
                    <i class="banner-btn el-icon-arrow-right" @click="changebanner('next')"></i>
                </div>
                <div class="dot-point">
                    <div class="dot-point-item" @click="pointIndex = index" :class="pointIndex == index ? 'dot-point-item-active' : ''" v-for="(item,index) in bannerList" :key="index"></div>
                </div>
                
            </div>
            <!-- <div class="card-banner">
                <div class="card-banner-main" :style="{ transform: `translateX(${translateY}px)` } ">
                    <div class="card-banner-main-item" v-for="(item,index) in cardList" :key="index">
                        <div class="card-banner-main-item-title">{{item.title}}</div>
                        <div class="card-banner-main-item-detail">{{item.detail}}</div>
                        <div class="card-banner-main-item-btn">
                            点击查看
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="card-dot-point">
                    <div class="card-dot-point-item" :class="cardPointerIndex == 0 ? 'card-dot-point-item-active' : ''" @click="cardChange(0)"></div>
                    <div class="card-dot-point-item" :class="cardPointerIndex == 1 ? 'card-dot-point-item-active' : ''" @click="cardChange(1)"></div>
                </div>
            </div> -->
        </div>
        
        <div class="data-card" ref="targetElement">
            <div class="data-card-item" v-for="(item,index) in dataList" :key="index">
                <div class="data-card-item-logo">
                    <img :src="item.logo" alt="" />
                </div>
                <div class="data-card-item-info">
                    <span class="data-card-item-info-title">{{item.title}}
                        <span class="data-card-item-info-unit">{{item.unit}}</span>
                    </span>
                    <span class="data-card-item-info-detail">{{item.detail}}</span>
                </div>
            </div>
        </div>
        <div class="small-tips">*数据统计时间截止2024年3月</div>
        <div class="bigger-tips" ref="targetInfo">八大核心产品能力</div>
        <div class="middle-tips">让赛事举办变的如此简单，省钱、省事、省力！</div>
        <div class="info-box" style="margin-top: 50px;">
            <div class="info-item" v-for="(item,index) in infoList" :key="index">
                <!-- <div class="info-item-title">{{item.title}}</div> -->
                <div class="info-item-main">
                    
                    <div class="info-item-main-r">
                        <div 
                            class="info-item-main-r-item" 
                            :class="item.activeIndex == index3 ? 'info-active' : ''" 
                            v-for="(info,index3) in item.child.slice(0,4)" 
                            :key="index3"
                            @mouseover="item.activeIndex = index3"
                        >
                            <div class="info-item-main-r-item-tag" :class="item.activeIndex == index3 ? 'info-item-main-r-item-tag-act' : ''">{{info.tag}}</div>
                            <div class="info-item-main-r-item-title">{{info.title}}</div>
                            <div class="info-item-main-r-item-detail">{{info.detail}}</div>
                        </div>
                    </div>
                    <div class="info-item-main-m">
                        
                            <div class="info-item-main-l-img" v-for="(img,index2) in item.child" :key="index2">
                                <transition name="slide-fade" mode="out-in">
                                <img v-if="item.activeIndex == index2" :src="img.img" alt="" />
                                </transition>
                            </div>
                        
                    </div>
                    <div class="info-item-main-l">
                        <div
                            class="info-item-main-r-item" 
                            :class="item.activeIndex == index3+4 ? 'info-active' : ''" 
                            v-for="(info,index3) in item.child.slice(4)" 
                            :key="index3"
                            @mouseover="item.activeIndex = index3+4"
                        >
                            <div class="info-item-main-r-item-tag" :class="item.activeIndex == index3+4 ? 'info-item-main-r-item-tag-act' : ''">{{info.tag}}</div>
                            <div class="info-item-main-r-item-title">{{info.title}}</div>
                            <div class="info-item-main-r-item-detail">{{info.detail}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bigger-tips" ref="targetChannel">合作共赢</div>
        <div class="middle-tips">合作共赢，共享数据，共享渠道，举办赛事，多方收益</div>
        <div class="channel-box">
            <div 
                class="channel-box-item" 
                :class="channelIndex == index ? 'channel-box-item-active' : ''" 
                v-for="(item,index) in channelList" 
                :key="index" 
                @mouseover="channelIndex = index"
            >
            <transition name="fade">
                <div class="normal-item" v-if="channelIndex !== index">
                    <i :class="item.icon"></i>
                    <span class="channel-box-item-title">{{item.title}}</span>
                    <p  class="channel-box-item-info" v-html="item.info"></p>
                    <img class="channel-box-item-img" :src="item.img" alt="" />
                </div>
                <div class="actvie-item" v-else>
                    <div class="actvie-item-nav">
                        <i class="actvie-item-nav-icon" :class="item.icon"></i>
                        <span class="channel-box-item-title">{{item.title}}</span>
                    </div>
                    <p  class="channel-box-item-info-active" v-html="item.info"></p>
                    <img class="channel-box-item-img-active" :src="item.img" alt="" />
                    <div class="channel-box-item-btn" @click="dialogVisible = true">我要加入</div>
                </div>
            </transition>
            </div>
        </div>
        
        <div class="bigger-tips" ref="targetTeam">团队介绍</div>
        <div class="middle-tips">*排名不分先后</div>
        <div class="card-banner">
            <div class="card-banner-main" :style="{ transform: `translateX(${translateY}px)` } ">
                <div class="card-banner-main-item" v-for="(item,index) in cardList" :key="index">
                    <span class="card-banner-icon">”</span>
                    <el-image :size="50" :src="item.logo" fit="cover" style="width: 100px;height: 100px;"></el-image>
                    <div class="card-banner-main-item-title">{{item.title}}</div>
                    <div class="card-banner-main-item-detail">{{item.detail}}</div>
                </div>
            </div>
            <div class="card-dot-point">
                <div 
                class="card-dot-point-item" 
                :class="cardPointerIndex == index ? 'card-dot-point-item-active' : ''" 
                @click="cardChange(index)" 
                v-for="(item,index) in paginatedCardList" 
                :key="index"></div>
            </div>
        </div>
        
        <div class="bigger-tips" ref="targetCity">城市合伙人</div>
        <div class="middle-tips">城市合伙人简介</div>
        <div class="city-box">
            <div class="city-box-left" ref="mapRef">
                
            </div>
            <div class="city-box-right">
                <div class="city-member-box card-banner-main-item1">
                    <div v-if="chooseCityInfo.title !== ''">
                        <span class="card-banner-icon">”</span>
                        <el-image :size="50" :src="chooseCityInfo.logo || graylogo" fit="cover" style="width: 100px;height: 100px;"></el-image>
                        <div class="card-banner-main-item-title">{{chooseCityInfo.title}} · {{chooseCityInfo.detail}}</div>
                        <div class="card-banner-main-item-detail"></div>
                        <div class="card-banner-main-item-detail">{{chooseCityInfo.tel}}</div>
                    </div>
                    <div v-else>
                        <div class="card-banner-main-item-detail">{{chooseCityInfo.detail}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bigger-tips" ref="targetBrand">他们都在用斗战</div>
        <div class="middle-tips">*排名不分先后</div>
        <div class="brand-box">
            <div class="brand-box-item slide1"  ref="scrollContainer1" @scroll="handleScroll1">
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[0]" :key="'original-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
                  <!-- 图片集副本 -->
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[0]" :key="'duplicate-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
            </div>
            <div class="brand-box-item slide2"  ref="scrollContainer2" @scroll="handleScroll1">
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[1]" :key="'original-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
                  <!-- 图片集副本 -->
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[1]" :key="'duplicate-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
            </div>
            <div class="brand-box-item slide3"  ref="scrollContainer3" @scroll="handleScroll1">
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[2]" :key="'original-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
                  <!-- 图片集副本 -->
                <div class="brand-box-item-img" v-for="(item, index) in dividedBrandImages[2]" :key="'duplicate-' + index">
                    <el-image :src="item" style="width: 100%; height: 100%;"></el-image>
                </div>
            </div>
        </div>
        
        
        
        <div style="height: 100px;"></div>
        <div style="color: #fff;background-color: #0067f2;height: 50px;line-height: 50px;">Copyright © 斗战 2017-2024 All Rights Reserved.版权所有 豫ICP备18004771号-1</div>
        <div class="kefu_box" v-if="isKefuVisible">
            <div class="close_box" @click="isKefuVisible = false"></div> 
            <img src="@/assets/images/kefu.png" />
            <div class="top_box" @click="toTop"></div> 
        </div>
        <el-dialog
          title="加入我们"
          :visible.sync="dialogVisible"
          width="20%"
          >
          <div class="join-box">
              <img src="@/assets/images/gt_qrcode.png" alt="" />
              <span class="join-box-title">郭涛</span>
              <span class="join-box-tel">15090007950</span>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import echarts from 'echarts';
    import mapJson from 'echarts/map/json/china.json';
    export default {
        data() {
            return {
                scrollInterval: null,
                scrollInterval2: null,
                scrollInterval3: null,
                brands: [],
                option: {},
                mapRef: null,
                form: {
                    username: "",
                    password: ""
                },
                isLoginVisible: false,
                isKefuVisible: true,
                dialogVisible: false,
                translateY: 0,
                pointIndex: 0,
                cardPointerIndex: 0,
                chooseCity: "河南",
                chooseCityInfo: {
                    logo: "",
                    title: "",
                    detail: ""
                },
                graylogo: require('@/assets/images/graylogo.jpg'),
                level1Menu: [
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题1",
                        detail: "菜单1的一行简介十二个字"
                    },
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题2",
                        detail: "菜单2的一行简介"
                    },
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题3",
                        detail: "菜单3的一行简介"
                    },
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题4",
                        detail: "菜单4的一行简介"
                    },
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题5",
                        detail: "菜单5的一行简介"
                    },
                    {
                        logo: "https://pic1.zhimg.com/v2-c1ddf1ed5ae263da6be3e36e25863381_is.jpg?source=1940ef5c",
                        title: "菜单标题6",
                        detail: "菜单6的一行简介"
                    }
                ],
                level1Btns: [
                    {
                        title: "菜单1标题",
                        child: [
                            {
                                title: "子菜单1",
                                detail: "子菜单1的描述"
                            },
                            {
                                title: "子菜单2",
                                detail: "子菜单2的描述"
                            },
                            {
                                title: "子菜单3",
                                detail: "子菜单3的描述"
                            },
                            {
                                title: "子菜单4",
                                detail: "子菜单4的描述"
                            },
                            {
                                title: "子菜单5",
                                detail: "子菜单5的描述"
                            }
                        ]
                    },
                    {
                        title: "菜单2标题",
                        child: [
                            {
                                title: "子菜单1",
                                detail: "子菜单1的描述"
                            },
                            {
                                title: "子菜单2",
                                detail: "子菜单2的描述"
                            },
                            {
                                title: "子菜单3",
                                detail: "子菜单3的描述"
                            },
                            {
                                title: "子菜单4",
                                detail: "子菜单4的描述"
                            },
                            {
                                title: "子菜单5",
                                detail: "子菜单5的描述"
                            }
                        ]
                    }
                ],
                bannerList: [
                    {
                        img: require("../../assets/images/1.jpg")
                    },
                    {
                        img: require("../../assets/images/2.jpg")
                    },
                    {
                        img: require("../../assets/images/3.jpg")
                    },
                    {
                        img: require("../../assets/images/4.jpg")
                    },
                ],
                interval: null,
                cardInterval: null,
                cityInfo: {},
                cardList: [
                    {
                        logo: require("@/assets/images/c1.jpg"),
                        title: "郭涛",
                        detail: "斗战创始人兼CEO，安阳市体育总会常务副会长，安阳市篮球协会秘书长，我最开心的是我最爱的运动成为了我的事业，我想打造一家综合性的赛事运动平台，为所有爱好运动的人提供满意的服务。我喜欢王阳明，非常认同知行合一，人须在事上磨炼，方立得住。不管做什么，始终需要坚持不懈，哪怕结局不利，又有如何。"
                    },
                    {
                        logo: require("@/assets/images/c2.jpg"),
                        title: "陈旭辉",
                        detail: "斗战合伙人，青春虽然散尽，但梦想永不熄灭！热爱篮球的我们终将成为一生的朋友！我是郑州航空港经济综合实验区篮球协会秘书长，同时也是河南篮球自媒体平台《非常篮球》创始人、赛事级篮球直播平台《斗战篮球》负责人，身份很多，但热爱唯有篮球，专业专注，成就你我，我是陈旭辉！"
                    },
                    {
                        logo: require("@/assets/images/c3.jpg"),
                        title: "申泽华",
                        detail: "斗战合伙人，热爱篮球运动使我们相聚一堂，因为篮球让我们相知相识。我是申泽华，是安阳市体育总会理事，同时也是斗战公司的架构师。在今后时间里我会以知行合一，砥砺前行为准则，写出更优秀的代码，为热爱篮球运动，喜爱篮球运动的大家，提供更好的服务。"
                    },
                    {
                        logo: require("@/assets/images/c4.jpg"),
                        title: "朱淼鑫",
                        detail: "斗战合伙人，我是一名资深前端工程师，做前端工作已经10年之久，有幸能够和伙伴们一起共同创建斗战篮球赛事平台，为热爱篮球的团体和个人提供更专业的篮球赛事；斗战，让你的参与更有价值，快来加入我们吧！"
                    },
                    {
                        logo: require("@/assets/images/c5.png"),
                        title: "张恩铭",
                        detail: "斗战合伙人，兼平面设计师、UI设计师、插画师。我很年轻，个性随和开朗稳重，心地善良，但不太活跃，喜欢参加活动，但不太喜欢喧闹的场面，喜欢随遇而安。做事认真谦虚谨慎，对工作认真负责，积极进取，个性乐观执着，敢于面对困难与挑战。我喜欢撸猫！"
                    },
                    {
                        logo: require("@/assets/images/c6.jpg"),
                        title: "李小宁",
                        detail: "斗战合伙人，JAVA工程师，负责斗战整体后台设计。我喜欢滑板运动，我一直想在平台出一个专门的滑板区，我觉得很快这个愿望就会实现，因为，不给我实现，我打算不写了！我很佛系，喜欢盘手串！"
                    }
                ],
                dataList: [
                    {
                        logo: require("../../assets/images/icon1.jpg"),
                        title: "10",
                        unit: "万人",
                        detail: "注册球员数"
                    },
                    {
                        logo: require("../../assets/images/icon2.jpg"),
                        title: "100",
                        unit: "万人",
                        detail: "最高同时在线"
                    },
                    {
                        logo: require("../../assets/images/icon3.jpg"),
                        title: "500",
                        unit: "家",
                        detail: "主办方合作"
                    },
                    {
                        logo: require("../../assets/images/icon4.jpg"),
                        title: "2000",
                        unit: "次",
                        detail: "举办赛事数"
                    }
                ],
                infoList: [
                    {
                        title: "功能介绍点一",
                        child: [
                            {
                                img: require("@/assets/images/g1.jpg"),
                                tag: "引流曝光",
                                title: "微信转发观看，让身边朋友及时知道赛事动态",
                                detail: "支持微信转发功能，及时收到赛事信息提醒，让用户帮你告诉其他人，增加曝光率。"
                            },
                            {
                                img: require("@/assets/images/g2.jpg"),
                                tag: "软硬一体",
                                title: "技术台通过软件硬件一体，让赛事记录简单",
                                detail: "通过斗战一体记录操作，一个人就可以在技术台操作，计时计分，省钱又省力。"
                            },
                            {
                                img: require("@/assets/images/g3.jpg"),
                                tag: "实时通知",
                                title: "任何比赛任何信息，通过微信实时接收",
                                detail: "从创建比赛活动开始，用户就能收到比赛详细信息和通知，不用实时关注就可及时知道。"
                            },
                            {
                                img: require("@/assets/images/g4.jpg"),
                                tag: "内容付费",
                                title: "提供多种内容形式，把内容变成付费产品",
                                detail: "视频回放，视频剪辑，直播打赏，让用户付费和购买，实现内容产品化，增加收益。"
                            },
                            {
                                img: require("@/assets/images/g5.jpg"),
                                tag: "管理后台",
                                title: "简单操作用户后台，让组织比赛变的简单",
                                detail: "主办方通过赛事管理后台，可以创建比赛，创建球队，让比赛从报名到设计赛程表，再到比赛记录，变的简单。"
                            },
                            {
                                img: require("@/assets/images/g6.jpg"),
                                tag: "赛事丰富",
                                title: "多赛事种类，多操作模式",
                                detail: "除了支持各种球类竞技运动外，还支持其他活动类型，也可以自由定制自己的赛事系统，让你成为赛事活动中佼佼者。"
                            },
                            {
                                img: require("@/assets/images/g7.jpg"),
                                tag: "志同道合",
                                title: "每到一个陌生城市，你会了解不一样的事情",
                                detail: "通过附近，可以搜索这个地方的球馆，活动，比赛信息，还可以约球，约朋友，让你的生活不再枯燥，不再孤独一人。"
                            },
                            {
                                img: require("@/assets/images/g8.jpg"),
                                tag: "官方合作",
                                title: "为官方免费建立第三方体育合作平台",
                                detail: "可以为官方建立一体化服务平台，构建自己本地大数据平台，和官方建立深度合作，为官方提供有力的内容价值。"
                            }
                        ],
                        activeIndex: 0
                    }
                ],
                channelList: [
                    {
                        title: "保险收益",
                        info: "每卖出一份保险<br>就有一份收益",
                        img: require("@/assets/images/baoxian.png"),
                        icon: "el-icon-unlock",
                    },
                    {
                        title: "回放收益",
                        info: "每观看一次回放<br>就有一份收益",
                        img: require("@/assets/images/huifang.png"),
                        icon: "el-icon-video-play",
                    },
                    {
                        title: "人气收益",
                        info: "每增加一次人气<br>就有一次收益",
                        img: require("@/assets/images/shouyi.png"),
                        icon: "el-icon-data-analysis",
                    },
                    {
                        title: "竞猜收益",
                        info: "每竞猜一次<br>就获得额外收益",
                        img: require("@/assets/images/jingcai.png"),
                        icon: "el-icon-s-opportunity",
                    },
                    {
                        title: "商城销售",
                        info: "让你自己的产品<br>直通用户手里",
                        img: require("@/assets/images/shangcheng.png"),
                        icon: "el-icon-shopping-bag-1",
                    }
                ],
                channelIndex: 2,
            }
        },
        created() {
            this.startAutoCycle();
            this.saveOption();
            this.getCitymember()
            const context = require.context('@/assets/images/brand/', false, /\.(png|jpe?g|svg)$/);
            this.brandImages = context.keys().map(key => context(key));
        },
        mounted() {
            this.mapRef = echarts.init(this.$refs.mapRef);
                echarts.registerMap('china', mapJson);
                this.getData();
                this.mapRef.on('click', params => {
                this.chooseCity = params.name
                this.getCitymember()
                if (params.componentType === 'geo') {
                    // 重置regions使所有省份恢复默认颜色
                    const resetRegions = this.mapRef.getOption().geo[0].regions.map((region) => {
                      return {
                        name: region.name,
                        itemStyle: {
                          areaColor: {
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                              {
                                offset: 0,
                                color: '#073684' // 0% 处的颜色
                              },
                              {
                                offset: 1,
                                color: '#061E3D' // 100% 处的颜色
                              }
                            ]
                          }
                        }
                      };
                    });
                
                    // 设置点击省份的新颜色
                    const clickedRegion = {
                      name: params.name,
                      itemStyle: {
                        areaColor: {
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: '#f38f01' // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: '#f9c013' // 100% 处的颜色
                            }
                          ]
                        }
                      },
                      
                    };
                
                    // 更新option中的geo组件的regions属性
                    this.mapRef.setOption({
                      geo: {
                        regions: resetRegions.concat(clickedRegion) // 合并更新后的regions数组
                      }
                    });
                }
                if (params.componentType === 'series') {
                    const dataIndex = params.dataIndex;
                    const seriesIndex = params.seriesIndex;
                    const currentShowLabel = this.option.series[seriesIndex].data[dataIndex].label.show;
                    console.log('currentShowLabel', currentShowLabel);
                    this.option.series[seriesIndex].data[dataIndex].label.show = !currentShowLabel;
                    this.mapRef.setOption(this.option, true);
                }
            });
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.echartsResize, false);
            this.startAutoScroll();
        },
        beforeDestroy() {
            this.stopAutoScroll();
            this.stopAutoCycle();
            window.removeEventListener('scroll', this.handleScroll);
            clearInterval(this.intervalId);
        },
        methods: {
            scrollToElement(el) {
                const targetElement = this.$refs[el];
                
                  if (targetElement) {
                    // 使用原生的scrollIntoView方法滚动页面
                    window.scrollTo({
                      top: targetElement.offsetTop - 80,
                      behavior: 'smooth',
                    });
                }
            },
            toTop() {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
            },
            changebanner(m) {
                let bannerNum = this.bannerList.length - 1
                if(m == "next") {
                    if (this.pointIndex < bannerNum) {
                        this.pointIndex++
                    }else {
                        this.pointIndex = 0
                    }
                } else{
                    if (this.pointIndex > 0) {
                        this.pointIndex--
                    }else {
                        this.pointIndex = bannerNum
                    }
                }
            },
            startAutoCycle() {
                this.interval = setInterval(() => {
                    this.changebanner('next');
                }, 5000);
                
                // this.cardInterval = setInterval(() => {
                //     let index = Math.abs(this.cardPointerIndex - 1) 
                //     this.cardChange(index);
                // }, 8000);
            },
            stopAutoCycle() {
                clearInterval(this.interval);
                clearInterval(this.cardInterval);
            },
            cardChange(index) {
                this.translateY = index * -1300;
                this.cardPointerIndex = index
            },
            handleScroll() {
                const navbar = document.querySelector('.nav-top');
                if (window.scrollY > 10) {
                    navbar.classList.add('nav-top-scrolled');
                } else {
                    navbar.classList.remove('nav-top-scrolled');
                }
            },
            toLogin() {
                this.$router.push({ path: '/e'});
            },
            onLogin() {
                let formData = new FormData();
                formData.append('username', this.form.username);
                formData.append('password', this.form.password);
                this.$api.post('playv2/login', formData).then(response => {
                  if (response.data.code == 1) {
                      this.$message({
                          type: 'success',
                          message: '登录成功'
                      });
                      localStorage.setItem('userinfo', JSON.stringify(response.data.data));
                      localStorage.setItem('tips_show', true);
                      this.$store.dispatch('setLogin', { username: this.form.username, timestamp: new Date().getTime() });
                      this.$router.replace({ path: '/' });
                  } else {
                      this.$message({
                          type: 'error',
                          message: response.data.data
                      });
                  }
                  
                  
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            onFreeLogin() {
                let formData = new FormData();
                formData.append('username', "wuji");
                formData.append('password', "123456");
                this.$api.post('playv2/login', formData).then(response => {
                  if (response.data.code == 1) {
                      this.$message({
                          type: 'success',
                          message: '登录成功'
                      });
                      localStorage.setItem('userinfo', JSON.stringify(response.data.data));
                      this.$store.dispatch('setLogin', { username: this.form.username, timestamp: new Date().getTime() });
                      this.$router.replace({ path: '/' });
                  } else {
                      this.$message({
                          type: 'error',
                          message: response.data.data
                      });
                  }
                  
                  
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            getCitymember() {
                switch(this.chooseCity) {
                    case "河南":
                        this.chooseCityInfo.logo = require("@/assets/images/c2.jpg");
                        this.chooseCityInfo.title = "陈旭辉";
                        this.chooseCityInfo.detail = "河南地区城市合伙人";
                        this.chooseCityInfo.tel = "17737171207";
                        break;
                    case "浙江":
                        this.chooseCityInfo.logo = ''
                        this.chooseCityInfo.title = "刘宸";
                        this.chooseCityInfo.detail = "浙江杭州市地区城市合伙人";
                        this.chooseCityInfo.tel = "1767118529";
                        break;
                    case "河北":
                        this.chooseCityInfo.logo = ''
                        this.chooseCityInfo.title = "索晓龙"
                        this.chooseCityInfo.detail = "河北邯郸市地区城市合伙人";
                        this.chooseCityInfo.tel = "15175055033";
                        break;
                    default:
                        this.chooseCityInfo = {
                            logo: "",
                            title: "",
                            detail: "等你加入城市合伙人"
                        };
                        break;
                }
            },
            echartsResize() {
                  this.mapRef.resize();
            },
            saveOption() {
                const option = {
                    grid: {
                       left: '0px',
                       right: '80px',
                       top: '10px',
                       bottom: '10px'
                    },
                    geo: {
                        map: 'china',
                        regions: [{
                            name: '河南', // 将 '某省' 替换成你想要高亮的省份名称，比如 '浙江'
                                itemStyle: {
                                    areaColor: {
                                      x: 0,
                                      y: 0,
                                      x2: 0,
                                      y2: 1,
                                      colorStops: [
                                        {
                                          offset: 0,
                                          color: '#f38f01' // 0% 处的颜色
                                        },
                                        {
                                          offset: 1,
                                          color: '#f9c013' // 100% 处的颜色
                                        }
                                      ]
                                    }
                                }
                        }],
                       zoom: 1,
                       center: [102.848234, 32.82333],
                       scaleLimit: {
                         min: 1,
                         max: 2
                      },
                      label: {
                        color: '#fff',
                        show: true
                      },
                      emphasis: {
                        label: {
                          color: '#fff',
                          show: true
                        },
                        itemStyle: {
                          areaColor: {
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                              {
                                offset: 0,
                                color: '#f38f01' // 0% 处的颜色
                              },
                              {
                                offset: 1,
                                color: '#f9c013' // 100% 处的颜色
                              }
                            ]
                          }
                        }
                      },
                      roam: false,
                      itemStyle: {
                        areaColor: {
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: '#073684' // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: '#061E3D' // 100% 处的颜色
                            }
                          ]
                        },
                        borderColor: new echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [
                            {
                              offset: 0,
                              color: '#00F6FF'
                            },
                            {
                              offset: 1,
                              color: '#87ADCB'
                            }
                          ],
                          false
                        ),
                        shadowColor: 'rgba(10,76,139,.0)',
                        shadowOffsetY: 0,
                        shadowBlur: 60,
                        borderWidth: 1
                      },
                      tooltip: {
                        show: false
                      }
                },
                };
                this.option = option;
            },
            getData() {
              this.mapRef.setOption(this.option);
            },
            handleScroll1() {
              const container = this.$refs.scrollContainer1;
              const singleSetWidth = container.firstChild.clientWidth * this.dividedBrandImages[0].length;
        
              // 当滚动超过第一组图片宽度时，复位滚动位置
              if (container.scrollLeft >= singleSetWidth) {
                container.scrollLeft -= singleSetWidth;
              }
            },
            startAutoScroll() {
                const container = this.$refs.scrollContainer1;
                const maxScroll = container.scrollWidth - container.clientWidth;
        
                this.scrollInterval = setInterval(() => {
                    if (container.scrollLeft >= maxScroll) {
                        container.scrollLeft = 0; // 重置到开始位置
                    } else {
                        container.scrollLeft += 1; // 向左滚动
                    }
                }, 10); // 10毫秒间隔滚动1像素
                
                const container2 = this.$refs.scrollContainer2;
                // const maxScroll2 = container2.scrollWidth + container2.clientWidth;
                        
                this.scrollInterval2 = setInterval(() => {
                    if (container2.scrollLeft <= 0) {
                      container2.scrollLeft = container2.scrollWidth / 2; // 如果滚动到最左边，将其设置为中间点
                    } else {
                      container2.scrollLeft -= 1; // 向右滚动
                    }
                }, 10); // 10毫秒间隔滚动1像素
                
                const container3 = this.$refs.scrollContainer3;
                const maxScroll3 = container3.scrollWidth - container3.clientWidth;
                        
                this.scrollInterval3 = setInterval(() => {
                    if (container3.scrollLeft >= maxScroll3) {
                        container3.scrollLeft = 0; // 重置到开始位置
                    } else {
                        container3.scrollLeft += 2; // 向左滚动
                    }
                }, 10); // 10毫秒间隔滚动1像素
            },
            stopAutoScroll() {
              clearInterval(this.scrollInterval); // 清除定时器
              clearInterval(this.scrollInterval2); // 清除定时器
              clearInterval(this.scrollInterval3); // 清除定时器
            }
            
        },
        computed: {
            paginatedCardList() {
                const chunkSize = 3;
                const chunks = [];
            
                for (let i = 0; i < this.cardList.length; i += chunkSize) {
                  chunks.push(this.cardList.slice(i, i + chunkSize));
                }
            
                return chunks;
            },
            portions() {
                  const total = this.brandImages.length;
                  let base = Math.floor(total / 3);
                  let remainder = total % 3;
                  let portions = [base, base, base];
            
                  // 余数按顺序分配给第一份和第二份，第三份不变
                  for (let i = 0; i < remainder; i++) {
                    portions[i]++;
                  }
            
                  return portions;
            },
            dividedBrandImages() {
                  let position = 0;
                  return this.portions.map(portionSize => {
                    // 使用slice来基于位置和部分的大小来切分数组
                    let part = this.brandImages.slice(position, position + portionSize);
                    position += portionSize;
                    return part;
                  });
            },
            imagesWithDuplicates() {
                return [...this.dividedBrandImages[0], ...this.dividedBrandImages[0]]; // 复制一遍图片以实现无缝连接
            }
        },
    }
</script>

<style scoped>
    .nav-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        line-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease;
        z-index: 9;
        background-color: #fff;
    }
    
    .nav-top-scrolled {
        background-color: #fff;
    }
    
    .nav-top-scrolled .nav-top-menu span {
        color: #000;
        
    }
    
    .nav-top-scrolled .login-btn {
        border: 1px solid #1472ff;
    }
    
    .nav-top-scrolled .nav-top-logo {
        background-image: url("../../assets/images/logo.png");
    }
    
    .nav-top:hover {
        background-color: #fff;
    }
    
    .nav-top:hover .nav-top-menu span {
        color: #000;
    }
    
    .nav-top:hover .login-btn {
        border: 1px solid #1472ff;
    }
    
    .nav-top-logo {
        height: 50px;
        margin-right: 50px;
        width: 160px;
    }
    
    .nav-top-logo img {
        height: 100%;
    }
    
    .nav-top:hover .nav-top-logo {
        background-image: url("../../assets/images/logo.png");
    }
    
    .nav-top-logo {
        background-image: url("../../assets/images/logo_white.png");
        background-size: 100% 100%;
    }
    
    .nav-top-menu span {
        margin-right: 20px;
        margin-left: 20px;
        cursor: pointer;
        font-size: 15px;
        color: #000;
        height: 80px;
        display: inline-block;
    }
    
    .nav-top-menu span:hover {
        color: #1472ff!important;
    }
    
    .nav-top-btns {
        display: flex;
        margin-left: 40px;
    }
    
    .nav-top-btns .btn {
        width: 80px;
        height: 30px;
        line-height: 30px;
        border-radius: 30px;
        padding: 0px 10px;
        margin-right: 20px;
        cursor: pointer;
    }
    
    .login-btn {
        
        color: #1472ff;
        background-color: #fff;
        border: 1px solid #1472ff;
    }
    
    .free-btn {
        color: #fff;
        background-image: linear-gradient(to right,rgb(247, 167, 83),rgb(246, 135, 81));
        border: 1px solid #f7a753;
    }
    .main-banner {
        width: 100%;
        height: calc(100vh / 2);
        position: relative;
    }
    
    .level1-item {
        width: 1220px;
        position: absolute;
        height: 0px;
        background-color: #fff;
        top: 80px;
        border-top: 0px;
        border-radius: 0px 0px 10px 10px;
        left: -270px;
        right: 0;
        margin: auto;
        transition: 0.2s ease-in-out;
        overflow: hidden;
        display: flex;
        padding: 0px 40px;
    }
    
    .level1-btn:hover .level1-item {
        height: 460px;
        border-top: 2px solid #1472ff;
        padding: 20px 40px;
    }
    
    .level1-btn {
        position: relative;
    }
    
    
    
    .el-icon-arrow-down {
        transition: transform 0.2s ease;
    }
    
    .drop-btn:hover .el-icon-arrow-down {
        transform: rotate(180deg);
    }
    
    .level1-item-l {
        width: 30%;
        height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        border-right: 1px solid #eaeaea;
    }
    
    .level1-item-r {
        width: 70%;
        color: #000;
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        padding-left: 60px;
    }
    
    .level1-item-l-menu {
        display: flex;
        height: 80px;
        line-height: 25px;
        align-items: center;
        
        
    }
    
    .level1-item-l-menu:hover .level1-item-l-menu-info-title{
        color: #1472ff;
    }
    
    .level1-item-l-menu:hover .level1-item-l-menu-info-detail{
        color: #000;
    }
    
    .level1-item-l-menu-logo {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    
    .level1-item-l-menu-info {
        text-align: left;
        margin-left: 20px;
    }
    .level1-item-l-menu-info-title {
        font-weight: bold;
        color: #000;
        font-size: 18px;
        margin-top: 8px;
        transition: 0.2s ease;
    }
    
    .level1-item-l-menu-info-detail {
        color: #969696;
        font-size: 14px;
        transition: 0.2s ease;
    }
    
    .level1-item-r-box-title {
        width: 100%;
        text-align: left;
        border-left: 3px solid #1472ff;
        height: 15px;
        line-height: 15px;
        padding-left: 10px;
        font-weight: bold;
        color: #666;
        margin: 30px 0px;
    }
    
    .level1-item-r-box-info {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 20px;
    }
    
    
    .level1-item-r-box-info-item {
        width: calc(100% / 3);
        text-align: left;
        height: 60px;
        line-height: 25px;
        margin-bottom: 12px;
    }
    
    .level1-item-r-box-info-item:hover .level1-item-r-box-info-item-title{
        color: #1472ff;
    }
    
    .level1-item-r-box-info-item:hover .level1-item-r-box-info-item-detail{
        color: #000;
    }
    
    .level1-item-r-box-info-item-title {
        font-size: 18px;
        font-weight: bold;
        transition: 0.2s ease;
    }
    
    .level1-item-r-box-info-item-detail {
        font-size: 14px;
        color: #969696;
        transition: 0.2s ease;
    }
    
    .custom-btn {
        width: 70px;
        height: 65px!important;
        display: inline-block;
        background-image: url('../../assets/images/activity.gif');
        background-size: 100% 35px;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .level2-item {
        width: 150px;
        height: 150px;
        position: absolute;
        background-color: #fff;
        left: 50%;
        bottom: -202px;
        transform: translateX(-50%);
        border-radius: 0px 0px 5px 5px;
        transition: 0.1s ease;
        overflow: hidden;
        line-height: 60px;
        padding: 0px 0px;
        border: 1px solid #ccc;
        display: none;
    }
    
    .level2-item-menu {
        height: 50px;
        line-height: 50px;
        color: #000;
        
    }
    
    .level2-item-menu:hover {
        color: #1472ff;
    }
    
    /* .level2-btn:active .level2-item {
        display: block;
        border-top: 2px solid #1472ff;
        padding: 20px 0px;
    } */
    
    .level2-btn {
        position: relative;
    }
    
    /* .level2-btn:active::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #1472ff;
    } */
    
    .level1-btn:hover::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0; /* 宽度为0 */
      height: 0; /* 高度为0 */
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #1472ff;
    }
    
    .banner-content {
        max-height: auto;
    }
    
    .banner-content:hover .banner-btn {
        display: block!important;
    }
    
    .banner-content img {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: 0.5s ease-in-out;
        
    }
    
    .dot-point {
        height: 10px;
        width: auto;
        display: flex;
        position: absolute;
        left: 320px;
        bottom: 150px;
        z-index: 3;
    }
    
    .dot-point-item {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #fff;
        opacity: .6;
        margin-right: 8px;
        cursor: pointer;
    }
    
    .dot-point-item-active {
        width: 30px;
        opacity: 1;
    }
    
    .banner-btn {
        display: none!important;
        color: #fff;
        position: absolute;
        bottom: 0;
        cursor: pointer;
        
    }
    
    .el-icon-arrow-left {
        left: 100px;
        font-size: 40px!important;
        top: 40%;
    }
    
    .el-icon-arrow-right {
        right: 100px;
        font-size: 40px!important;
        top: 40%;
    }
    
    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
    
    .card-banner {
        width: 1300px;
        margin: auto;
        position: relative;
        bottom: -50px;
        left: 0;
        right: 0;
        overflow: hidden;
        padding-bottom: 40px;
        padding-top: 20px;
        
    }
    
    .card-banner-main {
        width: 3600px;
        transition: 1s ease;
    }
    
    .card-banner-icon {
        position: absolute;
        right: 50px;
        font-size: 120px;
        color: #ccc;
    }
    
    .card-banner-main-item, .card-banner-main-item1{
        width: 295px;
        background-color: #f7f7f7;
        height: 430px;
        margin-right: 20px;
        padding: 60px;
        border-radius: 10px;
        float: left;
        box-shadow: 0 4px 6px 0 #e2efff;
        cursor: pointer;
        transition: 0.3s ease;
        position: relative;
        display: flex;
        flex-direction: column;
        
    }
    
    .card-banner-main-item:hover {
        transform: translateY(-20px)!important;
        /* background-image: url("../../assets/images/card-background-active.png"); */
        /* color: #fff; */
        box-shadow: none;
    }
    
    .card-banner-main-item:hover .card-banner-main-item-detail,
    .card-banner-main-item:hover .card-banner-main-item-btn
    {
        /* color: #fff!important; */
    }
    
    .card-banner-main-item:hover .card-banner-main-item-btn i {
        background-color: #fff;
        color: #1472ff;
    }
    
    .card-banner-main-item:nth-child(4n) {
        margin-right: 0px;
    }
    
    .card-banner-main-item:nth-child(5n) {
        margin-left: 20px;
    }
    
    .card-banner-main-item-title,.card-banner-main-item-detail {
        width: 100%;
        display: inline-block;
        margin-top: 10px;
        text-align: left;
    }
    
    .card-banner-main-item-detail {
        text-align: justify;
    }
    
    .card-banner-main-item-title {
        line-height: 60px;
        font-size: 26px;
    }
    
    .card-banner-main-item-detail {
        font-size: 18px;
        color: #666;
        margin-top: 8px;
        transition: 0.3s ease;
        
    }
    
    .card-banner-main-item-btn {
        height: 20px;
        line-height: 20px;
        font-size: 12px!important;
        color: #1472ff;
        text-align: right;
        margin-top: 16px;
    }
    
    .card-banner-main-item-btn i {
        font-size: 14px!important;
        background-color: #1472ff;
        color: #fff;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        border-radius: 16px;
    }
    
    .card-dot-point {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-dot-point-item {
        width: 30px;
        height: 6px;
        border-radius: 6px;
        background-color: #d5efff;
        cursor: pointer;
        margin: 0px 4px;
        
    }
    
    .card-dot-point-item-active {
        background-color: #1472ff;
    }
    
    .data-card-item {
        width: 240px;
        height: 80px;
        display: flex;
        padding: 15px 30px;
    }
    
    .data-card {
        width: 1300px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding-bottom: 20px;
        margin-top: 40px;
    }
    
    .data-card-item-logo {
        width: 80px;
        height: 80px;
        line-height: 70px;
        text-align: center;
        background-color: #1472ff;
        color: #fff;
    }
    
    .data-card-item-logo img {
        width: 100%;
        height: 100%;
    }
    
    .data-card-item-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 20px;
    }
    
    .data-card-item-info-title {
        font-size: 40px;
        font-weight: bold;
        margin-top: 6px;
        
    }
    
    .data-card-item-info-unit {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
    }
    
    .data-card-item-info-detail {
        font-size: 15px;
        margin-top: 6px;
    }
    
    .small-tips {
        text-align: center;
        font-size: 12px;
        color: #ccc;
    }
    
    .big-tips {
        font-size: 28px;
        font-weight: bold;
        line-height: 200px;
    }
    
    .bigger-tips {
        font-size: 42px;
        margin-top: 200px;
        margin-bottom: 20px;
    }
    
    .middle-tips {
        font-size: 20px;
    }
    
    .info-item-main {
        width: 1600px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
    }
    
    .info-item-main-l,.info-item-main-m,.info-item-main-r {
        width: 30%;
        position: relative;
    }
    
    .info-item-main-r {
        margin-right: 0px;
    }
    
    .info-item-main-l-img {
        width: 100%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
        overflow: hidden;
        height: 700px;
        /* background-color: #f00; */
    }
    
    .info-item-main-l img {
        width: 100%;
        height: 100%;
        transition: 0.5s ease-in-out;
    }
    
    .info-item-main-r-item {
        display: flex;
        flex-direction: column;
        text-align: left;
        height: 120px;
        border-radius: 15px;
        margin-bottom: 20px;
        padding: 20px 30px;
    }
    
    .info-active {
        border: 1px solid #1472ff;
        color: #1472ff;
        box-shadow: 0 3px 19px 0 #e1eeff;
    }
    
    .info-item-main-r-item-tag {
        background-color: rgb(226, 238, 255);
        color: rgb(95, 120, 156);
        width: 80px;
        font-size: 14px;
        text-align: center;
        height: 24px;
        line-height: 24px;
        border-radius: 0px 24px 24px 24px;
        margin-bottom: 15px;
    }
    
    .info-item-main-r-item-tag-act {
        background-color: #1472ff;
        color: #fff;
    }
    
    .info-item-main-r-item-title {
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    
    .info-item-main-r-item-detail {
        font-size: 14px;
    }
    
    .info-item-title {
        font-size: 26px;
        line-height: 200px;
    }
    
    .slide-fade-enter-active, .slide-fade-leave-active {
      transition: opacity 0.2s ease-in-out, transform 0.4s ease-in-out!important;
    }
    .slide-fade-enter, .slide-fade-leave-to {
      opacity: 0;
      transform: translateY(50px);
    }
    
    .login-box {
        width: 300px;
       
        border-radius: 15px;
        background: #fff;
        /* box-shadow: 0 4px 6px 0 #e2efff; */
        transform: translateX(-40%);
        border: 2px solid #1472ff;
        margin-top: 10px;
        padding: 40px;
    }
    
    .login-box:after {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0; /* 宽度为0 */
        height: 0; /* 高度为0 */
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #1472ff;
    }
    
    .channel-box {
        width: 1300px;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }
    
    .channel-box-item {
        width: 20%;
        border: 1px solid #ccc;
        border-radius: 15px;
        overflow: hidden;
        cursor: pointer;
        transition: 0.1s ease-in-out;
        
    }
    
    .normal-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #666
    }
    
    .normal-item i {
        font-size: 50px;
        line-height: 100px;
        margin-top: 50px;
    }
    
    .normal-item .channel-box-item-title {
        font-size: 18px;
        color: #000;
    }
    
    .normal-item .channel-box-item-info {
        font-size: 14px;
        width: 70%;
    }
    
    .normal-item .channel-box-item-img {
        width: 70%;
        height: 130px;
        margin-top: 50px;
    }
    
    .active-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #666
    }
    
    .actvie-item-nav {
        height: 70px;
        background-color: #1472ff;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
    }
    
    .actvie-item-nav-icon {
        line-height: 60px;
        margin-left: 40px;
        font-size: 50px;
        margin-right: 25px;
    }
    
    .channel-box-item-active {
        width: 400px;
        border: 1px solid #1472ff;
        transition: 0.1s ease-in-out;
        
    }
    
    .channel-box-item-info-active {
        text-align: left;
        font-size: 17px;
        margin-left: 40px;
    }
    
    .channel-box-item-img-active {
        width: 80%;
        height: 230px;
    }
    
    .channel-box-item-btn {
        width: 120px;
        height: 45px;
        background-color: #1472ff;
        border-radius: 45px;
        text-align: center;
        line-height: 45px;
        color: #fff;
        font-size: 18px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 40px;
    }
    
    .channel-box-item-btn:hover {
        background-color: #389fff;
        
    }
    
    .kefu_box {
        width: 180px;
        height: auto;
        position: fixed;
        right: 20px;
        top: 30%;
        animation: float 6s ease-in-out infinite;
    }
    
    .kefu_box img{
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    .close_box {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 28px;
        top: 10px;
        z-index: 9;
        cursor: pointer
    }
    
    .top_box {
        position: absolute;
        width: 35px;
        height: 35px;
        right: 78px;
        bottom: 5px;
        z-index: 9;
        cursor: pointer
    }
    
    @keyframes float {
      0%, 100% {
        transform: translateY(0); /* 初始位置 */
      }
      25%, 75% {
        transform: translateY(10px); /* 上移20像素 */
      }
      50% {
        transform: translateY(-10px); /* 下移20像素 */
      }
    }
    
    .join-box {
        height: 350px;
        display: flex;
        flex-direction: column;
    }
    
    .join-box img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        border-radius: 10px;
    }
    
    .join-box-title {
        height: 80px;
        font-size: 36px;
        line-height: 80px;
        color: #000;
    }
    
    .join-box-tel {
        font-size: 20px;
        color: #000;
    }
    
    .city-box {
        display: flex;
        width: 1300px;
        margin: 0 auto;
        margin-top: 30px;
    }
    
    .city-box-left {
        width: 65%;
        /* background-color: #f00; */
        height: 600px;
        /* border: 1px solid #ccc; */
    }
    
    .city-box-right {
        width: 35%;
        /* background-color: #00f; */
        height: 250px;
        
    }
    
    .city-member-box {
        width: 90%;
        background-color: #fff;
        border-radius: 15px;
        margin: 0 auto;
        height: 100%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-left: 20px;
    }
    .brand-box {
        background-color: #f6faff;
        margin-top: 50px;
        padding: 20px 0px;
    }
    
    .brand-box-item {
        height: auto;
        width: 1300px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
    }
    
    .slide2 {
        /* justify-content: right; */
    }
    
    .brand-box-item-img {
        width: calc(1300px /8 - 60px);
        height: 100px;
        padding: 40px;
        border-radius: 10px;
        background-color: #fff;
        margin-right: 50px;
        flex: 0 0 auto;
    }
    
    
</style>